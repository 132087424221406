import styled from "styled-components";
import Screen from "../../../consumercomponents/styledcomponents/ScreenSizes";


export default styled.textarea.attrs(props =>({
  id: props.id,
  value: props.val,
  onChange:props.change,
  className: props.cl
}))`
  width:${props => (props.width ? props.width : "506.3px")};
  height: ${props => (props.height ? props.height : "103px")};;
  border-radius: 6px; 
  border: solid 1px #cccccc;
  padding: 15px 0px 14px 20px;
  @media ${Screen.tablet} {
    width:${props => (props.m_width ? props.m_width : "100%")};
  height: ${props => (props.m_height ? props.m_height : "46p")};
  }
`;
