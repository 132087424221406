import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setTabAction, setSubMenuTabAction } from "../../../actions/tabAction";
import Down from "@material-ui/icons/KeyboardArrowDown";
import { Link } from "react-router-dom";
import SubListItem from "./SubListItem";

// styled component

import {FlexContainer} from "../../Home/subComponents/OverviewCard";

class ListItem extends React.Component {
  state = {
    open: false
  };

  handleClick = () => {
    this.props.setTabAction(this.props.name);
    if (!this.props.child) this.props.setSubMenuTabAction("");
    this.setState(prevState => ({
      open: !prevState.open
    }));
  };

  render() {
    const { classes, childNames, paths } = this.props;
    const { open } = this.state;

    const childs = childNames
      ? childNames.map((child, i) => {
          return <SubListItem key={i} name={child} path={paths[i]} parent="User Management" />;
        })
      : "";
    return (
      <div>
        {!this.props.childNames ? (
          <Link
            to={this.props.path ? this.props.path : "/"}
            style={{
              cursor: "pointer",
              display: "flex",
              // paddingLeft: "1rem",
              justifyContent: "space-between",
              textDecoration: "none",
              paddingRight: "1rem",
              fontWeight: this.props.tab === this.props.name && "500",
              marginBottom: "1.5rem",
              // fontFamily: "GothamBook",
              // fontWeight: "800",
              fontSize: "14px",
              color: this.props.tab === this.props.name ? "#4C10C1" : "#757575",
              // borderLeftColor:
              //   this.props.tab === this.props.name ? "#4C10C1" : "#FFFFFF",
              // borderLeftWidth: "5px",
              // borderLeftStyle: "solid"
            }}
            onClick={this.handleClick}
          >
            <FlexContainer alignItems={"center"} wrap={"no-wrap"}>
              {this.props.tab === this.props.name && <img
                src={require("../../../assets/images/selected.png")}
                alt="active"
                style={{ marginRight : "18px"}}
              />}
              <span style={{marginLeft : this.props.tab !== this.props.name && "23px"}}>{this.props.name}</span>
            {this.props.child && <i className="fas fa-chevron-down" />}
            </FlexContainer>
          </Link>
        ) : (
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              textDecoration: "none",
              marginBottom: "1.5rem",
              paddingRight: "1rem",
              // fontFamily: "GothamBook",
              // fontWeight: "800",
                fontSize: "14px",
                fontWeight: this.props.tab === this.props.name && "500",
              
              color: this.props.tab === this.props.name ? "#4C10C1" : "#757575",
             
            }}
            onClick={this.handleClick}
            >
              <FlexContainer alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
            <FlexContainer alignItems={"center"}>
              {this.props.tab === this.props.name && <img
                src={require("../../../assets/images/selected.png")}
                alt="active"
                style={{ marginRight : "18px"}}
              />}
              <span style={{marginLeft : this.props.tab !== this.props.name && "23px"}}>{this.props.name}</span>
            </FlexContainer>
              {this.props.child && <i className="fas fa-chevron-down" />}
              </FlexContainer>
          </div>
        )}
        {this.props.childNames && open && childs}
      </div>
    );
  }
}

ListItem.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  tab: state.selectedTab.sideMenuTab
});
export default connect(
  mapStateToProps,
  { setTabAction, setSubMenuTabAction }
)(ListItem);
