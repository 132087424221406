import React from "react";
import ReactDOM from "react-dom";
import Chart from "react-google-charts";



class App extends React.Component {
     render() {

          const data = [
               ["Task", "Hours per Day"],
               ["Work", this.props.data],
               ["Eat", 2],
               
             ];
          const options = {
               pieHole: 0.7,
                  is3D: false,
                  legend: 'none',
                  height: 150,
                  width: 150,
                  pieSliceText: "none",
                  slices: {
                       0: { color: this.props.color },
                       1: { color: '#F2F2F2' }
                  },
                  chartArea: {
                       width: "100%",
                       height:"100%"
                  }
             };
    return (
      <div className="App">
        <Chart
          chartType="PieChart"
          width="100%"
          height="150px"
          data={data}
          options={options}
        />
      </div>
    );
  }
}

export default App;