import React, { Component } from "react";
import Editor from "./EditorComponent";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import { EditorState, convertToRaw } from "draft-js";
import Button from "@material-ui/core/Button";
import { stateToHTML } from "draft-js-export-html";
import api from "../../../utils/api";
import { customBaseUrl, post } from "../../../config/apiUrl";
import { resetSnackbar, setSnackbar } from "../../../actions/snackbarAction";
import { connect } from "react-redux";
import TrueFalseQuestion from "./AddTrueFalseQuestion";
import MatchQuestion from "./AddMatchQuestion";
import EssayQuestion from "./AddEssayQuestion";
import Checkbox from "@material-ui/core/Checkbox";
import CustomEditor from "./MiceEditor";
import CustomButton from "../../../consumercomponents/styledcomponents/Button";

// styled components
import CT from "../../../consumercomponents/styledcomponents/CT";
import Label from "../../../consumercomponents/styledcomponents/Label";
import Input from "../../UserManagement/styledComponents/Input";

const styles = theme => ({
  input: {
    border: "1px solid #CCCCCC",
    borderRadius: "5px",
    paddingLeft: "12px"
  },
  root: {
    padding: 0
  },

  label: {
    marginBottom: "0.5rem"
  },
  section: {
    marginBottom: "1.5rem"
  },
  heading: {
    borderBottom: "1px solid black",
    color: "#484848",
    paddingBottom: "16px"
  },
  answerSection: {
    // backgroundColor: "#AAAAAA"
    marginBottom: "1.3rem",
    marginTop: "1.3rem",
    borderBottom: "1px solid black"
  },
  optionContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: "1rem",
    marginBottom: "1rem",
  },
  answerContainer: {
    display: "flex"
  }
});

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "300px",
    paddingLeft: "12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

class AddQuestion extends Component {
  state = {
    name: "",
    mark: "",
    answerNumber: "multichoice",
    age: "test",
    editorState: EditorState.createEmpty(),
    choice1: "",
    choice2: "",
    choice3: "",
    choice4: "",
    feedback1: "",
    feedback2: "",
    feedback3: "",
    feedback4: "",
    grade1: "none",
    grade2: "none",
    grade3: "none",
    grade4: "none",
    rightOption: 1,
    checkboxCounter: {
      rightOption0: false,
      rightOption1: false,
      rightOption2: false,
      rightOption3: false,
      optionCounter: 0
    },
    score: "",
    timeLimit: "",
    timeOpen: new Date(),
    timeClose: new Date(),
    trueFeedback: "",
    falseFeedback: "",
    truefalseOption: 1,
    defaultMark: "",
    essayEditor: "",
    miceEditorInitialState: "",
    matchingQuestion1: EditorState.createEmpty(),
    matchingQuestion1Answer: "",
    matchingQuestion2: EditorState.createEmpty(),
    matchingQuestion2Answer: "",
    matchingQuestion3: EditorState.createEmpty(),
    matchingQuestion3Answer: "",
    generalFeedback: ""
  };

  handleChange = event => {
    this.setState({ age: event.target.value });
  };

  handleChangeGeneralFeedback = e => {
    generalFeedback: e.target.getContent();
  };

  handleChangeMiceEditor = e => {
    this.setState({
      miceEditorInitialState: e.target.getContent()
    });
  };

  handleChangeEssayEditor = event => {
    this.setState({ essayEditor: event.target.value });
  };

  handleChangeChoice = (name, editorState) => {
    this.setState({ [name]: editorState });
  };

  miceEditorHandleChange = (name, e) => {
    this.setState({
      [name]: e.target.getContent()
    });
  };

  handleChangeName = (name, event) => {
    this.setState({ [name]: event.target.value });
  };
  handleChangeNameMiceEditor = (name, event) => {
    this.setState({ [name]: event.target.getContent() });
  };

  handleChangeNumber = event => {
    this.setState({ answerNumber: event.target.value });
  };

  handleChangeAnswer = event => {
    this.setState({ rightOption: event.target.value });
  };

  getHtml = state => {
    return stateToHTML(state.getCurrentContent());
  };

  handleDateOpenChange = date => {
    this.setState({
      timeOpen: date
    });
  };

  handleDateCloseChange = date => {
    this.setState({
      timeClose: date
    });
  };

  // runs on editor text change
  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };

  handleChangeTruefalseOption = e => {
    this.setState({
      truefalseOption: e.target.value
    });
  };

  AddQuestionApi = () => {
    const {
      editorState,
      choice1,
      choice2,
      choice3,
      choice4,
      feedback1,
      feedback2,
      feedback3,
      feedback4,
      answerNumber,
      name,
      rightOption,
      defaultMark,
      miceEditorInitialState,
      generalFeedback,
      checkboxCounter
    } = this.state;
    const { optionCounter } = checkboxCounter;

    const { qId, cId, cmId } = this.props.ids;

    const fractionValue = value => {
      if (optionCounter >= 2 && value) {
        return 0.5;
      } else if (optionCounter < 2 && value) {
        return "1.0";
      } else {
        return 0;
      }
    };

    //     const url = `${customBaseUrl}wsfunction=local_servifywebapis_add_question&courseid=${cId}&cmid=${cmId}&qtype=${answerNumber}&name=${encodeURIComponent(
    //       name
    //     )}&questiontext=${encodeURIComponent(
    //       miceEditorInitialState
    //     )}&option1=${encodeURIComponent(choice1Html)}&option2=${encodeURIComponent(
    //       choice2Html
    //     )}content&option3=${encodeURIComponent(
    //       choice3Html
    //     )}t&option4=${encodeURIComponent(
    //       choice4Html
    //     )}&correctoption=${rightOption}&defaultmark=${defaultMark}&shuffleanswers=1
    // `;

    const url = `${customBaseUrl}wsfunction=local_servifywebapis_add_question&courseid=${cId}&cmid=${cmId}&name=${encodeURIComponent(
      name
    )}&questiontext=${encodeURIComponent(
      miceEditorInitialState
    )}&defaultmark=${defaultMark}&generalfeedback=${encodeURIComponent(
      generalFeedback
    )}&correctfeedback=1&incorrectfeedback=Sorry, try again&partiallycorrectfeedback=good attempt&shuffleanswers=1&correctoption=${rightOption}&options[0][text]=${encodeURIComponent(
      choice1
    )}&options[0][format]=1&options[0][fraction]=${fractionValue(
      checkboxCounter.rightOption0
    )}&options[0][feedback]=${encodeURIComponent(
      feedback1
    )}&options[1][text]=${encodeURIComponent(
      choice2
    )}&options[1][format]=1&options[1][fraction]=${fractionValue(
      checkboxCounter.rightOption1
    )}&options[1][feedback]=${encodeURIComponent(
      feedback2
    )}&options[2][text]=${encodeURIComponent(
      choice3
    )}&options[2][format]=1&options[2][fraction]=${fractionValue(
      checkboxCounter.rightOption2
    )}&options[2][feedback]=${encodeURIComponent(
      feedback3
    )}&options[3][text]=${encodeURIComponent(
      choice4
    )}&options[3][format]=1&options[3][fraction]=${fractionValue(
      checkboxCounter.rightOption3
    )}&options[3][feedback]=${encodeURIComponent(
      feedback4
    )}&qtype=multichoice&single=${optionCounter < 2 ? 1 : 0}`;
    api(url, post, null, this.successAddQuestionApi);
  };

  successAddQuestionApi = res => {
    console.log("res", res);
    this.props.slectedTab(0);
    this.props.resetSnackbar();
    this.props.setSnackbar("Question Added Successfully");
  };

  AddTrueFalseQuestionApi = () => {
    const { cmId } = this.props.ids;
    const {
      editorState,
      answerNumber,
      name,
      truefalseOption,
      defaultMark,
      miceEditorInitialState,
      trueFeedback,
      falseFeedback
    } = this.state;
    const { qId, cId } = this.props.ids;

    const url = `${customBaseUrl}wsfunction=local_servifywebapis_add_truefalse_question&courseid=${cId}&cmid=${cmId}&qtype=truefalse&name=${encodeURIComponent(
      name
    )}&questiontext=${encodeURIComponent(
      miceEditorInitialState
    )}&correctoption=${truefalseOption}&defaultmark=${defaultMark}&feedbacktrue=${encodeURIComponent(
      trueFeedback
    )}&feedbackfalse=${encodeURIComponent(
      falseFeedback
    )}&generalfeedback=general`;

    api(url, post, null, this.successAddQuestionApi);
  };

  addEssayQuestionApi = () => {
    const {
      editorState,
      answerNumber,
      name,
      truefalseOption,
      defaultMark,
      miceEditorInitialState
    } = this.state;
    const { cId, cmId } = this.props.ids;
    const editorStateHtml = this.getHtml(editorState);
    const url = `${customBaseUrl}wsfunction=local_servifywebapis_add_essay_type_question&courseid=${cId}&cmid=${cmId}&name=${encodeURIComponent(
      name
    )}&questiontext=${encodeURIComponent(
      miceEditorInitialState
    )}&defaultmark=${defaultMark}
`;
    api(url, post, null, this.successAddQuestionApi);
  };

  handleChangeCheckboxAnswer = (name, index) => {
    const { checkboxCounter } = this.state;

    this.setState(prevState => {
      let counter = 0;

      Object.entries(checkboxCounter).forEach(([key, value]) => {
        if (value) {
          counter = counter + 1;
        }
      });

      console.log("counter", counter);
      console.log("counter", counter);
      if (counter <= 2 || prevState.checkboxCounter[name]) {
        return {
          ...prevState,
          rightOption: counter < 2 ? index : prevState.rightOption,
          checkboxCounter: {
            ...prevState.checkboxCounter,
            [name]: !prevState.checkboxCounter[name],
            optionCounter: prevState.checkboxCounter[name]
              ? counter - 1
              : counter + 1
          }
        };
      }
    });
  };

  render() {
    const { classes } = this.props;
    const {
      name,
      answerNumber,
      trueFeedback,
      falseFeedback,
      truefalseOption,
      defaultMark,
      checkboxCounter
    } = this.state;
    console.log(this.state.checkboxCounter);
    const multichoiceAnswer = (
      <div>
        <input
          name="image"
          type="file"
          id="upload"
          className="hidden"
          style={{ display: "none" }}
        />
        <h3 className={classes.heading}>Answers</h3>
        <div className={classes.answerSection}>
          <div className={classes.section}>
            {/* <div className={classes.label}>Choice 1 *</div> */}
            <CT>
              <Label>Choice 1 *</Label>
            </CT>
            <div>
              <CustomEditor
                state={this.state.choice1}
                handler={e => this.handleChangeNameMiceEditor("choice1", e)}
              />
            </div>
          </div>
          <div className={classes.section}>
            {/* <div className={classes.label}>Grade *</div> */}
            <div />
          </div>

          <div className={classes.section}>
            <CT>
              <Label>Feedback *</Label>
            </CT>
            <div>
              <CustomEditor
                state={this.state.feedback1}
                handler={e => this.handleChangeNameMiceEditor("feedback1", e)}
              />
            </div>
          </div>
        </div>

        <div className={classes.answerSection}>
          <div className={classes.section}>
            {/* <div className={classes.label}>Choice 2 *</div> */}
            <CT>
              <Label>Choice 2 *</Label>
            </CT>
            <div>
              <CustomEditor
                state={this.state.choice2}
                handler={e => this.handleChangeNameMiceEditor("choice2", e)}
              />
            </div>
          </div>

          <div className={classes.section}>
            <CT>
              <Label>Feedback *</Label>
            </CT>
            <div>
              <CustomEditor
                state={this.state.feedback2}
                handler={e => this.handleChangeNameMiceEditor("feedback2", e)}
              />
            </div>
          </div>
        </div>

        <div className={classes.answerSection}>
          <div className={classes.section}>
            <CT>
              <Label>Choice 3 *</Label>
            </CT>
            <div>
              <CustomEditor
                state={this.state.choice3}
                handler={e => this.handleChangeNameMiceEditor("choice3", e)}
              />
            </div>
          </div>

          <div className={classes.section}>
            <CT>
              <Label>Feedback *</Label>
            </CT>
            <div>
              <CustomEditor
                state={this.state.feedback3}
                handler={e => this.handleChangeNameMiceEditor("feedback3", e)}
              />
            </div>
          </div>
        </div>

        <div className={classes.answerSection}>
          <div className={classes.section}>
            {/* <div className={classes.label}>Choice 4 *</div> */}
            <CT>
              <Label>Choice 4 *</Label>
            </CT>
            <div>
              <CustomEditor
                state={this.state.choice4}
                handler={e => this.handleChangeNameMiceEditor("choice4", e)}
              />
            </div>
          </div>

          <div className={classes.section}>
            {/* <div className={classes.label}>Feedback *</div> */}
            <CT>
              <Label>Feedback *</Label>
            </CT>
            <div>
              {/* <Editor
                height={"40px"}
                editorState={this.state.feedback4}
                onEditorStateChange={this.handleChangeNameMiceEditor.bind(
                  this,
                  "feedback4"
                )}
              /> */}
              <CustomEditor
                state={this.state.feedback4}
                handler={e => this.handleChangeNameMiceEditor("feedback4", e)}
              />
            </div>
          </div>
        </div>
      </div>
    );

    const multipleTypeRightAnswer = (
      <div className={classes.section}>
        <div className={classes.label}>Right Option (max 2 allowed) *</div>

        <div>
          <FormControl className={classes.margin}>
            <NativeSelect
              value={this.state.rightOption}
              onChange={this.handleChangeAnswer}
              input={
                <BootstrapInput name="age" id="age-customized-native-simple" />
              }
            >
              <option value={0}>Choice 1</option>
              <option value={1}>Choice 2</option>
              <option value={2}>Choice 3</option>
              <option value={3}>Choice 4</option>
            </NativeSelect>
          </FormControl>
        </div>
      </div>
    );

    const multipleOptionRightAnswer = (
      <div className={classes.section}>
        {/* <div className={classes.label}>
          {checkboxCounter.optionCounter > 0
            ? "Right Option  (max 2 allowed) *"
            : "max option reached"}
        </div> */}
        <CT bottom={"1rem"}>
          <Label>Right Option (max 2 allowed) *</Label>
        </CT>
        <div className={classes.answerContainer}>
          <div>
            <div className={classes.optionContainer}>
              <div>
                <Checkbox
                  color="primary"
                  checked={checkboxCounter.rightOption0}
                  classes={{root : classes.root}}
                  onChange={() =>
                    this.handleChangeCheckboxAnswer("rightOption0", 0)
                  }
                />
              </div>
              <Label>choice 1</Label>
            </div>
            <div className={classes.optionContainer}>
              <div>
                <Checkbox
                  color="primary"
                  classes={{root : classes.root}}
                  checked={checkboxCounter.rightOption1}
                  onChange={() =>
                    this.handleChangeCheckboxAnswer("rightOption1", 1)
                  }
                />
              </div>
              <Label>choice 2</Label>
            </div>
          </div>
          <div>
            <div className={classes.optionContainer}>
              <div>
                <Checkbox
                  color="primary"
                  classes={{root : classes.root}}
                  checked={checkboxCounter.rightOption2}
                  onChange={() =>
                    this.handleChangeCheckboxAnswer("rightOption2", 2)
                  }
                />
              </div>
              <Label>choice 3</Label>
            </div>
            <div className={classes.optionContainer}>
              <div>
                <Checkbox
                  color="primary"
                  checked={checkboxCounter.rightOption3}
                  classes={{root : classes.root}}
                  onChange={() =>
                    this.handleChangeCheckboxAnswer("rightOption3", 3)
                  }
                />
              </div>
              <Label>choice 4</Label>
            </div>
          </div>
        </div>
      </div>
    );

    const truefalseTypeRightAnswer = (
      <CT width={"30%"} m_width={"100%"}>
        <div className={classes.section}>
          {/* <div className={classes.label}>Right Option *</div> */}
          <CT>
            <Label>Right Option *</Label>
          </CT>
          <div>
            {/* <FormControl className={classes.margin}>
            <NativeSelect
              value={truefalseOption}
              onChange={this.handleChangeTruefalseOption}
              input={
                <BootstrapInput name="age" id="age-customized-native-simple" />
              }
            >
              <option value={1} selected>
                true
              </option>
              <option value={0}>false</option>
            </NativeSelect>
          </FormControl> */}
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              defaultValue={truefalseOption}
              onChange={this.handleChangeTruefalseOption}
            >
              <option value={1} selected>
                true
              </option>
              <option value={0}>false</option>
            </select>
          </div>
        </div>
      </CT>
    );

    let answer;
    switch (answerNumber) {
      case "multichoice":
        answer = multipleOptionRightAnswer;
        break;
      case "truefalse":
        answer = truefalseTypeRightAnswer;
        break;

      default:
        break;
    }
    const buttonPress = () => {
      switch (answerNumber) {
        case "multichoice":
          this.AddQuestionApi();
          break;
        case "truefalse":
          this.AddTrueFalseQuestionApi();
          break;
        case "essay":
          this.addEssayQuestionApi();

        default:
          break;
      }
    };

    return (
      <div style={{ padding: "16px" }}>
        <div style={{ marginBottom: "2rem" }}>
          <h3 className={classes.heading}>General</h3>
          <div className={classes.section}>
            {/* <div className={classes.label}>Question name *</div> */}
            <CT>
              <Label>Question name </Label>
            </CT>
            <div>
              <InputBase
                className={classes.input}
                fullWidth
                value={name}
                onChange={this.handleChangeName.bind(this, "name")}
              />
            </div>
          </div>

          <div className={classes.section}>
            {/* <div className={classes.label}>Question text *</div> */}
            <CT>
              <Label>Question text *</Label>
            </CT>
            <div>
              {/* <Editor
                editorState={this.state.editorState}
                onEditorStateChange={this.onEditorStateChange}
              /> */}
              <CustomEditor
                state={this.state.miceEditorInitialState}
                handler={this.handleChangeMiceEditor}
              />
            </div>
          </div>

          <div className={classes.section}>
            {/* <div className={classes.label}>Feedback *</div> */}
            <CT>
              <Label>Feedback *</Label>
            </CT>
            <div>
              {/* <Editor
                editorState={this.state.editorState}
                onEditorStateChange={this.onEditorStateChange}
              /> */}
              <CustomEditor
                state={this.state.generalFeedback}
                handler={this.handleChangeGeneralFeedback}
              />
            </div>
          </div>

          {/* <div className={classes.section}>
            <div className={classes.label}>Add Score *</div>
            <div>
              <InputBase
                className={classes.input}
                fullWidth
                value={score}
                type="number"
                onChange={this.handleChangeName.bind(this, "score")}
              />
            </div>
          </div> */}

          {/* <div className={classes.section}>
            <div className={classes.label}>Expiry Time *</div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "60%"
              }}
            >
              <DatePicker
                label="Statrt Date"
                selectedDate={timeOpen}
                handleDateChange={this.handleDateOpenChange}
              />
              <DatePicker
                label="End Date"
                selectedDate={timeClose}
                handleDateChange={this.handleDateCloseChange}
              />
            </div>
          </div> */}

          {/* <div className={classes.section}>
            <div className={classes.label}>Time Limit *</div>
            <div>
              <InputBase
                className={classes.input}
                fullWidth
                value={timeLimit}
                type="number"
                onChange={this.handleChangeName.bind(this, "timeLimit")}
              />
            </div>
          </div> */}
          <div className={classes.section}>
            {/* <div className={classes.label}>Default Mark *</div> */}
            <CT>
              <Label>Default Mark *</Label>
            </CT>
            <div>
              <Input
                height={"35px"}
                val={defaultMark}
                change={this.handleChangeName.bind(this, "defaultMark")}
              />
              {/* <InputBase
                className={classes.input}
                value={defaultMark}
                onChange={this.handleChangeName.bind(this, "defaultMark")}
              /> */}
            </div>
          </div>

          <CT width={"30%"} m_width={"100%"}>
            {/* <div className={classes.label}>Question type *</div> */}
            <CT>
              <Label>Question type *</Label>
            </CT>
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              defaultValue={this.state.answerNumber}
              onChange={this.handleChangeNumber}
            >
              <option value={"multichoice"} selected>
                MCQ
              </option>
              <option value={"truefalse"}>True / False</option>
              <option value={"matching"}>Matching</option>
              <option value={"essay"}>Essay</option>
            </select>
          </CT>
          {/* <div className={classes.section}>
            <div className={classes.label}>Question type *</div>
            <div>
              <FormControl className={classes.margin}>
                <NativeSelect
                  value={this.state.answerNumber}
                  onChange={this.handleChangeNumber}
                  input={
                    <BootstrapInput
                      name="age"
                      id="age-customized-native-simple"
                    />
                  }
                >
                  <option value={"multichoice"} selected>
                    MCQ
                  </option>
                  <option value={"truefalse"}>True / False</option>
                  <option value={"matching"}>Matching</option>
                  <option value={"essay"}>Essay</option>
                </NativeSelect>
              </FormControl>
            </div>
          </div> */}
        </div>

        {answerNumber === "multichoice" && multichoiceAnswer}
        {answerNumber === "truefalse" && (
          <TrueFalseQuestion
            handleChangeChoice={this.handleChangeNameMiceEditor}
            trueFeedback={trueFeedback}
            falseFeedback={falseFeedback}
          />
        )}
        {answerNumber === "matching" && (
          <MatchQuestion
            ids={this.props.ids}
            name={this.state.name}
            qText={this.state.miceEditorInitialState}
            success={this.successAddQuestionApi}
            defaultMark={this.state.defaultMark}
          />
        )}
        {/* {answerNumber === "essay" && (
          <EssayQuestion
            editor={this.state.essayEditor}
            changeEditor={this.handleChangeEssayEditor}
          />
        )} */}
        {answer}

        {answerNumber !== "matching" && (
          <div>
            <CustomButton
              border="1px solid #4c10c1"
              onClick={buttonPress}
              m_width={"100%"}
            >
              {" "}
              Add
            </CustomButton>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  null,
  { setSnackbar, resetSnackbar }
)(withStyles(styles)(AddQuestion));
