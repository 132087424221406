import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";


// custom components
import Header from "../subComponents/Header";
import Body from "../Home/subComponents/Body";
import Heading from "../subComponents/Heading";
import AddGroupWrapper from "./styledComponents/AddGroupWrapper";
import AddGroupTab from "./subComponents/AddGroupTab";
import EditGroupTab from "./subComponents/EditGroupTab";
import { setTabAction,setSubMenuTabAction } from "../../actions/tabAction";


// styled components
import Container from "../../consumercomponents/styledcomponents/Container";
import CT from "../../consumercomponents/styledcomponents/CT";
import FlexContainer from "../../consumercomponents/styledcomponents/FlexContainer";
import PointerWrapper from "../../consumercomponents/styledcomponents/PointerWrapper";

// npm packages

//images
import back from "../../assets/icons/back.png";
class EditGroup extends Component {
  state = {
    path: null
  };

  componentDidMount() {
    this.props.setTabAction("User Management");
    this.props.setSubMenuTabAction("Groups");
  }

  redirect = path => this.setState({ path });
  render() {
    const styles = {
      default: {
        top: 0,
        right: 0,
        bottom: "42px",
        left: 0,
        pleft: "40px",
        m_padding: "0px"
      }
    };

    const { path } = this.state;
    const id = this.props.match.params.id

    return (
      <div>
        <Header />
        <Body>
          {/* <Heading text="Add Group" /> */}
          {path && <Redirect to={path} />}
          <CT left={"25px"} right={"40px"} top={"40px"}>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <Container bg="inherit" left="0px">
                  <FlexContainer m_justifyContent={"center"}>
                    <Heading text="User Management - Group" />
                  </FlexContainer>
                </Container>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <Container left="0px" m_margin="29px 0px 29px 0px" bg="inherit">
                  <FlexContainer
                    justifyContent="flex-end"
                    m_justifyContent="center"
                  >
                    <div onClick={() => this.redirect("/groupManagement")}>
                      <PointerWrapper>
                        <img src={back} alt="back" />
                      </PointerWrapper>
                    </div>
                  </FlexContainer>
                </Container>
              </div>
            </div>

            <AddGroupWrapper>
              <Container {...styles.default}>
                <FlexContainer m_justifyContent={"center"}>
                  <Heading text={"Edit Group"} />
                </FlexContainer>
              </Container>
              <section>
                <EditGroupTab id={id}/> 
              </section>
            </AddGroupWrapper>
          </CT>
        </Body>
      </div>
    );
  }
}


export default connect(
  null,
  { setTabAction,setSubMenuTabAction }
)(EditGroup);
