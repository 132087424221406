import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import QuestionDetails from "./QuestionDetails";
import api from "../../../utils/api";
import { post, customBaseUrl } from "../../../config/apiUrl";
import Loader from "../../subComponents/Loader";
import TabComponent from "./DemoTab";

import FC from "../../../consumercomponents/styledcomponents/FlexContainer";
import TEXT from "../../../consumercomponents/styledcomponents/Label";

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0,0,0,.125)",
    borderRadius: "0.5rem",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    }
  },
  expanded: {
    margin: "auto"
  }
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0,0,0,.03)",
    borderBottom: "1px solid rgba(0,0,0,.125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = "ExpansionPanelSummary";

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing.unit * 2
  }
}))(MuiExpansionPanelDetails);

class CustomizedExpansionPanel extends React.Component {
  state = {
    expanded: 0,
    data: [],
    deleteId: "",
    disabledEdit: true,
    initialLoading: false,
    showEmptyMessage: false,
    increase: 0
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  setLoading = (name, value) => {
    this.setState({
      initialLoading: value
    });
  };

  turnOnEdit = () => {
    this.setState({
      disabledEdit: false
    });
  };

  getQuestions = (isReload = false) => {
    if (isReload) {
      window.location.reload();
    }else{
      this.setState({ initialLoading: true });
    const { cmId } = this.props.ids;
    const url = `${customBaseUrl}wsfunction=local_servifywebapis_get_quiz_questions&moodlewsrestformat=json&quizcmid=${cmId}`;
    api(url, post, null, this.getQuesstionSuccess);
    }
  };

  getQuesstionSuccess = res => {
    this.setState({ initialLoading: false });
    this.setState(prevState => ({
      data: res.data,
      increase: prevState.increase++,
      showEmptyMessage: res.data.length > 0 ? false : true
    }));
  };

  deleteQuestion = id => {
    const { cId, qId } = this.props.ids;
    this.setState({ deleteId: id });
    const url = `${customBaseUrl}wsfunction=local_servifywebapis_delete_question&courseid=${cId}&questionid=${id}&quizid=${qId}&qtype=multichoice
`;
    api(url, post, null, this.deleteQuestionSuccess);
  };

  deleteQuestionSuccess = () => {
    this.getQuestions();
  };

  componentWillMount() {
    this.getQuestions();
  }

  render() {
    const { expanded, deleteId, disabledEdit } = this.state;
    const { cId } = this.props.ids;
console.log("---------------------------------------------------------------------------------------------------------------------")
    const question = (panel, details, i) => {
      return (
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-sm-12 col-md-11">
            <ExpansionPanel
              style={{ width: "100%" }}
              // square
              expanded={expanded === panel}
              onChange={this.handleChange(panel)}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  <TEXT>{details.questionname}</TEXT>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <QuestionDetails
                  details={details}
                  deleteQuestion={this.deleteQuestion}
                  deleteQuestionId={deleteId}
                  turnOnEdit={this.turnOnEdit}
                  cId={cId}
                  getQuestions={this.getQuestions}
                  ids={this.props.ids}
                  name={details.questionname}
                />

                {/* <div>
               
               </div> */}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <div className="col-sm-12 col-md-1">
            <FC m_justifyContent={"center"}>
              {details.questionid == this.state.deleteId ? (
                "Deleting..."
              ) : (
                <IconButton
                  aria-label="Delete"
                  onClick={() => this.deleteQuestion(details.questionid)}
                >
                  <DeleteIcon color="secondary" />
                </IconButton>
              )}
            </FC>
          </div>
        </div>
      );
    };

    const questionList = this.state.data.map((questionData, i) => {
      return question(i, questionData, i);
    });
    return (
      <div style={{ marginTop: "16px" }}>
        {this.state.initialLoading ? (
          <Loader />
        ) : this.state.showEmptyMessage ? (
          <p style={{ textAlign: "center" }}>No questions found.</p>
        ) : (
          questionList
        )}
      </div>
    );
  }
}

export default CustomizedExpansionPanel;
