
import styled from "styled-components";

export default styled.div`
  height: ${props => props.height};
  width: ${props => props.width};
     background:  url(${ props => props.src }) no-repeat center center;

  


  



  @media screen and (max-width: 800px) {
    background:  url(${ props => props.m_src }) no-repeat center center;
  }
`;
