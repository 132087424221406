import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import green from "@material-ui/core/colors/green";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import "../css/table.css";
import BoldText from "../../subComponents/BoldText";
import AddIcon from "@material-ui/icons/Add";
import NativeSelect from "@material-ui/core/NativeSelect";
import Button from "@material-ui/core/Button";
import api from "../../../utils/api";
import { post, customBaseUrl, get } from "../../../config/apiUrl";
import Loader from "../../subComponents/Loader";
import { resetSnackbar, setSnackbar } from "../../../actions/snackbarAction";
import { connect } from "react-redux";
//custom packages
import Container from "../../UserManagement/styledComponents/Container";
import FC from "../../../consumercomponents/styledcomponents/FlexContainer";
import CT from "../../../consumercomponents/styledcomponents/CT";
import CustomButton from "../../../consumercomponents/styledcomponents/Button";
import Input from "../../UserManagement/styledComponents/Input";
import TextArea from "../../UserManagement/styledComponents/TextArea";
import Label from "../../../consumercomponents/styledcomponents/Label";

// css
import "../css/QuestionDetails.css";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";
import { baseUrl } from "../../../config/apiUrl";

const Styles = theme => ({
  label: {
    marginBottom: "0.5rem",
    fontFamily: "GothamBook"
  },
  formControl: {
    width: "100%",
    marginBottom: "16px"
  },
  margin: {
    margin: theme.spacing.unit
  },
  customInput: {
    borderRadius: 4,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: "14px",
    padding: "8px 20px",
    // fontFamily: "GothamBook",
    fontWeight: "800",
    width: "300px"
  },
  root: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#757575"
  },
  checked: {},
  table: {
    border: "1px solid white"
  }
});

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "300px",
    padding: "12px 0px 12px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#cccccc",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

class QuestionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.findIndex = (id, array, questionType) => {
      let output = null;
      array.forEach((value, i) => {
        if (value.id === id) {
          output = questionType === "truefalse" ? value.id : i;
        }
      });
      return output;
    };
    this.state = {
      details: props.details,
      name: props.details.questionname,
      name2: props.name,
      answer: props.details.correctanswer,
      answers: props.details.answers,
      selectedValueAnswer: "b",
      maxMcqOption: 2,
      disabledEdit: true,
      option0: props.details.answers[0] ? props.details.answers[0].answer : "X",
      option1: props.details.answers[1] ? props.details.answers[1].answer : "X",
      option2: props.details.answers[2] ? props.details.answers[2].answer : "X",
      option3: props.details.answers[3] ? props.details.answers[3].answer : "X",
      matchOption0:
        props.details.answers[0] && props.details.answers[0].questiontext
          ? props.details.answers[0]
          : { questiontext: "", answertext: "" },
      matchOption1:
        props.details.answers[1] && props.details.answers[1].questiontext
          ? props.details.answers[1]
          : { questiontext: "", answertext: "" },
      matchOption2:
        props.details.answers[2] && props.details.answers[2].questiontext
          ? props.details.answers[2]
          : { questiontext: "", answertext: "" },
      matchOption3:
        props.details.answers[3] && props.details.answers[3].questiontext
          ? props.details.answers[3]
          : { questiontext: "", answertext: "" },
      rightAnswer: this.findIndex(
        props.details.correctanswer,
        props.details.answers,
        props.details.questiontype
      ),
      loading: false,
      score: props.details.defaultmark
        ? parseFloat(props.details.defaultmark)
        : null,
      questionType: props.details.questiontype
    };
  }

  handleChange = event => {
    this.setState({ age: event.target.value });
  };

  handleChangeScore = e => {
    this.setState({ score: e.target.value });
  };

  onChange = (name, e) => {
    console.log("text");
    this.setState({
      [name]: e.target.value
    });
    this.turnOnEdit();
  };

  onChangeMatchQuestion = (type = "", name, e) => {
    const value = e.target.value;
    if (type === "question") {
      this.setState(prevState => {
        return {
          [name]: {
            ...prevState[name],
            questiontext: value
          }
        };
      });
    } else {
      this.setState(prevState => {
        return {
          [name]: {
            ...prevState[name],
            answertext: value
          }
        };
      });
    }
  };

  handleChangeRadio = event => {
    this.setState({ selectedValue: event.target.value });
  };

  handleChangeAnswer = (event, i, type) => {
    if (type === "multichoice") {
      const { maxMcqOption } = this.state;
      let answers = this.state.answers.map(ans => {
        if (maxMcqOption === 2) ans.fraction = "0.0000000";
        return ans;
      });
      answers.forEach((answer, index) => {
        if (index === i && maxMcqOption > 0) {
          answer.fraction = "0.5000000";
          this.setState(prevState => ({
            maxMcqOption: prevState.maxMcqOption - 1
          }));
        }
      });

      this.setState({ answers });
      console.log("answers", answers);
      return;
    }
    this.setState({ answer: event.target.value, rightAnswer: i });
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.details !== prevState.details) {
  //     return {
  //       name: nextProps.name,
  //       name2: nextProps.name,
  //       score: nextProps.details.defaultmark
  //         ? parseFloat(nextProps.details.defaultmark)
  //         : null,
  //       answers: nextProps.details.answers
  //     }; // <- this is setState equivalent
  //   }
  // }

  turnOnEdit = () => {
    this.setState({
      disabledEdit: false
    });
  };

  editQuestion = () => {
    this.setState({ loading: true });
    const { questionid, quizid, questiontext } = this.props.details;
    const { cId } = this.props;
    const {
      name,
      option0,
      option1,
      option2,
      option3,
      answer,
      rightAnswer
    } = this.state;
    const url = `${customBaseUrl}wsfunction=local_servifywebapis_edit_question&courseid=${cId}&questionid=${questionid}&quizid=${quizid}&qtype=multichoice&name=${name} &questiontext=${encodeURIComponent(
      questiontext
    )}&option1=${encodeURIComponent(option0)}&option2=${encodeURIComponent(
      option1
    )}&option3=${encodeURIComponent(option2)}&option4=${encodeURIComponent(
      option3
    )}&correctoption=${rightAnswer}
`;
    api(url, post, null, this.editQuestionSuccess);
  };

  editMcqQuestionApi = () => {
    const { cmId } = this.props.ids;
    this.setState({ loading: true });
    const {
      questionid,
      questiontext,
      generalfeedback,
      answers
    } = this.props.details;
    const { cId } = this.props;
    const {
      name,
      option0,
      option1,
      option2,
      option3,
      rightAnswer,
      score,
      answers: stateAnswers
    } = this.state;
    let single = 0;
    stateAnswers.forEach(({ fraction }) => {
      if (fraction === "1.0000000") single = 1;
    });
    const url = `${customBaseUrl}wsfunction=local_servifywebapis_edit_question&courseid=${cId}&cmid=${cmId}&name=${name}&questiontext=${questiontext}&defaultmark=${score}&generalfeedback=${generalfeedback}&correctfeedback=1&incorrectfeedback=Sorry, try again&partiallycorrectfeedback=good attempt&shuffleanswers=1&correctoption=${rightAnswer}&options[0][text]=${encodeURIComponent(
      option0
    )}&options[0][format]=1&options[0][fraction]=${
      stateAnswers[0].fraction
    }&options[0][feedback]=${encodeURIComponent(
      answers[0].feedback
    )}&options[1][text]=${encodeURIComponent(
      option1
    )}&options[1][format]=1&options[1][fraction]=${
      stateAnswers[1].fraction
    }&options[1][feedback]=${encodeURIComponent(
      answers[1].feedback
    )}&options[2][text]=${encodeURIComponent(
      option2
    )}&options[2][format]=1&options[2][fraction]=${
      stateAnswers[2].fraction
    }&options[2][feedback]=${encodeURIComponent(
      answers[2].feedback
    )}&options[3][text]=${encodeURIComponent(
      option3
    )}&options[3][format]=1&options[3][fraction]=${
      stateAnswers[3].fraction
    }&options[3][feedback]=${encodeURIComponent(
      answers[3].feedback
    )}&qtype=multichoice&questionid=${questionid}&single=${single}`;

    console.log(url, stateAnswers);

    api(url, get, null, this.editQuestionSuccess);
  };

  editQuestionSuccess = res => {
    this.setState({ loading: false });
    this.props.getQuestions(true);
    this.props.resetSnackbar();
    this.props.setSnackbar("Changes are updated");
  };

  editTrueFalseQuestion = () => {
    this.setState({ loading: true });
    const { questionid, quizid, questiontext, answers } = this.props.details;
    const { cId } = this.props;
    const { name, rightAnswer, score } = this.state;
    const { cmId } = this.props.ids;

    const rightOption = rightAnswer === 0 ? 1 : 0;

    const url = `${customBaseUrl}wsfunction=local_servifywebapis_edit_truefalse_question&courseid=${cId}&questionid=${questionid}&cmid=${cmId}&qtype=truefalse&name=${name}&questiontext=${encodeURIComponent(
      questiontext
    )}&correctoption=${rightOption}&defaultmark=${score}&feedbacktrue=${
      answers[0].feedback
    }&feedbackfalse=${answers[1].feedback}&generalfeedback=general`;
    api(url, post, null, this.editQuestionSuccess);
  };

  setRightAnswer = (id, i) => {
    const { answer } = this.state;
    if (id === answer) {
      this.setState({
        rightAnswer: i
      });
    }
  };

  editMatchQuestionApi = () => {
    this.setState({ loading: true });

    const { questionid, questiontext } = this.props.details;
    const { cId } = this.props;
    const {
      name,
      matchOption0,
      matchOption1,
      matchOption2,
      score
    } = this.state;
    const { cmId } = this.props.ids;
    const url = `${customBaseUrl}wsfunction=local_servifywebapis_edit_matching_type_question&courseid=${cId}&cmid=${cmId}&name=${name}&questiontext=${encodeURIComponent(
      questiontext
    )}&defaultmark=${score}&options[0][question]=${
      matchOption0.questiontext
    }&options[0][answer]=${matchOption0.answertext}&options[1][question]=${
      matchOption1.questiontext
    }&options[1][answer]=${matchOption1.answertext}&options[2][question]=${
      matchOption2.questiontext
    }&options[2][answer]=${
      matchOption2.answertext
    }&shuffleanswers=1&questionid=${questionid}`;

    api(url, post, null, this.editQuestionSuccess);
  };

  editEssayQuestion = () => {
    const { cId, cmId } = this.props.ids;
    const { questionid, questiontext } = this.props.details;
    const { name, score } = this.state;
    this.setState({ loading: true });

    const url = `${customBaseUrl}wsfunction=local_servifywebapis_edit_essay_type_question&courseid=${cId}&cmid=${cmId}&name=${name}&questiontext=${encodeURIComponent(
      questiontext
    )}&defaultmark=${score}&questionid=${questionid}`;
    api(url, post, null, this.editQuestionSuccess);
  };

  render() {
    const { classes, details } = this.props;
    const { name, answers, disabledEdit, questionType, score } = this.state;
    console.log("props details", this.props.details);
    console.log("counter", this.state.maxMcqOption);
    const onPress = () => {
      switch (questionType) {
        case "multichoice":
          this.editMcqQuestionApi();
          break;
        case "truefalse":
          this.editTrueFalseQuestion();
          break;

        case "match":
          this.editMatchQuestionApi();
          break;

        case "essay":
          this.editEssayQuestion();
          break;

        default:
          break;
      }
    };

    let answerList = "";

    if (questionType !== "match") {
      answerList = answers.map((answerInfo, i) => {
        // this.setRightAnswer(answerInfo.id, i);
        let option = `option${i}`;
        console.log("info", answerInfo);
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              border: "1px solid #CCCCCC",
              borderRadius: "10px"
            }}
            key={i}
          >
            <div id="test" style={{ display: "none" }}>
              {ReactHtmlParser(answerInfo.answer)}
            </div>
            <div
              contenteditable="true"
              onChange={() => this.turnOnEdit()}
              id={answerInfo.id}
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "16px",
                borderRight: "1px solid #CCCCCC",
                width: "100%",
                flex: 2,
                contentEditable: "true",
                color: "red"
              }}
            >
              <InputBase
                value={this.state[option]
                  .replace(/<\/?[^>]+(>|$)/g, "")
                  .replace(/&nbsp;/g, " ")
                  .trim()}
                style={{ width: "100%" }}
                inputProps={{
                  className: classes.root
                }}
                classes={{ root: "imputtext" }}
                multiline={true}
                onChange={e => this.onChange(`option${i}`, e)}
                disabled={questionType === "truefalse" && true}
              />
              {/* {ReactHtmlParser(answerInfo.answer)} */}
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              {questionType === "multichoice" ? (
                <Radio
                  checked={
                    answerInfo.fraction == "0.5000000" ||
                    answerInfo.fraction == "1.0000000"
                  }
                  onChange={e => this.handleChangeAnswer(e, i, questionType)}
                  color="primary"
                  value={answerInfo.id}
                  name="radio-button-demo"
                  aria-label="A"
                />
              ) : (
                <Radio
                  checked={answerInfo.id == this.state.answer}
                  onChange={e => this.handleChangeAnswer(e, i)}
                  color="primary"
                  value={answerInfo.id}
                  name="radio-button-demo"
                  aria-label="A"
                />
              )}
            </div>
          </div>
        );
      });
    } else {
      answerList = answers.map((answerInfo, i) => {
        let option = `matchOption${i}`;
        console.log("info", answerInfo);
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              border: "1px solid #CCCCCC",
              borderRadius: "10px"
            }}
            key={i}
          >
            <div id="test" style={{ display: "none" }}>
              {ReactHtmlParser(answerInfo.answer)}
            </div>
            <div
              contenteditable="true"
              onChange={() => this.turnOnEdit()}
              id={answerInfo.id}
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "16px",
                borderRight: "1px solid #CCCCCC",
                flex: 1,
                contentEditable: "true",
                flexWrap: "wrap"
              }}
            >
              <InputBase
                value={this.state[option].questiontext.replace(
                  /<\/?[^>]+(>|$)/g,
                  ""
                )}
                classes={{ root: "imputtext" }}
                onChange={e =>
                  this.onChangeMatchQuestion("question", option, e)
                }
                disabled={questionType === "truefalse" && true}
              />
              {/* {ReactHtmlParser(answerInfo.answer)} */}
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              <InputBase
                classes={{ root: "imputtext" }}
                value={this.state[option].answertext.replace(
                  /<\/?[^>]+(>|$)/g,
                  ""
                )}
                onChange={e => this.onChangeMatchQuestion("answer", option, e)}
                disabled={questionType === "truefalse" && true}
              />
            </div>
          </div>
        );
      });
    }

    return (
      <CT width={"100%"}>
        <CT width={"75%"} m_width={"100%"}>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div>
                {/* <FormControl className={classes.margin}>
                  <span className={classes.label}>Quiz Name *</span>
                  <InputBase
                    className={classes.customInput}
                    value={name}
                    onChange={e => this.onChange("name", e)}
                  />
                </FormControl> */}

                <Container margin="0px 0px 10px 0px">
                  <Label weight={"bold"} for="role">
                    Quiz Name *
                  </Label>
                </Container>
                {/* <Input id="role" width="100%" val={name} change={e => this.onChange("name", e)} /> */}
                <TextArea
                  cl={"imputtext"}
                  val={this.state.name}
                  height={"46px"}
                  width="100%"
                  change={e => this.onChange("name", e)}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div>
                {/* <FormControl className={classes.margin}>
                  <span className={classes.label}>Add Score *</span>
                  <InputBase
                    className={classes.customInput}
                    value={score}
                    onChange={this.handleChangeScore}
                  />
                </FormControl> */}
                <Container margin="0px 0px 10px 0px">
                  <Label for="role" weight={"bold"}>
                    Add Score *
                  </Label>
                </Container>
                <Input
                  id="role"
                  width="100%"
                  val={score}
                  cl={"imputtext"}
                  change={this.handleChangeScore}
                />
              </div>
            </div>
          </div>
        </CT>

        <CT width={"75%"} m_width={"100%"} top={"1rem"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            {this.state.questionType !== "essay" && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  marginBottom: "16px"
                }}
              >
                <div style={{ display: "flex", flex: 3 }}>
                  <Label weight={"bold"}>Options</Label>
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end"
                  }}
                >
                  <Label weight={"bold"}>Correct answer</Label>
                </div>
              </div>
            )}

            {answerList}
          </div>
        </CT>

        <CT top={"1rem"}>
          {this.state.loading ? (
            <Loader />
          ) : (
            <CustomButton
              border="1px solid #4c10c1"
              onClick={onPress}
              m_width={"100%"}
            >
              {" "}
              Save Changes
            </CustomButton>
          )}
        </CT>
      </CT>
    );
  }
}

export default connect(
  null,
  { setSnackbar, resetSnackbar }
)(withStyles(Styles)(QuestionDetails));
