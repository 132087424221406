export const SET_COURSE_CATEGORY = 'SET_COURSE_CATEGORY'
export const SET_COURSE_DETAILS = 'SET_COURSE_DETAILS'


export const setCourseCategory = (payload) => {
  console.log("payload",payload)
  return {
    type: SET_COURSE_CATEGORY,
    payload
  }
}


export const setCourseDeatils = (payload) => {
  console.log("payload",payload)
  return {
    type: SET_COURSE_DETAILS,
    payload
  };
}
