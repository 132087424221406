import styled from "styled-components";

export default styled.span`
  height: 17px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  text-align: left;
  color: #757575;
`;
