import axios from "axios";

export default function api
(
    url,
    method = "get",
    data,
    callback = function(result)
    {
        console.log(result);
    },
    errorCallback = function(error)
    {
        console.log(error);
    }
) {

  console.log("url",url)
  console.log("method",method)
  console.log("data",data)
  axios[method](url, data) 
    .then(res => {
      callback(res);
    })
    .catch(error => {
      errorCallback(error);
    });
}
