import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import Chip from "./Chip";
import Button from "@material-ui/core/Button";
import QustionList from "./Questions";
import api from "../../../utils/api";
import { post, customBaseUrl, baseUrl } from "../../../config/apiUrl";
import { Redirect } from "react-router-dom";
import NativeSelect from "@material-ui/core/NativeSelect";
import { connect } from "react-redux";
import Loader from "../../subComponents/Loader";

// styled components
import CustomButton from "../../../consumercomponents/styledcomponents/Button";
import CT from "../../../consumercomponents/styledcomponents/CT";
import Label from "../../../consumercomponents/styledcomponents/Label";

const Styles = theme => ({
  label: {
    marginBottom: "0.5rem"
  },
  formControl: {
    width: "100%",
    marginBottom: "16px"
  },
  margin: {
    // margin: theme.spacing.unit,
    width: "auto"
  },
  input: {
    border: "1px solid #CCCCCC",
    borderRadius: "5px",
    paddingLeft: "12px",
    width: "100%"
    // marginTop: "16px"
  },
  customInput: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    // padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
});

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

class FindDetailsTab extends React.Component {
  state = {
    age: "test",
    name: "",
    nameError: false,
    path: null,
    selectedCourseId: "",
    topics: [],
    selectedTopicId: "",
    topicIdFromApi: "",
    topicNameFromApi: "",
    intro: "",
    content: "",
    timeopen: "",
    timeclose: "",
    timelimit: "",
    loading: false
  };

  handleChange = event => {
    this.setState({ selectedCourseId: event.target.value });
    this.getTopics(event.target.value);
  };

  handleChangeTopic = event => {
    this.setState({ selectedTopicId: event.target.value });
  };

  onChange = (name, event) => {
    this.setState({ [name]: event.target.value });
  };

  getTopics = selectedCourseId => {
    this.setState({
      getTopicsLoading: true
    });
    const { cId } = this.props.ids;
    const url = `${baseUrl}wsfunction=core_course_get_contents&moodlewsrestformat=json&courseid=${cId}`;
    api(url, post, null, this.successTopic);
  };

  successTopic = res => {
    this.setState({
      topics: res.data
    });
    const { cmId } = this.props.ids;
    console.log("topic ids", res.data);
    console.log("match id", cmId);
    res.data.forEach(tpc => {
      tpc.modules.forEach(md => {
        if (md.id == cmId) {
          console.log("api", tpc.id);
          this.setState({
            topicIdFromApi: tpc.id,
            selectedTopicId: tpc.id,
            topicNameFromApi: tpc.name
          });
        }
      });
    });
  };

  addQuiz = () => {
    const {
      name,
      intro,
      content,
      selectedCourseId,
      selectedTopicId,
      timeopen,
      timeclose,
      timelimit,
      topicIdFromApi
    } = this.state;
    const { cmId } = this.props.ids;
    if (!name) {
      return this.setState({ nameError: true });
    } else {
      this.setState({ nameError: false, loading: true });
    }

    const url = `${customBaseUrl}wsfunction=local_servifywebapis_update_quiz&moodlewsrestformat=json&courseid=${selectedCourseId}&topicid=${selectedTopicId}&name=${name}&visible=1&content=${content}&intro=${intro}&cmid=${cmId}&timeopen=${timeopen}&timeclose=${timeclose}&timelimit=${timelimit}&preferredbehaviour=deferredfeedback&rightanswerimmediately=1&marksimmediately=1`;

    // const url = `${customBaseUrl}wsfunction=local_servifywebapis_create_quiz&moodlewsrestformat=json&courseid=${selectedCourseId}&topicid=${selectedTopicId}&name=${name}&visible=1&content=${content}&intro=${intro}`;
    api(url, post, null, this.successAddQuiz);
  };

  successAddQuiz = res => {
    console.log(res);
    this.setState({ path: "/quizManagement", loading: true });
  };

  setInputValue = value => {
    this.setState({ name: value });
  };

  componentWillMount() {
    this.getTopics();
    const { quizes } = this.props;
    const { qId, cmId } = this.props.ids;
    console.log("quizzes", quizes);
    quizes.forEach(
      ({
        id,
        coursemodule,
        name,
        intro,
        course,
        timeclose,
        timeopen,
        timelimit
      }) => {
        if (id == qId && cmId == coursemodule) {
          console.log("course", course);
          this.setState({
            name,
            intro,
            selectedCourseId: course,
            timeclose,
            timeopen,
            timelimit
          });
        }
      }
    );
  }

  render() {
    const { classes, courses } = this.props;
    const {
      nameError,
      path,
      topics,
      name,
      intro,
      topicIdFromApi,
      topicNameFromApi
    } = this.state;
    console.log("courses", courses);
    const { qId, cmId } = this.props.ids;
    const coursesList = courses.map((crs, i) => {
      return (
        <option value={crs.id} key={i}>
          {crs.fullname}
        </option>
      );
    });

    const topicList = topics.map((tps, i) => {
      return (
        <option value={tps.id} key={i}>
          {tps.name}
        </option>
      );
    });
    return (
      <div style={{ marginTop: "2rem" }}>
        {path && <Redirect to={path} />}

        <div
          style={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              marginTop: "16px"
            }}
          >
            {/* <div
              className={classes.label}
              style={{ color: nameError ? "red" : "default" }}
            >
              {nameError ? "error" : "Quiz Name *"}
            </div> */}
            <CT>
              <Label error={nameError}>
                {nameError ? "error" : "Quiz Name *"}
              </Label>
            </CT>
            <FormControl className={classes.margin}>
              <InputBase
                value={name}
                className={classes.customInput}
                onChange={e => this.setInputValue(e.target.value)}
                style={{
                  borderColor: nameError ? "red" : "default",
                  paddingLeft: "16px"
                }}
              />
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              marginTop: "16px"
            }}
          >
            {/* <div
              className={classes.label}
              style={{ color: nameError ? "red" : "default" }}
            >
              {nameError ? "error" : "Course Name *"}
            </div> */}
            {/* <FormControl className={classes.margin}>
              <NativeSelect
                value={this.state.selectedCourseId}
                onChange={this.handleChange}
                input={
                  <BootstrapInput
                    name="age"
                    id="age-customized-native-simple"
                  />
                }
              >
                <option value="" />
                {coursesList}
              </NativeSelect>
            </FormControl> */}
            {/* <select
              className="form-control"
              id="exampleFormControlSelect1"
              value={this.state.selectedCourseId}
              onChange={this.handleChange}
            >
              {coursesList}
            </select> */}
          </div>
          {/* <div style={{ display: "flex", flex: 1, flexDirection: "column",marginTop : "16px" }}> */}
          {/* <div
              className={classes.label}
              style={{ color: nameError ? "red" : "default" }}
            >
              {"Topic Name *"}
            </div> */}
          {/* <FormControl className={classes.margin}>
              <NativeSelect
                value={this.state.selectedTopicId}
                onChange={this.handleChangeTopic}
                input={
                  <BootstrapInput
                    name="age"
                    id="age-customized-native-simple"
                  />
                } 
              >
                <option value={topicIdFromApi}>{topicNameFromApi}</option>
                {topicList}
              </NativeSelect>
            </FormControl> */}
          {/* <select
              className="form-control"
              id="exampleFormControlSelect1"
              value={this.state.selectedTopicId}
              onChange={this.handleChangeTopic}
            >
              {topicList}
            </select> */}
          {/* </div> */}
        </div>

        <div>
          <div style={{ marginTop: "16px", marginBottom: "16px" }}>
            {/* <div
              className={classes.label}
              style={{ color: nameError ? "red" : "default" }}
            >
              {"Quiz Intro *"}
            </div> */}
            <CT>
              <Label>{"Quiz Intro *"}</Label>
            </CT>
            <InputBase
              value={intro}
              className={classes.input}
              fullwidth
              onChange={e => this.onChange("intro", e)}
            />
          </div>

          {/* <div style={{ marginBottom: "16px" }}>
            <div
              className={classes.label}
              style={{ color: nameError ? "red" : "default" }}
            >
              {"Quiz Content *"}
            </div>
            <textarea
              className={classes.input}
              style={{ height: "200px" }}
              onChange={e => this.onChange("content", e)}
            />
          </div> */}
          <div>
            {this.state.loading ? (
              <Loader />
            ) : (
              <CustomButton
                border="1px solid #4c10c1"
                onClick={this.addCourse}
                m_width={"100%"}
                onClick={this.addQuiz}
              >
                Apply
              </CustomButton>
            )}
          </div>
        </div>

        {/* <QustionList /> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  courses: state.course.courses,
  quizes: state.quiz.quizes
});

export default connect(
  mapStateToProps,
  null
)(withStyles(Styles)(FindDetailsTab));
