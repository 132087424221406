import styled from "styled-components";


export default styled.div`
height : 30px;
width : 30px;
border-radius : 50%;
font-size : 12px;
display : flex;
justify-content :center;
align-items : center;
cursor: pointer;
background-color : ${props => props.selected ? "#4C10C1" : "#ffffff"};
border : ${props => props.selected ? "1px solid #4C10C1" : "1px solid #000000"};
color : ${props => props.selected ? "white" : "black"};

&:hover {
     background : #EEEEEE;
}
`