import styled from "styled-components";
import screen from "../../../consumercomponents/styledcomponents/ScreenSizes"


export default styled.button.attrs({
  type: props => props => props.type
})`
  height: ${props => (props.height ? props.height : "46px")};
  width: ${props => (props.width ? props.width : "160px")};
  object-fit: contain;
  background-color: ${props => (props.bg ? props.bg : "#4c10c1")};
  color: ${props => (props.color ? props.color : "#ffffff")};
  border: ${props => (props.border ? props.border : "none")};
  border-radius: 5px;
  outline : none;

  @media ${screen.tablet} {
   width: ${props => props.m_width && props.m_width}
  }
`;