import { SET_EDITOR_COURSE_ID } from "../../actions/editorAction";

const initialState = {
 coursIds : []
};

const editorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EDITOR_COURSE_ID:
      return {
        coursIds: [...state.coursIds , action.payload]
      };
    default:
      return state;
  }
};

export default editorReducer;
