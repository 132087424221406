import { SET_CONSUMER_COURSE_DETAILS } from '../../actions/consumer/courseAction'

const initialState = {
    courses: [],
}

const courseReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONSUMER_COURSE_DETAILS:
            return {
                ...state,
                courses: action.payload,
            }
        default:
            return state
    }
}

export default courseReducer
