import { SET_COURSE_DETAILS } from "../../actions/courseAction";


const initialState = {
  courses: []
};

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSE_DETAILS:
      return {
        courses: action.payload
      };
    default:
      return state;
  }
};

export default courseReducer;
