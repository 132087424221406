import styled from "styled-components";

export default styled.div`
  padding: 22px 40px 22px 40px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);

  @media screen and (max-width: 800px) {
    padding: ${props => props.m_padding};
  }
`;

export const TEXT = styled.span`
  height: 14px;
  font-family: system-ui;
  font-size: ${props => (props.size ? props.size : "12px")};
  font-weight: ${props => (props.weight ? props.weight : "400")};
  text-align: left;
  color: #757575;
`;

export const BOLD = styled.span`
  height: 14px;
  font-family: system-ui;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #757575;
`;

export const POINTER = styled.div`
  height: 10px;
  width: 20px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
  background-color: #fff;
  margin: ${props => props.margin};

  @media screen and (max-width: 800px) {
    margin: ${props => props.m_margin};
  }
`;

export const LINK = styled.div`
  height: 14px;
  font-family: system-ui;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.08;
  text-align: center;
  color: #4c10c1;
`;

export const WRAPPER = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
  width: ${props => props.width};

  @media screen and (max-width: 800px) {
    width: ${props => props.m_width};
    margin: ${props => props.m_margin};
  }
`;
