// import React, { Component } from "react";

// // custom packages
// import Wrapper from "../subcomponents/Wrapper";
// import Heading from "../../components/subComponents/Heading";
// import Search from "../../components/subComponents/Search";
// import Container from "../styledcomponents/Container";
// import PointerWrapper from "../styledcomponents/PointerWrapper"
// import FlexContainer from "../styledcomponents/FlexContainer"
// import EpisodeName from "../styledcomponents/EpisodeName"
// import Button from "../styledcomponents/Button"
// import {setConTabAction} from "../../actions/tabAction";
// import Card from "../subcomponents/CurrentCard";

// // npm packages
// import Paper from "@material-ui/core/Paper";
// import { connect } from "react-redux";

// //images
// import speaker from '../../assets/icons/Symbol 638.png';

// class Dashboard extends Component {
//   render() {
//     return (
//         <Wrapper>
//             <div className="row">
//                 <div className="col-sm-12 col-md-8 col-lg-8">
//                     <Heading text="News & Announcements" />
//                 </div>
//                 <div className="col-sm-12 col-md-4 col-lg-4">
//                     <Heading text="Recent Activity" />
//                 </div>
//             </div>

//             <div className="col-sm-12 col-md-8 col-lg-8">
//                 <Container top="40px">
//                     <Paper >
//                         <div className="row">
//                             <div className="col-sm-12 col-md-1 col-lg-1">
//                                 <FlexContainer>
//                                     <span style={{ paddingLeft:'15.7px', paddingTop:'14px',paddingBottom:'14.8px' }}>
//                                         <img src={speaker}/>
//                                     </span>
//                                 </FlexContainer>
//                             </div>
//                             <div className="col-sm-12 col-md-11 col-lg-11">
//                                 <FlexContainer justifyContent="flex-end" m_justifyContent="flex-start">
//                                     <PointerWrapper >
//                                         <FlexContainer alignItems="center">
//                                         <span style={{ color: "#757575",  paddingLeft:'15.7px', paddingTop:'25px',paddingBottom:'25px',fontSize:'16px' }} >
//                                         Congratulations on Completing your Monthly Learning Target. Keep up the Good Work !
//                                         </span>
//                                         </FlexContainer>
//                                     </PointerWrapper>
//                                 </FlexContainer>
//                             </div>
//                         </div>
//                     </Paper>
//                 </Container>
//                 <FlexContainer justifyContent="space-between">
//                   <Card />
//                   <Card />
//                 </FlexContainer>
//             </div>
//         </Wrapper>
//     )}
// }

// export default Dashboard;

import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";

// styled components
import FW from "../styledcomponents/FlexWrapper";
import FC from "../styledcomponents/FlexContainer";
import DIV from "../styledcomponents/DIV";

// custom packages
import Heading from "../../components/subComponents/Heading";
import Wrapper from "../subcomponents/Wrapper";
import Card from "./subcomponents/Card";
import CourseCard from "./subcomponents/CourseCard";
import BadgeCard from "./subcomponents/BadgeCard";
import LeadershipCard from "./subcomponents/LeadershipCard";
import OverviewCard from "./subcomponents/OverViewCard";
import Activity from "./subcomponents/ActivityWrapper";
import TaskCard from "./subcomponents/TaskCard";
import Calander from "./subcomponents/calander/Calendar";

// images
import speaker from "../../assets/icons/Symbol 638.png";
import ArrowRight from "../../assets/icons/Symbol639.png";

// css
import "./css/dashboard.css";

// custom styled components
const LINK = styled.div`
  font-family: system-ui;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.08;
  text-align: left;
  color: #4c10c1;
  margin-right: 9px;
`;

const styles = {
  fw1: {
    flex: "75%",
    right: "18px",
    order: 2,
    m_padding: "0px"
  },
  fw2: {
    flex: "25%",
    left: "18px",
    order: 1,
    m_padding: "0px"
  },
  DIV: {
    mtop: "22px",
    bg: "white",
    padding: "15px"
  },
  DIV2: {
    mtop: "38px"
  },
  card1: {
    flex: "50%",
    right: "18px",
    m_padding: "0px"
  },
  card2: {
    flex: "50%",
    left: "18px",
    m_padding: "0px"
  },
  padding: {
    pleft: "10px",
    pright: "10px",
    ptop: "10px",
    pbottom: "10px",
    bg: "white",
    mtop: "24px"
  },
  linkWrapper: {
    justifyContent: "center",
    width: "100%"
  }
};

class Dashboard extends Component {
  state = {
    imageIndex: 1
  };
  onSelect = (date, previousDate, currentMonth) => {
    if (moment(date).isSame(previousDate)) {
      console.info("onSelect: false", date);
      return false;
    } else if (currentMonth.isSame(date, "month")) {
      console.info("onSelect: true", date);
      return true;
    } else {
      console.info("onSelect: none", date);
    }
  };

  changeImage = (action, length) => {
    if (action) {
      this.setState(prevState => {
        var index = prevState.imageIndex + 1;
        if (prevState.imageIndex + 1 == length) {
          index = 0;
        }
        return {
          imageIndex: index
          // index
        };
      });
    } else {
      this.setState(prevState => {
        let index = prevState.imageIndex - 1;
        if (prevState.imageIndex - 1 < 0) {
          index = length - 1;
        }
        return {
          imageIndex: index
        };
      });
    }
  };
  render() {
    const { linkWrapper } = styles;
    const QuizDetails = {
      ["Quiz Name"]: "Adroid os upgrade \n -ADU5000",
      ["Category"]: "Refrigerators"
    };
    const CourseDetails = {
      ["Course Name"]: "Install parts in the Television -TVOU500",
      ["Category"]: "Trlrvision"
    };

    return (
      <Wrapper>
        <div>
          <DIV>
            <FC alignItems={"stretch"}>
              <FW {...styles.fw1}>
                <FC m_justifyContent={"center"}  m_margin={"22px 0 0 0"}>
                  <Heading text="News & Announcements" />
                </FC>

                <DIV {...styles.DIV}>
                  <FC m_direction={"column"} alignItems={"center"} m_alignItems={"center"}>
                    <DIV mright={"15.7px"} m_width={"100%"}>
                      <FC width={"100%"} m_justifyContent={"center"}>
                        <img src={speaker} />
                      </FC>
                    </DIV>
                    <DIV color={"#757575"}>
                      Congratulations on Completing your Monthly Learning
                      Target. Keep up the Good Work !
                    </DIV>
                  </FC>
                </DIV>
                <DIV {...styles.DIV2}>
                  <FC alignItems="stretch">
                    <FW {...styles.card1}>
                      <Card
                        header={"Current Quiz"}
                        details={QuizDetails}
                        total={"Questions"}
                        value={10}
                      />
                    </FW>
                    <FW {...styles.card2}>
                      <CourseCard
                        header={"Current Course"}
                        details={CourseDetails}
                        total={"Lessions"}
                        value={10}
                      />
                    </FW>
                  </FC>
                </DIV>
              </FW>
              <FW {...styles.fw2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%"
                  }}
                >
                  <FC m_justifyContent={"center"} m_margin={"22px 0 0 0"}>
                    <Heading text="Recent Activity" />
                  </FC>
                  <DIV
                    pleft={"30px"}
                    ptop={"35px"}
                    pright={"25px"}
                    pbottom={"40px"}
                    mtop={"22px"}
                    height={"100%"}
                    bg={"white"}
                    m_padding={"35px 15px 40px 15px"}
                  >
                    <Activity />
                  </DIV>
                  <DIV bg={"white"}>
                    <DIV mbottom={"30px"}>
                      <FC {...linkWrapper}>
                        <LINK>View All Activity</LINK>
                        <img src={ArrowRight} alt="right arrow" />
                      </FC>
                    </DIV>
                  </DIV>
                </div>
              </FW>
            </FC>
          </DIV>
          <DIV>
            <FC alignItems={"stretch"} wrap={"no-wrap"} m_direction={"column"}>
              <FW {...styles.fw1}>
                <DIV {...styles.DIV2}>
                  <FC alignItems="stretch" m_direction={"column"}>
                    <FW {...styles.card1}>
                      <BadgeCard
                        changeImage={this.changeImage}
                        index={this.state.imageIndex}
                      />
                    </FW>
                    <FW {...styles.card2}>
                      <LeadershipCard />
                    </FW>
                  </FC>

                  <DIV mtop={"38px"}>
                    <OverviewCard />
                  </DIV>
                </DIV>
              </FW>
              <FW {...styles.fw2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%"
                  }}
                >
                  <DIV mtop={"38px"}>
                    <FC m_justifyContent={"center"}>
                      <Heading text="Calendar" />
                    </FC>
                  </DIV>
                  <DIV {...styles.padding}>
                    <Calander onSelect={this.onSelect} />
                  </DIV>
                  <DIV
                    mtop={"38px"}
                    mbottom={"22px"}
                    m_margin={"22px 0 22px 0"}
                  >
                    <FC m_justifyContent={"center"}>
                      <Heading text="Tasks" />
                    </FC>
                  </DIV>
                  <TaskCard />
                </div>
              </FW>
            </FC>
          </DIV>
        </div>
      </Wrapper>
    );
  }
}

export default Dashboard;
