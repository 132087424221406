import React from "react";

// custom components
import FlexContainer from "../styledcomponents/FlexContainer";
import Container from "../styledcomponents/Container";

// npm packages
import InputBase from "@material-ui/core/InputBase";

function Header() {
  return (
    <div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6">
          <div style={{ padding: "15px 0px" }}>
            <FlexContainer alignItems="center"  m_padding="0px 0px 0px 30px" margin={"0 0 0 40px"} m_margin={"0 0 0 0"}>
              <i
                className="fas fa-search"
                style={{ color: "#cccccc", marginRight: "10.7px" }}
              />
              <span style={{ color: "#cccccc" }}>Search</span>
            </FlexContainer>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6">
          <div style={{ padding: "15px 0px", paddingRight: "32px" }}>
            <FlexContainer
              justifyContent="flex-end"
              m_justifyContent="flex-start"
              m_padding="0px 0px 0px 30px"
            >
              <div style={{ marginRight: "35px" }}>
                <FlexContainer alignItems="center">
                  <i
                    className="fas fa-cog"
                    style={{ color: "#cccccc", marginRight: "10.7px" }}
                  />
                  <span style={{ color: "#cccccc" }}>Settings</span>
                </FlexContainer>
              </div>

              <FlexContainer alignItems="center">
                <i
                  className="far fa-bell"
                  style={{ color: "#cccccc", marginRight: "10.7px" }}
                />
                <span style={{ color: "#cccccc" }}>Notifications</span>
              </FlexContainer>
            </FlexContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
