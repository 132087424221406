import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setConSubMenuTabAction } from "../../actions/tabAction";
import CircleIcon from "@material-ui/icons/TripOrigin";
import { Link } from "react-router-dom";

const styles = theme => ({
  active: {
    backgroundColor: "white",
    paddingLeft: "16px",
    color: "#4C10C1",
    fontWeight: "700",
    borderLeft: "5px solid #4C10C1"
  },
  colorBlue: {
    color: "red"
  },
  icon: {
       fontSize: "8px",
       marginRight : "16px"
  }
});

class NestedList extends React.Component {
  state = {
    open: true
  };

  handleClick = () => {
    this.props.setConSubMenuTabAction(this.props.name);
  };

  render() {
    const { classes } = this.props;

    return (
      <Link
        to={this.props.path ? this.props.path : "/"}
        style={{
          cursor: "pointer",
          display: "flex",
          paddingLeft: "1.8rem",
          alignItems: "center",
          textDecoration: "none",
          marginBottom: "1.5rem",
          fontSize: "14px",
          color: this.props.tab === this.props.name ? "#4C10C1" : "#757575",
        }}
        onClick={this.handleClick}
      >
              <CircleIcon className={classes.icon}/>
        <span>{this.props.name}</span>
      </Link>
    );
  }
}

NestedList.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  tab: state.selectedTab.subMenu
});
export default connect(
  mapStateToProps,
  { setConSubMenuTabAction }
)(withStyles(styles)(NestedList));
