import React, { Component } from "react";

// custom packages
import Container from "../styledComponents/Container";
import FC from "../../../consumercomponents/styledcomponents/FlexContainer";
import Input from "../styledComponents/Input";
import GridWrapper from "../styledComponents/GridWrapper";
import CG from "../../../consumercomponents/styledcomponents/CustomGrid";
import Label from "../styledComponents/Label";
import SubHeader from "../styledComponents/SubHeader";
import FlexContainer from "../../../consumercomponents/styledcomponents/FlexContainer";
import QuizNameWrapper from "../styledComponents/QuizNameWrapper";
import QuizName from "../styledComponents/QuizName";

// npm packages

export default class UserInfo extends Component {
  render() {
    return (
      <div>
        <GridWrapper>
          <CG
            cl="col-sm-12 col-md-3 col-lg-3"
            padding={"0 40px 0 15px"}
            m_padding={"0 15px 0 15px"}
          >
            <Container margin="0px 0px 10px 0px">
              <Label for="role">Role</Label>
            </Container>
            <Input id="role" width="100%" />
          </CG>
          <CG
            cl="col-sm-12 col-md-3 col-lg-3"
            padding={"0 40px 0 15px"}
            m_padding={"0 15px 0 15px"}
          >
            <Container margin="0px 0px 10px 0px">
              <Label for="skillLevel">Skill Level</Label>
            </Container>
            <Input id="skillLevel" width="100%" />
          </CG>
          <CG
            cl="col-sm-12 col-md-3 col-lg-3"
            padding={"0 40px 0 15px"}
            m_padding={"0 15px 0 15px"}
          >
            <Container margin="0px 0px 10px 0px">
              <Label for="coursesAssiggned">Courses Assigned</Label>
            </Container>
            <Input id="coursesAssiggned" width="100%" />
          </CG>
        </GridWrapper>
        <GridWrapper>
        <CG
            cl="col-sm-12 col-md-3 col-lg-3"
            padding={"0 40px 0 15px"}
            m_padding={"0 15px 0 15px"}
          >
            <Container margin="0px 0px 10px 0px">
              <Label for="quizzesAssigned">No. of Quizzess Assigned</Label>
            </Container>
            <Input id="quizzesAssigned" width="100%" />
          </CG>
          <CG
            cl="col-sm-12 col-md-3 col-lg-3"
            padding={"0 40px 0 15px"}
            m_padding={"0 15px 0 15px"}
          >
            <Container margin="0px 0px 10px 0px">
              <Label for="unattemptedQuizzess">
                No. of Unattempted Quizzess
              </Label>
            </Container>
            <Input id="unattemptedQuizzess" width="100%" />
          </CG>
          <CG
            cl="col-sm-12 col-md-3 col-lg-3"
            padding={"0 40px 0 15px"}
            m_padding={"0 15px 0 15px"}
          >
            <Container margin="0px 0px 10px 0px">
              <Label for="articleAssigned">No. of Articles Assigned</Label>
            </Container>
            <Input id="articleAssigned" width="100%" />
          </CG>
          <CG
            cl="col-sm-12 col-md-3 col-lg-3"
            padding={"0 40px 0 15px"}
            m_padding={"0 15px 0 15px"}
          >
            <Container margin="0px 0px 10px 0px">
              <Label for="unviewedArticle">No. of Unviewed Articles</Label>
            </Container>
            <Input id="unviewedArticle" width="100%" />
          </CG>
        </GridWrapper>

        <section>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <Container margin="30px 0px 0px 0px">
                <Container margin="30px 0px 0px 0px">
                  <FC m_justifyContent={"center"}>
                    <SubHeader>Names of Quiz Assigned</SubHeader>
                  </FC>
                </Container>
                <Container margin="10px 0px 0px 0px">
                  <FlexContainer m_direction={"column"} m_alignItems={"center"}>
                    <QuizNameWrapper>
                      <QuizName>Lorem ipsum dolor sit.</QuizName>
                    </QuizNameWrapper>
                    <QuizNameWrapper>
                      <QuizName>Lorem ipsum dolor sit.</QuizName>
                    </QuizNameWrapper>
                    <QuizNameWrapper>
                      <QuizName>Lorem ipsum dolor sit.</QuizName>
                    </QuizNameWrapper>
                    <QuizNameWrapper>
                      <QuizName>Lorem ipsum dolor sit.</QuizName>
                    </QuizNameWrapper>
                    <QuizNameWrapper>
                      <QuizName>Lorem ipsum dolor sit.</QuizName>
                    </QuizNameWrapper>
                    <QuizNameWrapper>
                      <QuizName>Lorem ipsum dolor sit.</QuizName>
                    </QuizNameWrapper>
                    <QuizNameWrapper>
                      <QuizName>Lorem ipsum dolor sit.</QuizName>
                    </QuizNameWrapper>
                    <QuizNameWrapper>
                      <QuizName>Lorem ipsum dolor sit.</QuizName>
                    </QuizNameWrapper>
                  </FlexContainer>
                </Container>
              </Container>
            </div>
            <div className="col-sm-12  col-md-6">
              <Container margin="30px 0px 0px 0px">
                <FC m_justifyContent={"center"}>
                  <SubHeader>Names of Articles Assigned</SubHeader>
                </FC>

                <Container margin="10px 0px 0px 0px" >
                  <FlexContainer m_direction={"column"} m_alignItems={"center"}>
                    <QuizNameWrapper>
                      <QuizName>Lorem ipsum dolor sit.</QuizName>
                    </QuizNameWrapper>
                    <QuizNameWrapper>
                      <QuizName>Lorem ipsum dolor sit.</QuizName>
                    </QuizNameWrapper>
                    <QuizNameWrapper>
                      <QuizName>Lorem ipsum dolor sit.</QuizName>
                    </QuizNameWrapper>
                    <QuizNameWrapper>
                      <QuizName>Lorem ipsum dolor sit.</QuizName>
                    </QuizNameWrapper>
                    <QuizNameWrapper>
                      <QuizName>Lorem ipsum dolor sit.</QuizName>
                    </QuizNameWrapper>
                    <QuizNameWrapper>
                      <QuizName>Lorem ipsum dolor sit.</QuizName>
                    </QuizNameWrapper>
                    <QuizNameWrapper>
                      <QuizName>Lorem ipsum dolor sit.</QuizName>
                    </QuizNameWrapper>
                    <QuizNameWrapper>
                      <QuizName>Lorem ipsum dolor sit.</QuizName>
                    </QuizNameWrapper>
                  </FlexContainer>
                </Container>
              </Container>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
