import React from "react";
import styled from "styled-components";

// styled components
import DIV from "../../styledcomponents/DIV";
import FC from "../../styledcomponents/FlexContainer";
import PW from "../../styledcomponents/PointerWrapper";
import BG from "../../styledcomponents/BG";

// images
import ArrowRight from "../../../assets/icons/Symbol639.png";
import ArrowLeft from "../../../assets/icons/left_arrow.png";
import Bg from "../../../assets/images/bg.png";
import Badge from "../../../assets/images/badge_consomer.png";
import Avatar1 from "../../../assets/images/avatar_1.png"
import Avatar4 from "../../../assets/images/avatar_4.png"
import Avatar2 from "../../../assets/images/avatar_2.png"
import Avatar3 from "../../../assets/images/avatar_3.png"
import Avatar5 from "../../../assets/images/avatar_5.png"

// css
import "../css/dashboard.css";



const POSITION = styled.div`
  font-family: system-ui;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #757575;
  margin-right: 20px;
`

const Name = styled.div`
  font-family: system-ui;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #757575;
`



const styles = {
  DIV: {
    padding: "40px",
    bg: "white",
    shadow: "0 2px 6px 0 rgba(0, 0, 0, 0.04)",
    height: "100%",
    m_padding : "35px 15px 40px 15px"
  },
  header: {
    fsize: "24px",
    color: "#393939",
    mbottom: "26px"
  },
  key: {
    fsize: "28px",
    color: "#757575"
  },
  value: {
    fsize: "16px",
    color: "#4bd800"
  },
  fc: {
    wrap: "no-wrap"
  },
  link: {
    fsize: "14px",
    color: "#4c10c1"
  },
  badge: {
    height: "242px",
    width: "242px"
  }
};

export default function Card({ header, details, total, value }) {
  console.log("details", details);

  return (
    <DIV {...styles.DIV}>
      <FC justifyContent={"space-between"}>
        <DIV {...styles.header}>Leadership Board</DIV>
        <DIV {...styles.link}>
          <PW>
            View All 
            <img src={ArrowRight} alt="" style={{ marginLeft: "6px" }} />
          </PW>
        </DIV>
      </FC>

      {/* ------------------------------------------------------------------ */}

      <DIV mtop={"28px"}>
        <FC alignItems={"center"} wrap={"no-wrap"}>
          <POSITION>1st</POSITION>
          <img src={Avatar1} alt="" style={{marginRight: "20px"}}/>
          <Name>Marie Winter  |  Expert</Name>
        </FC>
      </DIV>

      <DIV mtop={"28px"}>
        <FC alignItems={"center"} wrap={"no-wrap"}>
          <POSITION>2nd</POSITION>
          <img src={Avatar2} alt="" style={{marginRight: "20px"}}/>
          <Name>Anthony Peake  |  Expert</Name>
        </FC>
      </DIV>

      <DIV mtop={"28px"}>
        <FC alignItems={"center"} wrap={"no-wrap"}>
          <POSITION>3rd</POSITION>
          <img src={Avatar3} alt="" style={{marginRight: "20px"}}/>
          <Name>Neil Hunter  |  Expert</Name>
        </FC>
      </DIV>

      <DIV mtop={"28px"}>
        <FC alignItems={"center"} wrap={"no-wrap"}>
          <POSITION>4th</POSITION>
          <img src={Avatar4} alt="" style={{marginRight: "20px"}}/>
          <Name>Katherine Rutherford  |  Expert</Name>
        </FC>
      </DIV>

      <DIV mtop={"28px"}>
        <FC alignItems={"center"} wrap={"no-wrap"}>
          <POSITION>5th</POSITION>
          <img src={Avatar5} alt="" style={{marginRight: "20px"}}/>
          <Name>Ryan Vance  |  Expert</Name>
        </FC>
      </DIV>

     
    </DIV>
  );
}
