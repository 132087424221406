import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import Chip from "./Chip";
import Button from "@material-ui/core/Button";
import QustionList from "./Questions";
import api from "../../../utils/api";
import { post, customBaseUrl } from "../../../config/apiUrl";
import { Redirect } from "react-router-dom";
import NativeSelect from "@material-ui/core/NativeSelect";

const Styles = theme => ({
  label: {
    marginBottom: "0.5rem"
  },
  formControl: {
    width: "100%",
    marginBottom: "16px"
  },
  margin: {
    // margin: theme.spacing.unit,
    width: "auto"
  },
  customInput: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "300px",
    // padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
});

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "300px",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

class FindDetailsTab extends React.Component {
  state = {
    age: "test",
    name: "",
    nameError: false,
    path: null
  };

  handleChange = event => {
    this.setState({ age: event.target.value });
  };

  addQuiz = () => {
    const { name } = this.state;
    if (!name) {
      return this.setState({ nameError: true });
    } else {
      this.setState({ nameError: false });
    }

    const url = `${customBaseUrl}wsfunction=local_servifywebapis_create_quiz&moodlewsrestformat=json&courseid=59&topicid=3&name=${name}&visible=1&content=This is a test content for the page activity. This is a test content for the page activity. This is a test content for the page activity. This is a test content for the page activity. This is a test content for the page activity. This is a test content for the page activity. This is a test content for the page activity. This is a test content for the page activity. This is a test content for the page activity.&intro=This is a brief entry about the page`;
    api(url, post, null, this.successAddQuiz);
  };

  successAddQuiz = res => {
    console.log(res);
    this.setState({ path: "/quizManagement" });
  };

  setInputValue = value => {
    this.setState({ name: value });
  };

  render() {
    const { classes } = this.props;
    const { nameError, path } = this.state;

    return (
      <div style={{ marginTop: "2rem" }}>
        {path && <Redirect to={path} />}

        
       

        <QustionList ids={this.props.ids}/>
      </div>
    );
  }
}

export default withStyles(Styles)(FindDetailsTab);
