
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";

import React from 'react'

 function CustomInput(props) {
   const { onChange,value,error } = props
  return (
    <InputBase onChange={onChange} value={value} {...props} error={true} style={{  border: error ? "1px solid red" : "1px solid #ced4da" }}/>
  )
}



const BootstrapInput = withStyles(theme => ({
    root: {
      "label + &": {
        marginTop: theme.spacing.unit * 3
      }
    },
   
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      fontSize: 16,
      width: "100%",
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
      }
    }
  }))(CustomInput);

  export default BootstrapInput
  