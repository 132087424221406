import React, { Component } from "react";
import Editor from "./EditorComponent";
import MiceEditor from "./MiceEditor";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import { EditorState, convertToRaw } from "draft-js";
import Button from "@material-ui/core/Button";
import { stateToHTML } from "draft-js-export-html";
import api from "../../../utils/api";
import { customBaseUrl, post } from "../../../config/apiUrl";
import { resetSnackbar, setSnackbar } from "../../../actions/snackbarAction";
import { connect } from "react-redux";

// styled components
import CT from "../../../consumercomponents/styledcomponents/CT";
import Label from "../../../consumercomponents/styledcomponents/Label";

const styles = theme => ({
  input: {
    border: "1px solid #CCCCCC",
    borderRadius: "5px",
    paddingLeft: "12px"
  },

  label: {
    marginBottom: "0.5rem"
  },
  section: {
    marginBottom: "1.5rem"
  },
  heading: {
    borderBottom: "1px solid black",
    color: "#484848",
    paddingBottom: "16px"
  },
  answerSection: {
    // backgroundColor: "#AAAAAA"
    marginBottom: "1.3rem",
    marginTop: "1.3rem",
    borderBottom: "1px solid black"
  }
});

class AddTrueFalseQuestion extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <h3 className={classes.heading}>Answers</h3>
        <div className={classes.answerSection}>
          <div className={classes.section}>
            {/* <div className={classes.label}>Feedback for the response 'True'</div> */}
            <CT>
              <Label>Feedback for the response 'True'</Label>
            </CT>
            <div>
              <MiceEditor
                state={this.props.trueFeedback}
                handler={e => this.props.handleChangeChoice("trueFeedback", e)}
              />
            </div>
          </div>

          <div className={classes.section}>
            {/* <div className={classes.label}>Feedback for the response 'False'.</div> */}
            <CT>
              <Label>Feedback for the response 'False'</Label>
            </CT>
            <div>
              <MiceEditor
                state={this.props.trueFeedback}
                handler={e => this.props.handleChangeChoice("falseFeedback", e)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AddTrueFalseQuestion);
