import React, { Component } from 'react'

// custom components
import Container from '../../styledcomponents/Container'
import PointerWrapper from '../../styledcomponents/PointerWrapper'
import FlexContainer from '../../styledcomponents/FlexContainer'
import Option from '../../styledcomponents/Option'
import SmallText from '../../styledcomponents/SmallText'

// npm packages

class Quiz extends Component {
    state = {
        rightOption: null,
    }

    changeOption = (option) => {
        this.setState({ rightOption: option })
    }
    render() {
        const { rightOption } = this.state
        const { question } = this.props
        const { changeOption } = this
        const questionNumber = ["A","B","C","D"]
        let html = question.questiontext
        let div = document.createElement('div')
        div.innerHTML = html
        let text = div.textContent || div.innerText || ''
        return (
            <div style={{ border: '1px solid #EEEEEE', marginTop: '20px' }}>
                <Container
                    pleft="40px"
                    ptop="20px"
                    pright="40px"
                    pbottom="20px"
                    bg="#f5f5f5"
                    left="0px"
                    right="0px"
                    top="0px"
                    m_padding={'20px 0 20px 8px'}
                >
                    <FlexContainer justifyContent="space-between">
                        <span style={{ color: 'color: #757575' }}>
                            {' '}
                            {text}
                        </span>
                        <Container
                            bg="inherit"
                            left="0px"
                            m_margin="29px 0px 0px 0px"
                        >
                            <span style={{ color: 'color: #757575' }}>
                                10 Points
                            </span>
                        </Container>
                    </FlexContainer>
                </Container>
                <Container>
                    <Container
                        left="40px"
                        top="20px"
                        bottom="0px"
                        m_margin={'20px 0 0 0'}
                    >
                        <SmallText>Choose the Correct Answer</SmallText>
              </Container>
              
              {
                question.answers.map((a, i) => (
                  <Container
                        left="40px"
                        top="20px"
                        bottom="10px"
                    m_margin={'20px 0 10px 0'}
                    key={i}
                    >
                        <PointerWrapper onClick={() => changeOption(i + 1)}>
                            <FlexContainer>
                        <Option selected={rightOption === (i + 1)}>{questionNumber[i]}</Option>
                                <span
                                    style={{
                                        marginLeft: '9px',
                                        fontSize: '12px',
                                    }}
                                >
                                    {a.answer}
                                </span>
                            </FlexContainer>
                        </PointerWrapper>
                    </Container>
                ))
              }
                    

                   
                </Container>
            </div>
        )
    }
}

export default Quiz
