import React from "react";

export default function Heading(props) {
  return (
    <span
      style={{
        fontSize: "1.3rem",
        fontWeight: "500",
        color: "#484848"
      }}
    >
      {props.text}
    </span>
  );
}
