export const SET_COURSE_CATEGORY = "SET_COURSE_CATEGORY";
export const SET_QUIZ_DETAILS = "SET_QUIZ_DETAILS";

export const setCourseCategory = payload => {
  console.log("payload", payload);
  return {
    type: SET_COURSE_CATEGORY,
    payload
  };
};

export const setQuizDetails = payload => {
  console.log("payload", payload);
  return {
    type: SET_QUIZ_DETAILS,
    payload
  };
};
