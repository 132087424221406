import React from "react";
import Paper from "@material-ui/core/Paper";

//srtyled components
import FlexContainer from "../../../consumercomponents/styledcomponents/FlexContainer";
import FlexChild from "../../../consumercomponents/styledcomponents/FlexChild";
import { Container } from "../styledComponent/Dashboard";
import { NameWrapper, NumberWrapper } from "./OverviewCard";

export default function QuizMetricsInfo({
  Icon,
  number,
  name,
  extra,
  Component
}) {
  return (
    <Paper
      style={{
        minHeight: "100%"
      }}
    >
      <Container
        top="0px"
        right="0px"
        bottom="0px"
        left="0px"
        ptop="22px"
        pleft="20px"
        pbottom="20px"
        pright="10px"
        width="100%"
        m_width="100%"
      >
        <div>
          <FlexContainer justifyContent="space-between" alignItems="center">
            <FlexChild flex="10">
              {" "}
              <NameWrapper left={"0"} maxWidth={"100%"}>
                {name}
              </NameWrapper>{" "}
            </FlexChild>
            <FlexChild flex="1"> {Icon}</FlexChild>
          </FlexContainer>
          <div>
            <FlexContainer alignItems={Component && "center"}>
              <NumberWrapper>{number}</NumberWrapper>
              <span
                style={{
                  fontWeight: "700",
                  fontSize: "1.5rem",
                  color: "#484848",
                  marginLeft: "16px"
                }}
              >
                {extra && extra}
              </span>
              {Component && Component}
            </FlexContainer>
          </div>
        </div>
      </Container>
    </Paper>
  );
}
