import { SET_CONSUMER_QUIZ_DETAILS } from '../../actions/consumer/quizAction'

const initialState = {
    quizes: [],
}

const quizReducer = (state = initialState, action) => {
    switch (action.type) {
         case SET_CONSUMER_QUIZ_DETAILS:
          //     console.log("reducer",action.payload)
            return {
                ...state,
                quizes: action.payload,
            }
        default:
            return state
    }
}

export default quizReducer
