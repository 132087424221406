import styled from "styled-components";
import Screen from "./ScreenSizes";

export default styled.div`
  margin-top: 118px;
  @media ${Screen.tablet} {
    margin-top: 10px;
    display : flex!important;
    justify-content : flex-start!important;
    flex-direction: column;
  }
`;