import styled from "styled-components";

export default styled.div.attrs({
     className : "col-sm-12 col-md-6 col-lg-6"
})`
display : flex;
justify-content : ${props => props.justifyContent};
padding:0;

`
