import React, { Component } from "react";

// custom components
import Body from "../subComponents/Body";
import Header from "../subComponents/Header";
import Heading from "../subComponents/Heading";
import Tab from "./subComponents/Tab";

// npm packages
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import MediaQuery from "react-responsive";
import MobileHeader from "../Home/subComponents/MobileHeader";

const Styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "16px"
  },
  textField: {
    padding: 0,
    margin: 0,
    backgroundColor: "white"
  },
  textFieldContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px"
  },
  textFieldLabel: {
    fontWeight: "500",
    color: "#484848",
    marginBottom: "0.3rem"
  }
});

class AddTopic extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
         <Header />
        <Body>
          <Grid container spacing={0} style={{ marginBottom: "16px" }}>
            <Grid item xs={6}>
              <Heading text={"Article Management"} />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <IconButton onClick={() => this.props.history.goBack()}>
                <KeyboardArrowLeft style={{ color: "blue" }} />
                <span style={{ fontSize: "16px", color: "blue" }}>Back</span>
              </IconButton>
            </Grid>
          </Grid>
          <Paper style={{ padding: "2rem" }}>
            <div style={{ marginBottom: "16px" }}>
              <Heading text={"View Article"} />
            </div>
            <Tab type="view" />
          </Paper>
        </Body>
      </div>
    );
  }
}

export default withStyles(Styles)(AddTopic);
