import React from "react";

// styled components
import DIV from "../../styledcomponents/DIV";
import FC from "../../styledcomponents/FlexContainer";
import FChild from "../../styledcomponents/FlexChild";
import PW from "../../styledcomponents/PointerWrapper";

// images
import ArrowRight from "../../../assets/icons/Symbol639.png";

const styles = {
  DIV: {
    padding: "40px",
    bg: "white",
    height: "100%",
    m_padding : "35px 15px 40px 15px"
  },
  header: {
    fsize: "24px",
    color: "#393939",
    mbottom: "26px"
  },
  key: {
    fsize: "16px",
   
    color: "#757575"
  },
  value: {
    fsize: "16px",
    color: "#4bd800"
  },
  fc: {
    wrap: "no-wrap"
  },
  link: {
    fsize: "14px",
    color: "#4c10c1"
  }
};

export default function Card({ header, details, total, value }) {
  console.log("details", details);

  const test = Object.keys(details).map((name, i) => {
    return (
      <FC key={i} {...styles.fc}>
        <FChild flex={"30%"}>
          <DIV {...styles.key}>{`${name} - `}</DIV>
        </FChild>
        <FChild flex={"70%"}>
          <DIV {...styles.value}>&nbsp;{` ${details[name]}`}</DIV>
        </FChild>
      </FC>
    );
  });
  console.log("test", test);

  return (
    <DIV {...styles.DIV}>
      <DIV {...styles.header}>{header}</DIV>
      {/* {test} */}

      <FC wrap={"no-wrap"}>
        <DIV width={"90px"} {...styles.key}>Quiz Name - </DIV>
        <DIV {...styles.value}>&nbsp;Android OS Upgrade - ADU5000</DIV>
      </FC>

     

      <DIV mtop={"18px"}>
        <FC>
          <DIV {...styles.key}>Category - </DIV>
          <DIV {...styles.value}>&nbsp;Refrigerators </DIV>
        </FC>
      </DIV>
      <DIV mtop={"18px"}>
        <FC>
          <DIV {...styles.key}>Total Questions - </DIV>
          <DIV {...styles.key}>&nbsp; 12 </DIV>
        </FC>
      </DIV>

      <DIV mtop={"44px"}>
        <FC justifyContent={"space-between"}>
          <DIV {...styles.key}>42%</DIV>
          <DIV {...styles.key}>Deadline - 2 Days</DIV>
        </FC>
        <DIV mtop={"10px"}>
          <div className="custom-progress">
            <div
              className="custom-progress"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{
                width: "70%",
                backgroundColor: "#A688E0",
                height: "10px"
              }}
            />
          </div>
        </DIV>
      </DIV>

      <DIV mtop={"20px"} {...styles.link}>
        <PW>
          Resume Quiz
          <img src={ArrowRight} alt="" style={{ marginLeft: "6px" }} />
        </PW>
      </DIV>
    </DIV>
  );
}
