import React, { Component } from "react";
import { Redirect } from "react-router-dom";

// custom components
import Body from "../Home/subComponents/Body";
import Header from "../subComponents/Header";
import Heading from "../subComponents/Heading";
import Search from "../subComponents/Search";
import { setTabAction,setSubMenuTabAction } from "../../actions/tabAction";
// import Table from "./SubComponents/Table";
import NewTable from "./tables/NewTable";
import DateFilter from "./DateFilter";
import api from "../../utils/api";
import { post, baseUrl, customBaseUrl } from "../../config/apiUrl";
import { setCourseDeatils } from "../../actions/courseAction";
import { setQuizDetails } from "../../actions/quizAction";
import { setGroupsApi } from "../../actions/userManagementAction";

// npm packages
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import is from "is_js";


// styled components
import Container from "../../consumercomponents/styledcomponents/Container";
import CT from "../../consumercomponents/styledcomponents/CT";
import FlexContainer from "../../consumercomponents/styledcomponents/FlexContainer";
import Label from "../../consumercomponents/styledcomponents/Label";
import CustomButton from "../../consumercomponents/styledcomponents/Button";

const styles = theme => ({
  alignRight: {
    textAlign: "right"
  },
  smallText: {
    textTransform: "none"
  },
  buttonIcon: {
    marginLeft: "1.5rem",
    color: "black"
  },
  Link: {
    color: "white",
    textDecoration: "none"
  }
});
class TopicManagement extends Component {
  constructor() {
    super();
    this.unmount = false;
  }
  state = {
    open: false,
    search: "",
    data: [],
    loading: false,
    forceLoading: false,
    filteredData: [],
    deleteId: [],
    selected: [],
    deleteLoading: true,
    filterStartDate: new Date(),
    filterEndDate: new Date(),
    showDelete: false,
    path: null,
    isSearch: false
  };


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.groups.length !== prevState.filteredData.length && !prevState.isSearch) {
      return { filteredData: nextProps.groups }; // <- this is setState equivalent
    }
  }
  redirect = path => this.setState({ path });

  setLoading = loading => {
   
      console.log("---------",this.state.forceLoading)
      this.setState({ loading })
    
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  toggleShowDelete = (checkState = false) => {
    console.log("sdeddeddeded", checkState);
    this.setState({ showDelete: checkState });
  };



  onDateChange = (date, name) => this.setState({ [name]: date });

  onClick = () => {
    const { filterStartDate, filterEndDate } = this.state;
    let searchedProduct = this.state.data.filter(({ timecreated, name }) => {
      if (
        new Date(filterStartDate).getTime() / 1000 < timecreated &&
        timecreated < new Date(filterEndDate).getTime() / 1000
      ) {
        console.log(
          "record",
          name,
          new Date(filterStartDate).getTime() / 1000,
          timecreated,
          new Date(filterEndDate).getTime() / 1000
        );
      }

      return (
        new Date(filterStartDate).getTime() / 1000 < timecreated &&
        timecreated < new Date(filterEndDate).getTime() / 1000
      );
    });

    console.log("product", searchedProduct);
    this.setState({
      filteredData: searchedProduct
    });
    this.handleClose();
  };

  // on search
  search = e => {
    let keyword = e.target.value;
   
    this.setState({
      search: keyword,
      isSearch: true
    });
    if (keyword.trim()) {
      let searchedProduct = this.props.groups.filter(({ name }) =>
        name.toLowerCase().includes(keyword.toLowerCase())
      );
      console.log("filter", searchedProduct);
      this.setState({
        filteredData: searchedProduct
      });
    } else {
      this.setState({
        filteredData: this.props.groups
      });
    }
  };

  getQuizes = (loading = true) => {
    if (loading) {
      this.setState({ loading });
    }
    let url = `${baseUrl}wsfunction=core_cohort_get_cohorts &moodlewsrestformat=json`;
  
    api(url, post, null, this.getQuizesApiSuccess);
  };

  getQuizesApiSuccess = res => {
    if (!this.unmount) {
      this.setState({ loading: false });
      this.props.setQuizDetails(res.data);
      this.setState({
        data: res.data,
        filteredData: res.data.reverse()
      });
    }
  };

  getCourses = () => {
    this.setState({ loading: true });
    const url = `${baseUrl}&wsfunction=core_course_get_courses&moodlewsrestformat=json`;
    api(url, post, null, this.apiSuccess);
  };

  // success of api
  apiSuccess = res => {
    if (!this.unmount) {
      this.props.setCourseDeatils(res.data);
      this.getQuizes();
    }
  };

  // delete quiz
  deleteQuiz = (id) => {
    this.setState(prevState => ({
      deleteId: [...prevState.deleteId, id]
    }));
    const url = `${baseUrl}wsfunction=core_cohort_delete_cohorts &moodlewsrestformat=json&cohortids[0]=${id}`;
    api(url, post, null, this.successDeleteApi);
  };

  successDeleteApi = res => {
    console.log(res);
    this.props.setGroupsApi();
    // this.getQuizes(false);
  };

  getSelected = selected => {
    this.setState({ selected });
  };

  // delete selected courses
  deleteSelected = () => {
    if (this.state.selected.length > 0) {
      console.log("selected",this.state.selected)
      let url = `${baseUrl}wsfunction=core_cohort_delete_cohorts &moodlewsrestformat=json&`;
      this.state.selected.forEach((id, i) => {
        url += `cohortids[${i}]=${id}&`;
        this.setState(prevState => ({
          deleteId: [...prevState.deleteId, id],  
          deleteLoading: true
        }));
      });
      console.log(url.slice(0, -1));
      const trimmedUrl = url.slice(0, -1);
      api(trimmedUrl, "get", null, this.successDeleteApi);
    }
  };

  // componentWillMount() {
  //   this.props.setTabAction("User Management");
  //   this.props.setSubMenuTabAction("Groups");
  //   if (this.props.courses.length === 0) {
  //     this.getCourses();
  //   } else {
  //     this.getQuizes();
  //   }
  // }

  componentDidMount() {
    this.props.setTabAction("User Management");
    this.props.setSubMenuTabAction("Groups");
    this.props.setGroupsApi(this.setLoading,null);
    this.setState({
      filteredData: this.props.groups
    });
  }

  componentWillUnmount() {
    this.unmount = true
  }

  render() {
    const Loader = (
      <FlexContainer justifyContent={"center"} width={"100%"}>
        <Label>Loading Group details ...</Label>
      </FlexContainer>
    );
    const { classes } = this.props;
    const {
      data,
      loading,
      filteredData,
      deleteId,
      filterStartDate,
      filterEndDate,
      showDelete,
      path
    } = this.state;
    const windowPlatform = is.windows();

    return (
      <div>
        <Header />
        <Body>
          <CT left={"25px"} right={"40px"} m_margin={"0px"} m_padding={"0px"}>
            {path && <Redirect to={path} />}
            <DateFilter
              open={this.state.open}
              handleClose={this.handleClose}
              startDate={filterStartDate}
              endDate={filterEndDate}
              onDateChange={this.onDateChange}
              onClick={this.onClick}
            />
            <div className="row">
              <div
                className="col-sm -12 col-md-6"
                style={{ padding: "32px 8px" }}
              >
                <FlexContainer m_justifyContent={"center"}>
                  <Heading text={"User Management - Groups"} />
                </FlexContainer>
              </div>
              <div
                className="col-sm-12 col-md-6 "
                style={{ padding: "32px 8px" }}
              >
                <Container
                  left="0px"
                  m_margin="29px 0px 29px 0px"
                  bg="inherit"
                  m_padding={"15px"}
                >
                  <Search
                    value={this.state.search}
                    onChange={this.search}
                    search={"Search Group"}
                  />
                </Container>
              </div>
            </div>

            <div style={{ backgroundColor: "white" }}>
              <div
                className="row"
                style={{ alignItems: "center", padding: "32px 8px" }}
              >
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <CustomButton
                    bg="white"
                    color="#757575"
                    width="180px"
                    m_width={"100%"}
                    onClick={this.handleClickOpen}
                  >
                    Filter By
                    <img
                      src={require("../../assets/icons/Filter.png")}
                      alt="filter"
                      style={{ marginLeft: "30px" }}
                    />
                  </CustomButton>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <FlexContainer
                    justifyContent="flex-end"
                    m_justifyContent="flex-start"
                    m_direction={"column"}
                  >
                    <Container
                      left="0px"
                      top="0px"
                      bottom="0px"
                      m_width={"100%"}
                    >
                      {showDelete && (
                        <CustomButton
                          bg="white"
                          border="1px solid #4c10c1"
                          color="#4c10c1"
                          onClick={this.deleteSelected}
                          m_width={"100%"}
                        >
                          {" "}
                          Delete Selected
                        </CustomButton>
                      )}
                    </Container>
                    <Container left="0px" top="0px" m_width={"100%"}>
                      {/* <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classes.smallText}
                        style={{
                          marginLeft: "16px",
                          height: "40px",
                          borderRadius: "2px",
                          fontSize: "12pt",
                          fontFamily: "Gotham"
                        }}
                      >
                        <Link
                          to="/groupManagement/addGroup"
                          className={classes.Link}
                        >
                          Create Group
                        </Link>
                      </Button> */}
                      <CustomButton
                        border="1px solid #4c10c1"
                        onClick={() => this.redirect("/groupManagement/addGroup")}
                        m_width={"100%"}
                      >
                        {" "}
                        Create Group
                      </CustomButton>
                    </Container>
                  </FlexContainer>
                </div>
              </div>

              <Container
                // m_width={"500px"}
                left="0px"
                right={"0px"}
                m_margin="29px 0px 29px 0px"
                bg="inherit"
                m_flow={"scroll"}
                bottom={"0px"}
              >
                <Container
                  left="0px"
                  right={"0px"}
                  bottom={"0px"}
                  //  m_width={"500px"}
                >
                  {loading ? (
                    Loader
                  ) : (
                    <NewTable
                      courseData={filteredData}
                      deleteCourse={this.deleteQuiz}
                      deleteId={deleteId}
                      getSelected={this.getSelected}
                      deleteLoading={this.state.deleteLoading}
                      toggleDelete={this.toggleShowDelete}
                    />
                  )}
                </Container>
              </Container>
            </div>
          </CT>
        </Body>
      </div>
    );
  }
}

TopicManagement.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  courses: state.course.courses,
  groups: state.userManagement.groups
});

export default connect(
  mapStateToProps,
  { setTabAction, setCourseDeatils, setQuizDetails,setSubMenuTabAction,setGroupsApi }
)(withStyles(styles)(TopicManagement));
