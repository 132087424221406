import axios from 'axios'
import { baseUrl } from '../config/apiUrl'

// type
export const SET_USER_DETAILS = 'SET_USER_DETAILS'
export const SET_GROUP_DETAILS = 'SET_GROUP_DETAILS'
export const SET_USER_ID = 'SET_USER_ID'

export const setUserDetails = (payload) => {
    console.log('payload', payload)
    return {
        type: SET_USER_DETAILS,
        payload,
    }
}

export const setGroupDetails = (payload) => {
    console.log('payload', payload)
    return {
        type: SET_GROUP_DETAILS,
        payload,
    }
}

export const setUserId = (payload) => {
    return {
        type: SET_USER_ID,
        payload,
    }
}


export const setUsersIdsApi = (usersInfo, callback,dispatch)  => {
    if (callback) {
        callback(true)
    }
    let url = `${baseUrl}wsfunction=core_user_create_users&moodlewsrestformat=json&`

    usersInfo.forEach(({ username, name, email }, index) => {
        if (email) {
            url += `users[${index}][username]=${username}&users[${index}][firstname]=${name}&users[${index}][lastname]=null&users[${index}][email]=${email}&users[${index}][password]=Test@123&users[${index}][auth]=manual&`
        }
    })
    const trimmedUrl = url.slice(0, -1)

    axios.get(trimmedUrl).then((res) => {
        if (callback) {
            callback(false)
        }
        dispatch(setUserId(res.data))

    })
}

export const setUserApi = (loading) => (dispatch) => {
    const headers = {
        Authorization:
            'tZVYelozPSmyaUJGnqCnUY5CxsFdp6j37fZdiYqWE2CnoJSQnmf7iAWHVgkQNQEV',
        'content-Type': 'application/json',
    }

    const param = {
        partnerId: 9,
    }
    loading(true)

    axios
        .post(
            'http://localweb.servify.tech/lms/api/v1/users/getUserListByPartnerId',
            param,
            { headers: headers }
        )
        .then((resp) => {
            loading(false)
            let data = []
            for (const key in resp.data) {
                resp.data[key].forEach((val) => {
                    data = [...data, { ...val, role2: key }]
                })
            }

            dispatch(setUserDetails(data))
            setUsersIdsApi(data, loading,dispatch)
        })
        .catch((error) => console.log('error', error))
}

export const setGroupsApi = (loading, success) => (dispatch) => {
    if (loading) {
        loading(true)
    }

    axios
        .get(
            `${baseUrl}wsfunction=core_cohort_get_cohorts &moodlewsrestformat=json`
        )
        .then((resp) => {
            if (loading) {
                loading(false)
            }

            dispatch(setGroupDetails(resp.data))
            
            if (success) {
                success()
            }
        })
        .catch((error) => console.log('error', error))
}




