import styled from "styled-components";
import Screen from "./ScreenSizes";

export default styled.div.attrs(props => ({
  cl: props.cl
}))`
  height: ${props => props.height && props.height};
  width: ${props => props.width && props.width};
  margin: ${props => props.margin && props.margin};
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  align-items: ${props => (props.alignItems ? props.alignItems : "baseline")};
  flex-wrap: ${props => (props.wrap ? props.wrap : "wrap")};
  flex-direction: ${props => (props.direction ? props.direction : "row")};

  @media ${Screen.tablet} {
    justify-content: ${props =>
      props.m_justifyContent && props.m_justifyContent};
    align-items: ${props => props.m_alignItems && props.m_alignItems};
    padding: ${props => props.m_padding && props.m_padding};
    margin: ${props => props.m_margin && props.m_margin};
    flex-direction: ${props => (props.m_direction ? props.m_direction : "row")};
  width: ${props => props.m_width && props.m_width};
  order: ${props => props.m_order && props.m_order};

  }
`;
