import React from 'react';
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles } from "@material-ui/core/styles";


const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between"
  },
  action: {
    display: "flex",
       justifyContent: "space-between",
    background : "red"
  }
});

 function TablePaginationComponent(props) {
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      ActionsComponent="div"
      component="div"
      count={props.count}
      rowsPerPage={props.rowsPerPage}
      page={props.page}
      classes={{root : props.classes.root,actions : props.classes.root}}
      backIconButtonProps={{
        "aria-label": "Previous Page"
      }}
      nextIconButtonProps={{
        "aria-label": "Next Page"
      }}
      onChangePage={props.onChangePage}
      onChangeRowsPerPage={props.onChangeRowsPerPage}
    />
  );
}

export default withStyles(styles)(TablePaginationComponent);
