import styled from "styled-components";
import Screen from "./ScreenSizes"


export default styled.div.attrs(props => ({
     className: props.cl
}))`
padding : ${props => props.padding};



@media ${Screen.tablet} {
padding : ${props => props.m_padding};
  }
`