import { createStore, combineReducers, applyMiddleware, compose } from "redux";
// import paymentReducer from './paymentReducer'
// import trackReducer from './trackReducer'

import thunk from "redux-thunk";
import userReducer from "./userReducer";
import tabReducer from "./tabReducer";
import ConsumerTabReducer from "./ConsumerTabReducer";
import courseReducer from "./courseReducer";
import ConsumerCourseReducer from "./ConsumerCourseReducer";
import ConsumerQuizeReducer from "./ConsumerQuizReducer";
import QuizReducer from "./QuizReducer";
import SnackbarReducer from "./snackbarReducer";
import EditorReducer from "./editorReducer";
import UserManagementReducer from "./UserManagementReducer";
// import courseReducer from './courseManagementReducer';
// import plansReducer from './plansReducer'
// import devicedetailReducer from './devicedetailReducer'
// import uploadReducer from './uploadReducer'

const middleware = [thunk];
const initialState = {};

const reducers = combineReducers({
  user: userReducer,
  selectedTab: tabReducer,
  consumerSelectedTab: ConsumerTabReducer,
  course: courseReducer,
  quiz: QuizReducer,
  snackbar: SnackbarReducer,
  editor: EditorReducer,
  userManagement: UserManagementReducer,
  consumerCourses: ConsumerCourseReducer,
  consumerQuizes: ConsumerQuizeReducer

});

const store = createStore(
  reducers,
  initialState,
  compose(
    applyMiddleware(...middleware),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

// export const injectReducers = (store, { key, reducer }) => {
//   if (Object.hasOwnProperty.call(store.asyncReducers, key)) return
//
//   store.asyncReducers[key] = reducer
//   store.replaceReducer(makeRootReducer(store.asyncReducers))
// }

export default store;
