import styled from "styled-components";
import Screen from "./ScreenSizes"



export default styled.button.attrs(props =>({
  type: props.type,
  disabled: props.disabled
}))`
  height: ${props => (props.height ? props.height : "46px")};
  width: ${props => (props.width ? props.width : "160px")};
  object-fit: contain;
  background-color: ${props => (props.disabled? "#cccccc" : props.bg ? props.bg : "#4c10c1")};
  color: ${props => (props.color ? props.color : "#ffffff")};
  border: ${props => props.disabled? "1px solid #cccccc" : props.border ? props.border : "1px solid #cccccc"};
  outline : 0;
  border-radius: ${props => props.radius ? props.radius : "5px"};
  font-size: ${props => props.size};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal; 
  line-height: 1.21;
  letter-spacing: normal;
  &:hover {
    background-color :  ${props => props.hover && props.hover };
    outline : 0;
    
  }

  @media ${Screen.tablet} {
   width: ${props => props.m_width}
  }
`;