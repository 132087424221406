import styled from "styled-components";


export default styled.div`
  height: 22px;
  width:63px;
  color: #4bd800;
  border: 1px solid #4bd800;
  border-radius:20px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
`;