import React from "react";
import styled from "styled-components";

// custom components
import Activity from "./Activity";

// style dcomponents
import DIV from "../../styledcomponents/DIV";
import FC from "../../styledcomponents/FlexContainer";

// ikmages
import ArrowRight from "../../../assets/icons/Symbol639.png";

// custom styled components
const LINK = styled.div`
  font-family: system-ui;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.08;
  text-align: left;
  color: #4c10c1;
  margin-right: 9px;
`;

// styles
const styles = {
  wrapper: {
    height: "100%"
  },
  flexWrapper: {
    direction: "column",
    wrap: "no-wrap",
    justifyContent: "space-between",
    height: "100%",
    width: "100%"
  },
  linkWrapper: {
    justifyContent: "center",
    width: "100%"
  }
};

export default function ActivityWrapper() {
  const { wrapper, flexWrapper, linkWrapper } = styles;
  return (
    <div>
      <Activity />
      <Activity />
      <Activity />
    </div>
    // <DIV {...wrapper}>
    //      <FC {...flexWrapper}>
    //           <DIV>
    //                <Activity />
    //                <Activity />
    //                <Activity />
    //           </DIV>
    // <FC {...linkWrapper}>
    //      <LINK>View All Activity</LINK>
    //      <img src={ArrowRight} alt="right arrow"/>
    // </FC>
    //      </FC>
    // </DIV>
  );
}
