import styled from "styled-components";
import Screen from "../../../consumercomponents/styledcomponents/ScreenSizes";

export default styled.section`
  
  padding-top: 40px;
  background-color: #ffffff;
  padding-bottom: 40px;
  @media ${Screen.tablet} {
    padding-left : 10px;
    padding-right : 10px;
    padding-top :0px
  }
`;
