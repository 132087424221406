import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CalandarIcon from "@material-ui/icons/CalendarToday";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Calendar from "react-calendar";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';


import styled from "styled-components";


// styled components
import FC from "../../consumercomponents/styledcomponents/FlexContainer";
import DIV from "../../consumercomponents/styledcomponents/DIV";


















const BootstrapInput = withStyles(theme => ({
    root: {
      'label + &': {
        marginTop: theme.spacing.unit * 3,
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      width: '90%',
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);



const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
          style={{
            fontSize: "0.8rem",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          Close <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const styles = theme => ({
  border: {
    border: "none"
  },
  selected: {
    // color : "red"
  },
  margin: {
    // margin: theme.spacing.unit,
    width: "100%"
  },
  label: {
    marginBottom: "0.5rem"
  }
});

class ResponsiveDialog extends React.Component {
  dateObj = new Date();
  state = {
    date: this.dateObj
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onChange = date => this.setState({ date });

  onClick = () => {
    const { startDate, endDate } = this.props;
    console.log("dates",new Date(startDate).getTime()/1000,endDate)
    this.props.handleClose();
  }

  render() {
    const {
      fullScreen,
      classes,
      startDate,
      endDate,
      onDateChange,
      onClick
    } = this.props;
    console.log("date", this.state.date.toLocaleString().split(",")[0]);
    return (
      <div >
        <Dialog
          maxWidth={"md"}
          fullWidth={true}
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.props.handleClose}
          >
            Filter By Date
          </DialogTitle>
          <DialogContent>
            <div
              style={{marginTop : "16px" }}
            >
              <FC justifyContent={"space-between"} m_direction={"column"}>
              <DIV m_margin={"20px 0 0 0"}>
                <div style={{ marginBottom: "0.5rem" }}>
                  <span>From *</span>
                </div>
                <TextField
                  id="outlined-adornment-password"
                  variant="outlined"
                  type={"text"}
                  value={startDate.toLocaleString().split(",")[0]}
                  style={{
                    width: "100%"
                  }}
                  //   onChange={this.handleChange("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="Toggle password visibility">
                          <CalandarIcon color="primary" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <Calendar
                  value={startDate}
                  onChange={date => onDateChange(date, "filterStartDate")}
                  className={[classes.border]}
                  tileClassName={[classes.selected]}
                />
              </DIV>
              <DIV  m_margin={"20px 0 0 0"}>
                <div style={{ marginBottom: "0.5rem" }}>
                  <span>To *</span>
                </div>
                <TextField
                  id="outlined-adornment-password"
                  variant="outlined"
                  type={"text"}
                  value={endDate.toLocaleString().split(",")[0]}
                  style={{
                    width: "100%"
                  }}
                  //   onChange={this.handleChange("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="Toggle password visibility">
                          <CalandarIcon color="primary" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Calendar
                  value={endDate}
                  onChange={date => onDateChange(date, "filterEndDate")}
                  className={[classes.border]}
                />
              </DIV>
             </FC>
            </div>
            <div
              style={{
                marginTop: "16px",
                textAlign : "right"
              }}
            >
              <Button
                variant="contained"
                onClick={onClick}
                color="primary"
              >
                Apply
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(withStyles(styles)(ResponsiveDialog));
