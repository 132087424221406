import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setConTabAction, setConSubMenuTabAction } from "../../actions/tabAction";
import Down from "@material-ui/icons/KeyboardArrowDown";
import { Link } from "react-router-dom";
import SubListItem from "./SubListItem"


class ListItem extends React.Component {
  state = {
    open: false
  };

  handleClick = () => {
    this.props.setConTabAction(this.props.name);
    if(!this.props.child) this.props.setConSubMenuTabAction("");
    this.setState(prevState => ({
      open : !prevState.open
    }))
  };

  render() {
    const { classes,childNames,paths } = this.props;
    const { open } = this.state;

    const childs = childNames ? childNames.map((child, i) => {
      return <SubListItem key={i} name={child} path={paths[i]}/>;
    }) : ""
    return (
      <div>
        {!this.props.childNames ? (
          <Link
            to={
              this.props.path ? this.props.path : "/consumer/name/d/courses"
            }
            style={{
              cursor: "pointer",
              display: "flex",
              paddingLeft: "1rem",
              justifyContent: "space-between",
              textDecoration: "none",
              marginBottom: "1.5rem",
              // fontFamily: "GothamBook",
              // fontWeight: "800",
              fontSize: "14px",
              color:
                this.props.tab === this.props.name ? "#4C10C1" : "#757575",
              borderLeftColor:
                this.props.tab === this.props.name ? "#4C10C1" : "#FFFFFF",
              borderLeftWidth: "5px",
              borderLeftStyle: "solid"
            }}
            onClick={this.handleClick}
          >
            <span>{this.props.name}</span>
            {this.props.child && <i className="fas fa-chevron-down" />}
          </Link>
        ) : (
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              paddingLeft: "1rem",
              justifyContent: "space-between",
              textDecoration: "none",
              marginBottom: "1.5rem",
              // fontFamily: "GothamBook",
              // fontWeight: "800",
              fontSize: "14px",
              color:
                this.props.tab === this.props.name ? "#4C10C1" : "#757575",
              borderLeftColor:
                this.props.tab === this.props.name ? "#4C10C1" : "#FFFFFF",
              borderLeftWidth: "5px",
              borderLeftStyle: "solid"
            }}
            onClick={this.handleClick}
          >
            {this.props.name}
            {this.props.child && <i className="fas fa-chevron-down" />}
          </div>
        )}
        {this.props.childNames && open && childs}
      </div>
    );
  }
}

ListItem.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  tab: state.consumerSelectedTab.sideMenuTab
});
export default connect(
  mapStateToProps,
  { setConTabAction, setConSubMenuTabAction }
)(ListItem);
