import React, { Component } from "react";
import styled from "styled-components";

// custom packages
import Wrapper from "../subcomponents/Wrapper";
import Heading from "../../components/subComponents/Heading";

// images
import UpArrow from "../../assets/icons/up_blue.png";
import DownArrow from "../../assets/icons/down_blue.png";

// styled components
import Container from "../styledcomponents/Container";
import PointerWrapper from "../styledcomponents/PointerWrapper";
import FC from "../styledcomponents/FlexContainer";
import FChild from "../styledcomponents/FlexChild";
import SuccessMessage from "../styledcomponents/SuccessMessage";
import Button from "../styledcomponents/Button";
import DIV from "../styledcomponents/DIV";

// styles
const styles = {
  parent: {
    bg: "#ffffff",
    shadow: "0 2px 6px 0 rgba(0, 0, 0, 0.04)",
    padding: "40px",
    m_padding: "0px 8px",
    width: "100%",
    m_overflow: "scroll"
  },
  flex: {
    alignItems: "center",
    m_direction: "column",
    m_justifyContent: "center"
  },
  rightWrapper: {
    pleft: "22px",
    m_padding: "0px",
    m_width: "100%",
  }
};

// styled components
const TEXT = styled.span`
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.13;
  text-align: left;
  color: #757575;
`;

const BOLD = styled.span`
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.13;
  text-align: left;
  color: #393939;
`;

const TH = styled.th`
  padding: 17px 34px;
  background-color: #f5f5f5;
  border-right: 1px solid #d1d1d1;
`;

const TD = styled.th`
  padding: 17px 34px;
  text-align: center;
  border-right: 1px solid #d1d1d1;
  color: #393939;
`;

const LINK = styled.span`
  height: 14px;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-family: system-ui;
  line-height: 2.08;
  text-align: left;
  color: #4c10c1;
`;

const PLACEHOLDER = styled.div`
  min-width: 76px;
`;

// ---------------------------------------------------------------------------------------
class Home extends Component {
  state = {
    toggle: false
  };

  toggle = () => {
    this.setState(prevState => ({ toggle: !prevState.toggle }));
  };
  render() {
    const { parent, flex, rightWrapper } = styles;
    const { toggle } = this.state;
    return (
      <Wrapper>
        <div className="row">
          <div className="col-sm-12 col-md-11 col-lg-11">
          <FC m_justifyContent={"center"}>
                <Container left={"0px"} bg={"inherit"} m_margin="29px 0px 0px 8px">
                <Heading text="Quiz Result" />
                </Container>
              </FC>
            
          </div>
          <div className="col-sm-12 col-md-1 col-lg-1" />
        </div>

        <Container top="42px" left="0px" width={"100%"} m_padding={"29px 0 0 0"}>
          <DIV {...parent}>
            <FC {...flex}>
              <FChild flex={0.6}>
                <img
                  src={require("../../assets/images/quizz_attempt.png")}
                  alt=""
                />
              </FChild>
              <FChild flex={9.4} m_width={"100%"}>
                <DIV m_padding={0} {...rightWrapper}>
                  <FC m_justifyContent={"center"}>
                  <SuccessMessage>
                   Congratulations! You Have Passed
                  </SuccessMessage>
                 </FC>
                </DIV>
              </FChild>
            </FC>
            <FC {...flex}>
              <FChild flex={0.6}>
                <PLACEHOLDER />
              </FChild>
              <FChild flex={9.4}>
                <DIV m_padding={0} m_margin={"14px 0 0 0"} width={"inherit"} {...rightWrapper}>
                  <DIV top={"40px"} m_padding={0}>
                    <TEXT>Quiz Name - </TEXT>
                    <BOLD>Windows Software Update - ADU5789</BOLD>
                  </DIV>
                  <DIV top={"18px"} m_padding={0}>
                    <TEXT>Total Questions - </TEXT>
                    <BOLD>10</BOLD>
                  </DIV>
                </DIV>
              </FChild>
            </FC>
            <FC {...flex}>
              <FChild flex={0.6}>
                <PLACEHOLDER />
              </FChild>
              <FChild flex={9.4}>
                <DIV m_padding={0} width={"inherit"} {...rightWrapper}>
                  <DIV
                    mtop={"23px"}
                    m_padding={0}
                    maxwidth={"inherit"}
                    width={"inherit"}
                  >
                    <div style={{ maxWidth: "inherit", width: "inherit" }}>
                      <table
                        style={{
                          border: "1px solid #D1D1D1",
                          width: "inherit"
                        }}
                      >
                        <thead>
                          <tr>
                            <TH>Total Score</TH>
                            <TH>Your Score</TH>
                            <TH>Passing Score</TH>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <TD>100</TD>
                            <TD>90</TD>
                            <TD>80</TD>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </DIV>
                </DIV>
              </FChild>
            </FC>
            <FC {...flex}>
              <FChild flex={0.6}>
                <PLACEHOLDER />
              </FChild>
              <FChild flex={9.4}>
                <DIV m_padding={0} width={"inherit"} {...rightWrapper}>
                  <DIV
                    mtop={"23px"}
                    m_padding={0}
                    maxwidth={"inherit"}
                    width={"inherit"}
                  >
                    <PointerWrapper onClick={this.toggle}>
                      <LINK>View Details</LINK>
                      <img
                        src={toggle ? UpArrow : DownArrow}
                        alt=""
                        style={{ marginLeft: "8px" }}
                      />
                    </PointerWrapper>
                  </DIV>
                </DIV>
              </FChild>
            </FC>
            {toggle && (
              <FC {...flex}>
                <FChild flex={0.6}>
                  <PLACEHOLDER />
                </FChild>
                <FChild flex={9.4}>
                  <DIV m_padding={0} width={"100%"} {...rightWrapper}>
                    <DIV mtop={"23px"} m_padding={0} width={"100%"}>
                      <table className="table table-bordered">
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "#F5F5F5",
                              color: "#757575"
                            }}
                          >
                            <th className="text-left">Questions</th>
                            <th className="text-center">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-left pt-3 pb-3">
                            Q1 - Morbi pharetra tincidunt tortor, eget feugiat lorem
                            </td>
                            <td className="text-center ">
                              <img
                                src={require("../../assets/icons/success.png")}
                                alt="success"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left  pt-3 pb-3">
                            Q2 - Morbi pharetra tincidunt tortor, eget feugiat lorem
                            </td>
                            <td className="text-center ">
                              <img
                                src={require("../../assets/icons/success.png")}
                                alt="success"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left  pt-3 pb-3">
                            Q3 - Morbi pharetra tincidunt tortor, eget feugiat lorem
                            </td>
                            <td className="text-center ">
                              <img
                                src={require("../../assets/icons/success.png")}
                                alt="success"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left  pt-3 pb-3">
                            Q4 - Morbi pharetra tincidunt tortor, eget feugiat lorem
                            </td>
                            <td className="text-center ">
                              <img
                                src={require("../../assets/icons/success.png")}
                                alt="success"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left  pt-3 pb-3">
                            Q5 - Morbi pharetra tincidunt tortor, eget feugiat lorem
                            </td>
                            <td className="text-center ">
                              <img
                                src={require("../../assets/icons/success.png")}
                                alt="success"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left  pt-3 pb-3">
                            Q6 - Morbi pharetra tincidunt tortor, eget feugiat lorem
                            </td>
                            <td className="text-center ">
                              <img
                                src={require("../../assets/icons/success.png")}
                                alt="success"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left  pt-3 pb-3">
                            Q7 - Morbi pharetra tincidunt tortor, eget feugiat lorem
                            </td>
                            <td className="text-center ">
                              <img
                                src={require("../../assets/icons/success.png")}
                                alt="success"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left  pt-3 pb-3">
                            Q8 - Morbi pharetra tincidunt tortor, eget feugiat lorem <br />
                              <span style={{ color: "#4bd800" }}>
                               Morbi pharetra tincidunt tortor, eget feugiat lorem
                              </span>{" "}
                            </td>
                            <td className="text-center ">
                              <img
                                src={require("../../assets/icons/cross.png")}
                                alt="success"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left  pt-3 pb-3">
                            Q9 - Morbi pharetra tincidunt tortor, eget feugiat lorem
                            </td>
                            <td className="text-center ">
                              <img
                                src={require("../../assets/icons/success.png")}
                                alt="success"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </DIV>
                  </DIV>
                </FChild>
              </FC>
            )}
            <FC {...flex}>
              <FChild flex={0.6}>
                <PLACEHOLDER />
              </FChild>
              <FChild flex={9.4} m_width={"100%"}>
                <DIV m_padding={0} width={"inherit"} {...rightWrapper}>
                  <DIV
                    mtop={"23px"}
                    m_padding={0}
                    maxwidth={"inherit"}
                    width={"inherit"}
                  >
                    <Button
                      bg="white"
                      border="1px solid #4c10c1"
                      color="#4c10c1"
                      onClick={this.deleteSelected}
                      size={"12px"}
                      radius={"0"}
                      m_width={"100%"}
                    >
                      {" "}
                      Finish Quiz
                    </Button>
                  </DIV>
                </DIV>
              </FChild>
            </FC>
          </DIV>
        </Container>
      </Wrapper>
    );
  }
}

export default Home;
