import React, { Component,useState } from 'react';

// custom packages
import CardWrapper from "../../styledcomponents/CardWrapper"
import FlexContainer from "../../styledcomponents/FlexContainer"
import Tag from "../../styledcomponents/Tag"
import CardHeader from "../../styledcomponents/CardHeader"
import SmallText from "../../styledcomponents/SmallText";
import Container from "../../styledcomponents/Container";
import Divider from "@material-ui/core/Divider";
import PointerWrapper from "../../styledcomponents/PointerWrapper";

// npm packages
import { Redirect } from "react-router-dom";

 class Card extends Component {
   state = {
     path: null
   };

   redirect = () => {
     this.setState({path : `/consumer/name/id/appearQuiz/${this.props.quiz.coursemodule}/${this.props.quiz.id}/${encodeURIComponent(this.props.quiz.name)}`})
   }
   render() {
     const { path } = this.state;
     const { quiz } = this.props;
     return (
       <CardWrapper onClick={this.redirect} margin="20px 1rem 0 0"> 
         {path && <Redirect to={path} />}
         <FlexContainer direction="column" m_direction="column">
           <Tag>Mobile</Tag>
           <Container top="12px" bottom="12px" left="0px">
             <CardHeader>{quiz.name}</CardHeader>
           </Container>
           <FlexContainer>
             <Container right="10px" left="0px">
               <img
                 src={require("../../../assets/icons/questionMark.png")}
                 alt="document"
               />
             </Container>
             <SmallText>Questions-10</SmallText>
           </FlexContainer>
           <div style={{ width: "100%" }}>
             <Container top="10px" bottom="18px" left="0px">
               <Divider />
             </Container>
           </div>
           <PointerWrapper>
             <FlexContainer alignItems="center">
               <span style={{ color: "#4c10c1", marginRight: "8px",fontSize:"12px",lineHeight: "2.08",fontWeight: 500 }}>Start Quiz</span>
               <img
                 src={require("../../../assets/icons/Symbol639.png")}
                 alt="document"
               />
             </FlexContainer>
           </PointerWrapper>
         </FlexContainer>
       </CardWrapper>
     );
   }
 }


export default Card;
