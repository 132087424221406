import axios from 'axios'
import { baseUrl } from '../../config/apiUrl'

// type
export const SET_CONSUMER_COURSE_DETAILS = 'SET_CONSUMER_COURSE_DETAILS'
export const SET_GROUP_DETAILS = 'SET_GROUP_DETAILS'
export const SET_USER_ID = 'SET_USER_ID'

export const setConsumerCoursesList = (payload) => {
    return {
        type: SET_CONSUMER_COURSE_DETAILS,
        payload,
    }
}

// export const setGroupDetails = (payload) => {
//     console.log('payload', payload)
//     return {
//         type: SET_GROUP_DETAILS,
//         payload,
//     }
// }

// export const setUserId = (payload) => {
//     return {
//         type: SET_USER_ID,
//         payload,
//     }
// }

// export const setUsersIdsApi = (usersInfo, callback,dispatch)  => {
//     if (callback) {
//         callback(true)
//     }
//     let url = `${baseUrl}wsfunction=core_user_create_users&moodlewsrestformat=json&`

// usersInfo.forEach(({ username, name, email }, index) => {
//     if (email) {
//         url += `users[${index}][username]=${username}&users[${index}][firstname]=${name}&users[${index}][lastname]=null&users[${index}][email]=${email}&users[${index}][password]=Test@123&users[${index}][auth]=manual&`
//     }
// })
// const trimmedUrl = url.slice(0, -1)

//     axios.get(trimmedUrl).then((res) => {
//         if (callback) {
//             callback(false)
//         }
//         dispatch(setUserId(res.data))

//     })
// }

// export const setUserApi = (loading) => (dispatch) => {
//     const headers = {
//         Authorization:
//             'tZVYelozPSmyaUJGnqCnUY5CxsFdp6j37fZdiYqWE2CnoJSQnmf7iAWHVgkQNQEV',
//         'content-Type': 'application/json',
//     }

//     const param = {
//         partnerId: 9,
//     }
//     loading(true)

//     axios
//         .post(
//             'http://localweb.servify.tech/lms/api/v1/users/getUserListByPartnerId',
//             param,
//             { headers: headers }
//         )
//         .then((resp) => {
//             loading(false)
//             let data = []
//             for (const key in resp.data) {
//                 resp.data[key].forEach((val) => {
//                     data = [...data, { ...val, role2: key }]
//                 })
//             }

//             dispatch(setUserDetails(data))
//             setUsersIdsApi(data, loading,dispatch)
//         })
//         .catch((error) => console.log('error', error))
// }

export const CourseListApi = (dispatch, id, success, loading,courseId) => {
    axios
        .get(
            `${baseUrl}wsfunction=core_enrol_get_users_courses&moodlewsrestformat=json&userid=${id}`
        )
        .then((resp) => {
            if (loading) {
                loading(false)
            }

            dispatch(setConsumerCoursesList(resp.data))

            if (success) {
                success(dispatch, resp.data, null, loading,courseId)
            }
        })
        .catch((error) => console.log('error', error))
}

export const setConsumerCourseListApi = (loading, success, id) => (
    dispatch
) => {
    if (loading) {
        loading(true)
    }

    CourseListApi(dispatch, id, success, loading)
}
