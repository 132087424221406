import React, { Component } from "react";
import Editor from "./EditorComponent";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import { EditorState, convertToRaw } from "draft-js";
import Button from "@material-ui/core/Button";
import { stateToHTML } from "draft-js-export-html";
import api from "../../../utils/api";
import { customBaseUrl, post } from "../../../config/apiUrl";
import { resetSnackbar, setSnackbar } from "../../../actions/snackbarAction";
import { connect } from "react-redux";


const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "300px",
    paddingLeft: "12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

const styles = theme => ({
  input: {
    border: "1px solid #CCCCCC",
    borderRadius: "5px",
    paddingLeft: "12px"
  },

  label: {
    marginBottom: "0.5rem"
  },
  section: {
    marginBottom: "1.5rem"
  },
  heading: {
    borderBottom: "1px solid black",
    color: "#484848",
    paddingBottom: "16px"
  },
  answerSection: {
    // backgroundColor: "#AAAAAA"
    marginBottom: "1.3rem",
    marginTop: "1.3rem",
    borderBottom: "1px solid black"
  }
});

class AddTrueFalseQuestion extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <h3 className={classes.heading}>Answers</h3>
        <div className={classes.answerSection}>
          <div className={classes.section}>
            <div className={classes.label}>ResponseFormat *</div>
            <div>
              <FormControl className={classes.margin}>
                <NativeSelect
                  value={this.props.editor}
                  onChange={this.props.changeEditor}
                  input={
                    <BootstrapInput
                      name="age"
                      id="age-customized-native-simple"
                    />
                  }
                >
                  <option value={"multichoice"} selected>
                   HTML editor
                  </option>
                  <option value={"truefalse"}>Plane text</option>
                  
                </NativeSelect>
              </FormControl>
            </div>
          </div>

          <div className={classes.section}>
            <div className={classes.label}>Required Text *</div>
            <div>
              <FormControl className={classes.margin}>
                <NativeSelect
                  value={this.props.editor}
                  onChange={this.props.changeEditor}
                  input={
                    <BootstrapInput
                      name="age"
                      id="age-customized-native-simple"
                    />
                  }
                >
                  <option value={"multichoice"} selected>
                    Require the student to enter the text
                  </option>
                  <option value={"truefalse"}>Text input is optional</option>
                  
                </NativeSelect>
              </FormControl>
            </div>
          </div>

          <div className={classes.section}>
            <div className={classes.label}>Input box size *</div>
            <div>
              <FormControl className={classes.margin}>
                <NativeSelect
                  value={this.props.editor}
                  onChange={this.props.changeEditor}
                  input={
                    <BootstrapInput
                      name="age"
                      id="age-customized-native-simple"
                    />
                  }
                >
                  <option value={"multichoice"} selected>
                    Multiple answer allowed
                  </option>
                  <option value={"truefalse"}>True / False</option>
                  <option value={"matching"}>Matching</option>
                  <option value={"essay"}>Essay</option>
                </NativeSelect>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AddTrueFalseQuestion);
