import React, { Component } from "react";
import Editor from "./EditorComponent";
import MiceEditor from "./MiceEditor";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import { EditorState, convertToRaw } from "draft-js";
import Button from "@material-ui/core/Button";
import { stateToHTML } from "draft-js-export-html";
import api from "../../../utils/api";
import { customBaseUrl, post } from "../../../config/apiUrl";
import { resetSnackbar, setSnackbar } from "../../../actions/snackbarAction";
import { connect } from "react-redux";

// styled components
import CT from "../../../consumercomponents/styledcomponents/CT";
import Label from "../../../consumercomponents/styledcomponents/Label";
import CustomButton from "../../../consumercomponents/styledcomponents/Button";

const styles = theme => ({
  input: {
    border: "1px solid #CCCCCC",
    borderRadius: "5px",
    paddingLeft: "12px"
  },

  label: {
    marginBottom: "0.5rem"
  },
  section: {
    marginBottom: "1.5rem"
  },
  heading: {
    borderBottom: "1px solid black",
    color: "#484848",
    paddingBottom: "16px"
  },
  answerSection: {
    // backgroundColor: "#AAAAAA"
    marginBottom: "1.3rem",
    marginTop: "1.3rem",
    borderBottom: "1px solid black"
  }
});

class AddTrueFalseQuestion extends Component {
  state = {
    data: [
      {
        editorState: "",
        answer: ""
      },
      {
        editorState: "",
        answer: ""
      },
      {
        editorState: "",
        answer: ""
      }
    ],
    editorState: ""
  };

  handleChangeChoice = (index, e) => {
    this.setState(prevState => {
      const data = prevState.data;
      data[index].editorState = e.target.getContent();
      return {
        data
      };
    });
  };

  handleChangeName = (index, e) => {
    const value = e.target.value;
    this.setState(prevState => {
      const data = prevState.data;
      data[index].answer = value;
      return {
        data
      };
    });
  };

  addBlanks = () => {
    this.setState(prevState => ({
      data: [
        ...prevState.data,
        {
          editorState: EditorState.createEmpty(),
          answer: ""
        }
      ]
    }));
  };

  getHtml = state => {
    return stateToHTML(state.getCurrentContent());
  };

  addQuestion = () => {
    const { data } = this.state;
    let options = "";
    data.forEach((option, i) => {
      options += `options[${i}][question]=${encodeURIComponent(
        option.editorState
      )}&options[${i}][answer]=${option.answer}&`;
    });
    const { qId, cId, cmId } = this.props.ids;
    const { name, qText, defaultMark } = this.props;
    const url = `${customBaseUrl}wsfunction=local_servifywebapis_add_matching_type_question&courseid=${cId}&cmid=${cmId}&name=${name}&questiontext=${encodeURIComponent(
      qText
    )}&defaultmark=${defaultMark}&${options}shuffleanswers=1`;
    api(url, post, null, this.props.success);
  };

  render() {
    const { classes } = this.props;
    const { data, name } = this.state;
    console.log(this.state);
    const field = data.map((info, i) => (
      <div className={classes.answerSection} key={i}>
        <div className={classes.section}>
          {/* <div className={classes.label}>Question</div> */}
          <CT>
            <Label>Question</Label>
          </CT>
          <div>
            <MiceEditor
              state={info.editorState}
              handler={e => this.handleChangeChoice(i, e)}
            />
            {/* <Editor
              height={"40px"}
              editorState={info.editorState}
              onEditorStateChange={this.handleChangeChoice.bind(this, i)}
            /> */}
          </div>
        </div>

        <div className={classes.section}>
          <CT>
            <Label>Answer</Label>
          </CT>
          <div>
            <div className={classes.section}>
              <div>
                <InputBase
                  className={classes.input}
                  fullWidth
                  value={info.answer}
                  onChange={value => this.handleChangeName(i, value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
    return (
      <div>
        <h3 className={classes.heading}>Answers</h3>
        {field}
        <div style={{ marginBottom: "16px" }}>
          <CustomButton
            border="1px solid #4c10c1"
            onClick={this.addBlanks}
            width={"200px"}
            m_width={"100%"}
          >
            {" "}
            Blank More Question
          </CustomButton>
          {/* <Button variant="contained" color="primary" onClick={this.addBlanks}>
            Blank More Question
          </Button> */}
        </div>
        <div style={{ marginBottom: "16px" }}>
          <CustomButton
            border="1px solid #4c10c1"
            onClick={this.addQuestion}
            m_width={"100%"}
            width={"200px"}
          >
            {" "}
            Add
          </CustomButton>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={this.addQuestion}
          >
            Add
          </Button> */}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AddTrueFalseQuestion);
