import React, { Component } from "react";

// custom components
import Header from "../subComponents/Header";
import Body from "../Home/subComponents/Body";
import Heading from "../subComponents/Heading";
import AddGroupWrapper from "./styledComponents/AddGroupWrapper";
import UserInfo from "./subComponents/UserInfo";
import { setSubMenuTabAction } from "../../actions/tabAction";

// styled components
import Container from "../../consumercomponents/styledcomponents/Container";
import FlexContainer from "../../consumercomponents/styledcomponents/FlexContainer";
import PointerWrapper from "../../consumercomponents/styledcomponents/PointerWrapper"; 

// npm packages
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

//Image import
import back from "../../assets/icons/back.png";

const styles = {
  Wrapper: {
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    bg: "inherit",
    pleft: "30px",
    pright: "40px",
    m_padding: "0px"
  }
};

class AddGroup extends Component {
  state = {
    path: null
  };

  redirect = path => this.setState({ path });
  render() {
    const { path } = this.state;
    const { setSubMenuTabAction } = this.props;
    return (
      <div style={{ height: "100vh" }}>
        {path && <Redirect to={path} />}
        <Header />
        <Body>
          {/* <Heading text="Add Group" /> */}
          <Container {...styles.Wrapper}>
            <div
              className="row"
              style={{ marginBottom: "42px", marginTop: "42px" }}
            >
              <div className="col-sm-12 col-md-6 col-lg-6">
                <Container bg="inherit" left="0px" m_margin="0 0 0 0px">
                  <FlexContainer m_justifyContent={"center"}>
                    <Heading text="User Management - Users" />
                  </FlexContainer>
                </Container>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <Container left="0px" m_margin="29px 0px 29px 0px" bg="inherit">
                  <FlexContainer
                    justifyContent="flex-end"
                    m_justifyContent="center"
                  >
                    <div onClick={() => this.redirect("/userManagement")}>
                      <PointerWrapper>
                        <img src={back} alt="back" />
                      </PointerWrapper>
                    </div>
                  </FlexContainer>
                </Container>
              </div>
            </div>

            <AddGroupWrapper>
              <Container pleft={"40px"} m_padding={"0px"}>
                <Heading text={"View User - Maecenas sit amet"} />
              </Container>
              <section>
                <Container
                  margin="44px 0px 0px 0px"
                  top="44px"
                  pleft={"40px"}
                  m_padding={0}
                >
                  <UserInfo />
                </Container>
              </section>
            </AddGroupWrapper>
          </Container>
        </Body>
      </div>
    );
  }
}

export default connect(
  null,
  setSubMenuTabAction
)(AddGroup);
