import styled from "styled-components";
import Screen from "./ScreenSizes";

export default styled.div`
  height: ${props => props.height && props.height};
  width: ${props => props.width && props.width};
  margin-top: ${props => props.top};
  margin-right: ${props => props.right};
  margin-bottom: ${props => props.bottom};
  margin-left: ${props => props.left};
  padding-top: ${props => (props.ptop ? props.ptop : "0")};
  padding-right: ${props => (props.pright ? props.pright : "0")};
  padding-bottom: ${props => (props.pbottom ? props.pbottom : "0")};
  padding-left: ${props => (props.pleft ? props.pleft : "0")};
  background-color: ${props => (props.bg ? props.bg : "inherit")};
  border-radius: ${props => props.radius && props.radius};
  border: ${props => props.border && props.border};
  border-top: ${props => props.b_top && props.b_top};
  border-bottom: ${props => props.b_bottom && props.b_bottom};

  @media ${Screen.tablet} {
    padding: ${props => props.m_padding && props.m_padding}!important;
    margin: ${props => props.m_margin && props.m_margin};
    width: ${props => props.m_width && props.m_width};
    overflow: ${props => props.m_flow && props.m_flow};
  }
`;
