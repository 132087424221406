import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import Chip from "./Chip";
import Button from "@material-ui/core/Button";
import QustionList from "./Questions";
import api from "../../../utils/api";
import { post, customBaseUrl, baseUrl } from "../../../config/apiUrl";
import lineHeight from "../../../utils/lineHeight";
import { Redirect } from "react-router-dom";
import NativeSelect from "@material-ui/core/NativeSelect";
import { connect } from "react-redux";
import { setTabAction } from "../../../actions/tabAction";
import DatePicker from "./DatePicker";
import Checkbox from "@material-ui/core/Checkbox";
import TimePicker from "./TimePicker";
import CustomInput from "./CustomInput";
import Loader from "../../subComponents/Loader";

// styled components
import CustomButton from "../../../consumercomponents/styledcomponents/Button";
import CT from "../../../consumercomponents/styledcomponents/CT";
import Label from "../../../consumercomponents/styledcomponents/Label";
import FC from "../../../consumercomponents/styledcomponents/FlexContainer";
import TEXT from "../../../consumercomponents/styledcomponents/Label";

const Styles = theme => ({
  label: {
    marginBottom: "0.5rem"
  },
  formControl: {
    width: "100%",
    marginBottom: "16px"
  },
  margin: {
    width: "auto"
  },
  input: {
    border: "1px solid #CCCCCC",
    borderRadius: "5px",
    paddingLeft: "12px",
    width: "100%"
  },
  inputTime: {
    border: "1px solid #CCCCCC",
    borderRadius: "5px"
  },
  customInput: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  },
  checkboxContainer: {
    display: "flex"
  }
});

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    // fontFamily: "Gotham",
    fontSize: "12pt",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

class FindDetailsTab extends React.Component {
  state = {
    age: "test",
    name: "",
    nameError: false,
    nameErrorMessage: "",
    courseError: false,
    courseErrorMessage: "",
    topicError: false,
    topicErrorMessage: "",
    introError: false,
    introErrorMessage: "",
    descriptionError: false,
    descriptionErrorMessage: "",
    path: null,
    selectedCourseId: "",
    topics: [],
    selectedTopicId: "",
    intro: "",
    content: "",
    timeOpen: new Date(),
    timeClose: new Date(),
    timeLimit: null,
    timeLimitError: false,
    timeLimitErrorMessage: "",
    rightanswerimmediately: 0,
    marksimmediately: 0,
    timeLimitMinute: null,
    timeLimitSecs: null,
    AddQuizLoading: false
  };

  handleChange = event => {
    this.setState({ selectedCourseId: event.target.value });
    this.getTopics(event.target.value);
  };

  handleChangeTopic = event => {
    this.setState({ selectedTopicId: event.target.value });
  };

  handleDateOpenChange = date => {
    this.setState({
      timeOpen: date
    });
  };

  handleDateCloseChange = date => {
    this.setState({
      timeClose: date
    });
  };

  onChange = (name, event) => {
    this.setState({ [name]: event.target.value });
  };

  getTopics = selectedCourseId => {
    this.setState({
      getTopicsLoading: true
    });
    const url = `${baseUrl}wsfunction=core_course_get_contents&moodlewsrestformat=json&courseid=${selectedCourseId}`;
    api(url, post, null, this.successTopic);
  };

  successTopic = res => {
    this.setState({
      topics: res.data,
      selectedTopicId: res.data[0].id
    });
  };

  resetValidation = () => {
    this.setState({
      nameError: false,
      nameErrorMessage: "",
      courseError: false,
      courseErrorMessage: "",
      topicError: false,
      topicErrorMessage: "",
      descriptionError: false,
      introError: false,
      introErrorMessage: "",
      descriptionErrorMessage: ""
    });
  };

  validation = fields => {
    let validationResult = true;
    this.resetValidation();
    fields.forEach((field, i) => {
      if (!field) {
        validationResult = false;
        switch (i) {
          case 0:
            this.setState({
              nameError: true,
              nameErrorMessage: "Invalid name"
            });
            break;
          case 1:
            this.setState({
              introError: true,
              introErrorMessage: "Invalid intro"
            });
            break;
          case 2:
            this.setState({
              descriptionError: true,
              descriptionErrorMessage: "Invalid description"
            });
            break;
          case 3:
            this.setState({
              courseError: true,
              courseErrorMessage: "Invalid course"
            });
            break;

          case 4:
            this.setState({
              timeLimitError: true,
              timeLimitErrorMessage: "Invalid Time limit"
            });
            break;

          default:
            break;
        }
      }
    });
    return validationResult;
  };

  addQuiz = () => {
    const {
      name,
      intro,
      content,
      selectedCourseId,
      selectedTopicId,
      timeClose,
      timeOpen,
      timeLimitSecs,
      timeLimitMinute,
      rightanswerimmediately,
      marksimmediately
    } = this.state;
    const isValid = this.validation([name, intro, content, selectedCourseId]);
    const timeOpenUnix = Math.round(+timeOpen / 1000);
    const timeCloseUnix = Math.round(+timeClose / 1000);
    const timeLimit = timeLimitMinute * 60 + parseInt(timeLimitSecs);
    isValid && this.setState({ AddQuizLoading: true });
    if (isValid) {
      const url = `${customBaseUrl}wsfunction=local_servifywebapis_create_quiz&moodlewsrestformat=json&courseid=${selectedCourseId}&topicid=${selectedTopicId}&name=${name}&visible=1&content=${content}&intro=${intro}&timeopen=${timeOpenUnix}&timeclose=${timeCloseUnix}&timelimit=${timeLimit}&rightanswerimmediately=${rightanswerimmediately}&marksimmediately=${marksimmediately}&preferredbehaviour=deferredfeedback`;
      api(url, post, null, this.successAddQuiz);
    }
  };

  successAddQuiz = res => {
    console.log(res);
    this.setState({ path: "/quizManagement", AddQuizLoading: false });
  };

  setInputValue = value => {
    this.setState({ name: value });
  };

  setCheckbox = (e, name) => {
    this.setState({ [name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
  };

  render() {
    const { classes, courses } = this.props;
    const {
      nameError,
      nameErrorMessage,
      courseError,
      courseErrorMessage,
      topicError,
      topicErrorMessage,
      descriptionError,
      introError,
      introErrorMessage,
      descriptionErrorMessage,
      path,
      topics,
      timeClose,
      timeOpen,
      timeLimit,
      timeLimitError,
      timeLimitErrorMessage
    } = this.state;
    console.log("courses", courses);
    const coursesList = courses.map((crs, i) => {
      return (
        <option
          value={crs.id}
          key={i}
          style={{
            paddingLeft: "20px",

            fontSize: "12pt"
          }}
        >
          {crs.fullname}
        </option>
      );
    });

    const topicList = topics.map((tps, i) => {
      return (
        <option value={tps.id} key={i}>
          {tps.name}
        </option>
      );
    });
    return (
      <form onSubmit={this.onSubmit}>
        <div style={{ marginTop: "2rem" }}>
          {path && <Redirect to={path} />}

          <div
            style={{
              display: "flex",
              flexDirection: "column"
              // justifyContent: "space-between"
            }}
          >
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              {/* <div
                className={classes.label}
                style={{
                  color: nameError ? "red" : "#393939",
                  fontFamily: "GothamBook"
                }}
              >
                {nameError ? nameErrorMessage : "Quiz Name *"}
              </div> */}
              <CT>
                <Label error={nameError}>
                  {nameError ? nameErrorMessage : "Quiz Name *"}
                </Label>
              </CT>

              <FormControl className={classes.margin}>
                <InputBase
                  className={classes.customInput}
                  onChange={e => this.setInputValue(e.target.value)}
                  style={{
                    borderColor: nameError ? "red" : "#cccccc",
                    paddingLeft: "20px",
                    // fontFamily: "Gotham",
                    fontSize: "12pt"
                  }}
                />
              </FormControl>
              {/* <CustomInput /> */}
            </div>
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              {/* <div
                className={classes.label}
                style={{
                  color: courseError ? "red" : "#393939",
                  fontFamily: "GothamBook",
                  marginBottom: "16px",
                  marginTop: "16px"
                }}
              >
                {courseError ? courseErrorMessage : "Course Name *"}
              </div> */}
              <CT top={"1rem"}>
                <Label error={courseError}>
                  {courseError ? courseErrorMessage : "Course Name *"}
                </Label>
              </CT>
              {/* <FormControl className={classes.margin}>
              <NativeSelect
                value={this.state.selectedCourseId}
                onChange={this.handleChange}
                input={
                  <BootstrapInput
                    name="age"
                    id="age-customized-native-simple"
                  />
                }
              >
                <option value="" />
                {coursesList}
              </NativeSelect>
            </FormControl> */}
              <div className="form-group">
                <select
                  className="custom-select"
                  id="exampleFormControlSelect1"
                  // value={this.state.selectedCourseId}
                  onChange={this.handleChange}
                  required
                >
                  <option value="" />
                  {coursesList}
                </select>
                <div class="invalid-feedback">
                  Example invalid custom select feedback
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              {/* <div
                className={classes.label}
                style={{
                  color: topicError ? "red" : "#393939",
                  fontFamily: "GothamBook",
                  marginBottom: "16px",
                  marginTop: "16px"
                }}
              >
                {topicError ? topicErrorMessage : "Select topic"}
              </div> */}
              <CT>
                <Label error={topicError}>
                  {topicError ? topicErrorMessage : "Select topic"}
                </Label>
              </CT>
              {/* <FormControl className={classes.margin}>
              <NativeSelect
                value={this.state.selectedTopicId}
                onChange={this.handleChangeTopic}
                input={
                  <BootstrapInput
                    name="age"
                    id="age-customized-native-simple"
                    style={{ borderColor: topicError ? "red" : "#cccccc" }}
                  />
                }
              >
                {topicList}
              </NativeSelect>
            </FormControl> */}
              <select
                className="form-control"
                id="exampleFormControlSelect1"
                defaultValue={this.state.selectedTopicId}
                onChange={this.handleChangeTopic}
              >
                <option value="" />
                {topicList}
              </select>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "60%",
              marginTop: "16px"
            }}
          >
            <div style={{ marginRight: "16px" }}>
              {/* <div
                className={classes.label}
                style={{
                  color: "#393939",
                  fontFamily: "GothamBook",
                  marginBottom: "10px",
                  marginTop: "16px"
                }}
              >
                Start Date
              </div> */}
              <CT>
                <Label>Start Date</Label>
              </CT>
              <DatePicker
                selectedDate={timeOpen}
                minDate={new Date()}
                handleDateChange={this.handleDateOpenChange}
              />
            </div>
            <div style={{ marginRight: "16px" }}>
              {/* <div
                className={classes.label}
                style={{
                  color: "#393939",
                  fontFamily: "GothamBook",
                  marginBottom: "10px",
                  marginTop: "16px"
                }}
              >
                End Date
              </div> */}
              <CT>
                <Label> End Date</Label>
              </CT>
              <DatePicker
                selectedDate={timeClose}
                handleDateChange={this.handleDateCloseChange}
              />
            </div>
          </div>

          <div>
            <div style={{ marginTop: "16px", marginBottom: "16px" }}>
              {/* <div
                className={classes.label}
                style={{
                  color: introError ? "red" : "#393939",
                  fontFamily: "GothamBook"
                }}
              >
                {introError ? introErrorMessage : "Summary"}
              </div> */}
              <CT>
                <Label error={introError}>
                  {introError ? introErrorMessage : "Summary"}
                </Label>
              </CT>
              <InputBase
                className={classes.input}
                fullwidth
                onChange={e => this.onChange("intro", e)}
                style={{
                  borderColor: introError ? "red" : "#cccccc",
                  paddingLeft: "20px",
                  fontSize: "12pt"
                }}
              />
            </div>

            <div style={{ marginTop: "16px", marginBottom: "16px" }}>
              {/* <div
                className={classes.label}
                style={{
                  color: introError ? "red" : "#393939",
                  fontFamily: "GothamBook"
                }}
              >
                {timeLimitError
                  ? timeLimitErrorMessage
                  : "Time Limit in minutes"}
              </div> */}
              <CT>
                <Label error={timeLimitError}>
                  {timeLimitError
                    ? timeLimitErrorMessage
                    : "Time Limit in minutes"}
                </Label>
              </CT>
              <div
                style={{
                  display: "flex",
                  width: "200px"
                }}
              >
                <div style={{ marginRight: "16px" }}>
                  <InputBase
                    className={classes.inputTime}
                    fullwidth
                    placeholder="MM"
                    type="number"
                    onChange={e => this.onChange("timeLimitMinute", e)}
                    style={{
                      borderColor: introError ? "red" : "#cccccc",
                      paddingLeft: "20px",
                      fontSize: "12pt"
                    }}
                  />
                </div>
                <div>
                  <InputBase
                    className={classes.inputTime}
                    fullwidth
                    placeholder="SS"
                    type="number"
                    onChange={e => this.onChange("timeLimitSecs", e)}
                    style={{
                      borderColor: introError ? "red" : "#cccccc",
                      paddingLeft: "20px",
                      fontSize: "12pt"
                    }}
                  />
                </div>
              </div>
              {/* <InputBase
              className={classes.input}
              fullwidth
              type="number"
              onChange={e => this.onChange("timeLimit", e)}
              style={{
                borderColor: introError ? "red" : "#cccccc",
                paddingLeft: "20px",
                fontFamily: "Gotham",
                fontSize: "12pt"
              }}
            /> */}
              {/* <TimePicker /> */}
            </div>

            <div style={{ marginBottom: "16px" }}>
              {/* <div
                className={classes.label}
                style={{
                  color: descriptionError ? "red" : "#393939",
                  fontFamily: "GothamBook"
                }}
              >
                {descriptionError ? descriptionErrorMessage : "Description"}
              </div> */}
              <CT>
                <Label error={descriptionError}>
                  {descriptionError ? descriptionErrorMessage : "Description"}
                </Label>
              </CT>

              <InputBase
                className={classes.customInput}
                multiline={true}
                fullWidth={true}
                rows={8}
                onChange={e => this.onChange("content", e)}
                style={{
                  borderColor: nameError ? "red" : "#cccccc",
                  paddingLeft: "20px",
                  // fontFamily: "Gotham",
                  fontSize: "12pt"
                }}
              />
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="checkbox" />
              <span style={{ marginLeft: "8px" }}>
                <Label weight={"400"}>
                  Show right answer to the user after attempting quiz.
                </Label>
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="checkbox" />
              <span style={{ marginLeft: "8px" }}>
                <Label weight={"400"}>
                  Show scores to the user after attempting quiz. 
                </Label>
              </span>
            </div>

            <div style={{ marginTop: "1rem" }}>
              {this.state.AddQuizLoading ? (
                <Loader />
              ) : (
                <CustomButton
                  border="1px solid #4c10c1"
                  onClick={this.addQuiz}
                  m_width={"100%"}
                  type={"submit"}
                >
                  Done
                </CustomButton>
              )}
            </div>
          </div>

          {/* <QustionList /> */}
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  courses: state.course.courses
});

export default connect(
  mapStateToProps,
  { setTabAction }
)(withStyles(Styles)(FindDetailsTab));
