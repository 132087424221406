import styled from "styled-components";


export default styled.span`
  color: #4bd800;
  margin-left: ${props => props.left ? props.left : "0px"};
  margin-right: ${props => props.right ? props.right : "0px"};
  margin-top: ${props => props.top ? props.top : "0px"};
  margin-bottom: ${props => props.bottom ? props.bottom : "0px"};
  cursor: pointer;
  
`;