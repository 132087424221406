import React from 'react';
import Container from "../../styledcomponents/Container"
import FlexContainer from "../../styledcomponents/FlexContainer"

export default function Lession({ status }) {
     const image = status
       ? require("../../../assets/icons/success.png")
       : require("../../../assets/icons/failure.png");
     return (
       <Container left="0px" top="20px">
         <div className="row">
           <div className="col-sm-12 col-md-11 col-lg-11">
             <span
               style={{
                 fontSize: "14px",
                 color: "#757575"
               }}
             >
               Lorem ipsum dolor sit amet consectetur adipisicing elit.
               Illo, dignissimos.
             </span>
           </div>
           <div className="col-sm-12 col-md-1 col-lg-1">
             <FlexContainer justifyContent="flex-end">
               <img
                 src={image}
                 alt="success"
               />
             </FlexContainer>
           </div>
         </div>
         <hr />
       </Container>
     );
}
