import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";

// custom components
import Header from "../subComponents/Header";
import Body from "../Home/subComponents/Body";
import Heading from "../subComponents/Heading";
import BackWrapper from "./styledComponents/BackWrapper";
import GridWrapper from "./styledComponents/GridWrapper";
import HeadingWrapper from "./styledComponents/HeadingWrapper";
import AddGroupWrapper from "./styledComponents/AddGroupWrapper";
import Search from "../subComponents/Search";
import FlexContainer from "./styledComponents/FlexContainer";
import Table from "./subComponents/tables/UserManagementTable";
import NewTable from "./tables/UserTable";
import { setTabAction, setSubMenuTabAction } from "../../actions/tabAction";
import { setUserDetails, setUserApi } from "../../actions/userManagementAction";

import DateFilter from "./DateFilter";

// styled components
import Container from "../../consumercomponents/styledcomponents/Container";
import Button from "../../consumercomponents/styledcomponents/Button";
import FC from "../../consumercomponents/styledcomponents/FlexContainer";
import Label from "../../consumercomponents/styledcomponents/Label";

// npm packages

class AddGroup extends Component {
  state = {
    open: false,
    filterStartDate: new Date(),
    filterEndDate: new Date(),
    data: [],
    filteredData: [],
    loading: false,
    isSearch: false
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  onDateChange = (date, name) => this.setState({ [name]: date });

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  onClick = () => {
    const { filterStartDate, filterEndDate } = this.state;
    let searchedProduct = this.state.data.filter(({ timecreated, name }) => {
      if (
        new Date(filterStartDate).getTime() / 1000 < timecreated &&
        timecreated < new Date(filterEndDate).getTime() / 1000
      ) {
      }

      return (
        new Date(filterStartDate).getTime() / 1000 < timecreated &&
        timecreated < new Date(filterEndDate).getTime() / 1000
      );
    });

    this.setState({
      filteredData: searchedProduct
    });
    this.handleClose();
  };


  setLoading = loading => {
    if (this.props.users.length == 0) {
      this.setState({ loading })
    }
  }

  
  
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.length !== prevState.filteredData.length && !prevState.isSearch) {
      return { filteredData: nextProps.users }; // <- this is setState equivalent
    }
  } 
  
  componentDidMount() {
    this.props.setTabAction("User Management");
    this.props.setSubMenuTabAction("Users");
    this.props.setUserApi(this.setLoading);
    this.setState({
      filteredData: this.props.users
    });
  }

  search = e => {
    let keyword = e.target.value;
    this.setState({
      search: keyword,
      isSearch: true
    });
    if (keyword.trim()) {
      let searchedProduct = this.props.users.filter(({ username }) =>
        username.toLowerCase().includes(keyword.toLowerCase())
      );
      this.setState({
        filteredData: searchedProduct
      });
    } else {
      this.setState({
        filteredData: this.props.users
      });
    }
  };

  render() {
    const styles = {
      default: {
        left: "0px",
        right: "0px",
        bottom: "0px",
        top: "0px"
      },
      Wrapper: {
        ...this.default,
        bg: "inherit",
        pleft: "30px",
        pright: "40px",
        m_padding: "0px"
      },
      buttonWrapper: {
        ...this.default,
        pleft: "20px",
        m_padding: "10px 0 0 0px",
        left: "0px",
        right: "0px"
      },
      ParentWrapper: {
        ...this.default,
        bg: "inherit",
        top: "28px"
      }
    };
    const {
      filterStartDate,
      filterEndDate,
      data,
      loading,
      filteredData
    } = this.state;
    console.log("filter", filteredData);
    const Loader = (
      <FC justifyContent={"center"} width={"100%"}>
        <Label>Loading User details ...</Label>
      </FC>
    );
    return (
      <Container>
        <Header />
        <Body>
          {/* <Heading text="Add Group" /> */}
          <DateFilter
            open={this.state.open}
            handleClose={this.handleClose}
            startDate={filterStartDate}
            endDate={filterEndDate}
            onDateChange={this.onDateChange}
            onClick={this.onClick}
          />
          <Container {...styles.Wrapper}>
            <Container {...styles.ParentWrapper}>
              <GridWrapper>
                <HeadingWrapper>
                  <FC m_justifyContent={"center"}>
                    <Heading text="User Management - Users" />
                  </FC>
                </HeadingWrapper>
                <BackWrapper justifyContent="flex-start">
                  <div style={{ width: "100%" }}>
                    <Container m_margin="29px 0px 0px 0px">
                      <Search search="Search Users" onChange={this.search} />
                    </Container>
                  </div>
                </BackWrapper>
              </GridWrapper>
            </Container>

            <AddGroupWrapper>
              <Container {...styles.buttonWrapper}>
                <FlexContainer>
                  <Button
                    bg="white"
                    color="#757575"
                    width="180px"
                    onClick={this.handleClickOpen}
                    m_width={"100%"}
                  >
                    {" "}
                    Filter by Date{" "}
                    <img
                      src={require("../../assets/icons/Filter.png")}
                      alt=""
                      style={{ marginLeft: "32px" }}
                    />
                  </Button>
                </FlexContainer>
              </Container>
              <Container
                left="0px"
                right={"0px"}
                top="40px"
                m_margin={"20px 0 0 0"}
              >
                {/* <Table loading={false} /> */}
                {loading ? (
                  Loader
                ) : (
                  <NewTable courseData={this.state.filteredData} />
                )}
              </Container>
            </AddGroupWrapper>
          </Container>
        </Body>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  users: state.userManagement.users
});
export default connect(
  mapStateToProps,
  { setTabAction, setSubMenuTabAction, setUserDetails, setUserApi }
)(AddGroup);
