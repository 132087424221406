import React from "react";
import styled from "styled-components";

// custom components
import Chart from "./Chart";

// styled components
import DIV from "../../styledcomponents/DIV";
import FC from "../../styledcomponents/FlexContainer";
import PW from "../../styledcomponents/PointerWrapper";
import BG from "../../styledcomponents/BG";

// images
import ArrowRight from "../../../assets/icons/Symbol639.png";
import GreenDOT from "../../../assets/icons/green_dot.svg";
import RedDot from "../../../assets/icons/red_dot.svg";

// css
import "../css/dashboard.css";
import { relative } from "path";
import screens from "../../styledcomponents/ScreenSizes"

const POSITION = styled.div`
  font-family: system-ui;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #757575;
  margin-right: 20px;
`;

const NAME = styled.div`
  font-family: system-ui;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #757575;
`;

const VALUE = styled.div`
  width: 58px;
  height: 30px;
  font-size: 25px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.6;
  letter-spacing: normal;
  text-align: center;
  color: #393939;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: -28px;
`;

const CHARTWRAPPER = styled.div`
  width: 150px;
  height: 150px;
  position: relative;
  margin-right: ${props => props.right};

  @media ${screens.tablet} {
   margin: ${props => props.m_margin}
  }
`;

const styles = {
  DIV: {
    padding: "40px",
    bg: "white",
    shadow: "0 2px 6px 0 rgba(0, 0, 0, 0.04)",
    height: "100%",
    m_padding : "35px 15px 40px 15px"
  },
  header: {
    fsize: "24px",
    color: "#393939",
    mbottom: "26px"
  },
  key: {
    fsize: "28px",
    color: "#757575"
  },
  value: {
    fsize: "16px",
    color: "#4bd800"
  },
  fc: {
    wrap: "no-wrap"
  },
  link: {
    fsize: "14px",
    color: "#4c10c1"
  },
  badge: {
    height: "242px",
    width: "242px"
  }
};

export default function Card({ header, details, total, value }) {
  console.log("details", details);

  return (
    <DIV {...styles.DIV}>
      <FC justifyContent={"space-between"}>
        <DIV {...styles.header}>Overview - Courses</DIV>
        <DIV {...styles.link}>
          <PW>
            View All Courses
            <img src={ArrowRight} alt="" style={{ marginLeft: "6px" }} />
          </PW>
        </DIV>
      </FC>

      {/* ------------------------------------------------------------------ */}

      <DIV mtop={"35px"}>

        <FC alignItems="flex-start" justifyContent="space-between">
          <FC m_justifyContent={"center"} justifyContent="space-between" width={"100%"} alignItems={"center"}>
          <FC m_justifyContent={"center"}>
            <CHARTWRAPPER right={"50px"} m_margin={"0 0 29px 0"}>
              <Chart color={"#4BD800"} data={5} />
              <VALUE>75%</VALUE>
            </CHARTWRAPPER>
            <CHARTWRAPPER>
              <Chart color={"#EC1E24"} data={1} />
              <VALUE>25%</VALUE>
            </CHARTWRAPPER>
          </FC>

          <DIV width={"20vw"} shadow={"0 4px 16px 0 rgba(69, 91, 99, 0.08)"} m_width={"100%"}>
            <DIV pleft={"24px"} pright={"24px"} ptop={"24px"} pbottom={"24px"} border={"1px solid #f4f4f6"}>
              <FC justifyContent={"space-between"}>
                <FC>
                  <img src={GreenDOT} alt="" style={{ marginRight: "16px" }} />
                  <NAME>No of Courses Viewed</NAME>
                </FC>
                <NAME>14</NAME>
              </FC>
            </DIV>
            <DIV pleft={"24px"} pright={"24px"} ptop={"24px"} pbottom={"24px"}>
              <FC justifyContent={"space-between"}>
                <FC>
                  <img src={RedDot} alt="" style={{ marginRight: "16px" }} />
                  <NAME>No of Unseen Courses</NAME>
                </FC>
                <NAME>6</NAME>
              </FC>
            </DIV>
            {/* <FC>cdc</FC> */}
            </DIV>
            </FC>
        </FC>
      </DIV>
    </DIV>
  );
}
