import React, { Component } from "react";

// custom components
import TabDetails from "./TabDetails"
import EditTabDetails from "./EditTabDetails"

export default class CustomTab extends Component {
  render() {
    return (
      <div>
        {this.props.type === "edit" ? (
          <EditTabDetails
            id={this.props.id}
            type={this.props.type}
            onDonePress={this.props.onDonePress}
          />
        ) : (
          <TabDetails
            type={this.props.type}
            onDonePress={this.props.onDonePress}
          />
        )}
      </div>
    );
  }
}
