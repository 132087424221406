import React, { Component } from "react";

// custom packages
import Wrapper from "../subcomponents/Wrapper";
import Heading from "../../components/subComponents/Heading";
import Search from "../../components/subComponents/Search";
import Container from "../styledcomponents/Container";
import PointerWrapper from "../styledcomponents/PointerWrapper";
import FlexContainer from "../styledcomponents/FlexContainer";
import Tag from "../styledcomponents/Tag";
import Lession from "./subcomponents/Lession"

// npm packages
import Paper from "@material-ui/core/Paper";

class Home extends Component {
  render() {
    return (
      <Wrapper>
        <div className="row">
          <div className="col-sm-12 col-md-11 col-lg-11">
            <Heading text="My Courses" />
          </div>
          <div className="col-sm-12 col-md-1 col-lg-1">
            <PointerWrapper>
              <FlexContainer alignItems="center">
                <i
                  class="fas fa-angle-left"
                  style={{ color: "blue", marginRight: "8px" }}
                />
                <span style={{ color: "blue" }}>Back</span>
              </FlexContainer>
            </PointerWrapper>
          </div>
        </div>

        <Container top="42px" left="0px">
          <Paper>
            <Container
              ptop="43px"
              pleft="43px"
              pright="43px"
              pbottom="43px"
            >
              <div className="row">
                <div className="col-sm-12 col-md-11 col-lg-11">
                  <span style={{ fontSize: "24px", color: "#757575" }}>
                    Apple Os Upgrade - AOU1234
                  </span>
                </div>
                <div className="col-sm-12 col-md-1 col-lg-1">
                  <Tag>Mobile</Tag>
                </div>
              </div>

              <Container top="42px" left="0px" bottom="15px">
                <div className="row">
                  <div className="col-sm-12 col-md-11 col-lg-11">
                    <span
                      style={{
                        fontSize: "16px",
                        color: "#757575",
                        fontWeight: "bold"
                      }}
                    >
                      Lessions
                    </span>
                  </div>
                  <div className="col-sm-12 col-md-1 col-lg-1">
                    <FlexContainer justifyContent="flex-end">
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#757575",
                          fontWeight: "bold"
                        }}
                      >
                        Status
                      </span>
                    </FlexContainer>
                  </div>
                </div>
              </Container>
              <Lession status={1} />
              <Lession status={0} />
              <Lession status={0} />
              <Lession status={0} />
              <Lession status={0} />
              <Container left="0px" top="43px">
                <PointerWrapper>
                  <FlexContainer alignItems="center">
                    <span style={{ color: "blue" }}>Start course</span>
                    <i
                      class="fas fa-angle-right"
                      style={{ color: "blue", marginLeft: "8px" }}
                    />
                  </FlexContainer>
                </PointerWrapper>
              </Container>
            </Container>
          </Paper>
        </Container>
      </Wrapper>
    );
  }
}

export default Home;
