import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// styled components;
import Label from "../styledComponents/Label";
import Container from "../styledComponents/Container";
import CT from "../../../consumercomponents/styledcomponents/Container"; // A container without default spacing
import TEXT from "../../../consumercomponents/styledcomponents/Label";
import Input from "../styledComponents/Input";
import TextArea from "../styledComponents/TextArea";
import Button from "../styledComponents/Button";
import { setGroupsApi } from "../../../actions/userManagementAction"

// custom packages
import api from "../../../utils/api";
import { post, baseUrl } from "../../../config/apiUrl";
import Loader from "../../subComponents/Loader";

const initialState = {
  name: "",
  description: "",
  nameError: "",
  descriptionError: ""
};

 class AddDetailsTabDetails extends Component {
  state = {
    ...initialState,
    path: null,
    loading: false,
    idnumber:0
   
  };

  redirect = path => this.setState({ path });

  change = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  validate = () => {
    let { name, description } = this.state;
    let { nameError, descriptionError } = initialState;
    let error = true;
    if (!name) {
      error = false;
      nameError = "Please enter a name.";
    }

    if (!description) {
      error = false;
      descriptionError = "Please enter some description.";
    }

    this.setState({ nameError, descriptionError });
    return error;
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.validate()) {
      console.log("validated");
      this.addGroup();
    }
  };

  onSuccess_AddGroup = op => {
    

    console.log("group", op.data);
    this.props.setGroupsApi(null, () => {
      this.redirect("/groupManagement");
      this.setState({ loading: false });
    });
    // this.props.selectTab(1)
   
  };

  addGroup = () => {
    this.setState({ loading: true });

    const url = `${baseUrl}wsfunction=core_cohort_update_cohorts &moodlewsrestformat=json&cohorts[0][categorytype][type]=id&cohorts[0][categorytype][value]=2&cohorts[0][name]=${this.state.name}&cohorts[0][idnumber]=${this.state.name}&cohorts[0][description]=${this.state.description}&cohorts[0][descriptionformat]=1&cohorts[0][visible]=1&cohorts[0][theme]=boost&cohorts[0][id]=${this.props.id}`;

   

    api(url, "get", null, this.onSuccess_AddGroup);
  };


 

  componentDidMount() {
    console.log(
      "id",this.props.id
    )

  
    this.props.groups.forEach((group) => {
      
      if (group.id == this.props.id) {
        this.setState({
          name: group.name,
          description: group.description,
          idnumber : group.idnumber
        })
      }
    })
  }

  render() {
    const {
      name,
      description,
      nameError,
      descriptionError,
      path,
      loading
    } = this.state;
    return (
      <CT
        pleft={"40px"}
        top={"0px"}
        right={"0px"}
        bottom={"0px"}
        left={"0px"}
        m_padding={"0px"}
      >
        {path && <Redirect to={{
          pathname: path,
          state: {loading : true}
        }} />}
        <form onSubmit={this.onSubmit}>
          <Label for="groupName">Group Name*</Label>
          <Container margin="11px 0px 0px 0px">
            <Input
              id="groupName"
              name={"name"}
              change={this.change}
              val={name}
            />
          </Container>
          <CT>
            <TEXT error={true}>{nameError}</TEXT>
          </CT>
          <Container margin="20px 0px 0px 0px">
            <Label for="groupName">Description*</Label>
            <Container margin="11px 0px 0px 0px">
              <TextArea
                id="groupName"
                name={"description"}
                change={this.change}
                val={description}
              />
            </Container>
          </Container>
          <CT>
            <TEXT error={true}>{descriptionError}</TEXT>
          </CT>
          <Container margin="40px 0px 0px 0px">
            {loading ? (
              <Loader />
            ) : (
              <Button type="submit" m_width={"100%"}>
                Next
              </Button>
            )}
          </Container>
        </form>
      </CT>
    );
  }
}

const mapStateToProps = state => ({
  groups : state.userManagement.groups
})

export default connect(mapStateToProps,{setGroupsApi})(AddDetailsTabDetails)
