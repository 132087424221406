import styled from "styled-components";


export default styled.div`
  height: 36px;
  border-radius: 30px;
  background-color: #f2f2f2;
  padding:14px;
  display : flex;
  align-items : center;
  margin-right : 13.3px;
  margin-top : 10px
`;