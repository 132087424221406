export const SET_EDITOR_COURSE_ID = "SET_EDITOR_COURSE_ID";
export const SET_QUIZ_DETAILS = "SET_QUIZ_DETAILS";

export const setEditorCourseId = payload => {
  console.log("payload", payload);
  return {
    type: SET_EDITOR_COURSE_ID,
    payload
  };
};

export const setQuizDetails = payload => {
  console.log("payload", payload);
  return {
    type: SET_QUIZ_DETAILS,
    payload
  };
};
