import React, { Component } from "react";

// custom components
import Body from "../Home/subComponents/Body";
import Header from "../subComponents/Header";
import Heading from "../subComponents/Heading";
import Tab from "./SubComponents/QuestionTab";
import lineHeight from "../../utils/lineHeight";

// Actions
import { setTabAction } from "../../actions/tabAction";

// npm packages
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Redirect } from "react-router-dom";

// styled components
import Container from "../../consumercomponents/styledcomponents/Container";
import CT from "../../consumercomponents/styledcomponents/CT";
import FlexContainer from "../../consumercomponents/styledcomponents/FlexContainer";
import PointerWrapper from "../../consumercomponents/styledcomponents/PointerWrapper";

//Image import
import back from "../../assets/icons/back.png";

const Styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "16px"
  },
  textField: {
    padding: 0,
    margin: 0,
    backgroundColor: "white"
  },
  textFieldContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px"
  },
  textFieldLabel: {
    fontWeight: "500",
    color: "#484848",
    marginBottom: "0.3rem"
  }
});

class AddTopic extends Component {
  state = {
    path: null
  };

  setPath = path => this.setState({ path });

  componentWillMount() {
    this.props.setTabAction("Quiz Management");
  }
  render() {
    const { classes } = this.props;
    const qId = this.props.match.params.qId;
    const cId = this.props.match.params.cId;
    const cmId = this.props.match.params.cmId;

    return (
      <div>
        {this.state.path && <Redirect to={this.state.path} />}
        <Header />

        <Body>
          <CT left={"25px"} right={"40px"} m_margin={"0px"}>
            <div
              className="row"
              style={{ marginBottom: "42px", marginTop: "42px" }}
            >
              <div className="col-sm-12 col-md-6 col-lg-6">
                <Container bg="inherit" left="0px" m_margin="0 0 0 0px">
                  <FlexContainer m_justifyContent={"center"}>
                    <Heading text={"Quiz Management"} />
                  </FlexContainer>
                </Container>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <Container left="0px" m_margin="29px 0px 29px 0px" bg="inherit">
                  <FlexContainer
                    justifyContent="flex-end"
                    m_justifyContent="center"
                  >
                    <div onClick={() => this.setPath("/quizManagement")}>
                      <PointerWrapper>
                        <img src={back} alt="back" />
                      </PointerWrapper>
                    </div>
                  </FlexContainer>
                </Container>
              </div>
            </div>

            <Paper style={{ padding: "2rem" }}>
              <Tab ids={{ qId, cId, cmId }} />
            </Paper>
          </CT>
        </Body>
      </div>
    );
  }
}

export default connect(
  null,
  { setTabAction }
)(withStyles(Styles)(AddTopic));
