import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import $ from "jquery";
// import tinymce from "tinymce/tinymce";

class App extends React.Component {
  handleEditorChange = e => {
    console.log("Content was updated:", e.target.getContent());
  };

  componentWillMount(){
    // console.log("ed test", tinymce);
  }

  render() {
    return (
      <Editor
        apiKey="uofoi92f4cgzk3igmpuzse6yd2ipmy0oyxrdm96vfklu72re"
        initialValue={this.props.state}
        init={{
          plugins: [
            "advlist autolink link image lists charmap print preview hr anchor pagebreak ",
            "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking ",
            "save table contextmenu directionality emoticons template paste textcolor"
          ],
          toolbar:
            "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media     code | forecolor backcolor emoticons",
          menubar: ["insert"],
         
          image_advtab: true,
 
          branding: false,
         

          file_picker_callback: function(callback, value, meta) {
            console.log("file type", meta.filetype);
            if (meta.filetype == "image") {
              $("#upload").trigger("click");
              $("#upload").on("change", function () {
                var file = this.files[0];
                console.log("files", file);
                var reader = new FileReader();
                reader.onload = function (e) {
                  console.log("test", reader.result);
                  callback(reader.result, {
                    alt: ""
                  });
                };
                reader.readAsDataURL(file);
              });
            } if (meta.filetype == "media") { 
              $("#upload").trigger("click");
              $("#upload").on("change", function() {
                var file = this.files[0];
                console.log("inside media", file);
                var reader = new FileReader();
                reader.onload = function(e) {
                  console.log("test", reader.result);
                 callback("movie.mp4", {
                   source2: "alt.ogg",
                   poster: "image.jpg"
                 });
                };
                reader.readAsDataURL(file);
              });
            }
          },
          video_template_callback: function (data) {
            console.log("inside video")
            return (
              '<video width="' +
              data.width +
              '" height="' +
              data.height +
              '"' +
              (data.poster ? ' poster="' + data.poster + '"' : "") +
              ' controls="controls">\n' +
              '<source src="' +
              data.source1 +
              '"' +
              (data.source1mime ? ' type="' + data.source1mime + '"' : "") +
              " />\n" +
              (data.source2
                ? '<source src="' +
                  data.source2 +
                  '"' +
                  (data.source2mime
                    ? ' type="' + data.source2mime + '"'
                    : "") +
                  " />\n"
                : "") +
              "</video>"
            );
          }
        }}
        onChange={this.props.handler}
      />
    );
  }
}

export default App;
