import styled from "styled-components";
import Screen from "./ScreenSizes";



export default styled.div`
  list-style: none;
  flex: 0 0 30%;
  flex-wrap : wrap;
  max-width : 100%;
  min-width : 245px;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #f5f5f5;
  background-color: #ffffff;
  cursor: pointer;
  padding: 20px;
  margin-top: 20px;
  margin: ${props => props.margin};

  &:hover {
    box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.13);
  }


  @media ${Screen.tablet} {
  flex: 0 0 100%;

  }
`;