import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Settings from "@material-ui/icons/Settings";
import Notifications from "@material-ui/icons/Notifications";
import Down from "@material-ui/icons/KeyboardArrowDown";
import { fade } from "@material-ui/core/styles/colorManipulator";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import lineHeight, { customLineHeight } from "../../utils/lineHeight";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";

// custom components
import NotificationPanel from "./NotificationPanel";
import HomePanel from "./HomePanel";

// styled components
import FlexContainer from "../../consumercomponents/styledcomponents/FlexContainer";

import { Divider } from "../Home/styledComponent/Dashboard";
import { relative } from "path";

library.add(faSearch);
const styles = theme => ({
  root: {
    flexGrow: 1
  },
  div: {
    // padding: theme.spacing.unit / 2,
    // textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
    // display: "flex",
    alignItems: "center"
  },
  image: {
    height: "3rem",
    width: "auto"
  },
  userContainer: {
    // borderLeft: "2px solid #CCCCCC",
    borderColor: "#CCCCCC",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    cursor: "pointer"
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%"
    // paddingLeft: "1.5rem"
  },
  avatar: {
    width: "1rem",
    height: "1rem",
    borderRadius: "50%",
    backgroundColor: "#4C10C1",
    position: "relative"
  },
  active: {
    position: "absolute",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "#4BD800",
    bottom: "0px",
    right: "0px"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto"
    }
  },
  colorPrimary: {
    color: "#CCCCCC"
  },
  colorSelected: {
    color: "#000000"
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 7,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200
      }
    }
  },
  marginRight: {
    marginRight: "16px",
    display: "flex",
    alignItems: "flex-start"
  }
});

class CenteredGrid extends React.Component {
  state = {
    open: true,
    anchorEl: null,
    openNotification: false,
    openHome: false
  };

  toggleVisibility = state => {
    this.setState(prevState => ({
      [state]: !prevState[state]
    }));
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const { classes } = this.props;
    console.log(this.state);
    return (
      <div className="row" style={{ display: "flex", padding: "16px 0px" }}>
        <div className="col-sm-12 col-md-7">
          <div className="row">
            <div className="col-sm-12 col-md-3">
              <FlexContainer m_justifyContent="center" m_width={"100%"}>
                <img
                  src={require("../../assets/images/download.png")}
                  alt="logo"
                  className={classes.image}
                />
              </FlexContainer>
            </div>
            <div
              className="col-sm-12 col-md-9"
              style={{ padding: 0, margin: 0 }}
            >
              <div className={classes.div}>
                <div className={classes.searchContainer}>
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon className={classes.colorPrimary} />
                    </div>
                    <InputBase
                      placeholder="Search…"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-5">
          <FlexContainer
            justifyContent="flex-end"
            m_justifyContent="flex-start"
            m_direction={"column"}
            wrap="no-wrap"
            m_padding="29px 0px 29px 10px"
            alignItems={"stretch"}
          >
            <FlexContainer
              m_justifyContent="center"
              m_width={"100%"}
              m_order={2}
              alignItems={"center"}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "28px"
                }}
              >
                <div
                  style={{
                    color: "#cccccc",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <i
                    className="fas fa-cog"
                    style={{ color: "#cccccc", marginRight: "8px" }}
                  />
                  <React.Fragment >
                    Settings
                  </React.Fragment>
                </div>
              </div>

              <div
                onClick={() => {
                  this.toggleVisibility("openNotification");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "28px",
                  position: "relative",
                  cursor: "pointer"
                }}
              >
                <div
                  style={{
                    color: "#cccccc",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <i
                    class="fas fa-bell"
                    style={{ color: "#cccccc", marginRight: "8px" }}
                  />
                  <React.Fragment>Notifications</React.Fragment>
                </div>

                {this.state.openNotification && <NotificationPanel />}
              </div>
            </FlexContainer>
            <Divider />
            <FlexContainer
              m_justifyContent={"center"}
              m_width={"100%"}
              m_margin={"0 0 29px 0"}
              m_order={1}
            >
              <div
                style={{
                  display: "flex",
                  // alignItems: "center",
                  position: "relative",
                  marginRight: "28px",
                  cursor: "pointer"
                }}
                onClick={() => {
                  this.toggleVisibility("openHome");
                }}
              >
                <div
                  style={{
                    color: "#cccccc",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <React.Fragment>Suraj</React.Fragment>
                  <i
                    class="fas fa-chevron-down"
                    style={{ marginLeft: "8px", marginRight: "8px" }}
                  />
                  <img
                    src={require("../../assets/images/user_profile.png")}
                    alt="active user"
                  />
                </div>
                {this.state.openHome && <HomePanel />}
              </div>
            </FlexContainer>
          </FlexContainer>
        </div>
      </div>
    );
  }
}

CenteredGrid.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CenteredGrid);
