import React from "react";

import FlexContainer from "../../consumercomponents/styledcomponents/FlexContainer";
import CT from "../../consumercomponents/styledcomponents/Container";
import NW, {
  TEXT,
  BOLD,
  POINTER,
  LINK,
  WRAPPER
} from "../../consumercomponents/styledcomponents/NotificationPanel";

export default function NotificationPanel() {

     const style = {
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
          ptop: "8px",
          pbottom: "8px",
          
     }
  return (
    <WRAPPER width={"200px"}>
      <FlexContainer direction={"column"} m_direction={"column"} alignItems={"flex-end"}>
        <POINTER margin={"0 25px 0 0"} />
        <NW>
          <FlexContainer direction={"column"} m_direction={"column"} alignItems={"center"}>
            <CT b_bottom={"1px solid #F1F1F3"} {...style}>
              <TEXT size={"13px"} weight={"bold"}>
                Status :{" "}
              </TEXT>
              <TEXT size={"13px"}>Online</TEXT>
            </CT>
            <CT b_bottom={"1px solid #F1F1F3"} {...style}>
              <TEXT size={"13px"}>FeedBack</TEXT>
            </CT>
            <CT  {...style}>
              <TEXT size={"13px"}>Log out</TEXT>
            </CT>
          </FlexContainer>
        </NW>
      </FlexContainer>
    </WRAPPER>
  );
}
