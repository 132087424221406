import React, { Component } from "react";

// custom functions
import api from "../../../utils/api";
import { post, baseUrl } from "../../../config/apiUrl";

// custom components
import Editor from "./EditorComponent";
import Loader from "../../subComponents/Loader";
import MiceEditor from "./MiceEditor";

// npm packges
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import CustomInput from "../../subComponents/CustomInput";
import Button from "@material-ui/core/Button";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw
} from "draft-js";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { stateToHTML } from "draft-js-export-html";
import htmlToDraft from "html-to-draftjs";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// styled components
import CustomButton from "../../../consumercomponents/styledcomponents/Button";
import Label from "../../../consumercomponents/styledcomponents/Label";
import CT from "../../../consumercomponents/styledcomponents/CT";
import Input from "../../UserManagement/styledComponents/Input";

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 0px 10px 5px",
    boxSize: "border-box",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    // fontFamily: 'GothamBook',
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

const styles = theme => ({
  margin: {
    // margin: theme.spacing.unit,
    width: "100%"
  },
  label: {
    marginBottom: "0.5rem"
  },
  tabContainer: {
    display: "flex"
  },
  labelError: {
    color: "red"
  }
});

class EditDetals extends Component {
  state = {
    a: true,
    b: false,
    text: "",
    editorState: EditorState.createEmpty(),
    category: 1,
    summary: "",
    categoryList: [],
    categoryLoad: false,
    articleName: "",
    articleShortName: "",
    isError: false,
    error: {
      articleName: null,
      articleShortName: null,
      category: null,
      editorState: null
    },
    loading: false,
    redirectPath: null,
    initialLoad: false,
    convertToRaw: false,
    showHtml: false,
    disabledButton: false,
    uploadTag: ""
  };

  // runs on editor text change
  onEditorStateChange = (editorState, convertedToraw) => {
    if (convertedToraw) {
      this.setState({
        editorStateRaw: editorState
      });
    } else {
      this.setState({
        editorState
      });
    }
  };

  // on tab change
  onTabChange = a => {
    this.setState(a);
  };

  // change button state
  changeButtonState = () => {
    this.setState(prevState => ({
      disabledButton: !prevState.disabledButton
    }));
  };

  // runs on aricle name change
  articleNameChange = e => {
    console.log(e.target.value);
    this.setState({
      articleName: e.target.value
    });
  };

  changeEditorState = (e, name) => {
    console.log(e);
    this.setState({
      [name]: e.target.getContent()
    });
  };

  // reset error
  resetError = () => {
    this.setState({
      isError: false,
      error: {
        articleName: null,
        articleShortName: null,
        category: null,
        editorState: null
      }
    });
  };

  // runs on article short name change
  articleShortNameChange = e => {
    this.setState({
      articleShortName: e.target.value
    });
  };

  // runs on categotyChange change
  handleChange = e => {
    this.setState({
      category: parseInt(e.target.value)
    });
  };

  // validator function
  validate = (a, b) => {
    let error = false;
    a.forEach(element => {
      console.log(a);
      // All types of validation
      if (!element.value) {
        this.setState(prevState => ({
          error: {
            ...prevState.error,
            [element.name]: b[element.name]
          },
          isError: true
        }));
        error = true;
      }
    });
    console.log(error);
    if (!error) {
      const {
        articleName,
        articleShortName,
        category,
        editorState,
        editorStateRaw,
        uploadTag
      } = this.state;
      const raw = convertToRaw(editorStateRaw.getCurrentContent());
      let value = "";
      raw.blocks.forEach(({ text }) => {
        value += text;
      });
      console.log("value", value);

      const id = this.props.id;
      this.toggleLoading();
      let { summary } = this.state;
      if (uploadTag) {
        summary += uploadTag;
      }
      const url = `http://servifylms.ouronlineserver.com/webservice/rest/server.php?wstoken=81a7d84b3cbd5c8e10653a06a7362038&wsfunction=core_course_update_courses&moodlewsrestformat=json`;
      const body = new FormData();
      body.append("courses[0][fullname]", articleName);
      body.append("courses[0][shortname]", articleShortName);
      body.append("courses[0][categoryid]", category);
      body.append("courses[0][id]", id);
      body.append("courses[0][summary]", summary);
      api(url, post, body, this.successApi, this.failedApi);
    }
  };

  toggleLoading = () => {
    this.setState(prevState => ({
      loading: !prevState.loading
    }));
  };

  successApi = res => {
    // stop loading
    this.toggleLoading();
    this.setState({
      redirectPath: "/articleManagement"
    });
    console.log("res", res);
  };

  failedApi = error => {
    // stop loading
    this.toggleLoading();

    console.log("error", error);
  };

  // Add Course
  addCourse = () => {
    this.resetError();
    const { articleName, articleShortName, category, editorState } = this.state;
    const summery = stateToHTML(editorState.getCurrentContent());
    const raw = convertToRaw(editorState.getCurrentContent());

    console.log(raw.blocks);

    // validation of all field
    this.validate(
      [
        { name: "articleName", value: articleName },
        { name: "articleShortName", value: articleShortName },
        { name: "category", value: category },
        { name: "editorState", value: summery }
      ],
      {
        articleName: "Name required",
        articleShortName: "Short name required",
        category: "category required"
      }
    );
  };

  //toggle converted to raw
  toggleConversation = () => {
    this.setState(prevState => ({
      convertToRaw: !prevState.convertToRaw
    }));
  };

  // on get course success
  successgetCourse = res => {
    console.log(
      "summery",
      EditorState.createWithContent(
        ContentState.createFromText(res.data[0].summary)
      )
    );
    if (res.data.length > 0) {
      const blocksFromHtml = htmlToDraft(res.data[0].summary);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        summary: res.data[0].summary.replace(/\"/g, "'"),
        category: res.data[0].categoryid,
        articleName: res.data[0].fullname,
        articleShortName: res.data[0].shortname,
        editorState,
        editorStateRaw: EditorState.createWithContent(
          ContentState.createFromText(res.data[0].summary)
        ),
        initialLoad: false,
        showHtml: true
      });

      console.log("editor state", editorState);
    }
  };

  // getCategories
  getCategories = () => {
    this.setState({ categoryLoad: true });
    const url = `${baseUrl}wsfunction=core_course_get_categories&moodlewsrestformat=json`;
    api(url, post, null, res => {
      if (res.data.length > 0) {
        this.setState({
          categoryList: res.data,
          categoryLoad: false
        });
      }
    });
  };

  componentWillMount() {
    this.setState({
      initialLoad: true
    });
    const url = `${baseUrl}wsfunction=core_course_get_courses&moodlewsrestformat=json&options[ids][0]=${
      this.props.id
    }`;
    api(url, post, null, this.successgetCourse);
    this.getCategories();
  }

  onChangefile = e => {
    this.changeButtonState();
    const file = e.target.files[0];
    const url = "http://servifylms.ouronlineserver.com/webservice/upload.php";
    const body = new FormData();
    body.append("file", file);
    body.append("token", "81a7d84b3cbd5c8e10653a06a7362038");
    body.append("filepath", "/");
    api(url, post, body, res => {
      let outputUrl = `http://servifylms.ouronlineserver.com/draftfile.php/5/user/draft/${
        res.data[0].itemid
      }/${res.data[0].filename}`;
      let tag2 = `<br><a href=${outputUrl}>${res.data[0].filename}</a>`;
      this.changeButtonState();

      this.setState(prevState => ({
        uploadTag: tag2
      }));
    });
  };

  render() {
    const { classes } = this.props;
    const categories = this.state.categoryList.map(({ id, name }, i) => (
      <option key={i} value={id}>
        {name}
      </option>
    ));
    const { showHtml, editorStateRaw, editorState, summary } = this.state;
    console.log("summery", this.state.summary);
    console.log("edit", this.state);
    return (
      <div>
        <input
          name="image"
          type="file"
          id="upload1"
          className="hidden"
          style={{ display: "none" }}
        />
        {this.state.initialLoad || this.state.categoryLoad ? (
          <Loader />
        ) : (
          <div>
            {this.state.redirectPath && (
              <Redirect to={this.state.redirectPath} />
            )}
            <Grid container spacing={24} style={{ paddingTop: "16px" }}>
              <Grid item xs={12} md={12}>
                <FormControl className={classes.margin}>
                  {/* <span
                    className={[
                      classes.label,
                      this.state.isError &&
                        this.state.error.articleName &&
                        classes.labelError
                    ].join(" ")}
                  >
                    {this.state.isError && this.state.error.articleName
                      ? this.state.error.articleName
                      : "Course Name *"}
                    </span> */}

                  <CT>
                    <Label
                      error={this.state.isError && this.state.error.articleName}
                    >
                      {this.state.isError && this.state.error.articleName
                        ? this.state.error.articleName
                        : "Course Name *"}
                    </Label>
                  </CT>
                  {/* <CustomInput
                    onChange={this.articleNameChange}
                    value={this.state.articleName}
                    error={
                      this.state.isError && this.state.error.articleName
                        ? true
                        : false
                    }
                    /> */}
                  <Input
                    val={this.state.articleName}
                    change={this.articleNameChange}
                    error={
                      this.state.isError && this.state.error.articleName
                        ? true
                        : false
                    }
                    width={"100%"}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} />
            </Grid>

            {/* Category Details */}

            <Grid container spacing={24}>
              <Grid item xs={12} md={12}>
                <CT>
                  <Label>Category *</Label>
                </CT>

                <div className="form-group">
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    defaultValue={this.state.category || 1}
                    onChange={this.handleChange}
                  >
                    {categories} 
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} md={6} />
            </Grid>

            {/* Short name starts*/}

            <Grid container spacing={24}>
              <Grid item xs={12} md={12}>
                <FormControl className={classes.margin}>
                  {/* <span
                    className={[
                      classes.label,
                      this.state.isError &&
                        this.state.error.articleShortName &&
                        classes.labelError
                    ].join(" ")}
                  >
                    {this.state.isError && this.state.error.articleShortName
                      ? this.state.error.articleShortName
                      : "Short Name *"}
                    </span> */}
                  <CT>
                    <Label
                      error={
                        this.state.isError && this.state.error.articleShortName
                      }
                    >
                      {this.state.isError && this.state.error.articleShortName
                        ? this.state.error.articleShortName
                        : "Short Name *"}
                    </Label>
                  </CT>
                  {/* <CustomInput
                    onChange={this.articleShortNameChange}
                    value={this.state.articleShortName}
                    error={
                      this.state.isError && this.state.error.articleShortName
                        ? true
                        : false
                    }
                    /> */}
                  <Input
                    val={this.state.articleShortName}
                    change={this.articleShortNameChange}
                    error={
                      this.state.isError && this.state.error.articleShortName
                        ? true
                        : false
                    }
                    width={"100%"}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} />
            </Grid>

            {/* short name ends */}

            <Grid container spacing={24}>
              <Grid item xs={12} md={12}>
                <div>
                  <CT>
                    <Label>Course Description </Label>
                  </CT>
                  <MiceEditor
                    state={summary}
                    handler={e => this.changeEditorState(e, "summary")}
                    id="#upload1"
                  />
                </div>

                <div style={{ marginTop: "16px" }}>
                  <input
                    type="file"
                    name=""
                    id=""
                    onChange={e => this.onChangefile(e)}
                  />
                </div>
                <div style={{ marginTop: "16px" }}>
                  {this.props.type === "add" || this.props.type === "edit" ? (
                    this.state.loading ? (
                      <Loader />
                    ) : (
                      <CustomButton
                        border="1px solid #4c10c1"
                        onClick={this.addCourse}
                        m_width={"100%"}
                      >
                        {this.props.type === "edit" ? "Save Changes" : "Done"}
                      </CustomButton>
                    )
                  ) : (
                    <CustomButton
                      border="1px solid #4c10c1"
                      onClick={this.addCourse}
                      m_width={"100%"}
                    >
                      Edit Article
                    </CustomButton>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={6} />
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  courseIds: state.editor.courseIds
});

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(EditDetals));
