import React, { Component } from 'react';
import { EditorState,Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = () => ({
  editor : {
    border : "2px solid black"
  }
})



class CustomOption extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  };

  addStar = () => {
   this.props.toggleConversation();
  };

  render() {
    return (
      <div onClick={this.addStar} style={{ border : "1px solid #CCCCCC" , padding : "0.3rem", fontSize : "0.8rem" , display : "flex" , justifyContent : "center", alignItems : "center" , background : this.props.raw ? "#CCC" : "#fff"}}>HTML</div>
    );
  }
}

class ControlledEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  onEditorStateChange= (editorState) => {
    this.setState({
      editorState,
    });
  };

  render() {
    const { editorState } = this.state;
    const { classes,error,raw,toggleConversation,height } = this.props;
    return (
      <Editor
        editorState={this.props.editorState}
        wrapperClassName="demo-wrapper"
        editorClassName={classes.border}
        editorStyle={{
          border: error ? "2px solid red" : "2px solid #EEEEEE",
          minHeight: height ? height : "20rem"
        }}
        onEditorStateChange={this.props.onEditorStateChange}
        // toolbarCustomButtons={[
        //   <CustomOption raw={raw} toggleConversation={toggleConversation} />
        // ]}
        toolbar={{
          image: {
            uploadEnabled: true,
            uploadCallback: file => {
              return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
                xhr.open("POST", "https://api.imgur.com/3/image");
                xhr.setRequestHeader(
                  "Authorization",
                  "Client-ID 8d26ccd12712fca"
                );
                const data = new FormData(); // eslint-disable-line no-undef
                data.append("image", file);
                xhr.send(data);
                xhr.addEventListener("load", () => {
                  const response = JSON.parse(xhr.responseText);
                  resolve(response);
                });
                xhr.addEventListener("error", () => {
                  const error = JSON.parse(xhr.responseText);
                  reject(error);
                });
              });
            },
            previewImage: true
          }
        }}
      />
    );
  }
}

export default withStyles(styles)(ControlledEditor);