import styled from "styled-components";
import Screen from "../../../consumercomponents/styledcomponents/ScreenSizes";
import img from "../../../assets/images/divider.png"


export const Heading =  styled.div`
  height: 29px;
  font-size: 24px;
  line-height: 1.67;
  letter-spacing: normal;
  color: #393939;


  @media ${Screen.tablet} {
     text-align : center
  }
`
export const SkillWrapper =  styled.div`
  border-left : 3px solid #eeeeee;
  padding-left : 29px;

  @media ${Screen.tablet} {
     border : none;
     width : 100%;
     margin-top : 58px;
     padding-left : 0px;
  }
`

export const Container = styled.div.attrs({
  className : props => props.className
})`
margin-top : ${props => props.top ? props.top : "8px"};
margin-right : ${props => props.right ? props.right : "8px"};
margin-bottom : ${props => props.bottom ? props.bottom : "8px"};
width:${props => props.width};
min-width:${props => props.minWidth};
min-height:${props => props.minHeight};
margin-left : ${props => props.left ? props.left : "8px"};
padding-top : ${props => props.ptop ? props.ptop : "0"};
padding-right : ${props => props.pright ? props.pright : "0"};
padding-bottom : ${props => props.pbottom ? props.pbottom : "0"};
padding-left : ${props => props.pleft ? props.pleft : "0"};
background-color : ${props => props.bg ? props.bg : "white"};


@media ${Screen.tablet} {
    padding : ${props => props.m_padding &&  props.m_padding}!important;
    margin : ${props => props.m_margin && props.m_margin};
    width:${props => props.m_width};
  }
`


export const Divider = styled.div`
    /* content : url(${img}) */
    margin-right : 32px;
    border: 1px solid #CCCCCC;
    @media ${Screen.tablet} {
      margin-right : 0;
      border: none;
  }
`;

export const OverflowWrapper = styled.div.attrs({
  className : props => props.className
})`
min-height : ${props => props.minHeight && props.minHeight};
flex : ${props => props.flex && props.flex};
@media ${Screen.tablet} {
   overflow : hidden;
   overflow-x : scroll
  }
`

