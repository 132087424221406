import React, { Component } from "react";

// custom components
import Body from "../subComponents/Body";
import Header from "../subComponents/Header";
import Heading from "../subComponents/Heading";
import SubHeading from "../subComponents/SubHeading";

// npm packages
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const Styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "16px"
  },
  textField: {
    padding: 0,
    margin: 0,
    backgroundColor: "white"
  },
  textFieldContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px"
  },
  textFieldLabel: {
    fontWeight: "500",
    color: "#484848",
    marginBottom: "0.3rem"
  }
});

class AddTopic extends Component {
    state = {
        age: '',
        name: 'hai',
        labelWidth: 0,
      };
      handleChange = name => event => {
        this.setState({ [name]: event.target.value });
      };    
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header />
        <Body>
        <Grid container spacing={0} style={{ marginBottom: "16px" }}>
            <Grid item xs={6}>
              <Heading text={"Topic Management"} />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <IconButton onClick={() => this.props.history.goBack()}>
                <KeyboardArrowLeft style={{ color: "blue" }} />
                <span style={{ fontSize: "16px", color: "blue" }}>Back</span>
              </IconButton>
            </Grid>
          </Grid>
          <Paper>
            <Grid container spacing={0} style={{ marginBottom: "2rem" }}>
              <Grid item xs={6}>
                <div className={classes.container}>
                  <SubHeading text={"Add Subtopic"} />
                  <div
                    className={classes.textFieldContainer}
                    style={{ marginTop: "2rem" }}
                  >
                    <span className={classes.textFieldLabel}>Topic Name *</span>
                    <TextField
                      id="outlined-bare"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.textFieldContainer}>
                    <span className={classes.textFieldLabel}>Description</span>
                    <TextField
                      id="outlined-bare"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      multiline={true}
                      rows="4"
                    />
                  </div>
                  <div className={classes.textFieldContainer}>
                    <span className={classes.textFieldLabel}>
                      Link to Topic *
                    </span>
                    <FormControl
                      variant="filled"
                      className={classes.formControl}
                    >
                     
                      <Select
                        native
                        value={this.state.age}
                        onChange={this.handleChange("age")}
                        style={{backgroundColor : "white"}}
                        input={<OutlinedInput
                            labelWidth={this.state.labelWidth}
                            name="age"
                            id="outlined-age-simple"
                          />}
                        
                      >
                        <option value="" />
                        <option value={10}>Ten</option>
                        <option value={20}>Twenty</option>
                        <option value={30}>Thirty</option>
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <Button variant="contained" size="medium" color="primary">
                      Done
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                
              </Grid>
            </Grid>
          </Paper>
        </Body>
      </div>
    );
  }
}

export default withStyles(Styles)(AddTopic);
