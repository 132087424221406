import React, { Component } from "react";

// custom components
import Body from "../subComponents/Body";
import Header from "../subComponents/Header";
import Heading from "../subComponents/Heading";
import Search from "../subComponents/Search";
import { setTabAction } from "../../actions/tabAction";
import Table from "../subComponents/Table";

// npm packages
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import FilterIcon from "@material-ui/icons/Sort";
import { Link } from "react-router-dom"

const styles = theme => ({
  alignRight: {
    textAlign: "right"
  },
  smallText : {
    textTransform: 'none'
  },
  buttonIcon : {
      marginLeft : "1.5rem",
      color : "black"
  },
  Link : {
      color : "white",
      textDecoration : "none"
  }
});
class TopicManagement extends Component {
  componentWillMount() {
    this.props.setTabAction("Topic Management");
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header />
        <Body>
          <div>
            <Grid container spacing={0} style={{ marginBottom: "2rem" }}>
              <Grid item xs={6}>
                <Heading text={"Topic Management"} />
              </Grid>
              <Grid item xs={6}>
                <Search />
              </Grid>
            </Grid>



            <Grid container spacing={0} style={{ backgroundColor : "white",padding : "2rem 0.5rem" }}>
              <Grid item xs={6}>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    <Button variant="outlined" size="medium" color="default" className={classes.smallText}>
                      Sort By
                      <DownIcon className={classes.buttonIcon} />
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button variant="outlined" size="medium" color="default" className={classes.smallText}>
                      Filter by Date
                     <FilterIcon className={classes.buttonIcon}/>
                    </Button>
                  </Grid>
                  <Grid item xs={3} />
                  <Grid item xs={3} />
                </Grid>
              </Grid>

              <Grid item xs={6} >
                <Grid container spacing={0}>
                  <Grid item xs={3} />
                  <Grid item xs={3} className={classes.alignRight}>
                    <Button variant="outlined" size="medium" color="primary" className={classes.smallText}>
                      Delete Selected
                    </Button>
                  </Grid>
                  <Grid item xs={3} className={classes.alignRight}>
                    <Button variant="contained" size="medium" color="primary" className={classes.smallText}>
                      <Link to="/topicManagement/addTopic" className={classes.Link}>Create New Topic</Link>
                    </Button>
                  </Grid>
                  <Grid item xs={3} className={classes.alignRight}>
                    <Button variant="contained" size="medium" color="primary" className={classes.smallText}>
                    <Link to="/topicManagement/addSubTopic" className={classes.Link}> Create New Subtopic</Link>
                     
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>



            <Grid container spacing={0} style={{ marginBottom: "2rem" }}>
              <Grid item xs={12}>
                <Table />
              </Grid>
            </Grid>

          </div>
        </Body>
      </div>
    );
  }
}

TopicManagement.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  null,
  { setTabAction }
)(withStyles(styles)(TopicManagement));
