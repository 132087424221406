import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class FormDialog extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onChange = e => {
      this.props.onChange(e.target.value)
  }

  render() {
      const { open,handleClose,title,content,button,action,inputLabel,onChange,inputValue } = this.props
    return (
      <div>
        <Dialog
          open={open}
          fullWidth
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {content}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={inputValue}
              onChange={this.onChange}
              label={inputLabel}
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose} color="secondary">
              {button[0]}
            </Button>
            <Button variant="contained" onClick={action} color="primary">
            {button[1]}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}