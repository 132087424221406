import React from "react";

// styled components
import DIV from "../../styledcomponents/DIV";
import FC from "../../styledcomponents/FlexContainer";
import PW from "../../styledcomponents/PointerWrapper";
import BG from "../../styledcomponents/BG";

// images
import ArrowRight from "../../../assets/icons/Symbol639.png";
import ArrowLeft from "../../../assets/icons/left_arrow.png";
import Bg from "../../../assets/images/bg.png";
import Badge from "../../../assets/images/badge_consomer.png";
import BadgeGray from "../../../assets/images/badge.PNG";

// css
import "../css/dashboard.css";

const styles = {
  DIV: {
    padding: "40px",
    bg: "white",
    shadow: "0 2px 6px 0 rgba(0, 0, 0, 0.04)",
    m_padding : "35px 15px 40px 15px"
  },
  header: {
    fsize: "24px",
    color: "#393939",
    mbottom: "26px"
  },
  key: {
    fsize: "28px",
    color: "#757575"
  },
  value: {
    fsize: "16px",
    color: "#4bd800"
  },
  fc: {
    wrap: "no-wrap"
  },
  link: {
    fsize: "14px",
    color: "#4c10c1"
  },
  badge: {
    height: "242px",
    width: "242px"
  }
};

export default function Card({ header, details, total, value,changeImage,index }) {
 

  const images = [
    <img src={Badge} alt="" />,
    <img src={BadgeGray} alt="" />,
    <img src={BadgeGray} alt="" />,

    
  ]

  return (
    <DIV {...styles.DIV}>
      <FC justifyContent={"space-between"}>
        <DIV {...styles.header}>Badges</DIV>
        <DIV {...styles.link}>
          <PW>
            View All Badges
            <img src={ArrowRight} alt="" style={{ marginLeft: "6px" }} />
          </PW>
        </DIV>
      </FC>

      {/* ------------------------------------------------------------------ */}

      <DIV mtop={"21px"}>
        <FC justifyContent={"space-between"} alignItems={"center"}>
          <PW onClick={()=>changeImage(0,images.length)}>
            <img src={ArrowLeft} alt="" />
          </PW>
          <BG src={Bg} height={"242px"} width={"242px"}>
            <DIV padding={"38px"} height={"100%"} width={"100%"}>
              <DIV bg={"white"} height={"100%"} width={"100%"}>
                <FC
                  height={"100%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction={"column"}
                >
                  <DIV>
                    {images[index]}
                  </DIV>
                  <DIV {...styles.key}>Proficient</DIV>
                </FC>
              </DIV>
            </DIV>
          </BG>
          <PW onClick={()=>changeImage(1,images.length)}>
            <img src={ArrowRight} alt="" />
          </PW>
        </FC>
      </DIV>

      {/* ------------------------------------------------------------------------ */}

      <DIV mtop={"34px"} shadow={" 0 4px 16px 0 rgba(69, 91, 99, 0.1)"} padding={"20px"} radius={"12px"}>
        <div className="custom-progress">
          <div
            className="custom-progress"
            role="progressbar"
            aria-valuenow="100"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: "70%", backgroundColor: "#A688E0", height: "10px" }}
          />
        </div>
        <DIV mtop={"17px"} >
          <FC justifyContent={"space-between"}>
            <DIV>Next level progress</DIV>
            <DIV>Expert</DIV>
          </FC>
        </DIV>
      </DIV>
    </DIV>
  );
}
