import React, { Component } from "react";
import styled from "styled-components";

// styled components
import FC from "../../../../consumercomponents/styledcomponents/FlexContainer";
import CT from "../../../../consumercomponents/styledcomponents/Container";
import PW from "../../../../consumercomponents/styledcomponents/PointerWrapper";
import Screen from "../../../../consumercomponents/styledcomponents/ScreenSizes";

//images
import LeftArrow from "../../../../assets/icons/leftarrow.png";
import RightArrow from "../../../../assets/icons/rightarrow.png";
import EditIcon from "../../../../assets/icons/Edit.png";
import DeleteIcon from "../../../../assets/icons/delete.png";

// material components
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";

// css
import "./table.css";

const THEAD = styled.thead`
  height: 46px;
  background-color: #f2f2f2;
  border: none;
`;

const TH = styled.th`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
  text-align: left;
  color: #757575;
  width: ${props => props.width && props.width}!important;
  text-align: ${props => (props.align ? props.align : "left")};
  font-weight: 500;
  height: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  text-transform: uppercase;
`;

const TD = styled.td`
  height: 14px;
  font-size: 12px;
  line-height: 1.67;
  padding-left: ${props => props.left};
  color: #757575;
  text-align: left;
  /* &:first-child {
    padding-left: 20px;
  } */

  @media ${Screen.tablet} {
    padding-left: ${props => props.m_left};
  }
`;

const DROPDOWN = styled.div`
  width: 180px;
  border: 1px solid #ccc;
  background-color: white;
  display: ${props => (props.display ? "block" : "none")};
`;
const OPTION = styled.div`
  width: 100%;
  height: 46px;
  cursor: pointer;
  padding-left: 34px;
  display: flex;
  align-items: center;
  opacity: 0.5;
  font-size: 14px;
  line-height: 2.29;
  text-align: center;
  color: #757575;

  &:hover {
    background-color: #cccccc;
  }
`;

const TR = styled.tr`
  height: 50px;
  border-bottom: 0.2px solid #cccccc;

  &:last-child {
    border-bottom: none;
  }
`;

const TABLE = styled.table`
  width: 100%;
  background-color: #ffffff;
  overflow: scroll;
  min-width: 500px;
`;

const PG_WRAPPER = styled.div`
  display: flex;
  border: 1px solid #cccccc;
  flex-wrap: wrap;
`;

const PG = styled.div`
  height: 46px;
  width: 46px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-right: 1px solid #cccccc;
  background-color: ${props => (props.selected ? "#4C10C1" : "white")};
  color: ${props => (props.selected ? "white" : " #757575")};

  &:hover {
    background-color: ${props => !props.selected && "#cccccc"};
  }
`;

const PAGEINFOTEXT = styled.span`
  height: 17px;
  font-size: 14px;
  line-height: 2;
  text-align: left;
  color: #757575;
  margin-right: 23px;
`;

const Test = styled.div`
  /* overflow-x: hidden; */
  overflow-x: scroll;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

/* <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------- --!> */

const Sort = props => (
  <CT {...props}>
    <FC direction="column">
      <PW onClick={() => props.sort(props.sortBy, "b")}>
        <i class="fas fa-angle-up" style={{ height: "10px" }} />
      </PW>
      <PW onClick={() => props.sort(props.sortBy, "a")}>
        <i class="fas fa-angle-down" style={{ height: "10px" }} />
      </PW>
    </FC>
  </CT>
);

export default class NewTable extends Component {
  state = {
    checkedB: false,
    showPageLimit: false,
    currentpageValue: 10,
    pageCounter: 1,
    pageToDisplay: 8,
    selectedPageValue: 1,
    data: {
      th: [
        {
          sort: false,
          name: "user names"
        },
        {
          sort: true,
          name: "role of the user",
          sortBy: "users"
        },

       
      ],
      td: [
        {
          name: "Lorem, ipsum dolor13.",
          users: "Supervisor",
          date: "mar 14,2019/12:08:09 PM IST"
        },
        {
          name: "Lorem, ipsum dolor14.",
          users: "Engineer",
          date: "mar 14,2019/12:08:09 PM IST"
        },
        {
          name: "Lorem, ipsum dolor15.",
          users: "SuperVisor",
          date: "mar 14,2019/12:08:09 PM IST"
        },
        {
          name: "Lorem, ipsum dolor16.",
          users: "SuperVisor",
          date: "mar 14,2019/12:08:09 PM IST"
        },
        {
          name: "Lorem, ipsum dolor17.",
          users: "Engineer",
          date: "mar 14,2019/12:08:09 PM IST"
        },
        {
          name: "Lorem, ipsum dolor18.",
          users: "SuperVisor",
          date: "mar 14,2019/12:08:09 PM IST"
        },
        {
          name: "Lorem, ipsum dolor19.",
          users: "SuperVisor",
          date: "mar 14,2019/12:08:09 PM IST"
        },
        {
          name: "Lorem, ipsum dolor20.",
          users: "Engineer",
          date: "mar 14,2019/12:08:09 PM IST"
        },
        {
          name: "Lorem, ipsum dolor21.",
          users: "SuperVisor",
          date: "mar 14,2019/12:08:09 PM IST"
        },
        {
          name: "Lorem, ipsum dolor22.",
          users: "SuperVisor",
          date: "mar 14,2019/12:08:09 PM IST"
        },
        {
          name: "Lorem, ipsum dolor23.",
          users: "Engineer",
          date: "mar 14,2019/12:08:09 PM IST"
        },
        {
          name: "Lorem, ipsum dolor24.",
          users: "SuperVisor",
          date: "amar 14,2019/12:08:09 PM IST"
        }
      ]
    }
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  setCurrentPageValue = value => {
    this.setState({
      currentpageValue: value,
      showPageLimit: false
    });
  };

  changeSelectedPageValue = value => {
    this.setState(prevState => {
      return {
        selectedPageValue: value,
        pageCounter: (value - 1) * (prevState.currentpageValue - 1)
      };
    });
  };

  togglePageLimit = () => {
    this.setState(prevState => {
      console.log(!prevState.showPageLimit);
      const showPageLimit = !prevState.showPageLimit;
      return { showPageLimit };
    });
  };

  sortBy = (value, type) => {
    if (value) {
      const td =
        type === "a"
          ? this.state.data.td.sort((a, b) =>
              a[value].toLowerCase().localeCompare(b[value].toLowerCase())
            )
          : this.state.data.td.sort((a, b) =>
              b[value].toLowerCase().localeCompare(a[value].toLowerCase())
            );
      const data = this.state.data;
      data.td = td;
      console.log(data);
      this.setState({ data });
    }
  };

  render() {
    const styles = {
      default: {
        top: 0,
        right: 0,
        bottom: 0,
        left: "10px",
        bg: "inherit"
      },
      pagination: {
        ...this.default,
        left: 0,
        top: "40px",
        bg: "inherit"
      }
    };

    const {
      checkedB,
      showPageLimit,
      selectedPageValue,
      data,
      pageCounter,
      currentpageValue
    } = this.state;

    //     const Sort = (
    //       <CT {...styles.default}>
    //         <FC direction="column">
    //           <PW>
    //             <i class="fas fa-angle-up" style={{ height: "10px" }} />
    //           </PW>
    //           <PW>
    //             <i class="fas fa-angle-down" style={{ height: "10px" }} />
    //           </PW>
    //         </FC>
    //       </CT>
    //     );

    let pageDisplayValue;
    switch (this.state.currentpageValue) {
      case 10:
        pageDisplayValue = "10 / Page";
        break;
      case 20:
        pageDisplayValue = "20 / Page";
        break;
      case 30:
        pageDisplayValue = "30 / Page";
        break;

      default:
        break;
    }

    let pageInfo = [
      <PG>
        <img src={LeftArrow} alt="left" />
      </PG>
    ];

    let exceedReminder = false;
    for (let index = 1; index <= this.state.pageToDisplay; index++) {
      console.log("sdds", this.state.currentpageValue * index);
      if (index === 1 || !exceedReminder) {
        if (this.state.currentpageValue * index >= this.state.data.td.length)
          exceedReminder = true;

        pageInfo = [
          ...pageInfo,
          <PG
            key={index}
            onClick={() => this.changeSelectedPageValue(index)}
            selected={selectedPageValue === index}
          >
            {index}
          </PG>
        ];
      }
    }

    pageInfo = [
      ...pageInfo,
      <PG>
        <img src={RightArrow} alt="left" />
      </PG>
    ];

    let renderedRow = 0;
    console.log(this.state.pageCounter);

    return (
      <div
        style={{
          overflow: "hidden"
        }}
      >
        <Test
          style={{
            overflowX: "scroll",
            width: "100% "
          }}
        >
          <TABLE>
          <col width="6%" />
          <col width="47%" />
          <col width="47%" />
            <THEAD>
              <TR>
                <TH >
                  <CT {...styles.default} pleft="40px" m_padding={"0px"}>
                    <Checkbox
                      checked={checkedB}
                      onChange={this.handleChange("checkedB")}
                      value="checkedB"
                      color="primary"
                      classes={{ root: "root" }}
                      inputProps={{
                        "aria-label": "secondary checkbox"
                      }}
                    />
                  </CT>
                </TH>

                {data.th.map((t, i) => (
                  <TH key={i} width={"35%"} align="center">
                    <FC alignItems="center">
                      {t.name}
                      {t.sort && (
                        <Sort
                          {...styles.default}
                          sort={this.sortBy}
                          sortBy={t.sortBy ? t.sortBy : ""}
                        />
                      )}
                    </FC>
                  </TH>
                ))}
              </TR>
            </THEAD>
            <tbody>
              {data.td.map((t, i) => {
                const index = i + 1;
                if (index >= pageCounter) renderedRow++;

                if (index >= pageCounter && renderedRow <= currentpageValue) {
                  return (
                    <TR key={i}>
                      <TD left={"40px"} m_left={"0px"}>
                        <CT {...styles.default} top="0px" bottom="0px" >
                          <Checkbox
                            checked={checkedB}
                            onChange={this.handleChange("checkedB")}
                            value="checkedB"
                            color="primary"
                            classes={{ root: "root" }}
                            inputProps={{
                              "aria-label": "secondary checkbox"
                            }}
                          />
                        </CT>
                      </TD>
                      <TD align="center">{t.name}</TD>
                      <TD left="20px">{t.users}</TD>
                      
                    </TR>
                  );
                }
              })}
            </tbody>
          </TABLE>
        </Test>
      </div>
    );
  }
}
