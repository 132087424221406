import React, { Component } from "react";
import styled from "styled-components";

//styled components
import screens from "../../../consumercomponents/styledcomponents/ScreenSizes"
import FC from "../../../consumercomponents/styledcomponents/FlexContainer";

const Tab = styled.div`
  height: 46px;
  width: ${props => props.width};
  border-top: ${props => props.top};
  border-right: ${props => props.right};
  border-bottom: ${props => props.bottom};
  border-left: ${props => props.left};
  margin: ${props => props.margin};
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${props =>
    !props.isSelected && !props.placeholder && "#F5F5F5"};
  color: ${props => props.isSelected && "#4c10c1"};

  @media ${screens.tablet} {
    width: ${props => props.media  ? "500px" : "0px"};
  }
`;

class CustomTab extends Component {
  state = {
    selected: 0
  };

  slectTab = selected => this.setState({ selected });

  render() {
    const { selected } = this.props;
    return (
      <div>
        <div>
          <FC wrap="no-wrap" alignItems="flex-end">
            <Tab
              width={"40px"}
              bottom={"1px solid #cccccc"}
              placeholder={true}
            />
            <Tab
              width={"130px"}
              top={"1px solid #cccccc"}
              right={"1px solid #cccccc"}
              left={"1px solid #cccccc"}
              bottom={selected !== 0 && "1px solid #cccccc"}
              isSelected={selected === 0}
              onClick={() => this.props.slectTab(0)}
              media={true}
            >
              Questions
            </Tab>
            <Tab
              width={"4px"}
              bottom={"1px solid #cccccc"}
              placeholder={true}
            />
            <Tab
              width={"130px"}
              top={"1px solid #cccccc"}
              right={"1px solid #cccccc"}
              bottom={selected !== 1 && "1px solid #cccccc"}
              left={"1px solid #cccccc"}
              isSelected={selected === 1}
              onClick={() => this.props.slectTab(1)}
              media={true}
            >
             Add Questions
            </Tab>
            <Tab
              width={"100%"}
              bottom={"1px solid #cccccc"}
              placeholder={true}
            />
          </FC>
              </div>

              
              {/* -------------------------------------------------- */}



        <div style={{ marginTop : "42px"}}>{selected === 0 ? this.props.tab1 : this.props.tab2}</div>
      </div>
    );
  }
}

export default CustomTab;
