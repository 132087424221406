import React, { useState } from "react";

// custom packages
import AssignToTabDetails from "./EditGroupMemberPage";
import AddDetailsTabDetails from "./EditGroupPage";
import NewTab from "../../subComponents/QuestionTab"

// npm packages
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled from "styled-components";

//styled components
import Screen from "../../../consumercomponents/styledcomponents/ScreenSizes";

//css
import "react-tabs/style/react-tabs.css";

const CustomTab = styled.span`
  /* padding: 16px 32px;  */
  border-radius: 3px;
  background-color: ${props => props.color};
  @media ${Screen.tablet} {
   font-size : 12px;
   padding : 0px; 
   background-color : white
  }
`;

const CustomTabDetails = styled.div`
  padding-top: 40px;
`;

const TabWrapper = styled.div`
  margin-top: 42px;
`;

function AddGroupTab({id}) {
  const [tabIndex, setTabIndex] = useState(0);  
  return (
  
    <NewTab selected={tabIndex} selectTab={setTabIndex} tab1={<AddDetailsTabDetails selectTab={setTabIndex} id={id}/>} tab2={<AssignToTabDetails />}/>
  );
}

export default AddGroupTab;
