import HomePage from '../components/Home/container';
import TopicManagementPage from "../components/TopicManagement/TopicManagement";
import addTopicManagementPage from "../components/TopicManagement/AddTopic";
import AddSubTopicManagementPage from "../components/TopicManagement/AddSubTopic";
import QuizManagementPage from "../components/QuizManagement/QuizManagement";
import AddQuizManagementPage from "../components/QuizManagement/AddQuiz";
import QuestionManagementPage from "../components/QuizManagement/QuestionManagement";
import EditQuizPage from "../components/QuizManagement/editQuiz";
import ArticleManagementPage from "../components/ArticleManagement/ArticleManagement";
import AddArticleManagementPage from "../components/ArticleManagement/AddArticle";
import EditArticleManagementPage from "../components/ArticleManagement/EditArticle";
import ViewArticleManagementPage from "../components/ArticleManagement/ViewArticle";
import ArticleTopicManagementPage from "../components/ArticleManagement/TopicManagement";
import AddPage from "../components/ArticleManagement/AddPage";
import ViewPage from "../components/ArticleManagement/ViewPage";
import EditPage from "../components/ArticleManagement/EditPage";
import IframePage from "../components/ArticleManagement/IframeContainer";
import GroupManagementPage from "../components/UserManagement/GroupManagement";
import AddGroupPage from "../components/UserManagement/AddGroup";
import EditGroupPage from "../components/UserManagement/EditGroup";
import ViewUserPage from "../components/UserManagement/ViewUser";
import UserManagementPage from "../components/UserManagement/Users";

// consumer app
import ConsumerMyCoursesPage from "../consumercomponents/coursemanagement/Home"
import ConsumerMyCourseListPage from "../consumercomponents/coursemanagement/CourseList"
import ConsumerSelectedPage from "../consumercomponents/coursemanagement/ViewCourse";
// quizzess
import ConsumerMyQuizPage from "../consumercomponents/quizmanagement/Home";
import ConsumerMyQuizListPage from "../consumercomponents/quizmanagement/QuizList";
import ConsumerQuizSelectedPage from "../consumercomponents/quizmanagement/ViewQuiz";
import ConsumerAppearQuizPage from "../consumercomponents/quizmanagement/AppearQuiz";
import Dashboard from '../consumercomponents/dashboard/Dashboard';





// defining path for the components
let routesList = [
  { path: "/", component: HomePage, exactPath: true },
  { path: "/topicManagement", component: TopicManagementPage, exactPath: true },
  {
    path: "/topicManagement/addTopic",
    component: addTopicManagementPage,
    exactPath: true
  },
  {
    path: "/topicManagement/addSubTopic",
    component: AddSubTopicManagementPage,
    exactPath: true
  },
  { path: "/quizManagement", component: QuizManagementPage, exactPath: true },
  {
    path: "/quizManagement/addQuiz",
    component: AddQuizManagementPage,
    exactPath: true
  },
  {
    path: "/quizManagement/editQuiz/:qId/:cmId/:cId",
    component: EditQuizPage,
    exactPath: true
  },
  {
    path: "/quizManagement/questionManagement/:qId/:cId/:cmId",
    component: QuestionManagementPage,
    exactPath: true
  },
  {
    path: "/articleManagement",
    component: ArticleManagementPage,
    exactPath: true
  },
  {
    path: "/articleManagement/addArticle",
    component: AddArticleManagementPage,
    exactPath: true
  },
  {
    path: "/articleManagement/editArticle",
    component: EditArticleManagementPage,
    exactPath: true
  },
  {
    path: "/articleManagement/editArticle/:id",
    component: EditArticleManagementPage,
    exactPath: true
  },
  {
    path: "/articleManagement/viewArticle",
    component: ViewArticleManagementPage,
    exactPath: true
  },
  {
    path: "/articleManagement/viewPage/:courseId/:id",
    component: ViewPage,
    exactPath: true
  },
  {
    path: "/articleManagement/topicManagement/:id",
    component: ArticleTopicManagementPage,
    exactPath: true
  },
  {
    path: "/articleManagement/topicManagement/addPage/:id/:topicId",
    component: AddPage,
    exactPath: true
  },
  {
    path: "/articleManagement/topicManagement/editPage/:id/:cmId/:topicId",
    component: EditPage,
    exactPath: true
  },
  {
    path: "/groupManagement",
    component: GroupManagementPage,
    exactPath: true
  },
  {
    path: "/groupManagement/addGroup",
    component: AddGroupPage,
    exactPath: true
  },
  {
    path: "/groupManagement/editGroup/:id",
    component: EditGroupPage,
    exactPath: true
  },
  {
    path: "/userManagement",
    component: UserManagementPage,
    exactPath: true
  },
  {
    path: "/userManagement/:id",
    component: ViewUserPage,
    exactPath: true
  },
  { path: "/home", component: HomePage, exactPath: true },
  { path: "/iframe", component: IframePage, exactPath: true },

  {
    path: "/consumer/:name/:id/courses",
    component: ConsumerMyCoursesPage,
    exactPath: true
  },
  {
    path: "/consumer/:name/:id/courseList",
    component: ConsumerMyCourseListPage,
    exactPath: true
  },
  {
    path: "/consumer/:name/:id/courses/:courseId",
    component: ConsumerSelectedPage,
    exactPath: true
  },
  {
    path: "/consumer/:name/:id/quizzess/:courseId",
    component: ConsumerMyQuizPage,
    exactPath: true
  },
  {
    path: "/consumer/:name/:id/quizeList",
    component: ConsumerMyQuizListPage,
    exactPath: true
  },
  {
    path: "/consumer/:name/:id/quizzess/:quizId",
    component: ConsumerQuizSelectedPage,
    exactPath: true
  },
  {
    path: "/consumer/:name/:id/appearQuiz/:quizCmId/:quizId/:quizName",
    component: ConsumerAppearQuizPage,
    exactPath: true
  },
  {
    path: "/consumer/:id",
    component: Dashboard,
    exactPath: true
  }
  // { path: '/pagenotfound', component: HomePage, exactPath: true }
];

export default routesList
