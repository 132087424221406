import React, { Component } from 'react';

export default class MobileHeader extends Component {
  render() {
    return (
      <div style={{ display : "flex" , flexDirection : "column" , paddingLeft : "32px" , marginBottom : "32px"}}>
      <div>
                <img
                  src={require("../../../assets/images/download.png")}
                  alt="logo"
                  style={{ height : "32px" , width : "auto"}}
                />
              </div>
        <div style={{ display : "flex",alignItems : "flex-start",color : "#CCCCCC" , paddingLeft : "8px" , marginBottom : "16px", marginTop : "16px"}}>
        <i className="fas fa-cog"></i>
        <React.Fragment>Settings</React.Fragment>
        </div>
        <div style={{ display : "flex",alignItems : "center",color : "#CCCCCC",alignItems : "flex-start",color : "#CCCCCC" , paddingLeft : "8px" , marginBottom : "16px"}}>
        <i class="fas fa-bell"></i>
        <React.Fragment>Notifications</React.Fragment>
        </div>
        <div style={{ display : "flex",alignItems : "center",color : "#CCCCCC",alignItems : "flex-start",color : "#CCCCCC" , paddingLeft : "8px" , marginBottom : "16px"}}>
        <div style={{ height : "16px" , width : "16px" , borderRadius : "50%" ,backgroundColor : "green"}}></div>
        <React.Fragment>Suraj</React.Fragment>
        </div>
        <div></div>
        <div></div>
      </div>
    )
  }
}
