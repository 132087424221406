import React, { Component } from 'react';
import { Redirect } from "react-router-dom"

// custom packages
import CardWrapper from "../../styledcomponents/CardWrapper"
import FlexContainer from "../../styledcomponents/FlexContainer"
import Tag from "../../styledcomponents/Tag"
import CardHeader from "../../styledcomponents/CardHeader"
import SmallText from "../../styledcomponents/SmallText";
import Container from "../../styledcomponents/Container";
import Divider from "@material-ui/core/Divider";
import PointerWrapper from "../../styledcomponents/PointerWrapper";

// npm packages



//Img
import arrow from '../../../assets/icons/Symbol639.png'

class Card extends Component {
  state = {
     path : null
   }

  redirect = path => this.setState({ path })
   render() {
    const { course } = this.props
    const { path } = this.state
    return (
      <CardWrapper onClick={() => this.redirect(`/consumer/name/id/quizzess/${course.id}`)}>
        {path && <Redirect to={path}/>}
        <FlexContainer direction="column" m_direction="column"> 
          <Tag>Mobile</Tag>
          <Container top="12px" bottom="16px" left="0px">
            <CardHeader>{course.fullname}</CardHeader>
          </Container>
          <FlexContainer>
            <Container right="10px" left="0px">
              <img
                src={require("../../../assets/icons/document.png")}
                alt="document"
              />
            </Container>
            <SmallText>14 Lessons</SmallText>
          </FlexContainer>
          <div style={{ width: "100%" }}>
            <Container top="10px" bottom="18px" left="0px">
              <Divider />
            </Container>
          </div>
          <PointerWrapper>
            <FlexContainer alignItems="center">
              <span style={{ color: "#4c10c1", marginRight: "8px", fontSize: "12px",fontWeight: 500 }}>
                View
              </span>
              <img src={arrow}/>
            </FlexContainer>
          </PointerWrapper>
        </FlexContainer>
      </CardWrapper>
    );
  }
}


export default Card;
