import styled from "styled-components";

export default styled.span`
  width: 137px;
  height: 14px;
  font-size: 12px;
  line-height: 1.2;
  text-align: left;
  color: #757575;
`;
