// import React from "react";
// import PropTypes from "prop-types";
// import { withStyles } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import Paper from "@material-ui/core/Paper";
// import Checkbox from "@material-ui/core/Checkbox";
// import EditIcon from "@material-ui/icons/Create";
// import DeleteIcon from '@material-ui/icons/Delete';
// import DownIcon from '@material-ui/icons/KeyboardArrowDown';
// import TableFooter from '@material-ui/core/TableFooter';
// import TablePagination from '@material-ui/core/TablePagination';
// import IconButton from '@material-ui/core/IconButton';
// import FirstPageIcon from '@material-ui/icons/FirstPage';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import LastPageIcon from '@material-ui/icons/LastPage';
// import PersonIcon from "@material-ui/icons/PersonAdd";

// const actionsStyles = theme => ({
//     root: {
//       flexShrink: 0,
//       color: theme.palette.text.secondary,
//       marginLeft: theme.spacing.unit * 2.5,
//     },
//   });

//   class TablePaginationActions extends React.Component {
//     handleFirstPageButtonClick = event => {
//       this.props.onChangePage(event, 0);
//     };

//     handleBackButtonClick = event => {
//       this.props.onChangePage(event, this.props.page - 1);
//     };

//     handleNextButtonClick = event => {
//       this.props.onChangePage(event, this.props.page + 1);
//     };

//     handleLastPageButtonClick = event => {
//       this.props.onChangePage(
//         event,
//         Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
//       );
//     };

//     render() {
//       const { classes, count, page, rowsPerPage, theme } = this.props;

//       return (
//         <div className={classes.root}>
//           <IconButton
//             onClick={this.handleFirstPageButtonClick}
//             disabled={page === 0}
//             aria-label="First Page"
//           >
//             {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
//           </IconButton>
//           <IconButton
//             onClick={this.handleBackButtonClick}
//             disabled={page === 0}
//             aria-label="Previous Page"
//           >
//             {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
//           </IconButton>
//           <IconButton
//             onClick={this.handleNextButtonClick}
//             disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//             aria-label="Next Page"
//           >
//             {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
//           </IconButton>
//           <IconButton
//             onClick={this.handleLastPageButtonClick}
//             disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//             aria-label="Last Page"
//           >
//             {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
//           </IconButton>
//         </div>
//       );
//     }
//   }

//   TablePaginationActions.propTypes = {
//     classes: PropTypes.object.isRequired,
//     count: PropTypes.number.isRequired,
//     onChangePage: PropTypes.func.isRequired,
//     page: PropTypes.number.isRequired,
//     rowsPerPage: PropTypes.number.isRequired,
//     theme: PropTypes.object.isRequired,
//   };

//   const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
//     TablePaginationActions,
//   );

// const CustomTableCell = withStyles(theme => ({
//   head: {
//     backgroundColor: "#EEEEEE",
//     color: "black"
//   },
//   body: {
//     fontSize: 14
//   }
// }))(TableCell);

// const styles = theme => ({
//   root: {
//     width: "100%",
//     marginTop: theme.spacing.unit * 3,
//     overflowX: "auto"
//   },
//   table: {
//     minWidth: 700
//   },
//   row: {
//     "&:nth-of-type(odd)": {
//       backgroundColor: theme.palette.background.default
//     }
//   }
// });

// let id = 0;
// function createData(name, calories, fat, carbs, protein) {
//   id += 1;
//   return { id, name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9)
// ];

// class CustomizedTable extends React.Component {

//     state = {
//         rows : [
//             createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//             createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//             createData("Eclair", 262, 16.0, 24, 6.0),
//             createData("Cupcake", 305, 3.7, 67, 4.3),
//             createData("Gingerbread", 356, 16.0, 49, 3.9)
//           ],
//           rowsPerPage : 5,
//           page : 0
//     }

//     handleChangePage = (event, page) => {
//         this.setState({ page });
//       };

//       handleChangeRowsPerPage = event => {
//         this.setState({ page: 0, rowsPerPage: event.target.value });
//       };

//   render(){

//     const { classes } = this.props;
//     const { rows,rowsPerPage,page } = this.state

//   return (
//     <Paper className={classes.root}>
//       <Table className={classes.table}>
//         <TableHead>
//           <TableRow>
//             <CustomTableCell>
//               {" "}
//               <Checkbox /> TOPIC NAME
//             </CustomTableCell>
//             <CustomTableCell align="center">COURSE NAME</CustomTableCell>
//             <CustomTableCell align="center">ASSIGNEES</CustomTableCell>
//             <CustomTableCell align="center">READ</CustomTableCell>
//             <CustomTableCell align="center">UNREAD</CustomTableCell>
//             <CustomTableCell align="center">DATE & TIME</CustomTableCell>
//             <CustomTableCell align="center">ACTIONS</CustomTableCell>
//             <CustomTableCell align="center">ASSIGN COURSE</CustomTableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {rows.map(row => (
//             <TableRow className={classes.row} key={row.id} role="checkbox">
//               <CustomTableCell component="th" scope="row">
//                 <Checkbox />
//                 {row.name}
//               </CustomTableCell>
//               <CustomTableCell align="center">{row.calories}</CustomTableCell>
//               <CustomTableCell align="center">{row.fat}</CustomTableCell>
//               <CustomTableCell align="center">{row.calories}</CustomTableCell>
//               <CustomTableCell align="center">{row.fat}</CustomTableCell>
//               <CustomTableCell align="center">{row.calories}</CustomTableCell>
//               <CustomTableCell align="center">
// <IconButton className={classes.button} aria-label="Delete">
//     <EditIcon style={{ color : "#f39c12"}}/>
//   </IconButton>
//   <IconButton className={classes.button} aria-label="Delete">
//     <DeleteIcon color="secondary"/>
//   </IconButton>
//               </CustomTableCell>
//               <CustomTableCell align="center"><IconButton className={classes.button} aria-label="Delete">
//               <PersonIcon style={{ color : "green"}} />
//                 </IconButton></CustomTableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//         <TableFooter>
//               <TableRow>
//                 <TablePagination
//                   rowsPerPageOptions={[5, 10, 25]}
//                   colSpan={3}
//                   count={rows.length}
//                   rowsPerPage={rowsPerPage}
//                   page={page}
//                   SelectProps={{
//                     native: true,
//                   }}
//                   onChangePage={this.handleChangePage}
//                   onChangeRowsPerPage={this.handleChangeRowsPerPage}
//                   ActionsComponent={TablePaginationActionsWrapped}
//                 />
//               </TableRow>
//             </TableFooter>
//       </Table>
//     </Paper>
//   );
// }
// }

// CustomizedTable.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default withStyles(styles)(CustomizedTable);

// custom packages
import api from "../../../utils/api";
import unixTime from "../../../utils/unixTime";
import { post } from "../../../config/apiUrl";
import Loader from "../../subComponents/Loader";

// npm packages
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "./TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import EditIcon from "@material-ui/icons/Create";
import PersonIcon from "@material-ui/icons/PersonAdd";
import LoaderComponent from "../../subComponents/Loader";
import { Redirect } from "react-router-dom";
import QuestionIcon from "@material-ui/icons/QuestionAnswer";
import moment from "moment";

const TableCellComponent = props => <TableCell align="center" {...props} />;

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#EEEEEE",
    color: "black",
    textAlign : "center",
    fontFamily : "GothamBook"
  },
  body: {
    fontSize: 14
  }
}))(TableCellComponent);

let counter = 0;
function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return { id: counter, name, calories, fat, carbs, protein };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {
    id: "courseName",
    numeric: false,
    disablePadding: false,
    label: "QUIZ NAME"
  },
  { id: "assignees", numeric: true, disablePadding: false, label: "ASSIGNEES" },
  { id: "read", numeric: true, disablePadding: false, label: "ATTEMPTED" },
  { id: "unread", numeric: true, disablePadding: false, label: "UNATTEMPED" },
  { id: "date", numeric: true, disablePadding: false, label: "DATE & TIME" },
  { id: "actions", numeric: true, disablePadding: false, label: "ACTIONS" },
  {
    id: "assignCourse",
    numeric: true,
    disablePadding: false,
    label: "ASSIGN QUIZ"
  }
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <CustomTableCell align="center" padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              color="primary"
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </CustomTableCell>
          {rows.map(
            row => (
              <CustomTableCell
                key={row.id}
                align="center"
                // padding={row.disablePadding ? 'none' : 'default'}
                padding="none"
                sortDirection={orderBy === row.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.createSortHandler(row.id)}
                >
                  {row.label}
                </TableSortLabel>
              </CustomTableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
  root: {
    // paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  }
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 && (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);







const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: "white",
  
  },
  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: "auto"
  },
  fontBook : {
    fontFamily : "GothamBook"
  }

});

class EnhancedTable extends React.Component {
  state = {
    order: "asc",
    orderBy: "calories",
    selected: [],
    data: [
      createData("Cupcake", 305, 3.7, 67, 4.3),
      createData("Donut", 452, 25.0, 51, 4.9),
      createData("Eclair", 262, 16.0, 24, 6.0),
      createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
      createData("Gingerbread", 356, 16.0, 49, 3.9),
      createData("Honeycomb", 408, 3.2, 87, 6.5),
      createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
      createData("Jelly Bean", 375, 0.0, 94, 0.0),
      createData("KitKat", 518, 26.0, 65, 7.0),
      createData("Lollipop", 392, 0.2, 98, 0.0),
      createData("Marshmallow", 318, 0, 81, 2.0),
      createData("Nougat", 360, 19.0, 9, 37.0),
      createData("Oreo", 437, 18.0, 63, 4.0)
    ],
    page: 0,
    rowsPerPage: 5,
    path : null
  };

  // Success api
  successApi = res => {
    if (res.data.warnings && res.data.warnings.length === 0) {
      this.props.getCourses();
    }
  };

  // Delete courses
  deleteCourse = id => {
    const url = `http://servifylms.ouronlineserver.com/webservice/rest/server.php?wstoken=81a7d84b3cbd5c8e10653a06a7362038&wsfunction=core_course_delete_courses&moodlewsrestformat=json&courseids[0]=${id}`;
    api(url, post, null, this.successApi);
  };


  //editCourse
  editCourse = (id,cmId,cId) => {
    this.setState({
      path: `quizManagement/editQuiz/${id}/${cmId}/${cId}`
    });
  }

  // Redirect
  redirectTo = path => {
    this.setState({ path })
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.props.toggleDelete(event.target.checked)

      this.setState(state => ({ selected: this.props.courseData.map(n => n.coursemodule) }));
      return;
    }
    this.props.toggleDelete(event.target.checked)

    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
    this.props.getSelected(newSelected)
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  changeCheckbox = e => {
    const checkValue = e.target.checked;
    console.log("test check",checkValue)
    const stateValue = checkValue === true ? true : false
    this.props.toggleDelete(stateValue);
  }

  

  createRowsPage = (count,array) => array.filter(num => num < count)

  render() {
    const {
      classes,
      courseData,
      loading,
      deleteLoading,
      deleteId,
      deleteCourse
    } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page,path } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

   let rowsPageOption = this.createRowsPage(courseData.length,[5,10,25]);

   
  //  console.log("data", courseData);
    return (
      <div className={classes.root}>
      {path && <Redirect to={path}/>}
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <div className={classes.tableWrapper}>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <LoaderComponent />
            </div>
          ) : (
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={courseData.length}
              />
              <TableBody>
                {stableSort(courseData, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    const isSelected = this.isSelected(n.coursemodule);
                    return (
                      <TableRow
                        hover
                        // onClick={event => this.handleClick(event, n.id)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                      >
                        <TableCell
                          align="center"
                          padding="checkbox"
                          onClick={event =>
                            this.handleClick(
                              event,
                              n.coursemodule
                            )
                          }
                        >
                          <Checkbox
                            checked={isSelected}
                            color="primary"
                            onChange={this.changeCheckbox}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          align="center"
                          scope="row"
                          padding="none"
                          className={classes.fontBook}
                        >
                          {n.name}
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="center"
                          className={classes.fontBook}
                        >
                          {0}
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="center"
                          className={classes.fontBook}
                        >
                          {0}
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="center"
                          className={classes.fontBook}
                        >
                          {0}
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="center"
                          className={classes.fontBook}
                        >
                          {/* {moment(n.timecreated).format(
                            "HH:mm:ss"
                          )} */}
                          {unixTime(Number(n.timecreated))}
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="center"
                          className={classes.fontBook}
                        >
                          <IconButton
                            className={classes.button}
                            aria-label="Delete"
                            onClick={() =>
                              this.editCourse(
                                n.id,
                                n.coursemodule,
                                n.course
                              )
                            }
                            disabled={n.id === 1}
                          >
                            <img src={require("../../../assets/icons/Edit.png")} />
                          </IconButton>
                          {deleteLoading &&
                          deleteId.indexOf(
                            n.coursemodule
                          ) !== -1 ? (
                            <span>Deleting...</span>
                          ) : (
                            <IconButton
                              className={classes.button}
                              aria-label="Delete"
                              onClick={() =>
                                deleteCourse(
                                  n.course,
                                  n.coursemodule,
                                  n.id
                                )
                              }
                              disabled={n.id === 1}
                            >
                              <img src={require("../../../assets/icons/delete.png")} />
                            </IconButton>
                          )}
                          <Tooltip title="Question management">
                            <IconButton
                              className={classes.button}
                              aria-label="Delete"
                              onClick={() =>
                                this.redirectTo(
                                  `/quizManagement/questionManagement/${
                                    n.id
                                  }/${n.course}/${
                                    n.coursemodule
                                  }`
                                )
                              }
                              disabled={n.id === 1}
                            >
                              <QuestionIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="center"
                          className={classes.fontBook}
                        >
                          <IconButton
                            className={classes.button}
                            aria-label="Delete"
                          >
                            <PersonIcon
                              style={{ color: "green" }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          )}
        </div>
       <div>
          <TablePagination
          rowsPerPageOption={rowsPageOption}
          count={courseData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
       </div>
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EnhancedTable);
