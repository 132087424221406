import React from 'react';
import styled from "styled-components";

const Input = styled.input`
width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  border-radius: 5px;
  font-family:Gotham;
  
  &:focus{
  background: skyblue;
 }`

export default function CustomInput() {
  return <Input />
}
