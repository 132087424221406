import styled from "styled-components";
import Screen from "./ScreenSizes";



export default styled.div`
  font-size: 20px;
  line-height: 1.3;
  text-align: left;
  color: #757575;


  @media ${Screen.tablet} {
    font-size: 15px;
  }

`;