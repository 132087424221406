import { SET_SNACKBAR } from "../../actions/snackbarAction.js";
import { RESET_SNACKBAR } from "../../actions/snackbarAction.js";

const initialState = {
  open: false,
  vertical: "right",
  message: ""
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SNACKBAR:
      return {
        ...state,
        message: action.payload,
        open: true
      };

    case RESET_SNACKBAR:
      return {
        ...state,
        message: "",
        open: false
      };
    default:
      return state;
  }
};

export default snackbarReducer;
