import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Redirect } from "react-router-dom"

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: "100%",
  },
});

class DialogSelect extends React.Component {
  state = {
    open: false,
    activity: '',
    path : null
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
    

  };

  redirect = () => {
    if(this.state.activity === 'page'){
        this.setState({
            path : `/articleManagement/topicManagement/addPage/${this.props.id}/${this.props.topicId}`
        })
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes,open,handleClose,title } = this.props;

    return (
      <div>
          {this.state.path && <Redirect  to={this.state.path}/>}
        <Dialog
        fullWidth
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <form className={classes.container}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-simple">Add Activity</InputLabel>
                <Select
                  value={this.state.activity}
                  onChange={this.handleChange('activity')}
                  input={<Input id="age-simple" />}
                >
                  
                  <MenuItem value={'page'}>Page</MenuItem>
                </Select>
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.redirect} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DialogSelect);