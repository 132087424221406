import styled from "styled-components";

export default styled.label.attrs({
  for: props => props.for
})`
  height: 17px;
  font-size: 14px;
font-weight: ${props => props.weight ? props.weight : 500};
  line-height: 1.79;
  text-align: left;
  color:${props => props.error ? "red" : "#757575"} ;
`;