import React, { Component } from "react";

// custom components
import GridWrapper from "../styledComponents/GridWrapper";
import LeftWrapper from "../styledComponents/HeadingWrapper";
import SectionWrapper from "../styledComponents/Section";
import RighttWrapper from "../styledComponents/BackWrapper";
import Search from "../../subComponents/Search";
import SkillLevel from "./SkillLevel";
import UserTable from "./tables/NewUserTable";
import Button from "../styledComponents/Button";

// styled components
import FlexContainer from "../../../consumercomponents/styledcomponents/FlexContainer";
import Container from "../../../consumercomponents/styledcomponents/Container";

// npm packages
class AssignToTabDetails extends Component {
  state = {
    search: ""
  };

  stars = array =>
    array.map((el, i) => (
      <img src={require("../../../assets/icons/star.png")} alt="star" key={i} />
    ));
  onSearchChange = e => this.setState({ search: e.target.value });
  render() {
    const { search } = this.state;

    const styles = {
      container: {
        top:0,
        right:0,
        bottom:0,
        left: "40px",
        m_margin: "0px"
      }
    }

    return (
      <div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <Container {...styles.container}>
              <Search search="Search User" border="1px solid #cccccc" width={"80%"} m_width={"100%"} />
              </Container>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <Container pright="43px">
            <FlexContainer justifyContent="flex-end" m_justifyContent="flex-start" m_margin="29px 0px 0px 0px">
              <FlexContainer>
                {/* <i
                  class="fas fa-exclamation-circle"
                  style={{ color: "blue", marginRight: "8px" }}
                /> */}
                {/* skill level info */}
              </FlexContainer>
            </FlexContainer>
            </Container>
          </div>
        </div>

        <SectionWrapper top="40px">
          <UserTable loading={false} />
        </SectionWrapper>
        <Container top="40px">
          <Button m_width={"100%"}>Done</Button>
        </Container>
      </div>
    );
  }
}

export default AssignToTabDetails;
