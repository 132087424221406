import styled from "styled-components";
import Screen from "../../../consumercomponents/styledcomponents/ScreenSizes";



export default styled.input.attrs(props =>({
  type: "text",
  id: props.id,
  value: props.val,
  onChange:props.change,
  name:props.name,
  className: props.cl
}))`
  width: ${props => (props.width ? props.width : "506.3px")};
  height: ${props => (props.height ? props.height : "46px")};
  border-radius: 6px;
  border: ${props => (props.error ?  "solid 1px red" : "solid 1px #cccccc")};
  padding: 15px 0px 14px 20px;
  @media ${Screen.tablet} {
    width : 100%
  }
`;