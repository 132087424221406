import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

// custom components/packages
import Wrapper from '../subcomponents/Wrapper'
import Quiz from './subcomponents/Quiz'
import api from '../../utils/api'
import { baseUrl } from '../../config/apiUrl'
import Loader from '../../components/subComponents/Loader'

// Styled Components
import Heading from '../../components/subComponents/Heading'
import Container from '../styledcomponents/Container'
import FlexContainer from '../styledcomponents/FlexContainer'
import SmallText from '../styledcomponents/SmallText'
import PointerWrapper from '../styledcomponents/PointerWrapper'
import Button from '../styledcomponents/Button'

// Material Component
import Paper from '@material-ui/core/Paper'

// css
import '../dashboard/css/dashboard.css'

//images
import Back from '../../assets/icons/back.png'

// Submit button
function SubmitButton({ dataAvailable }) {
    return (
        <Fragment>
            {dataAvailable && (
                <FlexContainer m_justifyContent={'center'}>
                    <Button m_width={'100%'} radius={'0px'} size={'12px'}>
                        Submit Quiz
                    </Button>
                </FlexContainer>
            )}
        </Fragment>
    )
}

class Home extends Component {
    state = {
        path: null,
        data: [],
        loading: false,
        error: null,
    }

    redirect = (path) => this.setState({ path })

    redirectToQuizzList = () => this.redirect('/consumer/name/id/quizzess/all')

    success_getQuestion = (resp) => {
        this.setState({ loading: false })
        if (resp.data.length !== 0) {
            this.setState({ data: resp.data })
        } else {
            this.setState({ error: 'No data found' })
        }
    }

    success_attemptQuiz = (resp) => {
        console.log(resp.data)
    }

    componentWillMount() {
        this.setState({ loading: true })
        const url = `${baseUrl}wsfunction=local_servifywebapis_get_quiz_questions&moodlewsrestformat=json&quizcmid=${
            this.props.match.params.quizCmId
        }`
        api(url, 'get', null, this.success_getQuestion)
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.loading === false && this.state.loading === true) {
            const url = `${baseUrl}wsfunction=mod_quiz_start_attempt&moodlewsrestformat=json&quizid=${
                this.props.match.params.quizId
            }`
            api(url, 'get', null, this.success_attemptQuiz)
        }
        return true
    }

    render() {
        const styles = {
            wrapper: {
                top: '0',
                right: '0',
                bottom: '0',
                left: '0',
                bg: 'inherit',
            },
        }

        const { data, loading, path } = this.state
        const questionnumber =
            data.length === 0 ? 'Not available ' : data.length
        const quizName = decodeURIComponent(this.props.match.params.quizName)
        const dataAvailable = data.length === 0 ? false : true
        return (
            <Wrapper>
                {path && <Redirect to={path} />}
                <Container {...styles.wrapper} m_padding={'40px 0 0 0'}>
                    <div className="row">
                        <div className="col-sm-12 col-md-11 col-lg-11">
                            <FlexContainer m_justifyContent={'center'}>
                                <Container
                                    left={'0px'}
                                    bg={'inherit'}
                                    m_margin="0 0px 0px 8px"
                                >
                                    <Heading text="My Quizzess" />
                                </Container>
                            </FlexContainer>
                        </div>
                        <div className="col-sm-12 col-md-1 col-lg-1">
                            <FlexContainer m_justifyContent={'center'}>
                                <Container
                                    left={'0px'}
                                    bg={'inherit'}
                                    m_margin="0 0px 0px 8px"
                                >
                                    <Container
                                        bg="inherit"
                                        m_margin="29px 0px 0px 0px"
                                    >
                                        <PointerWrapper
                                            onClick={this.redirectToQuizzList}
                                        >
                                            <img src={Back} alt="" />
                                        </PointerWrapper>
                                    </Container>
                                </Container>
                            </FlexContainer>
                        </div>
                    </div>
                </Container>

                {loading ? (
                    <Loader />
                ) : (
                    <Container top="40px" m_margin={'40px 0 40px 0'}>
                        <Paper>
                            <Container
                                ptop="40px"
                                pright="40px"
                                pbottom="40px"
                                pleft="40px"
                                m_padding={'40px 0 40px 0'}
                            >
                                <div className="row">
                                    <div className="col-sm-12 col-md-9 col-lg-9">
                                        <FlexContainer>
                                            <span
                                                style={{
                                                    fontSize: '24px',
                                                    color: '#757575',
                                                }}
                                            >
                                                {quizName}
                                            </span>
                                        </FlexContainer>
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3">
                                        <Container
                                            top={'0px'}
                                            m_margin={'14px 0 0 0'}
                                            left={'0px'}
                                            right={'0px'}
                                            bottom={'0px'}
                                        >
                                            <div className="custom-progress">
                                                <div
                                                    className="custom-progress"
                                                    role="progressbar"
                                                    aria-valuenow="100"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                    style={{
                                                        width: '90%',
                                                        backgroundColor:
                                                            '#A688E0',
                                                        height: '10px',
                                                    }}
                                                />
                                            </div>
                                        </Container>
                                        <Container left="0px" top="15px">
                                            <FlexContainer justifyContent="space-between">
                                                <SmallText>
                                                    Quiz Progress
                                                </SmallText>
                                                <SmallText>90%</SmallText>
                                            </FlexContainer>
                                        </Container>
                                    </div>
                                </div>
                            </Container>

                            <div
                                style={{
                                    borderTop: '1px solid #EEEEEE',
                                    borderBottom: '1px solid #EEEEEE',
                                }}
                            >
                                <div className="row">
                                    <div className="col-sm-12  col-md-10 col-lg-10">
                                        <FlexContainer
                                            m_justifyContent={'center'}
                                        >
                                            <Container ptop="5px" pbottom="5px">
                                                <FlexContainer>
                                                    <Container
                                                        left="40px"
                                                        right="20px"
                                                        top="0px"
                                                        bottom="0px"
                                                        m_margin={'0px'}
                                                    >
                                                        <FlexContainer alignItems="center">
                                                            <img
                                                                src={require('../../assets/icons/mobile_key.png')}
                                                                alt="mobile key"
                                                                style={{
                                                                    marginRight:
                                                                        '10px',
                                                                }}
                                                            />
                                                            <SmallText>
                                                                Mobile
                                                            </SmallText>
                                                        </FlexContainer>
                                                    </Container>
                                                    <Container
                                                        right="20px"
                                                        top="0px"
                                                        bottom="0px"
                                                    >
                                                        <div
                                                            style={{
                                                                height: '12px',
                                                                borderLeft:
                                                                    '2px solid #cccccc',
                                                            }}
                                                        />
                                                    </Container>
                                                    <Container
                                                        right="20px"
                                                        top="0px"
                                                        bottom="0px"
                                                    >
                                                        <FlexContainer alignItems="center">
                                                            <img
                                                                src={require('../../assets/icons/questionMark.png')}
                                                                alt="mobile key"
                                                                style={{
                                                                    marginRight:
                                                                        '10px',
                                                                }}
                                                            />
                                                            <SmallText>
                                                                No of questions
                                                                -{' '}
                                                                {questionnumber}
                                                            </SmallText>
                                                        </FlexContainer>
                                                    </Container>
                                                </FlexContainer>
                                            </Container>
                                        </FlexContainer>
                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                        <FlexContainer
                                            m_justifyContent={'center'}
                                        >
                                            <Container
                                                lefy={'0px'}
                                                m_padding="0px 0px 0px 8px"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: '12px',
                                                        fontWeight: 'bold',
                                                        color: '#757575',
                                                    }}
                                                >
                                                    Time Spent - 20 mins
                                                </span>
                                            </Container>
                                        </FlexContainer>
                                    </div>
                                </div>
                            </div>
                            <Container
                                pright="40px"
                                pleft="40px"
                                m_padding={'0px'}
                            >
                                {this.state.error ? (
                                    <h5>{this.state.error}</h5>
                                ) : (
                                    data.map((q, i) => (
                                        <Quiz key={i} question={q} />
                                    ))
                                )}
                            </Container>
                            <Container
                                ptop="40px"
                                pright="40px"
                                pbottom="40px"
                                pleft="40px"
                                m_padding={'0px'}
                            >
                                <SubmitButton dataAvailable={dataAvailable} />
                            </Container>
                        </Paper>
                    </Container>
                )}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    quizes: state.consumerQuizes,
})

export default connect(
    mapStateToProps,
    null
)(Home)
