import React, { Component } from "react";

// custom components
import GridWrapper from "../styledcomponents/GridWrapper";
import BodyWrapper from "../styledcomponents/BodyWrapper";
import Nav from "./Header";
import SideBar from "./SideBar";

// npm packages

class Header extends Component {
  render() {
    return (
      <div>
        <GridWrapper>
          {/* <Grid sm="12" md="3" lg="3" /> */}
          <div className="col-sm-12 col-md-2 col-lg-2">
            <SideBar />
          </div>
          <div className="col-sm-12 col-md-10 col-lg-10">
            <Nav />
            <BodyWrapper>{this.props.children}</BodyWrapper>
          </div>
        </GridWrapper>
      </div>
    );
  }
}

export default Header;
