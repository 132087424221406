import React from "react";
import styled, { css } from "styled-components";

// custom packages
import Screen from "../../../consumercomponents/styledcomponents/ScreenSizes";
import ChildContainer from "../../../consumercomponents/styledcomponents/FlexChild";

// default css
const sharedStyle = css`
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  align-items: ${props => (props.alignItems ? props.alignItems : "baseline")};
  flex-wrap: ${props => (props.wrap ? props.wrap : "wrap")};
  flex-direction: ${props => (props.direction ? props.direction : "row")};
`;

// styled components
export const FlexContainer = styled.div`
  ${sharedStyle};
  width: ${props => props.width && props.width};
  margin: ${props => props.margin && props.margin};

  @media ${Screen.tablet} {
    justify-content: ${props =>
      props.m_justifyContent && props.m_justifyContent};
    align-items: ${props => props.m_alignItems && props.m_alignItems};
    padding: ${props => props.m_padding && props.m_padding};
    margin: ${props => props.m_margin && props.m_margin};
    flex-direction: ${props => (props.m_direction ? props.m_direction : "row")};
    width: ${props => props.width && props.m_width};
  }
`;

export const NumberWrapper = styled.div`
  height: 48px;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #393939;

  @media ${Screen.tablet} {
    text-align: center;
  }
`;

export const NameWrapper = styled.div`
  line-height: 1.2;
  max-width: ${props => (props.maxWidth ? props.maxWidth : "200px")};
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  letter-spacing: normal;
  text-align: left;
  color: #757575;
  margin-left: ${props => (props.left ? props.left : "15px")};

  @media ${Screen.tablet} {
    text-align: center;
    margin-left: 0px;
    margin-top: ${props => props.m_margin && props.m_margin};
  }
`;

export default function OverviewCard({ src, number, name }) {
  return (
    <FlexContainer
      justifyContent="space-between"
      alignItems="center"
      margin="0 83px 0 0"
      m_direction="column"
      m_margin="40px 0px 0px 0px"
      m_alignItems="center"
    >
      <ChildContainer flex="5">
        <FlexContainer
          justifyContent="space-between"
          alignItems="center"
          m_direction="column"
          m_alignItems="center"
          wrap="no-wrap"
        >
          <img src={src} alt="eye" />
          <NameWrapper m_margin={"18px"}>{name}</NameWrapper>
        </FlexContainer>
      </ChildContainer>
      <ChildContainer flex="2" justifyContent="flex-end">
        <div>
          <NumberWrapper>{number}</NumberWrapper>
        </div>
      </ChildContainer>
    </FlexContainer>
  );
}
