import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import $ from "jquery";

class App extends React.Component {
  handleEditorChange = e => {
    console.log("Content was updated:", e.target.getContent());
  };

  render() {
    const { id } = this.props;
    return (
      <Editor
        apiKey="uofoi92f4cgzk3igmpuzse6yd2ipmy0oyxrdm96vfklu72re"
        initialValue={this.props.state}
        init={{
          height: 400,
          branding: false,
          plugins: [
            "advlist autolink link image lists charmap print preview hr anchor pagebreak ",
            "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking ",
            "save table contextmenu directionality emoticons template paste textcolor"
          ],
          toolbar:
            "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media  code | forecolor backcolor emoticons",
          image_advtab: true,
          media_live_embeds: true,
          branding: false,
         
          // images_upload_url: "/Content/UploadContentImg",
          
          file_picker_callback: function(callback, value, meta) {
            if (meta.filetype == "image") {
              $(`${id}`).trigger("click");
              $(`${id}`).on("change", function() {
                var file = this.files[0];
                var reader = new FileReader();
                reader.onload = function (e) {
                    console.log("test", reader.result);
                  callback(reader.result, {
                    alt: ""
                  });
                };
                reader.readAsDataURL(file);
                // console.log("durl", reader.readAsDataURL(file));
              });
            }
             if (meta.filetype == "media") {
               $(`${id}`).trigger("click");
               $(`${id}`).on("change", function() {
                 var file = this.files[0];
                 var reader = new FileReader();
                 reader.onload = function(e) {
                   console.log("test", reader.result);
                   callback("movie.mp4", {
                     source2: "video/mp4",
                     poster: "image.jpg"
                   });
                 };
                 reader.readAsDataURL(file);
                 // console.log("durl", reader.readAsDataURL(file));
               });
             }
          }
        }}
        onChange={this.props.handler}
      />
    );
  }
}

export default App;
