import styled from "styled-components";
import Screen from "./ScreenSizes";

export default styled.div`
  display: flex;
 flex : ${props => props.flex && props.flex};
 justify-content : ${props =>
   props.justifyContent ? props.justifyContent : "flex-start"};
 align-items : ${props => (props.alignItems ? props.alignItems : "flex-start")};

  @media ${Screen.tablet} {
   flex : ${props => props.m_flex && props.m_flex} ;
   width : ${props => props.m_width} ;
   justify-content : ${props =>
     props.m_justifyContent ? props.m_justifyContent : "flex-start"};
 align-items : ${props => (props.m_alignItems ? props.m_alignItems : "flex-start")};
  }
`;
