import styled from "styled-components";
import Screen from "./ScreenSizes";


export default styled.div`

  font-size: 24px;

  line-height: 1.67;

  text-align: left;
  color: #4bd800;

  @media ${Screen.tablet} {
    font-size: 20px;
  }
`;
