import React, { Component } from "react";
import { Redirect } from "react-router-dom";

// custom components
import Body from "../Home/subComponents/Body";

import Header from "../subComponents/Header";
import MobileHeader from "../Home/subComponents/MobileHeader";
import Heading from "../subComponents/Heading";
import Search from "../subComponents/Search";
import { setTabAction } from "../../actions/tabAction";
// import Table from "./tab/Table";
import NewTable from "./tables/NewTable";
import api from "../../utils/api";
import { post, baseUrl } from "../../config/apiUrl";
import Loader from "../subComponents/Loader";
import {
  setCourseCategory,
  setCourseDeatils
} from "../../actions/courseAction";
import lineHeight from "../../utils/lineHeight";

// styled components
import Container from "../../consumercomponents/styledcomponents/Container";
import CT from "../../consumercomponents/styledcomponents/CT";
import FlexContainer from "../../consumercomponents/styledcomponents/FlexContainer";
import CustomButton from "../../consumercomponents/styledcomponents/Button";

// npm packages
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";

const styles = theme => ({
  alignRight: {
    textAlign: "right"
  },
  smallText: {
    textTransform: "none"
  },
  buttonIcon: {
    marginLeft: "1.5rem",
    color: "black"
  },
  Link: {
    color: "white",
    textDecoration: "none"
  }
});

class TopicManagement extends Component {
  state = {
    loading: false,
    deleteLoading: false,
    deleteId: [],
    data: [],
    filteredData: [],
    search: "",
    selected: [],
    showDelete: false,
    path: null
  };

  redirect = path => this.setState({ path });
  componentDidMount() {
    this.props.setTabAction("Course Management");
    this.getCourses();
  }

  toggleShowDelete = (checkState = false) => {
    console.log("sdeddeddeded", checkState);
    this.setState({ showDelete: checkState });
  };

  //get selected
  getSelected = selected => {
    this.setState({ selected });
  };

  // success of api
  apiSuccess = res => {
    this.setState({
      data: res.data,
      filteredData: res.data.reverse(),
      loading: false,
      deleteLoading: false
    });
    this.props.setCourseDeatils(res.data);
  };

  // on search
  search = e => {
    let keyword = e.target.value;
    this.setState({
      search: keyword
    });
    if (keyword.trim()) {
      let searchedProduct = this.state.data.filter(({ fullname }) =>
        fullname.toLowerCase().includes(keyword.toLowerCase())
      );
      console.log("filter", searchedProduct);
      this.setState({
        filteredData: searchedProduct
      });
    } else {
      this.setState({
        filteredData: this.state.data
      });
    }
  };

  //set loading
  setLoading = loading => {
    this.setState({ loading });
  };

  getCourses = () => {
    this.setState({ loading: true})
    const url = `${baseUrl}&wsfunction=core_course_get_courses&moodlewsrestformat=json`;
    api(url, post, null, this.apiSuccess);
  };

  //success delete api
  successDeleteApi = res => {
    if (res.data.warnings.length === 0) {
      const url = `${baseUrl}&wsfunction=core_course_get_courses&moodlewsrestformat=json`;
      api(url, post, null, this.apiSuccess);
    }
  };

  //deleteCourse
  deleteCourse = id => {
    this.setState(prevState => ({
      deleteId: [...prevState.deleteId, id],
      deleteLoading: true
    }));
    const url = `${baseUrl}&wsfunction=core_course_delete_courses&moodlewsrestformat=json&courseids[0]=${id}`;
    api(url, post, null, this.successDeleteApi);
  };

  // delete selected courses
  deleteSelected = () => {
    if (this.state.selected.length > 0) {
      let url = `${baseUrl}wsfunction=core_course_delete_courses&moodlewsrestformat=json&`;
      this.state.selected.forEach((id, i) => {
        url += `courseids[${i}]=${id}&`;
        this.setState(prevState => ({
          deleteId: [...prevState.deleteId, id],
          deleteLoading: true
        }));
      });
      console.log(url.slice(0, -1));
      const trimmedUrl = url.slice(0, -1);
      api(trimmedUrl, post, null, this.successDeleteApi);
    }
  };

  render() {
    const { classes } = this.props;
    const { showDelete, path } = this.state;
    console.log("state", this.state);
    console.log("selected", this.state.selected);
    return (
      <div>
        {/* <MediaQuery query="(min-device-width: 1224px)">
        <Header />
      <MediaQuery query="(min-device-width: 1824px)">
      <Header />
      </MediaQuery>
      <MediaQuery query="(max-width: 1224px)">
       
      </MediaQuery>
    </MediaQuery> */}

        <Header />

        <Body>
          {path && <Redirect to={path} />}
          <CT left={"25px"} right={"40px"} m_margin={"0px"}  m_padding={"0px"}>
            <div className="row">
              <div
                className="col-sm -12 col-md-6"
                style={{ padding: "32px 8px" }}
              >
                <FlexContainer m_justifyContent={"center"}>
                  <Heading text={"Course Management"} />
                </FlexContainer>
              </div>
              <div
                className="col-sm-12 col-md-6 "
                style={{ padding: "32px 8px" }}
              >
                <Container
                  left="0px"
                  m_margin="29px 0px 29px 0px"
                  bg="inherit"
                  m_padding={"15px"}
                >
                  <Search value={this.state.search} onChange={this.search} />
                </Container>
              </div>
            </div>

            <div style={{ padding: "32px 0px", backgroundColor: "white" }}>
              <div>
                <div className="row" style={{ alignItems: "center" }}>
                  <div className="col-sm-12 col-md-6 col-lg-6" />
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <FlexContainer
                      justifyContent="flex-end"
                      m_justifyContent="flex-start"
                      m_direction={"column"}
                    >
                      <Container left="0px" right="8px" m_margin={"0 8px 0 8px"} m_width={"100%"}>
                        {/* <Button
                        variant="outlined"
                        size="medium"
                        color="primary"
                        className={classes.smallText}
                        onClick={this.deleteSelected}
                        style={{
                          marginLeft: "16px",
                          height: "40px",
                          borderRadius: "2px",
                          fontSize: "12pt",
                          fontFamily: "Gotham",
                          borderColor: "#393939",
                          color: "#393939" 
                        }}
                      >
                        Delete Selected
                      </Button> */}

                        {showDelete && (
                          <CustomButton
                            bg="white"
                            border="1px solid #4c10c1"
                            color="#4c10c1"
                            onClick={this.deleteSelected}
                            m_width={"100%"}
                          >
                            {" "}
                            Delete Selected
                          </CustomButton>
                        )}
                      </Container>
                      <Container left="0px" top="0px"  m_margin={"0 8px 0 8px"} m_width={"100%"}>
                        {/* <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classes.smallText}
                        style={{
                          marginLeft: "16px",
                          height: "40px",
                          borderRadius: "2px",
                          fontSize: "12pt",
                          fontFamily: "Gotham"
                        }}
                      >
                        <Link
                          to="/groupManagement/addGroup"
                          className={classes.Link}
                        >
                          Create Group
                        </Link>
                      </Button> */}
                        <CustomButton
                          border="1px solid #4c10c1"
                          onClick={() =>
                            this.redirect("/articleManagement/addArticle")
                          }
                          m_width={"100%"}
                        >
                          {" "}
                          Create New Course
                        </CustomButton>
                      </Container>
                    </FlexContainer>
                  </div>
                </div>
                <Container
                  // m_width={"500px"}
                  left="0px"
                  right="0px"
                  m_margin="29px 8px 29px 8px"
                  bg="inherit"
                  m_flow={"scroll"}
                >
                  <CT
                  >
                    {/* <Table
                      courseData={this.state.filteredData}
                      getCourses={this.getCourses}
                      loading={this.state.loading}
                      deleteLoading={this.state.deleteLoading}
                      deleteId={this.state.deleteId}
                      deleteCourse={this.deleteCourse}
                      getSelected={this.getSelected}
                      toggleDelete={this.toggleShowDelete}
                    /> */}
                    <NewTable
                      courseData={this.state.filteredData}
                      getCourses={this.getCourses}
                      loading={this.state.loading}
                      deleteLoading={this.state.deleteLoading}
                      deleteId={this.state.deleteId}
                      deleteCourse={this.deleteCourse}
                      getSelected={this.getSelected}
                      toggleDelete={this.toggleShowDelete}
                    />
                  </CT>
                </Container>
              </div>
            </div>
          </CT>
        </Body>
      </div>
    );
  }
}

TopicManagement.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  null,
  { setTabAction, setCourseCategory, setCourseDeatils }
)(withStyles(styles)(TopicManagement));
