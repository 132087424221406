import React, { Component } from "react";

// custom functions
import api from "../../../utils/api";
import { post, baseUrl } from "../../../config/apiUrl";

// custom components
import Editor from "./EditorComponent";
import Loader from "../../subComponents/Loader";

// npm packges
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import CustomInput from "../../subComponents/CustomInput";
import Button from "@material-ui/core/Button";
import { EditorState, convertToRaw } from "draft-js";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { stateToHTML } from "draft-js-export-html";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { setEditorCourseId } from "../../../actions/editorAction";
import MiceEditor from "./MiceEditor";

// styled components
import CustomButton from "../../../consumercomponents/styledcomponents/Button";
import Label from "../../../consumercomponents/styledcomponents/Label";
import CT from "../../../consumercomponents/styledcomponents/CT";
import Input from "../../UserManagement/styledComponents/Input";

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 0px 10px 5px",
    boxSize: "border-box",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

const styles = theme => ({
  margin: {
    // margin: theme.spacing.unit,
    width: "100%"
  },
  label: {
    marginBottom: "0.5rem"
  },
  tabContainer: {
    display: "flex"
  },
  labelError: {
    color: "red"
  }
});

class EditDetals extends Component {
  state = {
    a: true,
    b: false,
    text: "",
    editorState: EditorState.createEmpty(),
    miceEditorInitialState: "",
    category: 1,
    categoryList: [],
    articleName: "",
    articleShortName: "",
    isError: false,
    error: {
      articleName: null,
      articleShortName: null,
      category: null,
      summary: null
    },
    loading: false,
    redirectPath: null,
    categoryLoad: false,
    convertedRaw: false,
    disabledButton: false,
    uploadTag: ""
  };

  // runs on editor text change
  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };

  changeButtonState = () => {
    this.setState(prevState => ({
      disabledButton: !prevState.disabledButton
    }));
  };

  handleChangeMiceEditor = e => {
    this.setState({
      miceEditorInitialState: e.target.getContent()
    });
  };

  // on tab change
  onTabChange = a => {
    this.setState(a);
  };

  // runs on aricle name change
  articleNameChange = e => {
    console.log(e.target.value);
    this.setState({
      articleName: e.target.value
    });
  };

  // reset error
  resetError = () => {
    this.setState({
      isError: false,
      error: {
        articleName: null,
        articleShortName: null,
        category: null,
        summary: null
      }
    });
  };

  // runs on article short name change
  articleShortNameChange = e => {
    this.setState({
      articleShortName: e.target.value
    });
  };

  // runs on categotyChange change
  handleChange = e => {
    this.setState({
      category: parseInt(e.target.value)
    });
  };

  //toggle converted to raw
  toggleConversation = () => {
    this.setState(prevState => ({
      convertedRaw: !prevState.convertedRaw
    }));
  };

  // validator function
  validate = (a, b) => {
    let error = false;
    a.forEach(element => {
      console.log(a);
      // All types of validation
      if (!element.value) {
        this.setState(prevState => ({
          error: {
            ...prevState.error,
            [element.name]: b[element.name]
          },
          isError: true
        }));
        error = true;
      }
    });
    console.log(error);
    if (!error) {
      const {
        articleName,
        articleShortName,
        category,
        editorState,
        convertedRaw,
        uploadTag
      } = this.state;
      const raw = convertToRaw(editorState.getCurrentContent());
      let value = "";
      raw.blocks.forEach(({ text }) => {
        value += text;
      });
      console.log("value", value);
      const summery = convertedRaw
        ? value
        : stateToHTML(editorState.getCurrentContent());
      this.toggleLoading();
      let { miceEditorInitialState } = this.state;
      if (uploadTag) {
        miceEditorInitialState += uploadTag;
      }
      const url = `${baseUrl}wsfunction=core_course_create_courses&moodlewsrestformat=json`;
      const body = new FormData();
      body.append("courses[0][fullname]", articleName);
      body.append("courses[0][shortname]", articleShortName);
      body.append("courses[0][categoryid]", category);
      body.append("courses[0][summary]", miceEditorInitialState);
      api(url, post, body, this.successApi, this.failedApi);
    }
  };

  toggleLoading = () => {
    this.setState(prevState => ({
      loading: !prevState.loading
    }));
  };

  successApi = res => {
    // stop loading
    this.toggleLoading();
    if (this.state.convertedRaw) {
      this.props.setEditorCourseId(res.data[0].id);
    }
    this.setState({
      redirectPath: "/articleManagement"
    });
    console.log("res", res);
  };

  failedApi = error => {
    // stop loading
    this.toggleLoading();

    console.log("error", error);
  };

  // Add Course
  addCourse = () => {
    this.resetError();
    const {
      articleName,
      articleShortName,
      category,
      editorState,
      miceEditorInitialState
    } = this.state;
    const summery = stateToHTML(editorState.getCurrentContent());
    const raw = convertToRaw(editorState.getCurrentContent());

    console.log("blocks", raw.blocks);

    // validation of all field
    this.validate(
      [
        { name: "articleName", value: articleName },
        { name: "articleShortName", value: articleShortName },
        { name: "category", value: category },
        { name: "summary", value: miceEditorInitialState }
      ],
      {
        articleName: "Name required",
        articleShortName: "Short name required",
        category: "category required",
        summary: "Description required"
      }
    );
  };

  // get all categories
  getCategories = () => {
    this.setState({ categoryLoad: true });
    const url = `${baseUrl}wsfunction=core_course_get_categories&moodlewsrestformat=json`;
    api(url, post, null, res => {
      if (res.data.length > 0) {
        this.setState({
          categoryList: res.data,
          categoryLoad: false
        });
      }
    });
  };

  componentWillMount() {
    this.getCategories();
  }

  componentDidMount() {
    // document.querySelector(".tox-tinymce").style.border = "2px solid red"
  }

  onChangefile = e => {
    this.changeButtonState();
    const file = e.target.files[0];
    const url = "http://servifylms.ouronlineserver.com/webservice/upload.php";
    const body = new FormData();
    body.append("file", file);
    body.append("token", "81a7d84b3cbd5c8e10653a06a7362038");
    body.append("filepath", "/");
    api(url, post, body, res => {
      let outputUrl = `http://servifylms.ouronlineserver.com/draftfile.php/5/user/draft/${
        res.data[0].itemid
      }/${res.data[0].filename}`;
      let tag2 = `<br><a href=${outputUrl}>${res.data[0].filename}</a>`;
      this.changeButtonState();

      this.setState(prevState => ({
        uploadTag: tag2
      }));
    });
  };

  render() {
    const { classes } = this.props;
    const categories = this.state.categoryList.map(({ id, name }, i) => (
      <option key={i} value={id}>
        {name}
      </option>
    ));
    // console.log( stateToHTML(this.state.editorState.getCurrentContent()))
    console.log("state", this.state);
    return (
      <div>
        <input
          name="image"
          type="file"
          id="upload1"
          className="hidden"
          style={{ display: "none" }}
        />
        {this.state.categoryLoad ? (
          <Loader />
        ) : (
          <div>
            {this.state.redirectPath && (
              <Redirect to={this.state.redirectPath} />
            )}
            <div id="test" />
            <Grid container spacing={24} style={{ paddingTop: "16px" }}>
              <Grid item xs={12} md={12}>
                <FormControl className={classes.margin}>
                  {/* <span
                    className={[
                      classes.label,
                      this.state.isError &&
                        this.state.error.articleName &&
                        classes.labelError
                    ].join(" ")}
                  >
                    {this.state.isError && this.state.error.articleName
                      ? this.state.error.articleName
                      : "Course Name *"}
                  </span> */}
                  <CT>
                    <Label
                      error={this.state.isError && this.state.error.articleName}
                    >
                      {this.state.isError && this.state.error.articleName
                        ? this.state.error.articleName
                        : "Course Name *"}
                    </Label>
                  </CT>
                  {/* <CustomInput
                    onChange={this.articleNameChange}
                    value={this.setState.articleName}
                    error={
                      this.state.isError && this.state.error.articleName
                        ? true
                        : false
                    }
                    /> */}
                  <Input
                    val={this.state.articleName}
                    change={this.articleNameChange}
                    error={
                      this.state.isError && this.state.error.articleName
                        ? true
                        : false
                    }
                    width={"100%"}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} />
            </Grid>

            {/* Category Details */}

            <Grid container spacing={24}>
              <Grid item xs={12} md={12}>
                {/* <div style={{ marginBottom: "0.5rem" }}>
                  <span>Category *</span>
                  </div> */}
                <CT>
                  <Label>Category *</Label>
                </CT>
 
                <div className="form-group">
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    defaultValue={this.state.category || 1}
                    onChange={this.handleChange}
                  >
                    {categories}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} md={6} />
            </Grid>

            {/* Short name starts*/}

            <Grid container spacing={24}>
              <Grid item xs={12} md={12}>
                <FormControl className={classes.margin}>
                  {/* <span
                    className={[
                      classes.label,
                      this.state.isError &&
                        this.state.error.articleShortName &&
                        classes.labelError
                    ].join(" ")}
                  >
                    {this.state.isError && this.state.error.articleShortName
                      ? this.state.error.articleShortName
                      : "Short Name *"}
                  </span> */}
                  <CT>
                    <Label
                      error={
                        this.state.isError && this.state.error.articleShortName
                      }
                    >
                      {this.state.isError && this.state.error.articleShortName
                        ? this.state.error.articleShortName
                        : "Short Name *"}
                    </Label>
                  </CT>
                  {/* <CustomInput
                    onChange={this.articleShortNameChange}
                    value={this.setState.articleShortName}
                    error={
                      this.state.isError && this.state.error.articleShortName
                        ? true
                        : false
                    }
                    /> */}
                  <Input
                    val={this.state.articleShortName}
                    change={this.articleShortNameChange}
                    error={
                      this.state.isError && this.state.error.articleShortName
                        ? true
                        : false
                    }
                    width={"100%"}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} />
            </Grid>

            {/* short name ends */}

            <Grid container spacing={24}>
              <Grid item xs={12} md={12}>
                <div>
                  {/* <p>Course Description</p> */}
                  {/* <Editor
                    type={this.props.type}
                    editorState={this.state.editorState}
                    onEditorStateChange={this.onEditorStateChange}
                    raw={this.state.convertedRaw}
                    toggleConversation={this.toggleConversation}
                  /> */}
                  {/* <div
                    className={[
                      classes.label,
                      this.state.isError &&
                        this.state.error.summary &&
                        classes.labelError
                    ].join(" ")}
                    style={{ marginBottom: "1rem" }}
                  >
                    {this.state.isError && this.state.error.summary
                      ? this.state.error.summary
                      : "Course Description *"}
                  </div> */}

                  <CT>
                    <Label
                      error={this.state.isError && this.state.error.summary}
                    >
                      {this.state.isError && this.state.error.summary
                        ? this.state.error.summary
                        : "Course Description *"}
                    </Label>
                  </CT>

                  <MiceEditor
                    state={this.state.miceEditorInitialState}
                    handler={this.handleChangeMiceEditor}
                    id="#upload1"
                  />
                </div>
                <div style={{ marginTop: "16px" }}>
                  <input
                    type="file"
                    name=""
                    id=""
                    onChange={e => this.onChangefile(e)}
                  />
                </div>
                <div style={{ marginTop: "16px" }}>
                  {this.props.type === "add" || this.props.type === "edit" ? (
                    this.state.loading ? (
                      <Loader />
                    ) : (
                      <CustomButton
                        border="1px solid #4c10c1"
                        onClick={this.addCourse}
                        m_width={"100%"}
                      >
                        {this.props.type === "edit" ? "Save Changes" : "Done"}
                      </CustomButton>
                    )
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.button}
                    >
                      Edit Article
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={6} />
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  categories: state
});

export default connect(
  mapStateToProps,
  { setEditorCourseId }
)(withStyles(styles)(EditDetals));
