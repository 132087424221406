import React, { Component } from "react";

// custom functions
import api from "../../utils/api";
import { post, baseUrl, customBaseUrl } from "../../config/apiUrl";
import Body from "../Home/subComponents/Body";
import Header from "../subComponents/Header";

// Actions
import { setTabAction } from "../../actions/tabAction";

// custom components
import Editor from "./subComponents/EditorComponent";
import MiceEditor from "./subComponents/MiceEditor";
import Loader from "../subComponents/Loader";
import CustomInput from "../subComponents/CustomInput";

// npm packges
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { EditorState, convertToRaw } from "draft-js";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { stateToHTML } from "draft-js-export-html";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import IconButton from "@material-ui/core/IconButton";
import lineHeight from "../../utils/lineHeight";

// styled components
import Container from "../../consumercomponents/styledcomponents/Container";
import FlexContainer from "../../consumercomponents/styledcomponents/FlexContainer";
import PointerWrapper from "../../consumercomponents/styledcomponents/PointerWrapper";
import CustomButton from "../../consumercomponents/styledcomponents/Button";
import CT from "../../consumercomponents/styledcomponents/CT";
import Label from "../../consumercomponents/styledcomponents/Label";
import Input from "../UserManagement/styledComponents/Input";

//Image import
import back from "../../assets/icons/back.png";

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 0px 10px 5px",
    boxSize: "border-box",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: "GothamBook",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

const styles = theme => ({
  margin: {
    // margin: theme.spacing.unit,
    width: "100%"
  },
  label: {
    marginBottom: "0.5rem"
  },
  tabContainer: {
    display: "flex"
  },
  labelError: {
    color: "red"
  }
});

class EditDetals extends Component {
  state = {
    a: true,
    b: false,
    text: "",
    editorState: "",
    editorState2: "",
    category: 1,
    categoryList: [],
    articleName: "",
    articleShortName: "",
    isError: false,
    error: {
      articleName: null,
      articleShortName: null,
      category: null,
      editorState: null
    },
    loading: false,
    redirectPath: null,
    categoryLoad: false,
    path: null,
    convertedRaw1: false,
    convertedRaw2: false,
    disabledButton: false,
    uploadTag: ""
  };

  // runs on editor text change
  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };

  changeButtonState = () => {
    this.setState(prevState => ({
      disabledButton: !prevState.disabledButton
    }));
  };

  handleGoback = () => {
    this.setState({
      path: `/articleManagement/topicManagement/${this.props.match.params.id}`
    });
  };

  miceEditorNameChange = (name, e) => {
    this.setState({
      [name]: e.target.getContent()
    });
  };

  onEditorStateChange2 = editorState2 => {
    this.setState({
      editorState2
    });
  };

  //toggle converted to raw
  toggleConversation = name => {
    this.setState(prevState => ({
      [name]: !prevState[name]
    }));
  };

  // on tab change
  onTabChange = a => {
    this.setState(a);
  };

  // runs on aricle name change
  articleNameChange = e => {
    console.log(e.target.value);
    this.setState({
      articleName: e.target.value
    });
  };

  // reset error
  resetError = () => {
    this.setState({
      isError: false,
      error: {
        articleName: null,
        articleShortName: null,
        category: null,
        editorState: null
      }
    });
  };

  // runs on article short name change
  articleShortNameChange = e => {
    this.setState({
      articleShortName: e.target.value
    });
  };

  // runs on categotyChange change
  handleChange = e => {
    this.setState({
      category: parseInt(e.target.value)
    });
  };

  // validator function
  validate = (a, b) => {
    let error = false;
    a.forEach(element => {
      console.log(a);
      // All types of validation
      if (!element.value) {
        this.setState(prevState => ({
          error: {
            ...prevState.error,
            [element.name]: b[element.name]
          },
          isError: true
        }));
        error = true;
      }
    });
    console.log(error);
    if (!error) {
      const {
        articleName,
        articleShortName,
        category,
        editorState,
        uploadTag
      } = this.state;

      let { editorState2 } = this.state;

      const courseId = this.props.match.params.id;
      const topicId = this.props.match.params.topicId;
      this.toggleLoading();

      if (uploadTag) {
        editorState2 += uploadTag;
      }

      const url = `${customBaseUrl}wsfunction=local_servifywebapis_create_pages&moodlewsrestformat=json&courseid=${courseId}&topicid=${topicId}&name=${articleName}&visible=1`;
      const body = new FormData();
      body.append("content", editorState2);
      body.append("intro", editorState);
      api(url, post, body, this.successApi, this.failedApi);
    }
  };

  toggleLoading = () => {
    this.setState(prevState => ({
      loading: !prevState.loading
    }));
  };

  successApi = res => {
    // stop loading
    this.toggleLoading();
    this.setState({
      uploadTag: "",
      redirectPath: `/articleManagement/topicManagement/${
        this.props.match.params.id
      }`
    });
    console.log("res", res);
  };

  failedApi = error => {
    // stop loading
    this.toggleLoading();

    console.log("error", error);
  };

  // Add Course
  addCourse = () => {
    this.resetError();
    const { articleName, editorState, editorState2 } = this.state;

    // validation of all field
    this.validate(
      [
        { name: "articleName", value: articleName },
        { name: "editorState", value: editorState },
        { name: "editorState2", value: editorState2 }
      ],
      {
        articleName: "Name required"
      }
    );
  };

  // get all categories
  getCategories = () => {
    this.setState({ categoryLoad: true });
    const url = `${baseUrl}wsfunction=core_course_get_categories&moodlewsrestformat=json`;
    api(url, post, null, res => {
      if (res.data.length > 0) {
        this.setState({
          categoryList: res.data,
          categoryLoad: false
        });
      }
    });
  };

  componentWillMount() {
    this.props.setTabAction("Course Management");
    this.getCategories();
  }

  onChangefile = e => {
    this.changeButtonState();
    const file = e.target.files[0];
    const url = "http://servifylms.ouronlineserver.com/webservice/upload.php";
    const body = new FormData();
    body.append("file", file);
    body.append("token", "81a7d84b3cbd5c8e10653a06a7362038");
    body.append("filepath", "/");
    api(url, post, body, res => {
      let outputUrl = `http://servifylms.ouronlineserver.com/draftfile.php/5/user/draft/${
        res.data[0].itemid
      }/${res.data[0].filename}`;
      let tag2 = `<br><a href=${outputUrl}>${res.data[0].filename}</a>`;
      this.changeButtonState();

      this.setState(prevState => ({
        uploadTag: tag2
      }));
    });
  };

  render() {
    const { classes } = this.props;
    const categories = this.state.categoryList.map(({ id, name }, i) => (
      <option key={i} value={id}>
        {name}
      </option>
    ));
    // console.log( stateToHTML(this.state.editorState.getCurrentContent()))
    console.log("state", this.state);
    return (
      <div>
        {this.state.path && <Redirect to={this.state.path} />}
        <Header />
        <Body>
          <CT left={"25px"} right={"40px"} m_margin={"0px"}>
            <input
              name="image"
              type="file"
              id="upload5"
              className="hidden"
              style={{ display: "none" }}
            />
            <input
              name="image"
              type="file"
              id="upload6"
              className="hidden"
              style={{ display: "none" }}
            />
            <FlexContainer margin={"40px 0 1rem 0"} m_justifyContent="center">
              <div onClick={this.handleGoback}>
                <PointerWrapper>
                  <img src={back} alt="back" />
                </PointerWrapper>
              </div>
            </FlexContainer>

            <Paper style={{ padding: "16px" }}>
              {this.state.categoryLoad ? (
                <Loader />
              ) : (
                <div>
                  {this.state.redirectPath && (
                    <Redirect to={this.state.redirectPath} />
                  )}
                  <Grid container spacing={24} style={{ paddingTop: "16px" }}>
                    <Grid item xs={12} md={12}>
                      <FormControl className={classes.margin}>
                        {/* <span
                          className={[
                            classes.label,
                            this.state.isError &&
                              this.state.error.articleName &&
                              classes.labelError
                          ].join(" ")}
                        >
                          {this.state.isError && this.state.error.articleName
                            ? this.state.error.articleName
                            : "Name *"}
                        </span> */}
                        <CT>
                          <Label
                            error={
                              this.state.isError && this.state.error.articleName
                            }
                          >
                            {this.state.isError && this.state.error.articleName
                              ? this.state.error.articleName
                              : "Name *"}
                          </Label>
                        </CT>
                        {/* <CustomInput
                          onChange={this.articleNameChange}
                          value={this.setState.articleName}
                          error={
                            this.state.isError && this.state.error.articleName
                              ? true
                              : false
                          }
                          /> */}

                        <Input
                          change={this.articleNameChange}
                          val={this.state.articleName}
                          error={
                            this.state.isError && this.state.error.articleName
                              ? true
                              : false
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Category Details */}

                  {/* Short name starts*/}

                  <Grid container spacing={24}>
                    <Grid item xs={12} md={12}>
                      <div style={{ marginTop: "16px" }}>
                        <CT>
                          <Label>Description</Label>
                        </CT>
                        <MiceEditor
                          state={this.state.editorState}
                          handler={e =>
                            this.miceEditorNameChange("editorState", e)
                          }
                          id="#upload5"
                        />
                        {/* <Editor
                        type={this.props.type}
                        editorState={this.state.editorState}
                        onEditorStateChange={this.onEditorStateChange}
                        raw={this.state.convertedRaw1}
                        toggleConversation={() =>
                          this.toggleConversation("convertedRaw1")
                        }
                      /> */}
                      </div>
                      <div style={{ marginTop: "16px" }}>
                        <CT>
                          <Label>Content</Label>
                        </CT>
                        <MiceEditor
                          state={this.state.editorState2}
                          handler={e =>
                            this.miceEditorNameChange("editorState2", e)
                          }
                          id="#upload6"
                        />

                        {/* <Editor
                        type={this.props.type}
                        editorState={this.state.editorState2}
                        onEditorStateChange={this.onEditorStateChange2}
                        raw={this.state.convertedRaw2}
                        toggleConversation={() =>
                          this.toggleConversation("convertedRaw2")
                        }
                      /> */}
                      </div>
                      <div style={{ marginTop: "16px" }}>
                        <input
                          type="file"
                          name=""
                          id=""
                          onChange={e => this.onChangefile(e)}
                        />
                      </div>
                      <div style={{ marginTop: "16px" }}>
                        {this.state.loading ? (
                          <Loader />
                        ) : (
                          // <Button
                          //   variant="contained"
                          //   className={classes.button}
                          //   color="primary"
                          //   onClick={this.addCourse}
                          //   disabled={this.state.disabledButton}
                          // >
                          //   Add
                          // </Button>
                          <CustomButton
                            border="1px solid #4c10c1"
                            onClick={this.addCourse}
                            m_width={"100%"}
                            disabled={this.state.disabledButton}
                          >
                            Add
                          </CustomButton>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} />
                  </Grid>
                </div>
              )}
            </Paper>
          </CT>
        </Body>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  categories: state
});

export default connect(
  mapStateToProps,
  { setTabAction }
)(withStyles(styles)(EditDetals));
