import React from "react";
import styled from "styled-components";


const Header = styled.div`
  width: ${({ width }) => (width ? width : "initial")};
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #393939;
`;

export default function Heading(props) {
  return (
    <Header>{props.text}</Header>
  );
}
