export const SET_TAB = 'SET_TAB';
export const SET_SUBMENU = "SET_SUBMENU";


// consumer tab

export const SET_CON_TAB = 'SET_CON_TAB';
export const SET_CON_SUBMENU = "SET_CON_SUBMENU";


export const setTabAction = (payload) => {
  return {
    type: SET_TAB,
    payload
  }
}
export const setSubMenuTabAction = (payload) => { 
  return {
    type: SET_SUBMENU,
    payload
  };
}


// consumer tab action



export const setConTabAction = (payload) => {
  return {
    type: SET_CON_TAB,
    payload
  }
}
export const setConSubMenuTabAction = (payload) => {
  return {
    type: SET_CON_SUBMENU,
    payload
  };
}
