import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from "./ListItem";
import SubListItem from "./SubListItem";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height : "fill-available"
  },
  customGrid : {
    height : "fill-available" ,
    marginRight : "1rem" 
  },
  paper: {
    padding: theme.spacing.unit * 2,
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    height : "100%"
  },

  backgroundGray : {
      backgroundColor : "#EEEEEE",
      padding : "2rem"
  }
});

function CenteredGrid(props) {
  const { classes } = props;

  return (
    <div className="row">
      <div className="col-sm -12 col-md-2" style={{ position : "relative",paddingBottom : "38px"}}>
        <List name={"Dashboard"} />
      
        <List name={"Course Management"} path={"/articleManagement"} />
        <List name={"Quiz Management"} path={"/quizManagement"} />
       
      
        <List
          name={"User Management"}
          child={true}
          childNames={["Groups", "Users"]}
          paths={["/groupManagement", "/userManagement"]}
        />
        
        <List name={"Consumer Design(This link is for demonstrate purpose)"} path="/consumer/name/id/courses"/>
        <List name={"Connect to 360 App for reference Article"} />
        <div style={{ textAlign: "center",position : "absolute",bottom : "0px",width: "100%" }}>
          <img src={require("../../../assets/images/footer.PNG")} alt="" />
        </div>
      </div>
      <div className="col-sm-12 col-md-10 " style={{ backgroundColor : "#F5F5F5"}}>{props.children}</div>
    </div>
    // <div className={classes.root}>
    //   <Grid container spacing={8} className={classes.customGrid}>
    //     <Grid item xs={2}>
    //       <div
    //         className={classes.paper}
    //         style={{
    //           display: "flex",
    //           flexDirection: "column",
    //           justifyContent: "space-between"
    //         }}
    //       >
    //         <div>
    //           <List name={"Dashboard"} />
    //           <List name={"User & Groups"} child={true} />
    //           <List name={"Topic Management"} path={"/topicManagement"} />
    //           <List name={"Course Management"} path={"/articleManagement"} />
    //           <List
    //             name={"Quiz / Test Management"}
    //             path={"/quizManagement"}
    //           />
    //           <List name={"BoardCasting"} />
    //           <List name={"Trigger Notifications"} />
    //           <List
    //             name={"User Management"}
    //             child={true}
    //             childNames={["Groups", "Users"]}
    //             paths={["/groupManagement", "Users"]}
    //           />
    //           <List name={"Role Access"} />
    //           <List name={"Gamifications"} />
    //           <List name={"Compliance"} />
    //           <List name={"Connect to 360 App for reference Article"} />
    //         </div>
    // <div style={{ textAlign: "center" }}>
    //   <img
    //     src={require("../../../assets/images/footer.PNG")}
    //     alt=""
    //   />
    // </div>
    //       </div>
    //     </Grid>
    //     <Grid item xs={10}>
    //       <div className={[classes.paper, classes.backgroundGray].join(" ")}>
    //         {props.children}
    //       </div>
    //     </Grid>
    //   </Grid>
    // </div>
  );
}

CenteredGrid.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CenteredGrid);