import React, { Component } from "react";

// custom components
import FindmoreTab from "./QuestionDetailsTab";
import AddQuestionTab from "./AddQuestion";
import NewTab from "./NewTab"

// npm packages
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { withStyles } from "@material-ui/core/styles";


const CustomTab = ({ children, isStyle, index, realIndex }) => (
  <span
    style={{
      background: "#ffffff" ,
      borderRadius: index === realIndex &&  "10px",
      padding:
        index === realIndex
          ? "16px 8px 10px 8px"
          : "16px 8px 10px 8px"
      // padding : "0.5rem 0.5rem 0.8rem 0.5rem"
    }}
  >
    {children}
  </span>
);

const Styles = theme => ({
  inActive: {
    background: "#EEEEEE",
    borderRadius : "10px"
    // borderRight : "1px solid white"
  },
  updateContainer: {
    padding: "16px"
  }
});

class TabComponent extends Component {
  constructor() {
    super();
    this.state = { tabIndex: 0,selected : 0 };
  }

  slectTab = selected => this.setState({ selected });


  changeTab = () => {
    this.setState({ tabIndex: 0 });
  }
  render() {
    console.log("state", this.state);
    const { classes } = this.props; 
    return (
      <NewTab slectTab={this.slectTab} selected={this.state.selected} tab1={<FindmoreTab ids={this.props.ids} />} tab2={ <AddQuestionTab ids={this.props.ids} changeTab={this.changeTab} slectedTab={this.slectTab} />}/>
      // <Tabs
      //   selectedIndex={this.state.tabIndex}
      //   onSelect={tabIndex => this.setState({ tabIndex })}
      // >
      //   <TabList>
      //     <Tab
      //       style={{
      //         color: this.state.tabIndex === 0 && "blue",
      //         fontFamily : "GothamBook",
      //         fontWeight : 800,
      //         padding: "16px 8px"
      //       }}
      //     >
      //       <CustomTab index={this.state.tabIndex} realIndex={0}>
      //         Questions
      //       </CustomTab>
      //     </Tab>
      //     <Tab
      //       style={{
      //         color: this.state.tabIndex === 1 && "blue",
      //         fontFamily : "GothamBook",
      //         fontWeight : 800,
      //         padding: "16px 8px"
      //       }}
      //     >
      //       <CustomTab index={this.state.tabIndex} realIndex={1}>
      //         Add Questions
      //       </CustomTab>
      //     </Tab>
      //   </TabList>

      //   <TabPanel>
      //     <FindmoreTab ids={this.props.ids} />
      //   </TabPanel>
      //   <TabPanel>
      //     <AddQuestionTab ids={this.props.ids} changeTab={this.changeTab} />
      //   </TabPanel>
      // </Tabs> 
    );
  }
}

export default withStyles(Styles)(TabComponent);
