import styled from "styled-components";
import Screen from "./ScreenSizes"


export default styled.div.attrs(props => ({
     className: props.cl
}))`
background-color: #EEEEEE;
padding: 32px;



@media ${Screen.tablet} {
   padding: 0
  }
`