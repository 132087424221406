import React from "react";

import FlexContainer from "../../consumercomponents/styledcomponents/FlexContainer";
import CT from "../../consumercomponents/styledcomponents/Container";
import NW, {
  TEXT,
  BOLD,
  POINTER,
  LINK,
  WRAPPER
} from "../../consumercomponents/styledcomponents/NotificationPanel";

export default function NotificationPanel() {
  return (
    <WRAPPER width={"500px"} m_width={"300px"} m_margin={"0 -14vw 0 0"}>
      <FlexContainer
        direction={"column"}
        m_direction={"column"}
        alignItems={"flex-end"}
      >
        <POINTER margin={"0 5.5vw 0 0"} m_margin={"0 40.5vw 0 0"} />
        <NW>
          <CT bottom={"31px"}>
            <FlexContainer m_justifyContent={"center"}>
              <TEXT size={"14px"}>Notifications</TEXT>
            </FlexContainer>
          </CT>
          <CT b_top={"1px solid #F1F1F3"}>
            <FlexContainer alignItems={"center"} m_direction={"column"} m_alignItems={"center"}>
              <CT right={"16px"}>
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    backgroundColor: "#DBCFF3"
                  }}
                />
              </CT>
              <FlexContainer direction={"column"} m_direction={"column"} m_alignItems={"center"}>
                <div style={{ textAlign: "center"}}>
                  <BOLD>Joshua D. Sylvester</BOLD>{" "}
                  <TEXT>sent you a message.</TEXT>
                </div>
                <div>
                  <TEXT size={"10px"}>4 min ago</TEXT>
                </div>
              </FlexContainer>
            </FlexContainer>
          </CT>
          <CT b_top={"1px solid #F1F1F3"}>
            <FlexContainer alignItems={"center"} m_direction={"column"} m_alignItems={"center"}>
              <CT right={"16px"}>
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    backgroundColor: "#DBCFF3"
                  }}
                />
              </CT>
              <FlexContainer direction={"column"} m_direction={"column"} m_alignItems={"center"}>
                <div style={{ textAlign: "center"}}>
                  <BOLD>Joshua D. Sylvester</BOLD>{" "}
                  <TEXT>sent you a message.</TEXT>
                </div>
                <div>
                  <TEXT size={"10px"}>4 min ago</TEXT>
                </div>
              </FlexContainer>
            </FlexContainer>
          </CT>
          <CT b_top={"1px solid #F1F1F3"}>
            <FlexContainer alignItems={"center"} m_direction={"column"} m_alignItems={"center"}>
              <CT right={"16px"}>
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    backgroundColor: "#DBCFF3"
                  }}
                />
              </CT>
              <FlexContainer direction={"column"} m_direction={"column"} m_alignItems={"center"}>
                <div style={{ textAlign: "center"}}>
                  <BOLD>Joshua D. Sylvester</BOLD>{" "}
                  <TEXT>sent you a message.</TEXT>
                </div>
                <div>
                  <TEXT size={"10px"}>4 min ago</TEXT>
                </div>
              </FlexContainer>
            </FlexContainer>
          </CT>
          <CT b_top={"1px solid #F1F1F3"}>
            <FlexContainer alignItems={"center"} m_direction={"column"} m_alignItems={"center"}>
              <CT right={"16px"}>
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    backgroundColor: "#DBCFF3"
                  }}
                />
              </CT>
              <FlexContainer direction={"column"} m_direction={"column"} m_alignItems={"center"}>
                <div style={{ textAlign: "center"}}>
                  <BOLD>Joshua D. Sylvester</BOLD>{" "}
                  <TEXT>sent you a message.</TEXT>
                </div>
                <div>
                  <TEXT size={"10px"}>4 min ago</TEXT>
                </div>
              </FlexContainer>
            </FlexContainer>
          </CT>
          <CT b_top={"1px solid #F1F1F3"}>
            <FlexContainer alignItems={"center"} m_direction={"column"} m_alignItems={"center"}>
              <CT right={"16px"}>
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    backgroundColor: "#DBCFF3"
                  }}
                />
              </CT>
              <FlexContainer direction={"column"} m_direction={"column"} m_alignItems={"center"}>
                <div style={{ textAlign: "center"}}>
                  <BOLD>Joshua D. Sylvester</BOLD>{" "}
                  <TEXT>sent you a message.</TEXT>
                </div>
                <div>
                  <TEXT size={"10px"}>4 min ago</TEXT>
                </div>
              </FlexContainer>
            </FlexContainer>
          </CT>
          <CT top={"22px"}>
            <FlexContainer justifyContent={"center"}>
              <LINK>View all notifications</LINK>
            </FlexContainer>
          </CT>
        </NW>
      </FlexContainer>
    </WRAPPER>
  );
}
