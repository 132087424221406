import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { setTabAction } from "../../../actions/tabAction"

import Home from '../Home';

import React, { Component } from 'react'

 class Dashboard extends Component {

    componentWillMount(){
        this.props.setTabAction("Dashboard")
    }
  render() {
    return (
       <Home />
    )
  }
}



const mapStateToProps = (state) => ({
})

export default withRouter(connect(mapStateToProps, {setTabAction})(Dashboard))
