import axios from 'axios'
import { baseUrl } from '../../config/apiUrl'

// actions
import { CourseListApi } from './courseAction'

import store from '../../redux/reducers'

// type
export const SET_CONSUMER_QUIZ_DETAILS = 'SET_CONSUMER_QUIZ_DETAILS'
export const SET_GROUP_DETAILS = 'SET_GROUP_DETAILS'
export const SET_USER_ID = 'SET_USER_ID'

export const setConsumerQuizList = (payload) => {
    return {
        type: SET_CONSUMER_QUIZ_DETAILS,
        payload,
    }
}

export const consumerlistApi = (
    dispatch,
    courses,
    success,
    loading,
    courseId
) => {
    console.log('courseId', courseId)

    let url = `${baseUrl}wsfunction=mod_quiz_get_quizzes_by_courses&moodlewsrestformat=json&`
    let trimmedUrl = `${baseUrl}wsfunction=mod_quiz_get_quizzes_by_courses&moodlewsrestformat=json&courseids[0]=${courseId}`

    if (courseId === 'all') {
        courses.forEach(({ id }, index) => {
            url += `courseids[${index}]=${id}&`
        })
         trimmedUrl = url.slice(0, -1)
    } 

    axios
        .get(trimmedUrl)
        .then((resp) => {
            if (loading) {
                loading(false)
            }

            console.log('data', resp.data)
            dispatch(setConsumerQuizList(resp.data.quizzes))

            if (success) {
                success()
            }
        })
        .catch((error) => console.log('error', error))
}

export const setConsumerQuizListApi = (
    loading,
    success,
    courses,
    id,
    courseId
) => (dispatch) => {
    let c = store.getState().consumerCourses.courses
    if (loading) {
        loading(true)
    }

    if (courses.length == 0) {
        CourseListApi(dispatch, id, consumerlistApi, loading, courseId)
    } else {
        consumerlistApi(
            dispatch,
            c,
            store.getState().consumerCourses.courses,
            loading,
            courseId
        )
    }
}
