import React from "react";
import SearchIcon from "../../assets/icons/search.png";

//styled components
import FP from "../../consumercomponents/styledcomponents/FlexContainer";
import CT from "../../consumercomponents/styledcomponents/Container";
import Space from "../../consumercomponents/styledcomponents/Space";

// npm packages
import InputBase from "@material-ui/core/InputBase";

function Search(props) {

  const { value, onChange, search } = props;
  
  // styles
  const style = {
    CT: {
      top: "0",
      right: "0",
      bottom: "0",
      left: "0",
      height: "46px",
      pleft: "30px",
      radius: "5px",
      border: props.border,
      width: props.width,
      m_width: props.m_width,
      m_padding: "0 0 0 8px"
    },
    FP: {
      alignItems: "center",
      height: "100%",
      wrap : "no-wrap"
    },
    Space: {
      width : "30px"
    }
  };



//props
  const _props = {
    InputBase: {
      placeholder : search ? search : "Search…" ,
      value,
      onChange
    }
  }


  return (
    <CT {...style.CT}>
      <FP {...style.FP}>
        <img src={SearchIcon} alt="search" />
        <Space {...style.Space} />
        <InputBase {..._props.InputBase}  />
      </FP>
    </CT>
  );
}

export default Search;
