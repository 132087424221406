
import { SET_TAB, SET_SUBMENU } from "../../actions/tabAction";
const initialState = {
    sideMenuTab: "Dashboard",
    subMenu : ""
}

const tabReducer = (state=initialState,action) => {
    switch (action.type) {
      case SET_TAB:
        return {
          ...state,
          sideMenuTab: action.payload
        };
      case SET_SUBMENU:
        return {
          ...state,
          subMenu: action.payload
        };
      default:
        return state;
    }
}

export default tabReducer;