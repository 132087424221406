import React, { Component } from "react";
import Header from "../subComponents/Header";
import Body from "./subComponents/Body";
import Content from "./subComponents/Content";

class Home extends Component {
  render() {
    return (
      <div>
        <Header />
        <Body>
          <Content />
        </Body>
      </div>
    );
  }
}

export default Home;
