import React, { Component } from 'react'
// import './styles/main.scss'
import {
  Route,
  Switch,
  HashRouter, withRouter
} from 'react-router-dom';
import store from "./redux/reducers";
import { Provider } from "react-redux";
import routesList from './routes/createRoutes'


class App extends Component {
  componentWillMount () {
    document.addEventListener('touchmove', function (event) {
      if (event.scale !== 1) { event.preventDefault() }
    }, false)
  }

  render () {
    return (
      <Provider store={store}>
        <HashRouter >
          <Switch>
            {
              routesList.map(
                (route, index) => (
                  <Route
                    exact={route.exactPath || false}
                    path={route.path}
                    component={route.component}
                    key={index}
                  />
                )
              )
            }
            {/* {
              <Redirect path='*' to='/pagenotfound' />
            } */}
          </Switch>
        </HashRouter>
        </Provider>

    )
  }
}

export default App
