import React, { Component } from "react";
import SideBarWrapper from "../styledcomponents/SideBarWrapper";
import FlexContainer from "../styledcomponents/FlexContainer";
import ProfileWrapper from "../styledcomponents/ProfileWrapper";
import ActiveUserIcon from "../styledcomponents/ActiveUserIcon";
import Container from "../styledcomponents/Container";
import Name from "../styledcomponents/ProfileName";
import Email from "../styledcomponents/ProfileEmail";
import List from "./ListItem";

import servify from "../../assets/images/servify_iocn.png";
class SideBar extends Component {
  render() {
    return (
      <SideBarWrapper>
        <Container top="59px">
          <FlexContainer justifyContent="center">
            <ProfileWrapper>
              <ActiveUserIcon />
            </ProfileWrapper>
          </FlexContainer>

          <Container top="24px" left={"0px"} right={"0px"}>
            <FlexContainer m_direction={"column"} alignItems="center" direction="column">
              <Name>Mahesh Patil</Name>
              <Email>mahesh.p@survify.tech</Email>
            </FlexContainer>
          </Container>
          <Container top="24px">
            <List name={"Dashboard"} path="/consumer/:idsdsd12345"/>
            <List name={"My Courses"} />
            
            <List name={"My Quizzes"} path="/consumer/name/id/quizzess/all" />
            
            <List
              name={"Quiz Result(This link is for design purpose)"}
              path="/consumer/name/id/quizzess/quizId"
            />
            <List name={"Help"} />
          </Container>
        </Container>
        <Container top="116px" left="20px" m_margin={"0 0 40px 40px"}>
          <FlexContainer>
            <i
              className="fas fa-sign-out-alt"
              style={{ marginRight: "10px", color: "#758B8B" }}
            />
            <span>Log out</span>
          </FlexContainer>
        </Container>
        <Container top="63px" left="20px" m_margin={"0 0 40px 40px"}>
          <FlexContainer>
            <img src={servify} />
          </FlexContainer>
        </Container>
      </SideBarWrapper>
    );
  }
}

export default SideBar;
