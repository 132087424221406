import styled from "styled-components"


export default styled.div`
height:${props => props.height};
max-width:${props => props.width};
flex:${props => props.flex};
padding-left:${props => props.left};
padding-right:${props => props.right};

@media screen and (max-width: 800px) {
  flex : 100%;
  order:${props => props.order};
  margin:${props => props.m_margin};
  padding:${props => props.m_padding};

}

`