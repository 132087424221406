import React from 'react'


// custom components
import Divider from "../styledComponents/Divider";
import FlexContainer from "../styledComponents/FlexContainer";


// npm packages
import ReactTooltip from "react-tooltip";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  shadow: {
    boxShadow: "2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )"
  }
});

 function SkillLevel(props) {
   const { classes } = props;
   return (
     <ReactTooltip
       id="global"
       aria-haspopup="true"
       role="example"
       type="light"
       className={classes.shadow}
       effect="solid"
     >
       <div style={{ minWidth: "200px" }}>
         <FlexContainer justifyContent="space-between">
           <div>
             <img src={require("../../../assets/icons/star.png")} alt="star" />
           </div>
           <span>Beginner</span>
         </FlexContainer>

         <Divider top="10px" bottom="5px" />
         <FlexContainer justifyContent="space-between">
           <div>
             <img src={require("../../../assets/icons/star.png")} alt="star" />
             <img src={require("../../../assets/icons/star.png")} alt="star" />
           </div>
           <span>Proficient</span>
         </FlexContainer>
         <Divider top="10px" bottom="5px" />
         <FlexContainer justifyContent="space-between">
           <div>
             <img src={require("../../../assets/icons/star.png")} alt="star" />
             <img src={require("../../../assets/icons/star.png")} alt="star" />
             <img src={require("../../../assets/icons/star.png")} alt="star" />
           </div>
           <span>Expert</span>
         </FlexContainer>
       </div>
     </ReactTooltip>
   );
 }


export default withStyles(styles)(SkillLevel)