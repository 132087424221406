import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import UserIcon from "@material-ui/icons/AccountCircle";
import TickIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import Chart from "./Chart";
import OverviewCard from "./OverviewCard";
import QuizMetricsInfo from "./QuizMetricsInfo";
import styled from "styled-components";

// styled components
import FlexContainer from "../../../consumercomponents/styledcomponents/FlexContainer";
import FlexChild from "../../../consumercomponents/styledcomponents/FlexChild";
import PointerWrapper from "../../../consumercomponents/styledcomponents/PointerWrapper";
import { Heading, SkillWrapper, Container,OverflowWrapper } from "../styledComponent/Dashboard";
import { NameWrapper } from "./OverviewCard";

const TItleWrapper = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: left;
  color: #757575;
`;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "fill-available"
    // padding : "1rem"
  },
  customGrid: {
    // height: "fill-available"
    // marginRight : "1rem"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    // textAlign: 'center',
    color: theme.palette.text.secondary
    // height: "100%"
  },

  backgroundGray: {
    backgroundColor: "#EEEEEE"
  },

  metricsContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  paperInnerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  paperContainer: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem"
  },
  boldHeader: {
    fontSize: "1.7rem",
    fontWeight: "500",
    color: "#484848"
  },
  horizontalPadding: {
    padding: "1rem"
  }
});

function Articles({ name, link }) {
  return (
    <Container top={"22px"} right={"0px"} bottom={"0px"} left={"0px"}>
      <FlexContainer>
        <FlexChild flex={2}>
          <NameWrapper maxWidth={"100%"} fontSize={"12px"}>
            {name}
          </NameWrapper>
        </FlexChild>
        <FlexChild
          flex={1}
          justifyContent={"center"}
          m_justifyContent={"flex_end"}
        >
          <PointerWrapper>
            <img src={require("../../../assets/icons/view.png")} alt="view" />
          </PointerWrapper>
        </FlexChild>
      </FlexContainer>
    </Container>
  );
}

function CenteredGrid(props) {
  const { classes } = props;
  const articles = (
    <Container top={"22px"} right={"0px"} bottom={"0px"} left={"0px"}>
      <FlexContainer>
        <FlexChild flex={2}>
          <NameWrapper maxWidth={"100%"} fontSize={"12px"}>
            1. Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta,
            amet?
          </NameWrapper>
        </FlexChild>
        <FlexChild flex={1} justifyContent={"center"}>
          <PointerWrapper>
            <img src={require("../../../assets/icons/view.png")} alt="view" />
          </PointerWrapper>
        </FlexChild>
      </FlexContainer>
    </Container>
  );

  return (
    // first section
    <Container
      top="0px"
      right="0px"
      bottom="0px"
      left="0px"
      bg="#F5F5F5"
      ptop="43px"
      pleft="40px"
      pright="40px"
      pbottom="40px"
      m_padding={"29px 0 0 0"}
    >
      <Container bottom={"24px"} top={"0px"} right={"0px"} bg="inherit">
        <Heading>Overview - Quiz Metrics</Heading>
      </Container>
      <div className="row">
        <div className="col-sm-12 col-md-3 col-lg-3">
          <QuizMetricsInfo
            number={50}
            Icon={<UserIcon color="primary" />}
            name={"Number Of Assignees"}
          />
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <QuizMetricsInfo
            number={30}
            Icon={<TickIcon style={{ color: "green" }} />}
            name={"Attempted User Count"}
          />
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <QuizMetricsInfo
            number={20}
            Icon={<ClearIcon style={{ color: "red" }} />}
            name={"Unattempted User Count"}
          />
        </div>

        <div className="col-sm-12 col-md-3 col-lg-3">
          <QuizMetricsInfo
            number={45}
            Icon={<QueryBuilderIcon style={{ color: "#ffdb58" }} />}
            name={"Time Taken To Finish Test"}
            extra="MIN"
          />
        </div>
      </div>

      {/* second section */}
      <Paper>
        <Container
          top="40px"
          right="0px"
          bottom="0px"
          left="0px"
          ptop="43px"
          pbottom="46px"
          pleft="40px"
          // pright="40px"
          m_padding="0px 0px 0px 0px"
          m_width="100%"
        >
          <Container
            bottom={"32px"}
            top={"0px"}
            right={"0px"}
            bg="inherit"
            m_margin="32px 0px 32px 0"
          >
            <Heading>Overview-User Metrics</Heading>
          </Container>

          <div className="row">
            <div className="col-sm-12 col-md-12">
              <Container
                bottom={"50px"}
                top={"0px"}
                right={"0px"}
                bg="inherit"
                pright={"32px"}
                m_padding={0}
                m_margin="0px"
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <OverviewCard
                      number={100}
                      src={require("../../../assets/images/articles_viewed.png")}
                      name={"Number Of Artices Viewed"}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <OverviewCard
                      number={40}
                      src={require("../../../assets/images/quizz_attempt.png")}
                      name={"Number Of Quizzess Attempeted"}
                    />
                  </div>
                </div>
              </Container>
              <Container
                bottom={"50px"}
                top={"0px"}
                right={"0px"}
                bg="inherit"
                pright={"32px"}
                m_padding={0}
                m_margin="0px"
              >
              <div className="row">
             
                <div className="col-sm-12 col-md-6">
                  <OverviewCard
                    number={30}
                    src={require("../../../assets/images/pending_quiz.png")}
                    name={"Number Of Pending Quizzess"}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <OverviewCard
                    number={10}
                    src={require("../../../assets/images/unattempt_quiz.png")}
                    name={"Number Of Unattempeted Quizzess "}
                  />
                  </div>
                 
                </div>
                </Container>
            </div>
            {/* <div className="col-sm-12 col-md-3">
              <FlexContainer m_alignItems="center">
              <SkillWrapper>
                <div style={{ textAlign: "center" }}>
                  <img
                    src={require("../../../assets/images/badge.PNG")}
                    alt="badge"
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <span>
                    Roll and skill level of the user <br />
                    <b>Supervisor / Proficient</b>
                  </span>
                </div>
              </SkillWrapper>
              </FlexContainer>
            </div> */}
          </div>
        </Container>
      </Paper>

      {/* third section */}
      <Container
        top={"43px"}
        right={"0px"}
        bottom={"0px"}
        left={"0px"}
        bg={"inherit"}
      >
        <Container bottom={"24px"} top={"0px"} right={"0px"} bg="inherit">
          <Heading>Information Metrics</Heading>
        </Container>

        <div className="row" style={{ display : "flex"}}>
          <div className="col-sm-12 col-md-6" style={{ minHeight: "100%",flex : "0 0 100%" }}>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <QuizMetricsInfo
                  number={50}
                  Icon={
                    <img
                      src={require("../../../assets/icons/eye.png")}
                      alt="eye"
                    />
                  }
                  name={"Number of Views (Articles) "}
                  Component={
                    <PointerWrapper>
                      <img
                        src={require("../../../assets/icons/view.png")}
                        alt="view"
                      />
                    </PointerWrapper>
                  }
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <QuizMetricsInfo
                  number={30}
                  Icon={
                    <img
                      src={require("../../../assets/icons/clock.png")}
                      alt="clock"
                    />
                  }
                  name={"Avg. Time spent on Article"}
                  extra={"MIN"}
                />
              </div>
            </div>

            <Paper>
              <Container
                top={"36px"}
                m_padding={0}
                right={"0px"}
                bottom={"0px"}
                left={"0px"}
                pleft={"22px"}
                pright={"22px"}
                ptop={"22px"}
                pbottom={"50px"}
                m_padding="20px"
              >
                <FlexContainer>
                  <FlexChild flex={10}>
                    <NameWrapper maxWidth={"100%"}>
                    Top 5 Articles (Highest Viewed)
                    </NameWrapper>
                  </FlexChild>
                  <FlexChild flex={1} justifyContent={"flex-end"}>
                    <img
                      src={require("../../../assets/icons/doc_blue.png")}
                      alt="document"
                    />
                  </FlexChild>
                </FlexContainer>
                <Articles
                  name={
                    "1.  Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate,"
                  }
                />
                <Articles
                  name={
                    "2.  Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate,"
                  }
                />
                <Articles
                  name={
                    "3.  Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate,"
                  }
                />
                <Articles
                  name={
                    "4.  Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate,"
                  }
                />
                <Articles
                  name={
                    "5.  Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate,"
                  }
                />
              </Container>
            </Paper>
          </div>
          <OverflowWrapper className="col-sm-12 col-md-6" minHeight="100%" flex="0 0 100%">
            <Container
              top={"0px"}
              right={"0px"}
              bottom={"0px"}
              left={"0px"}
              pleft="20px"
              className="MuiPaper-elevation2-59"
              minHeight="100%"
              m_margin="40px 0px 0px 0px"
              minWidth={"500px"}
            >
              <Container
                top={"0px"}
                right={"0px"}
                bottom={"20px"}
                left={"0px"}
                ptop="20px"
                pright="20px"
                pleft="20px"
              >
                <FlexContainer justifyContent="space-between">
                  <TItleWrapper>
                  Top 5 Users (Scored highest in Quiz)
                  </TItleWrapper>
                  <img
                    src={require("../../../assets/images/graph.png")}
                    alt="graph"
                  />
                </FlexContainer>
              </Container>

              <Chart />
            </Container>
          </OverflowWrapper>
        </div>
      </Container>
    </Container>
  );
}

CenteredGrid.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CenteredGrid);
