export const SET_SNACKBAR = "SET_SNACKBAR";
export const RESET_SNACKBAR = "RESET_SNACKBAR";

export const setSnackbar = payload => {
  console.log("payload", payload);
  return {
    type: SET_SNACKBAR,
    payload
  };
};

export const resetSnackbar = () => {
  return {
    type: RESET_SNACKBAR
  };
};

// export const setQuizDetails = payload => {
//   console.log("payload", payload);
//   return {
//     type: SET_QUIZ_DETAILS,
//     payload
//   };
// };
