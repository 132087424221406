import React, { Component } from 'react'

// custom packages
import Wrapper from '../subcomponents/Wrapper'
import Heading from '../../components/subComponents/Heading'
import Search from '../../components/subComponents/Search'
import Container from '../styledcomponents/Container'
import PointerWrapper from '../styledcomponents/PointerWrapper'
import FlexContainer from '../styledcomponents/FlexContainer'
import EpisodeName from '../styledcomponents/EpisodeName'
import Button from '../styledcomponents/Button'
import Table from './subcomponents/QuizTable'
import { post, baseUrl, customBaseUrl } from '../../config/apiUrl'
import api from '../../utils/api'
import { setTabAction } from '../../actions/tabAction'
import { setCourseDeatils } from '../../actions/courseAction'
import { setQuizDetails } from '../../actions/quizAction'

import { Redirect } from 'react-router-dom'
import DateFilter from '../../components/UserManagement/DateFilter'

// npm packages
import Paper from '@material-ui/core/Paper'
import { connect } from 'react-redux'

// images
import List from '../../assets/icons/list.svg'
import Block from '../../assets/icons/block.svg'
import arrow from '../../assets/icons/Symbol639.png'

class CourseList extends Component {
    state = {
        open: false,
        search: '',
        data: [],
        loading: false,
        filteredData: [],
        deleteId: [],
        selected: [],
        deleteLoading: true,
        filterStartDate: new Date(),
        filterEndDate: new Date(),
        path: null,
    }

    redirect = (path) => {
        this.setState({ path })
    }

    handleClickOpen = () => {
        this.setState({ open: true })
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    onDateChange = (date, name) => this.setState({ [name]: date })

    onClick = () => {
        const { filterStartDate, filterEndDate } = this.state
        let searchedProduct = this.state.data.filter(
            ({ timecreated, name }) => {
                if (
                    new Date(filterStartDate).getTime() / 1000 < timecreated &&
                    timecreated < new Date(filterEndDate).getTime() / 1000
                ) {
                    console.log(
                        'record',
                        name,
                        new Date(filterStartDate).getTime() / 1000,
                        timecreated,
                        new Date(filterEndDate).getTime() / 1000
                    )
                }

                return (
                    new Date(filterStartDate).getTime() / 1000 < timecreated &&
                    timecreated < new Date(filterEndDate).getTime() / 1000
                )
            }
        )

        console.log('product', searchedProduct)
        this.setState({
            filteredData: searchedProduct,
        })
        this.handleClose()
    }

    // on search
    search = (e) => {
        let keyword = e.target.value
        this.setState({
            search: keyword,
        })
        if (keyword.trim()) {
            let searchedProduct = this.state.data.filter(({ name }) =>
                name.toLowerCase().includes(keyword.toLowerCase())
            )
            console.log('filter', searchedProduct)
            this.setState({
                filteredData: searchedProduct,
            })
        } else {
            this.setState({
                filteredData: this.state.data,
            })
        }
    }

    getQuizes = (loading = true) => {
        const { courses } = this.props
        if (loading) {
            this.setState({ loading })
        }
        let url = `${baseUrl}wsfunction=mod_quiz_get_quizzes_by_courses&moodlewsrestformat=json&`
        courses.forEach((course, i) => {
            if (course.id !== 1) {
                url += `courseids[${i}]=${course.id}&`
            }
        })
        const trimmedUrl = url.slice(0, -1)
        console.log('url', trimmedUrl)
        api(trimmedUrl, post, null, this.getQuizesApiSuccess)
    }

    getQuizesApiSuccess = (res) => {
        this.setState({ loading: false })
        this.props.setQuizDetails(res.data.quizzes)
        this.setState({
            data: res.data.quizzes,
            filteredData: res.data.quizzes.reverse(),
        })
    }

    getCourses = () => {
        this.setState({ loading: true })
        const url = `${baseUrl}&wsfunction=core_course_get_courses&moodlewsrestformat=json`
        api(url, post, null, this.apiSuccess)
    }

    // success of api
    apiSuccess = (res) => {
        this.props.setCourseDeatils(res.data)
        this.getQuizes()
    }

    // delete quiz
    deleteQuiz = (cId, cmId, id) => {
        this.setState((prevState) => ({
            deleteId: [...prevState.deleteId, cmId],
        }))
        const url = `${customBaseUrl}wsfunction=local_servifywebapis_delete_quiz&moodlewsrestformat=json&courseid=${cId}&cmid=${cmId}`
        api(url, post, null, this.successDeleteApi)
    }

    successDeleteApi = (res) => {
        console.log(res)
        this.getQuizes(false)
    }

    getSelected = (selected) => {
        this.setState({ selected })
    }

    // delete selected courses
    deleteSelected = () => {
        if (this.state.selected.length > 0) {
            let url = `${baseUrl}wsfunction=core_course_delete_modules&moodlewsrestformat=json&`
            this.state.selected.forEach((id, i) => {
                url += `cmids[${i}]=${id}&`
                this.setState((prevState) => ({
                    deleteId: [...prevState.deleteId, id],
                    deleteLoading: true,
                }))
            })
            console.log(url.slice(0, -1))
            const trimmedUrl = url.slice(0, -1)
            api(trimmedUrl, post, null, this.successDeleteApi)
        }
    }

    componentWillMount() {
        this.props.setTabAction('Quiz / Test Management')
        if (this.props.courses.length === 0) {
            this.getCourses()
        } else {
            this.getQuizes()
        }
    }

    padding = (num) => ({
        ptop: `${num}px`,
        pright: `${num}px`,
        pbottom: `${num}px`,
        pleft: `${num}px`,
    })
    render() {
        const {
            data,
            loading,
            filteredData,
            deleteId,
            filterStartDate,
            filterEndDate,
        } = this.state

        const styles = {
            wrapper: {
                right: '0',
                bottom: '0',
                left: '0',
            },
        }
        return (
            <Wrapper>
                {this.state.path && <Redirect to={this.state.path} />}
                <DateFilter
                    open={this.state.open}
                    handleClose={this.handleClose}
                    startDate={this.state.filterStartDate}
                    endDate={this.state.filterEndDate}
                    onDateChange={this.onDateChange}
                    onClick={this.onClick}
                />
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <FlexContainer m_justifyContent={'center'}>
                            <Container
                                left={'0px'}
                                bg={'inherit'}
                                m_margin="29px 0px 0px 8px"
                            >
                                <Heading text="My Quizzess" />
                            </Container>
                        </FlexContainer>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <Container m_margin="29px 0px 0px 0px">
                            <Search search={'Search Quizzes'} />
                        </Container>
                    </div>
                </div>

                <Container top="40px" {...styles.wrapper}>
                    <Paper>
                        <Container {...this.padding(25)} pleft={'8px'}>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <FlexContainer wrap={'no-wrap'}>
                                        <span>
                                            Continue from where you left off :
                                        </span>
                                        <EpisodeName left="10px">
                                            Install parts in the television
                                        </EpisodeName>
                                    </FlexContainer>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <FlexContainer
                                        justifyContent="flex-end"
                                        m_justifyContent="center"
                                        m_margin={'25px 0 0 0'}
                                    >
                                        <PointerWrapper>
                                            <FlexContainer
                                                alignItems="center"
                                                wrap={'no-wrap'}
                                            >
                                                <span
                                                    style={{
                                                        color: '#4c10c1',
                                                        marginRight: '8px',
                                                        fontSize: '12px',
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    Resume
                                                </span>
                                                <img src={arrow} />
                                            </FlexContainer>
                                        </PointerWrapper>
                                    </FlexContainer>
                                </div>
                            </div>
                        </Container>
                    </Paper>
                </Container>

                <Container
                    left={'0px'}
                    right={'0px'}
                    top="40px"
                    m_margin={'40px 0 0 0'}
                >
                    <Paper>
                        <Container
                            ptop="40px"
                            pright="40px"
                            pleft="40px"
                            m_padding="29px 0 0 0"
                        >
                            <div className="row">
                                <div className="col-sm-12 col-md-10 col-lg-10">
                                    <FlexContainer>
                                        <Container
                                            right="20px"
                                            m_margin={'0px'}
                                            m_width={'100%'}
                                        >
                                            <Button
                                                bg="white"
                                                color="#757575"
                                                width="180px"
                                                m_width={'100%'}
                                            >
                                                Sort By
                                                <img
                                                    src={require('../../assets/icons/down.png')}
                                                    alt="filter"
                                                    style={{
                                                        marginLeft: '30px',
                                                    }}
                                                />
                                            </Button>
                                        </Container>
                                        <Container
                                            m_margin={'14px 0 0 0'}
                                            m_width={'100%'}
                                        >
                                            <Button
                                                bg="white"
                                                color="#757575"
                                                width="180px"
                                                m_width={'100%'}
                                                onClick={this.handleClickOpen}
                                            >
                                                Filter By
                                                <img
                                                    src={require('../../assets/icons/Filter.png')}
                                                    alt="filter"
                                                    style={{
                                                        marginLeft: '30px',
                                                    }}
                                                />
                                            </Button>
                                        </Container>
                                    </FlexContainer>
                                </div>
                                <div className="col-sm-12 col-md-2 col-lg-2">
                                    <FlexContainer
                                        alignItems={'center'}
                                        justifyContent={'flex-end'}
                                        m_justifyContent={'center'}
                                    >
                                        <FlexContainer
                                            m_justifyContent="center"
                                            alignItems="center"
                                            m_margin="29px 0px 0px 0px"
                                        >
                                            <Container right="16px">
                                                <PointerWrapper>
                                                    <img
                                                        src={List}
                                                        alt="icon1"
                                                    />
                                                </PointerWrapper>
                                            </Container>
                                            <Container>
                                                <PointerWrapper
                                                    onClick={() =>
                                                        this.redirect(
                                                            '/consumer/name/id/quizzess'
                                                        )
                                                    }
                                                >
                                                    <img
                                                        src={Block}
                                                        alt="icon1"
                                                    />
                                                </PointerWrapper>
                                            </Container>
                                        </FlexContainer>
                                    </FlexContainer>
                                </div>
                            </div>
                        </Container>
                        <Container top="40px" left={'0px'} right={'0px'}>
                            <FlexContainer justifyContent="space-between">
                                <Table
                                    courseData={filteredData}
                                    deleteCourse={this.deleteQuiz}
                                    deleteId={deleteId}
                                    getSelected={this.getSelected}
                                    deleteLoading={this.state.deleteLoading}
                                />
                            </FlexContainer>
                        </Container>
                    </Paper>
                </Container>
            </Wrapper> 
        )
    }
}

const mapStateToProps = (state) => ({
    courses: state.course.courses,
    quizes: state.consumerQuizes.quizes,
})

export default connect(
    mapStateToProps,
    { setTabAction, setCourseDeatils, setQuizDetails }
)(CourseList)
