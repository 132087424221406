import React, { Component,Fragment } from 'react'

// custom packages
import Wrapper from '../subcomponents/Wrapper'
import Heading from '../../components/subComponents/Heading'
import Search from '../../components/subComponents/Search'
import Container from '../styledcomponents/Container'
import PointerWrapper from '../styledcomponents/PointerWrapper'
import FlexContainer from '../styledcomponents/FlexContainer'
import EpisodeName from '../styledcomponents/EpisodeName'
import Button from '../styledcomponents/Button'
import Card from './subcomponents/Card'
import { Redirect } from 'react-router-dom'
import DateFilter from '../../components/UserManagement/DateFilter'
import { connect } from 'react-redux'
import Loader from '../../components/subComponents/Loader'
import Table from './subcomponents/QuizTable'

// Actions
import { setConTabAction } from '../../actions/tabAction'
import { setConsumerQuizListApi } from '../../actions/consumer/quizAction'

// npm packages
import Paper from '@material-ui/core/Paper'

// images
import arrow from '../../assets/icons/Symbol639.png'
import ListActive from '../../assets/icons/list.svg'
import MenuActive from '../../assets/icons/icon2.png'
import List from '../../assets/icons/icon1.png'
import Menu from '../../assets/icons/block.svg'

class Home extends Component {
    state = {
        path: null,
        open: false,
        filterStartDate: new Date(),
        filterEndDate: new Date(),
        loading: false,
        view: 0,
        filteredData: [],
        search: '',
        isSearch: false,
    }

    loading = (loading) => {
        this.setState({ loading })
    }

    handleClickOpen = () => {
        this.setState({ open: true })
    }

    handleClose = () => {
        this.setState({ open: false })
    }
    onDateChange = (date, name) => this.setState({ [name]: date })

    onClick = () => {
        const { filterStartDate, filterEndDate } = this.state
        let searchedProduct = this.state.data.filter(
            ({ timecreated, name }) => {
                if (
                    new Date(filterStartDate).getTime() / 1000 < timecreated &&
                    timecreated < new Date(filterEndDate).getTime() / 1000
                ) {
                    console.log(
                        'record',
                        name,
                        new Date(filterStartDate).getTime() / 1000,
                        timecreated,
                        new Date(filterEndDate).getTime() / 1000
                    )
                }

                return (
                    new Date(filterStartDate).getTime() / 1000 < timecreated &&
                    timecreated < new Date(filterEndDate).getTime() / 1000
                )
            }
        )

        console.log('product', searchedProduct)
        this.setState({
            filteredData: searchedProduct,
        })
        this.handleClose()
    }

    search = (e) => {
        let keyword = e.target.value
        this.setState({
            search: keyword,
            isSearch: true,
        })
        if (keyword.trim()) {
            let searchedProduct = this.props.quizzes.filter(({ name }) =>
                name.toLowerCase().includes(keyword.toLowerCase())
            )
            this.setState({
                filteredData: searchedProduct,
            })
        } else {
            this.setState({
                filteredData: this.props.quizzes,
            })
        }
    }

    padding = (num) => ({
        ptop: `${num}px`,
        pright: `${num}px`,
        pbottom: `${num}px`,
        pleft: `${num}px`,
    })

    redirect = (path) => {
        this.setState({ path })
    }

    componentDidMount() {
        this.props.setConTabAction('My Quizzes')
        const { courses } = this.props
        this.props.setConsumerQuizListApi(
            this.loading,
            null,
            courses,
            30,
            this.props.match.params.courseId
        )
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.quizzes.length !== prevState.filteredData.length &&
            !prevState.isSearch
        ) {
            return { filteredData: nextProps.quizzes } // <- this is setState equivalent
        }
    }

    render() {
        const styles = {
            wrapper: {
                right: '0',
                bottom: '0',
                left: '0',
            },
        }

        const { filteredData } = this.state

        return (
            <Wrapper>
                {this.state.path && <Redirect to={this.state.path} />}
                <DateFilter
                    open={this.state.open}
                    handleClose={this.handleClose}
                    startDate={this.state.filterStartDate}
                    endDate={this.state.filterEndDate}
                    onDateChange={this.onDateChange}
                    onClick={this.onClick}
                />
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <FlexContainer m_justifyContent={'center'}>
                            <Container
                                left={'0px'}
                                bg={'inherit'}
                                m_margin="29px 0px 0px 8px"
                            >
                                <Heading text="My Quizzes" />
                            </Container>
                        </FlexContainer>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <Container m_margin="29px 0px 0px 0px">
                            <Search
                                search={'Search Quizzes'}
                                onChange={this.search}
                            />
                        </Container>
                    </div>
                </div>

                <Container top="40px" {...styles.wrapper}>
                    <Paper>
                        <Container {...this.padding(25)} pleft={'8px'}>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <FlexContainer wrap={'no-wrap'}>
                                        <span>
                                            Continue from where you left off :
                                        </span>
                                        <EpisodeName left="10px">
                                            Install parts in the television
                                        </EpisodeName>
                                    </FlexContainer>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <FlexContainer
                                        justifyContent="flex-end"
                                        m_justifyContent="center"
                                        m_margin={'25px 0 0 0'}
                                    >
                                        <PointerWrapper>
                                            <FlexContainer
                                                alignItems="center"
                                                wrap={'no-wrap'}
                                            >
                                                <span
                                                    style={{
                                                        color: '#4c10c1',
                                                        marginRight: '8px',
                                                        fontSize: '12px',
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Resume
                                                </span>
                                                <img src={arrow} />
                                            </FlexContainer>
                                        </PointerWrapper>
                                    </FlexContainer>
                                </div>
                            </div>
                        </Container>
                    </Paper>
                </Container>

                <Container
                    left={'0px'}
                    right={'0px'}
                    top="40px"
                    m_margin={'40px 0 0 0'}
                >
                    <Paper>
                        <Container
                            ptop="40px"
                            // pright="40px"
                            pbottom="40px"
                            pleft="40px"
                            pright="38px"
                            m_padding="29px 0 29px 0"
                        >
                            <div className="row">
                                <div className="col-sm-12 col-md-10 col-lg-10">
                                    <FlexContainer>
                                        <Container
                                            right="20px"
                                            m_margin={'0px'}
                                            m_width={'100%'}
                                        >
                                            <Button
                                                bg="white"
                                                color="#757575"
                                                width="180px"
                                                m_width={'100%'}
                                            >
                                                Sort By
                                                <img
                                                    src={require('../../assets/icons/down.png')}
                                                    alt="filter"
                                                    style={{
                                                        marginLeft: '30px',
                                                    }}
                                                />
                                            </Button>
                                        </Container>
                                        <Container
                                            m_margin={'14px 0 0 0'}
                                            m_width={'100%'}
                                        >
                                            <Button
                                                bg="white"
                                                color="#757575"
                                                width="180px"
                                                m_width={'100%'}
                                                onClick={this.handleClickOpen}
                                            >
                                                Filter By
                                                <img
                                                    src={require('../../assets/icons/Filter.png')}
                                                    alt="filter"
                                                    style={{
                                                        marginLeft: '30px',
                                                    }}
                                                />
                                            </Button>
                                        </Container>
                                    </FlexContainer>
                                </div>
                                <div className="col-sm-12 col-md-2 col-lg-2">
                                    <FlexContainer
                                        alignItems={'center'}
                                        justifyContent={'flex-end'}
                                        m_justifyContent={'center'}
                                    >
                                        <FlexContainer
                                            justifyContent="flex-end"
                                            m_justifyContent="center"
                                            m_margin="29px 0px 0px 0px"
                                        >
                                            <Container right="16px">
                                                <PointerWrapper
                                                    onClick={() =>
                                                        this.setState({
                                                            view: 1,
                                                        })
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            this.state.view ===
                                                            1
                                                                ? ListActive
                                                                : List
                                                        }
                                                        alt="icon1"
                                                    />
                                                </PointerWrapper>
                                            </Container>
                                            <Container>
                                                <PointerWrapper
                                                    onClick={() =>
                                                        this.setState({
                                                            view: 0,
                                                        })
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            this.state.view ===
                                                            0
                                                                ? MenuActive
                                                                : Menu
                                                        }
                                                        alt="icon1"
                                                    />
                                                </PointerWrapper>
                                            </Container>
                                        </FlexContainer>
                                    </FlexContainer>
                                </div>
                            </div>
                           
                            {this.state.loading ? (
                                <Loader />
                            ) : (
                                this.state.view === 0 && (
                                    <Container
                                        top="40px"
                                        m_margin={'25px 0 0 0'}
                                    >
                                        <FlexContainer>
                                            {filteredData.map(
                                                (q, i) => (
                                                    <Card quiz={q} key={i} />
                                                )
                                            )}
                                        </FlexContainer>
                                    </Container>
                                )
                                    )}
                                

                            {/* <Container top="50px">
                                <FlexContainer justifyContent="center">
                                    <PointerWrapper>
                                        <FlexContainer
                                            justifyContent="center"
                                            alignItems="center"
                                            direction="column"
                                        >
                                            <span
                                                style={{
                                                    color: '#4c10c1',
                                                    marginRight: '8px',
                                                    fontSize: '12px',
                                                    lineHeight: '2.08',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                View More
                                            </span>
                                            <img
                                                src={require('../../assets/icons/down_blue.png')}
                                                alt="document"
                                            />
                                        </FlexContainer>
                                    </PointerWrapper>
                                </FlexContainer>
                            </Container> */}
                        </Container>
                        {this.state.view === 1 && (
                            <Container top="40px" left={'0px'} right={'0px'}>
                                <FlexContainer justifyContent="space-between">
                                    <Table
                                        courseData={this.state.filteredData}
                                    />
                                </FlexContainer>
                            </Container>
                        )}
                    </Paper>
                </Container>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    courses: state.consumerCourses.courses,
    quizzes: state.consumerQuizes.quizes,
})

export default connect(
    mapStateToProps,
    { setConTabAction, setConsumerQuizListApi }
)(Home)
