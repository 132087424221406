// import { Bar } from "react-chartjs-2";
// import Paper from "@material-ui/core/Paper";
// const chartData = {
//   labels: [
//     "Vyom Beginner",
//     "Amit Beginner",
//     "David Expert",
//     "Ayan Expert",
//     "Asish Beginner",
//     "Achal Beginner"
//   ],
//   datasets: [
//     {
//       label: "Quizz results",
//       data: [617594, 181045, 153060, 106519, 105162, 95072],
//       backgroundColor: [
//         "rgba(255, 99, 132, 0.6)",
//         "rgba(54, 162, 235, 0.6)",
//         "rgba(255, 206, 86, 0.6)",
//         "rgba(75, 192, 192, 0.6)",
//         "rgba(153, 102, 255, 0.6)",
//         "rgba(255, 159, 64, 0.6)",
//         "rgba(255, 99, 132, 0.6)"
//       ]
//     }
//   ]
// };
// class Chart extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       chartData
//     };
//   }

//   static defaultProps = {
//     displayTitle: true,
//     displayLegend: true,
//     legendPosition: "right",
//     location: "City"
//   };

//   render() {
//     return (
//       <Paper className="chart" style={{ height : "100%"}}>
//         <Bar
//           data={this.state.chartData}
//           options={{
//             maintainAspectRatio : false
//           }}
//         />

//       </Paper>
//     );
//   }
// }

// export default Chart;

// import React, { Component } from "react";
// import CanvasJSReact from "../../../plugins/canvasjs.react"
// var CanvasJS = CanvasJSReact.CanvasJS;
// var CanvasJSChart = CanvasJSReact.CanvasJSChart;

// class App extends Component {
//   render() {
//     const options = {

//       dataPointWidth: 40,
//       data: [{
//         type: "column",

//                 dataPoints: [
//                     { label: "Vyom Beginner",  y: 20 , color : "#9f77eb"  },
//                     { label: "Amit Expert", y: 30 , color : "#9f77eb"  },
//                     { label: "Ketki Expert", y: 50 , color : "#9f77eb"  },
//                     { label: "Mahesh Proficient",  y: 80 , color : "#9f77eb"  },
//                     { label: "Asish Beginner",  y: 90 , color : "#9f77eb"  }
//                 ]
//        }]
//    }

//    return (
//       <div>
//         <CanvasJSChart options = {options}
//             /* onRef = {ref => this.chart = ref} */

//         />
//       </div>
//     );
//   }
// }

import React from "react";
import Chart from "react-google-charts";

var options = {
  bar: { groupWidth: "30%" },
  chartArea: { left: "5%", top: "10%", width: "100%", height: "80%" },
  legend: { position: "none" }
};

const makeCurve = () => {
  const chartColumns = document.getElementsByTagName("rect");
  chartColumns.forEach(column => {
    if (column.getAttribute("fill") === "#9f77eb") {
      console.log("bars", column);
      column.setAttribute("rx", 5);
    }
  });
};



const chartEvents = [
  {
    eventName: "ready",
    callback({ chartWrapper, google }) {
      console.log("ready");
      const chart = chartWrapper.getChart();
      google.visualization.events.addListener(chart, "onmouseover", e => {
        makeCurve();
      });
      google.visualization.events.addListener(chart, "onmouseout", e => {
        makeCurve();
      });
      makeCurve();
      // addCurve();
    }
  },
  {
    eventName: "onmouseover",
    callback() {
      console.log("mouse over");
      // addCurve();
    }
  },
  {
    eventName: "onmouseout",
    callback() {
      console.log("mouse out");
      // addCurve();
    }
  },
  {
    eventName: "select",
    callback({ chartWrapper }) {
      console.log("object");
      const chartColumns = document.getElementsByTagName("rect");
      chartColumns.forEach(column => {
        if (column.getAttribute("fill") === "#9f77eb") {
          console.log("bars", column);
          column.setAttribute("rx", 5);
        }
      });
    }
  }
];

const data = [
  ["Element", "Density", { role: "style" }],
  ["Vyom\n Beginner", 20, "#9f77eb"], // RGB value
  ["Amit\n Expert", 30, "#9f77eb"], // English color name
  ["Ketki\n Expert", 50, "#9f77eb"],
  ["Mahesh\n Proficient", 70, "#9f77eb"], // CSS-style declaration
  ["Asish\n Beginner", 90, "#9f77eb"] // CSS-style declaration
];
class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Chart
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={data}
          options={options}
          chartEvents={chartEvents}
        />
      </div>
    );
  }
}

export default App;
