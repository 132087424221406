import styled from "styled-components";
import Screen from "./ScreenSizes";


export default styled.div`
  width: 102px;
  height: 102px;
  position: relative;
  border-radius: 50%;
  background-color: #4c10c1;
  @media ${Screen.tablet} {
    width: 50px;
    height: 50px;
  }
`;