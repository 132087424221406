import { SET_CON_TAB, SET_CON_SUBMENU } from "../../actions/tabAction";
const initialState = {
  sideMenuTab: "Dashboard",
  subMenu: ""
};

const ConsumerTabReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CON_TAB:
      return {
        ...state,
        sideMenuTab: action.payload
      };
    case SET_CON_SUBMENU:
      return {
        ...state,
        subMenu: action.payload
      };
    default:
      return state;
  }
};

export default ConsumerTabReducer;
