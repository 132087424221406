import styled from "styled-components";
import Screen from "./ScreenSizes";



export default styled.div`
  padding-top: 28px; 
  padding-left: 40px;
  padding-right: 40px;
  background: #f5f5f5;
  height : 100%;


  @media ${Screen.tablet} {
    padding: 0
  }
 
`;