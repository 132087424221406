import {
    SET_USER_DETAILS,
    SET_GROUP_DETAILS,
    SET_USER_ID,
} from '../../actions/userManagementAction'

const initialState = {
    users: [],
    groups: [],
    userids: [],
}

const quizReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DETAILS:
            return {
                ...state,
                users: action.payload,
            }
        case SET_GROUP_DETAILS:
            return {
                ...state,
                groups: action.payload,
            }
        case SET_USER_ID:
            return {
                ...state,
                userids: action.payload,
            }
        default:
            return state
    }
}

export default quizReducer
