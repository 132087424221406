import React, { Component } from "react";
import Header from "../subComponents/Header";
import Body from "../Home/subComponents/Body";
import Heading from "../subComponents/Heading";
import api from "../../utils/api";
import { post, baseUrl, customBaseUrl, get } from "../../config/apiUrl";
import Modal from "../subComponents/Modal";
import ActivityModal from "./subComponents/ActivityModal";
import Loader from "../subComponents/Loader";
import MobileHeader from "../Home/subComponents/MobileHeader";
import lineHeight from "../../utils/lineHeight";
import { connect } from "react-redux";


// Actions
import { setTabAction } from "../../actions/tabAction";


// npm packges
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import GearIcon from "@material-ui/icons/Settings";
import CreateIcon from "@material-ui/icons/Create";
import BorderColor from "@material-ui/icons/BorderColor";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import Fab from "@material-ui/core/Fab";
import { Redirect } from "react-router-dom";
import DescriptionIcon from "@material-ui/icons/Description";
import MediaQuery from "react-responsive";

// styled components
import Container from "../../consumercomponents/styledcomponents/Container";
import CT from "../../consumercomponents/styledcomponents/CT";
import FlexContainer from "../../consumercomponents/styledcomponents/FlexContainer";
import PointerWrapper from "../../consumercomponents/styledcomponents/PointerWrapper";

import back from "../../assets/icons/back.png";

const Styles = theme => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "16px"
  },
  topicContainber: {
    // width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "16px",
    borderBottom: "2px solid #CCCCCC",
    backgroundColor: "#ffffff",
    marginTop: "16px",
    padding: "16px"
  },
  topicHeading: {
    fontSize: "1.5rem",
    marginRight: "16px"
  },
  smallIcon: {
    fontSize: "0.9rem",
    color: "#34495e",
    fontWeight: "bold "
  },
  marginRight: {
    marginRight: "16px"
  },
  menuContainer2: {
    position: "absolute",
    backgroundColor: "white",
    padding: "16px",
    minWidth: "10rem",
    right: "0px",
    cursor: "pointer",
    zIndex: 1
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  }
});

class TopicManagement extends Component {
  state = {
    anchorEl: null,
    edit: false,
    showContainer: false,
    editTopic: false,
    topics: [],
    modal: false,
    topicName: "",
    activityModal: false,
    getTopicsLoading: false,
    editTopicLoading: false,
    addTopicLoading: false,
    editTopicId: "",
    addPageTopicId: "",
    path: null,
    courseName: "",
    courseDescription: "",
    deleteModuleId: "",
    increase: 0
  };

  handleGoback = () => {
    this.setState({ path: "/articleManagement" });
  };

  handleClick = event => {
    this.setState(prevState => ({ showContainer: !prevState.showContainer }));
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  openModal = () => {
    this.setState({ modal: true });
  };
  closeModal = () => {
    this.setState({ modal: false });
  };

  openActivityModal = id => {
    this.setState({ activityModal: true, addPageTopicId: id });
  };
  closeActivityModal = () => {
    this.setState({ activityModal: false });
  };

  // turn edit on
  toggleEdit = () => {
    this.setState(prevState => ({
      edit: !prevState.edit,
      showContainer: !prevState.showContainer,
      editTopicId: ""
    }));
  };

  // toggle edit topic
  toggleEditTopic = id => {
    if (this.state.editTopicId === id) {
      this.setState(prevState => ({
        editTopicId: ""
      }));
    } else {
      this.setState(prevState => ({
        editTopicId: id
      }));
    }
  };

  getCourseDetails = () => {
    const id = this.props.match.params.id;
    const url = `${baseUrl}wsfunction=core_course_get_courses&moodlewsrestformat=json&options[ids][0]=${id}`;
    api(url, post, null, res => {
      console.log("summary",res.data[0].summary)
      document.getElementById("content").innerHTML = res.data[0].summary;
      this.setState({
        courseName: res.data[0].fullname,
        courseDescription: res.data[0].summary
      });
    });
  };

  successTopic = res => {
    this.setState({
      topics: res.data,
      getTopicsLoading: false
    });
  };

  getTopics = () => {
    this.setState({
      getTopicsLoading: true
    });
    const id = this.props.match.params.id;
    const url = `${baseUrl}wsfunction=core_course_get_contents&moodlewsrestformat=json&courseid=${id}`;
    api(url, post, null, this.successTopic);
  };

  // add Topics
  addTopic = () => {
    this.setState({ addTopicLoading: true });
    const id = this.props.match.params.id;
    const url = `${customBaseUrl}wsfunction=local_servifywebapis_create_topics&moodlewsrestformat=json&courseid=${id}`;
    api(url, get, null, res => {
      const url = `${baseUrl}wsfunction=core_course_get_contents&moodlewsrestformat=json&courseid=${id}`;
      api(url, post, null, res => {
        this.setState({
          topics: res.data,
          editTopicId: "",
          addTopicLoading: false
        });
      });
    });
  };

  onChangeTopic = topicName => {
    this.setState({ topicName });
  };

  editTopicName = (e, id) => {
    if (e.key === "Enter") {
      this.setState({
        editTopicLoading: true
      });
      const url = `${baseUrl}wstoken=81a7d84b3cbd5c8e10653a06a7362038&wsfunction=core_update_inplace_editable&moodlewsrestformat=json&itemid=${id}&component=format_topics&itemtype=sectionname&value=${
        e.target.value
      }`;
      api(url, post, null, res => {
        const id = this.props.match.params.id;
        const url = `${baseUrl}wsfunction=core_course_get_contents&moodlewsrestformat=json&courseid=${id}`;
        api(url, post, null, res => {
          this.setState({
            topics: res.data,
            editTopicId: "",
            editTopicLoading: false
          });
        });
      });
    }
  };

  deleteTopic = id => {
    const url = `${baseUrl}wstoken=81a7d84b3cbd5c8e10653a06a7362038&wsfunction=core_course_edit_section&action=delete&id=${id}`;
    api(url, post, null, res => {
      // console.log(res.data)
      this.getTopics();
    });
  };

  deleteModule = id => {
    const url = `${baseUrl}wsfunction=core_course_delete_modules &cmids[0]=${id}`;
    this.setState({ deleteModuleId: id });
    api(url, post, null, res => {
      api(url, post, null, res => {
        const id = this.props.match.params.id;
        const url = `${baseUrl}wsfunction=core_course_get_contents&moodlewsrestformat=json&courseid=${id}`;
        api(url, post, null, res => {
          this.setState({
            topics: res.data,
            editTopicId: "",
            editTopicLoading: false
          });
        });
      });
    });
  };

  componentWillMount() {
    this.props.setTabAction("Course Management");
    this.getCourseDetails();
    this.getTopics();
  }

  render() {
    const { classes } = this.props;
    const {
      anchorEl,
      modal,
      topicName,
      activityModal,
      deleteModuleId
    } = this.state;
    console.log(this.state);
    const topics = this.state.topics.map((topic, i) => {
      const pages = topic.modules.map((page, index) => {
        return (
          // page title
          <div style={{ marginTop: "16px", display: "flex" }} key={index}>
            <DescriptionIcon style={{ marginRight: "0.5rem" }} />
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.setState({
                  path: `/articleManagement/viewPage/${
                    this.props.match.params.id
                  }/${page.id}`
                })
              }
            >
              {page.name}
            </div>

            {this.state.edit && (
              <div
                style={{ marginLeft: "16px", cursor: "pointer" }}
                onClick={() =>
                  this.setState({
                    path: `/articleManagement/topicManagement/editPage/${
                      this.props.match.params.id
                    }/${topic.section}/${page.id}`
                  })
                }
              >
                <BorderColor fontSize="small" />
              </div>
            )}

            {this.state.edit && (
              <div
                style={{ marginLeft: "16px", cursor: "pointer" }}
                onClick={() => this.deleteModule(page.id)}
              >
                {deleteModuleId === page.id ? (
                  <span>Deleting ...</span>
                ) : (
                  <DeleteIcon fontSize="small" />
                )}
              </div>
            )}
          </div>
        );
      });

      return (
        <FlexContainer
          justifyContent={"space-between"}
          m_direction={"column"}
          key={i}
          margin={"1.5rem 0 0 0"}
        >
          <CT m_width={"100%"} left={"12px"}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className={classes.topicHeading}>{topic.name}</span>
              {this.state.edit && (
                <span
                  onClick={() => this.toggleEditTopic(topic.id)}
                  style={{ cursor: "pointer" }}
                >
                  <CreateIcon />
                </span>
              )}
            </div>
            {this.state.editTopicId === topic.id &&
              (this.state.editTopicLoading ? (
                <p>Changing topic name...</p>
              ) : (
                <TextField
                  autoFocus
                  id="standard-bare"
                  className={classes.textField}
                  defaultValue={topic.name}
                  onKeyPress={e => {
                    this.editTopicName(e, topic.id);
                  }}
                  margin="normal"
                />
              ))}
            {pages}
          </CT>
          {this.state.edit && (
            <CT m_margin={"1rem 0 0 0"}>
              <div>
                <div>
                  <div>
                    <IconButton
                      style={{ borderRadius: 0 }}
                      onClick={() => this.openActivityModal(topic.section)}
                    >
                      <AddIcon
                        className={[
                          classes.smallIcon,
                          classes.marginRight
                        ].join(" ")}
                      />
                      <span className={classes.smallIcon}>
                        Add an activity or resource
                      </span>
                    </IconButton>

                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={this.handleClose}
                    >
                      <MenuItem onClick={this.handleClose}>
                        Turn Editing on
                      </MenuItem>
                    </Menu>
                  </div>
                  <div>
                    <IconButton
                      style={{ borderRadius: 0 }}
                      onClick={() => this.deleteTopic(topic.id)}
                    >
                      <DeleteForeverIcon
                        className={[
                          classes.smallIcon,
                          classes.marginRight
                        ].join(" ")}
                      />
                      <span className={classes.smallIcon}>Delete</span>
                    </IconButton>
                  </div>
                </div>
              </div>
            </CT>
          )}
        </FlexContainer>
      );
    });
    return (
      <div>
        {this.state.path && <Redirect to={this.state.path} />}
        <Modal
          open={modal}
          handleClose={this.closeModal}
          button={["cancel", "save"]}
          action={this.closeModal}
          title="Add topic"
          inputLabel="Topic name"
          onChange={this.onChangeTopic}
          inputValue={topicName}
        />
        <ActivityModal
          open={activityModal}
          handleClose={this.closeActivityModal}
          title={"Add Activity"}
          id={this.props.match.params.id}
          topicId={this.state.addPageTopicId}
        />
        <Header />
        <Body>
          {/* Heading section */}
          <Container
            top={"40px"}
            right={"16px"}
            bottom={"0px"}
            left={"0px"}
            pleft={"1rem"}
            bg={"inherit"}
            m_padding={"10px"}
          >
            <FlexContainer m_justifyContent={"center"}>
              <div
                style={{ color: "blue", cursor: "pointer" }}
                onClick={this.handleGoback}
              >
                <PointerWrapper>
                  <img src={back} alt="back" />
                </PointerWrapper>
              </div>
            </FlexContainer>

            <FlexContainer
              m_direction={"column"}
              justifyContent={"space-between"}
            >
              <CT m_width={"100%"}>
                <FlexContainer m_justifyContent={"center"} m_width={"100%"} m_direction={"column"} direction={"column"}>
                  <div>
                    <Heading text={this.state.courseName} />
                  </div>
                  <div id="content" >
                    {}
                  </div>
                </FlexContainer>
              </CT>
              <CT m_width={"100%"}>
                <FlexContainer m_justifyContent={"center"} m_width={"100%"}>
                  <div
                    className={classes.menuContainer}
                    style={{ position: "relative" }}
                  >
                    <IconButton onClick={this.handleClick}>
                      <GearIcon />
                    </IconButton>

                    {this.state.showContainer && (
                      <Paper
                        className={classes.menuContainer2}
                        onClick={this.toggleEdit}
                      >
                        {" "}
                        {`Turn Editing ${this.state.edit ? "off" : "on"}`}
                      </Paper>
                    )}
                  </div>
                </FlexContainer>
              </CT>
            </FlexContainer>

            {/* Body Section */}
            <div>
              {this.state.getTopicsLoading ? <Loader /> : topics}
              {this.state.edit && (
                <FlexContainer
                  justifyContent={"flex-end"}
                  m_justifyContent={"center"}
                  margin={"1rem 0 0 0"}
                >
                  {this.state.addTopicLoading ? (
                    <Loader />
                  ) : (
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      aria-label="Add"
                      className={classes.margin}
                      onClick={this.addTopic}
                    >
                      <AddIcon className={classes.extendedIcon} />
                      Add topics
                    </Fab>
                  )}
                  {/* <IconButton onClick={this.handleClick}>
                  <AddIcon
                    className={[classes.smallIcon, classes.marginRight].join(
                      " "
                    )}
                  />
                  <span className={classes.smallIcon}>Add topics</span>
                </IconButton> */}
                </FlexContainer>
              )}
            </div>
          </Container>
        </Body>
      </div>
    );
  }
}


export default connect(
  null,
  {setTabAction}
)(withStyles(Styles)(TopicManagement));
