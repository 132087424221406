import styled from "styled-components";


export default styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  border: solid 2px #ffffff;
  background-color: #4bd800;
  bottom: 0;
  right: 10%;
`;