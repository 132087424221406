import React, { Component } from "react";

// custom components
import TabDetails from "./TabDetails"
import EditTabDetails from "./EditTabDetails"

// npm packages
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { withStyles } from "@material-ui/core/styles";


const CustomTab = ({ children, isStyle }) => (
  <b style={{ padding: "16px 16px" }}>{children}</b>
);

const Styles = theme => ({
  inActive: {
    background: "#EEEEEE"
    // borderRight : "1px solid white"
  },
  updateContainer: {
    padding: "16px"
  }
});

class TabComponent extends Component {
  constructor() {
    super();
    this.state = { tabIndex: 0 };
  }
  render() {
    console.log("state", this.state);
    const { classes } = this.props;
    return (
      <Tabs
        selectedIndex={this.state.tabIndex}
        onSelect={tabIndex => this.setState({ tabIndex })}
      >
        <TabList>
          <Tab
            style={{
              background: this.state.tabIndex === 0 ? "#ffffff" : "#EEEEEE",
              color: this.state.tabIndex === 0 && "#000000"
            }}
          >
            <CustomTab>{this.props.type} Details</CustomTab>
          </Tab>
          
          
        </TabList> 
        <TabPanel>
          {this.props.type === 'edit' ?<EditTabDetails id={this.props.id} type={this.props.type} onDonePress={this.props.onDonePress} /> : <TabDetails type={this.props.type} onDonePress={this.props.onDonePress} />}
        </TabPanel>
        <TabPanel>
            {/* <FindmoreTab /> */}
        </TabPanel>
      </Tabs>
    );
  }
}

export default withStyles(Styles)(TabComponent);
