import React from "react";
import styled from "styled-components";

// custom components
import Chart from "./Chart";

// styled components
import DIV from "../../styledcomponents/DIV";
import FC from "../../styledcomponents/FlexContainer";

// images
import GreenDOT from "../../../assets/icons/green_dot.svg";
import SmallClock from "../../../assets/icons/small_clock.svg";


// css
import "../css/dashboard.css";


const GREENHEADING = styled.div`
  height: 17px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  text-align: left;
  color: #4bd800;
`;

const SMALLTEXT = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.color ? props.color :"#757575"};
`;


export default function Card({ header, details, total, value }) {
  console.log("details", details);

  return (
     <FC wrap={"no-wrap"} alignItems={"stretch"}>
     <FC direction={"column"} m_direction={"column"} alignItems={"center"} wrap={"no-wrap"}>
       
         <img
           src={GreenDOT}
           alt=""
           style={{ marginTop: "5px" }}
         />
       
       <div
         style={{
           height: "100%",
           borderLeft: "1px dotted green"
         }}
       />
     </FC>
     <DIV pleft={"13px"} pbottom={"20px"}>
       <GREENHEADING>Quiz Passed</GREENHEADING>
       <DIV mtop={"8px"}>
         <SMALLTEXT color={"#393939"}>
           <img
             src={SmallClock}
             alt=""
             style={{ marginRight: "5px" }}
           />
           16th April, 2019
         </SMALLTEXT>
         <DIV mtop={"5px"}>
         <SMALLTEXT>
           Vestibulum pellentesque fermentum dolor maximus porta.
         </SMALLTEXT>
            </DIV>
       </DIV>
     </DIV>
   </FC>
  );
}
