import React, { Component } from "react";
import { Redirect } from "react-router-dom";

// custom components
import Body from "../Home/subComponents/Body";
import Header from "../subComponents/Header";
import Heading from "../subComponents/Heading";
import Search from "../subComponents/Search";
import { setTabAction } from "../../actions/tabAction";
import Table from "./SubComponents/Table";
import NewTable from "./tables/NewTable";
import DateFilter from "./DateFilter";
import api from "../../utils/api";
import lineHeight from "../../utils/lineHeight";
import { post, baseUrl, customBaseUrl } from "../../config/apiUrl";
import { setCourseDeatils } from "../../actions/courseAction";
import { setQuizDetails } from "../../actions/quizAction";
import Loader from "../subComponents/Loader";

// npm packages
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import FilterIcon from "@material-ui/icons/Sort";
import { Link } from "react-router-dom";
import is from "is_js";

import MediaQuery from "react-responsive";
import MobileHeader from "../Home/subComponents/MobileHeader";

// styled components
import Container from "../../consumercomponents/styledcomponents/Container";
import CT from "../../consumercomponents/styledcomponents/CT";
import FlexContainer from "../../consumercomponents/styledcomponents/FlexContainer";
import Label from "../../consumercomponents/styledcomponents/Label";
import CustomButton from "../../consumercomponents/styledcomponents/Button";

const styles = theme => ({
  alignRight: {
    textAlign: "right"
  },
  smallText: {
    textTransform: "none"
  },
  buttonIcon: {
    marginLeft: "1.5rem",
    color: "black"
  },
  Link: {
    color: "white",
    textDecoration: "none"
  }
});
class TopicManagement extends Component {
  state = {
    open: false,
    search: "",
    data: [],
    loading: false,
    filteredData: [],
    deleteId: [],
    selected: [],
    deleteLoading: true,
    filterStartDate: new Date(),
    filterEndDate: new Date(),
    showDelete: false,
    path: null
  };

  redirect = path => this.setState({ path });

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  toggleShowDelete = (checkState = false) => {
    console.log("sdeddeddeded", checkState);
    this.setState({ showDelete: checkState });
  };

  onDateChange = (date, name) => this.setState({ [name]: date });

  onClick = () => {
    const { filterStartDate, filterEndDate } = this.state;
    let searchedProduct = this.state.data.filter(({ timecreated, name }) => {
      if (
        new Date(filterStartDate).getTime() / 1000 < timecreated &&
        timecreated < new Date(filterEndDate).getTime() / 1000
      ) {
        console.log(
          "record",
          name,
          new Date(filterStartDate).getTime() / 1000,
          timecreated,
          new Date(filterEndDate).getTime() / 1000
        );
      }

      return (
        new Date(filterStartDate).getTime() / 1000 < timecreated &&
        timecreated < new Date(filterEndDate).getTime() / 1000
      );
    });

    console.log("product", searchedProduct);
    this.setState({
      filteredData: searchedProduct
    });
    this.handleClose();
  };

  // on search
  search = e => {
    let keyword = e.target.value;
    this.setState({
      search: keyword
    });
    if (keyword.trim()) {
      let searchedProduct = this.state.data.filter(({ name }) =>
        name.toLowerCase().includes(keyword.toLowerCase())
      );
      console.log("filter", searchedProduct);
      this.setState({
        filteredData: searchedProduct
      });
    } else {
      this.setState({
        filteredData: this.state.data
      });
    }
  };

  getQuizes = (loading = true) => {
    const { courses } = this.props;
    if (loading) {
      this.setState({ loading });
    }
    let url = `${baseUrl}wsfunction=mod_quiz_get_quizzes_by_courses&moodlewsrestformat=json&`;
    courses.forEach((course, i) => {
      if (course.id !== 1) {
        url += `courseids[${i}]=${course.id}&`;
      }
    });
    const trimmedUrl = url.slice(0, -1);
    console.log("url", trimmedUrl);
    api(trimmedUrl, post, null, this.getQuizesApiSuccess);
  };

  getQuizesApiSuccess = res => {
    this.setState({ loading: false });
    this.props.setQuizDetails(res.data.quizzes);
    this.setState({
      data: res.data.quizzes,
      filteredData: res.data.quizzes.reverse()
    });
  };

  getCourses = () => {
    this.setState({ loading: true });
    const url = `${baseUrl}&wsfunction=core_course_get_courses&moodlewsrestformat=json`;
    api(url, post, null, this.apiSuccess);
  };

  // success of api
  apiSuccess = res => {
    this.props.setCourseDeatils(res.data);
    this.getQuizes();
  };

  // delete quiz
  deleteQuiz = (cId, cmId, id) => {
    this.setState(prevState => ({
      deleteId: [...prevState.deleteId, cmId]
    }));
    const url = `${customBaseUrl}wsfunction=local_servifywebapis_delete_quiz&moodlewsrestformat=json&courseid=${cId}&cmid=${cmId}`;
    api(url, post, null, this.successDeleteApi);
  };

  successDeleteApi = res => {
    console.log(res);
    this.getQuizes(false);
  };

  getSelected = selected => {
    this.setState({ selected });
  };

  // delete selected courses
  deleteSelected = () => {
    if (this.state.selected.length > 0) {
      let url = `${baseUrl}wsfunction=core_course_delete_modules&moodlewsrestformat=json&`;
      this.state.selected.forEach((id, i) => {
        url += `cmids[${i}]=${id}&`;
        this.setState(prevState => ({
          deleteId: [...prevState.deleteId, id],
          deleteLoading: true
        }));
      });
      console.log(url.slice(0, -1));
      const trimmedUrl = url.slice(0, -1);
      api(trimmedUrl, post, null, this.successDeleteApi);
    }
  };

  componentWillMount() {
    this.props.setTabAction("Quiz Management");
    if (this.props.courses.length === 0) {
      this.getCourses();
    } else {
      this.getQuizes();
    }
  }

  componentDidMount() {
    console.log("windows", is.windows());
  }

  render() {
    const Loader = (
      <FlexContainer justifyContent={"center"} width={"100%"}>
        <Label>Loading Quiz details ...</Label>
      </FlexContainer>
    );
    const { classes } = this.props;
    const {
      data,
      loading,
      filteredData,
      deleteId,
      filterStartDate,
      filterEndDate,
      showDelete,
      path
    } = this.state;
    const windowPlatform = is.windows();

    return (
      <div>
        <Header />
        <Body>
          <CT left={"25px"} right={"40px"} m_margin={"0px"} m_padding={"0px"}>
            {path && <Redirect to={path} />}
            <DateFilter
              open={this.state.open}
              handleClose={this.handleClose}
              startDate={filterStartDate}
              endDate={filterEndDate}
              onDateChange={this.onDateChange}
              onClick={this.onClick}
            />
            <div className="row">
              <div
                className="col-sm -12 col-md-6"
                style={{ padding: "32px 8px" }}
              >
                <FlexContainer m_justifyContent={"center"}>
                  <Heading text={"Quiz / Test Management"} />
                </FlexContainer>
              </div>
              <div
                className="col-sm-12 col-md-6 "
                style={{ padding: "32px 8px" }}
              >
                <Container
                  left="0px"
                  m_margin="29px 0px 29px 0px"
                  bg="inherit"
                  m_padding={"15px"}
                >
                  <Search value={this.state.search} onChange={this.search} />
                </Container>
              </div>
            </div>

            <div style={{ padding: "32px 8px", backgroundColor: "white" }}>
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <CustomButton
                    bg="white"
                    color="#757575"
                    width="180px"
                    m_width={"100%"}
                    onClick={this.handleClickOpen}
                  >
                    Filter By
                    <img
                      src={require("../../assets/icons/Filter.png")}
                      alt="filter"
                      style={{ marginLeft: "30px" }}
                    />
                  </CustomButton>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <FlexContainer
                    justifyContent="flex-end"
                    m_justifyContent="flex-start"
                    m_direction={"column"}
                  >
                    <Container left="0px" m_width={"100%"}>
                      {showDelete && (
                        <CustomButton
                          bg="white"
                          border="1px solid #4c10c1"
                          color="#4c10c1"
                          onClick={this.deleteSelected}
                          m_width={"100%"}
                        >
                          {" "}
                          Delete Selected
                        </CustomButton>
                      )}
                    </Container>
                    <Container left="0px" top="0px" m_width={"100%"}>
                      {/* <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classes.smallText}
                        style={{
                          marginLeft: "16px",
                          height: "40px",
                          borderRadius: "2px",
                          fontSize: "12pt",
                          fontFamily: "Gotham"
                        }}
                      >
                        <Link
                          to="/groupManagement/addGroup"
                          className={classes.Link}
                        >
                          Create Group
                        </Link>
                      </Button> */}
                      <CustomButton
                        border="1px solid #4c10c1"
                        onClick={() => this.redirect("/quizManagement/addQuiz")}
                        m_width={"100%"}
                      >
                        {" "}
                        Create New Quiz
                      </CustomButton>
                    </Container>
                  </FlexContainer>
                </div>
              </div>

              <Container
                // m_width={"500px"}
                left="0px"
                right={"0px"}
                m_margin="29px 0px 29px 0px"
                bg="inherit"
                m_flow={"scroll"}
              >
                <Container
                //  m_width={"500px"}
                >
                  {loading ? Loader : (
                    // <Table
                    //   courseData={filteredData}
                    //   deleteCourse={this.deleteQuiz}
                    //   deleteId={deleteId}
                    //   getSelected={this.getSelected}
                    //   deleteLoading={this.state.deleteLoading}
                    //   toggleDelete={this.toggleShowDelete}
                    //   />
                    <NewTable
                      courseData={filteredData}
                      deleteCourse={this.deleteQuiz}
                      deleteId={deleteId}
                      getSelected={this.getSelected}
                      deleteLoading={this.state.deleteLoading}
                      toggleDelete={this.toggleShowDelete}
                    />
                  )}
                </Container>
              </Container>
            </div>
          </CT>
        </Body>
      </div>
    );
  }
}

TopicManagement.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  courses: state.course.courses
});

export default connect(
  mapStateToProps,
  { setTabAction, setCourseDeatils, setQuizDetails }
)(withStyles(styles)(TopicManagement)); 
