import React from "react";
import styled from "styled-components";

// custom components
import Chart from "./Chart";

// styled components
import DIV from "../../styledcomponents/DIV";
import FC from "../../styledcomponents/FlexContainer";
import PW from "../../styledcomponents/PointerWrapper";
import BG from "../../styledcomponents/BG";

// images
import ArrowRight from "../../../assets/icons/down_blue.png";
import GreenDOT from "../../../assets/icons/green_dot.svg";
import YelloDot from "../../../assets/icons/yellow_dot.svg";
import grayFlag from "../../../assets/icons/gray_flag.svg";
import redFlag from "../../../assets/icons/red_flag.svg";

// css
import "../css/dashboard.css";
import { relative } from "path";

const POSITION = styled.div`
  font-family: system-ui;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #757575;
  margin-right: 20px;
`;

const NAME = styled.div`
  font-family: system-ui;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #757575;
`;

const VALUE = styled.div`
  width: 58px;
  height: 30px;
  font-size: 25px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.6;
  letter-spacing: normal;
  text-align: center;
  color: #393939;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: -28px;
`;

const CHARTWRAPPER = styled.div`
  width: 150px;
  height: 150px;
  position: relative;
  margin-right: ${props => props.right};
`;

const styles = {
  DIV: {
    padding: "23px",
    bg: "white",
    shadow: "0 2px 6px 0 rgba(0, 0, 0, 0.04)",
    height: "100%"
  },
  header: {
    fsize: "12px",
    color: "#757575"
  },
  key: {
    fsize: "28px",
    color: "#757575"
  },
  value: {
    fsize: "16px",
    color: "#4bd800"
  },
  fc: {
    wrap: "no-wrap"
  },
  link: {
    fsize: "12px",
    color: "#4c10c1"
  },
  badge: {
    height: "242px",
    width: "242px"
  }
};

export default function Card({ header, details, total, value }) {
  console.log("details", details);

  return (
    <DIV {...styles.DIV}>
      <FC justifyContent={"space-between"}>
        <DIV {...styles.header}>Sort by:</DIV>
        <DIV {...styles.link}>
          <PW>
            In Progress
            <img src={ArrowRight} alt="" style={{ marginLeft: "6px" }} />
          </PW>
        </DIV>
      </FC>

      <DIV mtop={"23px"}>
        <DIV>
          <FC alignItems={"center"} justifyContent={"space-between"} wrap={"no-wrap"}>
            <FC wrap={"no-wrap"}>
              <img src={YelloDot} alt="" style={{ marginRight: "20px" }} />
              <FC direction={"column"}>
                <DIV {...styles.header}>10.00 AM</DIV>
                <DIV {...styles.header}>Take Course </DIV>
              </FC>
            </FC>
            <img src={grayFlag} alt="" />
          </FC>
        </DIV>
        <DIV mtop={"23px"}>
          <FC alignItems={"center"} justifyContent={"space-between"} wrap={"no-wrap"}>
            <FC wrap={"no-wrap"}>
              <img src={YelloDot} alt="" style={{ marginRight: "20px" }} />
              <FC direction={"column"}>
                <DIV {...styles.header}>12.20 PM</DIV>
                <DIV {...styles.header}>Update your Profile</DIV>
              </FC>
            </FC>
            <img src={redFlag} alt="" />
          </FC>
        </DIV>
        <DIV mtop={"23px"}>
          <FC alignItems={"center"} justifyContent={"space-between"} wrap={"no-wrap"}>
            <FC wrap={"no-wrap"}>
              <img src={YelloDot} alt="" style={{ marginRight: "20px" }} />
              <FC direction={"column"}>
                <DIV {...styles.header}>10.00 AM</DIV>
                <DIV {...styles.header}>Update your Profile</DIV>
              </FC>
            </FC>
            <img src={redFlag} alt="" />
          </FC>
        </DIV>
        <DIV mtop={"23px"}>
          <FC alignItems={"center"} justifyContent={"space-between"} wrap={"no-wrap"}>
            <FC wrap={"no-wrap"}>
              <img src={YelloDot} alt="" style={{ marginRight: "20px" }} />
              <FC direction={"column"}>
                <DIV {...styles.header}>10.00 AM</DIV>
                <DIV {...styles.header}>Update your Profile</DIV>
              </FC>
            </FC>
            <img src={grayFlag} alt="" />
          </FC>
        </DIV>
        <DIV mtop={"23px"}>
          <FC alignItems={"center"} justifyContent={"space-between"} wrap={"no-wrap"}>
            <FC wrap={"no-wrap"}>
              <img src={YelloDot} alt="" style={{ marginRight: "20px" }} />
              <FC direction={"column"}>
                <DIV {...styles.header}>10.00 AM</DIV>
                <DIV {...styles.header}>Update your Profile</DIV>
              </FC>
            </FC>
            <img src={grayFlag} alt="" />
          </FC>
        </DIV>
      </DIV>

      {/* ------------------------------------------------------------------ */}
    </DIV>
  );
}
