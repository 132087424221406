import React, { Component } from "react";
import Header from "../subComponents/Header";
import Body from "../Home/subComponents/Body";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Redirect } from "react-router-dom";
import Loader from "../subComponents/Loader";
import Paper from "@material-ui/core/Paper";
import { post, baseUrl } from "../../config/apiUrl";
import api from "../../utils/api";
import MediaQuery from "react-responsive";
import MobileHeader from "../Home/subComponents/MobileHeader";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";

import back from "../../assets/icons/back.png";
import FlexContainer from "../../consumercomponents/styledcomponents/FlexContainer";
import PointerWrapper from "../../consumercomponents/styledcomponents/PointerWrapper";
import CT from "../../consumercomponents/styledcomponents/CT";

const styles = theme => ({
  container: {
    width: "100%"
  }
});

class ViewPage extends Component {
  state = {
    path: null,
    data: [],
    initialLoading: false
  };

  handleGoback = () => {
    const id = this.props.match.params.courseId;
    this.setState({ path: `/articleManagement/topicManagement/${id}` });
  };

  getPageInfo = () => {
    this.setState({
      initialLoading: true
    });
    const id = this.props.match.params.courseId;
    const moduleId = this.props.match.params.id;
    const url = `${baseUrl}wsfunction=mod_page_get_pages_by_courses&moodlewsrestformat=json&courseids[0]=${id}`;
    api(url, post, null, res => {
      if (res.data.pages) {
        const data = res.data.pages.filter(
          ({ coursemodule }) => coursemodule === parseInt(moduleId)
        );
        console.log("data", data);
        this.setState({ data, initialLoading: false });
      }
    });
  };

  componentWillMount() {
    this.getPageInfo();
  }

  render() {
    const { classes } = this.props;
    const { path, initialLoading, data } = this.state;
    let content;
    if (data.length > 0) {
      content = data.map((page, i) => {
        return (
          <div style={{ padding: "16px" }} key={i}>
            <h2>{ReactHtmlParser(page.name)}</h2>
            <h4>{ReactHtmlParser(page.intro)}</h4>
            <div style={{ objectFit: "contain" }}>
              {ReactHtmlParser(page.content)}
            </div>
          </div>
        );
      });
    } else {
      content = (
        <div style={{ padding: "16px", textAlign: "center" }}>
          <p>No Content</p>
        </div>
      );
    }
    return (
      <div>
        {path && <Redirect to={path} />}
        <Header />

        <Body>
          <CT left={"25px"} right={"40px"} m_margin={"0px"}>
            <div className={classes.container}>
              <FlexContainer margin={"40px 0 1rem 0"} m_justifyContent="center">
                <div onClick={this.handleGoback}>
                  <PointerWrapper>
                    <img src={back} alt="back" />
                  </PointerWrapper>
                </div>
              </FlexContainer>
              <Paper>
                {initialLoading && data.length === 0 ? <Loader /> : content}
              </Paper>
            </div>
          </CT>
        </Body>
      </div>
    );
  }
}

export default withStyles(styles)(ViewPage);
