import React, { Component } from 'react';
import TimeInput from "material-ui-time-picker";

export default class TimePicker extends Component {
                 state = {
                   time: new Date()
                 };

                 handleChange = time => {
                   this.setState({ time });
                 };
                 render() {
                   return (
                     <div>
                       <TimeInput
                         mode="12h"
                         value={this.state.time}
                         onChange={time =>
                           this.handleChange(time)
                         }
                       />
                     </div>
                   );
                 }
               }
