import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "../Home/subComponents/ListItem";
import Snackbar from "@material-ui/core/Snackbar";
import { connect } from "react-redux";
import { resetSnackbar } from "../../actions/snackbarAction";

// styled components
import COL from "../../consumercomponents/styledcomponents/Col"

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "fill-available"
  },
  customGrid: {
    height: "fill-available",
    marginRight: "16px"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    height: "100%"
  },

  backgroundGray: {
    backgroundColor: "#EEEEEE",
    padding: "2rem"
  }
});

function CenteredGrid(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Snackbar
        variant="success"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={props.snackbarOpen}
        onClose={props.resetSnackbar}
        autoHideDuration={2000}
        message={props.snackbarMessage}
      />

      <div className="row">
        <div className="col-sm -12 col-md-2">
          <List name={"Dashboard"} />
          {/* <List name={"User & Groups"} child={true} /> */}
          <List name={"Course Management"} path={"/articleManagement"} />
          <List name={"Quiz Management"} path={"/quizManagement"} />
          {/* <List name={"BoardCasting"} />
          <List name={"Trigger Notifications"} /> */}
          <List
            name={"User Management"}
            child={true}
            childNames={["Groups", "Users"]}
            paths={["/groupManagement", "/userManagement"]}
          />
          <List name={"Role Access"} />
          {/* <List name={"Gamifications"} />
          <List name={"Compliance"} /> */}
          <List name={"Consumer Design(This link is for demonstrate purpose)"} path="/consumer/name/id/courses"/>
          <List name={"Connect to 360 App for reference Article"} />
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/images/footer.PNG")} alt="" />
          </div>
        </div>
        {/* <div
          className="col-sm-12 col-md-10 "
          style={{ backgroundColor: "#EEEEEE", padding: "32px" }}
        >
          
        </div> */}
        <COL cl={"col-sm-12 col-md-10"}>
        {props.children}
        </COL>
      </div>

      {/* <Grid container spacing={8} className={classes.customGrid}>
        <Grid item xs={2}>
          <div
            className={classes.paper}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between"
            }}
          >
            <div>
              <List name={"Dashboard"} />
              <List name={"User & Groups"} child={true} />
              <List name={"Topic Management"} path={"/topicManagement"} />
              <List name={"Course Management"} path={"/articleManagement"} />
              <List
                name={"Quiz / Test Management"}
                path={"/quizManagement"}
              />
              <List name={"BoardCasting"} />
              <List name={"Trigger Notifications"} />
              <List
                name={"User Management"}
                child={true}
                childNames={["Groups", "Users"]}
                paths={["/groupManagement", "Users"]}
              />
              <List name={"Role Access"} />
              <List name={"Gamifications"} />
              <List name={"Compliance"} />
              <List name={"Connect to 360 App for reference Article"} />
            </div>
            <div style={{ textAlign: "center" }}>
              <img src={require("../../assets/images/footer.PNG")} alt="" />
            </div>
          </div>
        </Grid>
        <Grid item xs={10}>
          <div className={[classes.paper, classes.backgroundGray].join(" ")}>
            {props.children}
          </div>
        </Grid>
      </Grid> */}
    </div>
  );
}

CenteredGrid.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  snackbarMessage: state.snackbar.message,
  snackbarOpen: state.snackbar.open
});

export default connect(
  mapStateToProps,
  { resetSnackbar }
)(withStyles(styles)(CenteredGrid));
