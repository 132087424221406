import styled from "styled-components";

export default styled.div`
  height: ${props => props.height};
  width: ${props => props.width};
  min-width: ${props => props.width};

  padding-left: ${props => props.pleft};
  padding-right: ${props => props.pright};
  padding-top: ${props => props.ptop};
  padding-bottom: ${props => props.pbottom};
  margin-left: ${props => props.mleft};
  margin-right: ${props => props.mright};
  margin-top: ${props => props.mtop};
  margin-bottom: ${props => props.mbottom};
  margin: ${props => props.margin};
  background-color: ${props => props.bg ? props.bg : "inherit"};
  padding: ${props => props.padding};
  color: ${props => props.color ? props.color : "#757575"};
  font-size: ${props => props.fsize ? props.fsize : "14px"};
  font-weight: ${props => props.fweight ? props.fweight : 400};
  box-shadow: ${props => props.shadow};
  line-height: ${props => props.lh};
  border-radius: ${props => props.radius};
  border: ${props => props.border};


  



  @media screen and (max-width: 800px) {
    margin: ${props => props.m_margin};
    padding: ${props => props.m_padding};
    overflow: ${props => props.m_overflow};
  max-width: ${props => props.maxwidth};
  width: ${props => props.m_width};


  }
`;
