import React, { Component } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";

// styled components
import FC from "../../../consumercomponents/styledcomponents/FlexContainer";
import CT from "../../../consumercomponents/styledcomponents/Container";
import PW from "../../../consumercomponents/styledcomponents/PointerWrapper";
import BUTTON from "../../../consumercomponents/styledcomponents/Button";

//images
import LeftArrow from "../../../assets/icons/leftarrow.png";
import RightArrow from "../../../assets/icons/rightarrow.png";
import EditIcon from "../../../assets/icons/Edit.png";
import DeleteIcon from "../../../assets/icons/delete.png";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import QuestionIcon from "@material-ui/icons/QuestionAnswer";

// material components
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import PersonIcon from "@material-ui/icons/PersonAdd";

// css
import "./table.css";

// utils
import unixTime from "../../../utils/unixTime";

const THEAD = styled.thead`
  height: 46px;
  background-color: #f2f2f2;
  border: none;
`;

const TH = styled.th`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
  text-align: left;
  color: #757575;
  width: ${props => props.width && props.width}!important;
  text-align: ${props => (props.align ? props.align : "left")};
  font-weight: 500;
  padding-left: ${props => props.left};

  height: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  text-transform: uppercase;
`;

const TD = styled.td`
  height: 14px;
  font-size: 12px;
  line-height: 1.67;
  padding-left: ${props => props.left};
  color: #757575;
  text-align: ${props => props.align};
  /* &:first-child {
    padding-left: 20px;
  } */
`;

const DROPDOWN = styled.div`
  width: 180px;
  border: 1px solid #ccc;
  background-color: white;
  display: ${props => (props.display ? "block" : "none")};
`;
const OPTION = styled.div`
  width: 100%;
  height: 46px;
  cursor: pointer;
  padding-left: 34px;
  display: flex;
  align-items: center;
  opacity: 0.5;
  font-size: 14px;
  line-height: 2.29;
  text-align: center;
  color: #757575;

  &:hover {
    background-color: #cccccc;
  }
`;

const TR = styled.tr`
  height: 50px;
  border-bottom: 0.2px solid #cccccc;

  &:last-child {
    border-bottom: none;
  }
`;

const TABLE = styled.table`
  width: 100%;
  background-color: #ffffff;
  overflow: scroll;
  min-width: 500px;
`;

const PG_WRAPPER = styled.div`
  display: flex;
  border: 1px solid #cccccc;
  flex-wrap: wrap;
`;

const PG = styled.div`
  height: 46px;
  width: 46px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-right: 1px solid #cccccc;
  background-color: ${props => (props.selected ? "#4C10C1" : "white")};
  color: ${props => (props.selected ? "white" : " #757575")};

  &:hover {
    background-color: ${props => !props.selected && "#cccccc"};
  }
`;

const PAGEINFOTEXT = styled.span`
  height: 17px;
  font-size: 14px;
  line-height: 2;
  text-align: left;
  color: #757575;
  margin-right: 23px;
`;

const Test = styled.div`
  /* overflow-x: hidden; */
  overflow-x: scroll;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

/* <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------- --!> */

const Sort = props => (
  <CT {...props}>
    <FC direction="column" m_direction="column">
      <PW onClick={() => props.sort(props.sortBy, "b")}>
        <i class="fas fa-angle-up" style={{ height: "10px" }} />
      </PW>
      <PW onClick={() => props.sort(props.sortBy, "a")}>
        <i class="fas fa-angle-down" style={{ height: "10px" }} />
      </PW>
    </FC>
  </CT>
);

export default class NewTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedB: false,
      showPageLimit: false,
      currentpageValue: 10,
      pageCounter: 1,
      pageToDisplay: 8,
      selectedPageValue: 1,
      checkedAll: false,
      selected: [],
      path: null,
      data: {
        th: [
          {
            sort: false,
            name: "USER NAMES"
          },
          {
            sort: true,
            name: "ROLE",
            sortBy: "role2",
            type: "string"
          },

          {
            sort: true,
            name: "ARTICLES ASSIGNED",
            sortBy: "timecreated",
            type: "number"
          },
          {
            sort: true,
            name: "QUIZZES ASSIGNED",
            sortBy: "timecreated",
            type: "number"
          },

          {
            sort: false,
            name: "ACTIONS"
          }
        ],
        td: props.courseData
      }
    };
  }

  redirect = path => this.setState({ path });
  setSelected = id => {
    this.setState(prevState => ({ selected: [...prevState.selected, id] }));
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  setChackedAll = () => {
    this.setState(prevState => {
      this.props.toggleDelete(!prevState.checkedAll);
      if (!prevState.checkedAll) {
        if (this.state.data.td.length > 0) {
          let ids = this.state.data.td.map(({ id }) => id);
          this.props.getSelected(ids);
          ids.forEach(id => this.setSelected(id));
        }
      }
      return {
        checkedAll: !prevState.checkedAll,
        selected: prevState.checkedAll ? [] : [...prevState.selected]
      };
    });
  };

  isSelected = id => {
    const boolValue = this.state.selected.indexOf(id) !== -1;
    // if(boolValue){this.props.toggleDelete();}
    return boolValue;
  };

  setCurrentPageValue = value => {
    this.setState({
      currentpageValue: value,
      showPageLimit: false
    });
  };

  changeSelectedPageValue = value => {
    this.setState(prevState => {
      return {
        selectedPageValue: value,
        pageCounter: (value - 1) * (prevState.currentpageValue - 1)
      };
    });
  };

  togglePageLimit = () => {
    this.setState(prevState => {
      console.log(!prevState.showPageLimit);
      const showPageLimit = !prevState.showPageLimit;
      return { showPageLimit };
    });
  };

  //   sortBy = (value, type) => {
  //     const td =
  //       type === "a"
  //         ? this.state.data.td.sort((a, b) => a[value] - b[value])
  //         : this.state.data.td.sort((a, b) => b[value] - a[value]);
  //     const data = this.state.data;
  //     data.td = td;
  //     console.log(data);
  //     this.setState({ data });
  //      };

  sortBy = (value, type, dataType) => {
    let td;
    if (dataType === "number") {
      td =
        type === "a"
          ? this.state.data.td.sort((a, b) => a[value] - b[value])
          : this.state.data.td.sort((a, b) => b[value] - a[value]);
    } else {
      td =
        type === "a"
          ? this.state.data.td.sort((a, b) =>
              a[value].toLowerCase().localeCompare(b[value].toLowerCase())
            )
          : this.state.data.td.sort((a, b) =>
              b[value].toLowerCase().localeCompare(a[value].toLowerCase())
            );
    }
    const data = this.state.data;
    data.td = td;
    this.setState({ data });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.courseData.length !== prevState.data.td.length) {
      return { data: { ...prevState.data, td: nextProps.courseData } }; // <- this is setState equivalent
    }
  }

  //editCourse
  editCourse = (id, cmId, cId) => {
    this.setState({
      path: `quizManagement/editQuiz/${id}/${cmId}/${cId}`
    });
  };

  changeCheckox = id => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
    this.props.getSelected(newSelected);
    this.props.toggleDelete(newSelected.length > 0 ? true : false);
  };

  render() {
    const styles = {
      default: {
        top: 0,
        right: 0,
        bottom: 0,
        left: "10px",
        bg: "inherit"
      },
      pagination: {
        ...this.default,
        left: 0,
        top: "40px",
        bg: "inherit"
      }
    };

    const {
      path,
      showPageLimit,
      selectedPageValue,
      data,
      pageCounter,
      currentpageValue
    } = this.state;

    const { deleteId, deleteCourse } = this.props;

    //     const Sort = (
    //       <CT {...styles.default}>
    //         <FC direction="column">
    //           <PW>
    //             <i class="fas fa-angle-up" style={{ height: "10px" }} />
    //           </PW>
    //           <PW>
    //             <i class="fas fa-angle-down" style={{ height: "10px" }} />
    //           </PW>
    //         </FC>
    //       </CT>
    //     );

    let pageDisplayValue;
    switch (this.state.currentpageValue) {
      case 10:
        pageDisplayValue = "10 / Page";
        break;
      case 20:
        pageDisplayValue = "20 / Page";
        break;
      case 30:
        pageDisplayValue = "30 / Page";
        break;

      default:
        break;
    }

    let pageInfo = [
      <PG>
        <img src={LeftArrow} alt="left" />
      </PG>
    ];

    let exceedReminder = false;
    for (let index = 1; index <= this.state.pageToDisplay; index++) {
      console.log("sdds", this.state.currentpageValue * index);
      if (index === 1 || !exceedReminder) {
        if (this.state.currentpageValue * index >= this.state.data.td.length)
          exceedReminder = true;

        pageInfo = [
          ...pageInfo,
          <PG
            key={index}
            onClick={() => this.changeSelectedPageValue(index)}
            selected={selectedPageValue === index}
          >
            {index}
          </PG>
        ];
      }
    }

    pageInfo = [
      ...pageInfo,
      <PG>
        <img src={RightArrow} alt="left" />
      </PG>
    ];

    let renderedRow = 0;
    console.log("state", this.state);
    console.log("props", this.props.courseData);

    const Action = (
      <FC justifyContent={"center"}>
        <IconButton>
          <EyeIcon color="primary"/>
        </IconButton>
      </FC>
    );

    return (
      <div
        style={{
          overflow: "hidden"
        }}
      >
        {path && <Redirect to={path} />}
        <Test
          style={{
            overflowX: "scroll",
            width: "100% "
          }}
        >
          <TABLE>
            <THEAD>
              <TR>
                <TH width={"4%"} />

                {data.th.map((t, i) => {
                  return (
                    <TH
                      key={i}
                      width={i !== 0 ? "23%" : "12%"}
                      left={t.name == "actions" && "0.65vw"}
                      align="center"
                    >
                      <FC
                        alignItems="center"
                        justifyContent={i !== 0 ? "center" : "flex-start"}
                        wrap={"no-wrap"}
                      >
                        {t.name}
                        {t.sort && (
                          <Sort
                            {...styles.default}
                            sort={this.sortBy}
                            sortBy={t.sortBy ? t.sortBy : ""}
                          />
                        )}
                      </FC>
                    </TH>
                  );
                })}
              </TR>
            </THEAD>
            <tbody>
              {data.td.map((t, i) => {
                const index = i + 1;
                if (index >= pageCounter) renderedRow++;

                if (index >= pageCounter && renderedRow <= currentpageValue) {
                  return (
                    <TR key={i}>
                      <TD />
                      <TD align="left">{t.username}</TD>
                      <TD align="center">{t.role2}</TD>
                      <TD align="center">0</TD>
                      <TD align="center">{0}</TD>
                      <TD align="center">{Action}</TD>
                    </TR>
                  );
                }
              })}
            </tbody>
          </TABLE>
        </Test>

        <CT {...styles.pagination}>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <FC m_justifyContent={"center"}>
                <FC
                  direction="column"
                  m_direction="column"
                  m_alignItems={"center"}
                >
                  <BUTTON
                    bg="white"
                    color="#757575"
                    width="180px"
                    onClick={this.togglePageLimit}
                  >
                    {pageDisplayValue}
                    <img
                      src={require("../../../assets/icons/down.png")}
                      alt=""
                      style={{ marginLeft: "32px" }}
                    />
                  </BUTTON>
                  <DROPDOWN display={showPageLimit}>
                    <OPTION onClick={() => this.setCurrentPageValue(10)}>
                      10 / Page
                    </OPTION>
                    <OPTION onClick={() => this.setCurrentPageValue(20)}>
                      20 / Page
                    </OPTION>
                    <OPTION onClick={() => this.setCurrentPageValue(30)}>
                      30 / Page
                    </OPTION>
                  </DROPDOWN>
                </FC>
              </FC>
            </div>
            <div className="col-sm-12 col-md-6">
              <FC
                justifyContent={"flex-end"}
                m_direction={"column"}
                m_alignItems={"center"}
                m_margin={"14px 0 0 0"}
              >
                <PAGEINFOTEXT>
                  1 to {this.state.currentpageValue} of{" "}
                  {this.state.data.td.length}
                </PAGEINFOTEXT>
                <CT m_margin="20px">
                  <PG_WRAPPER>{pageInfo}</PG_WRAPPER>
                </CT>
              </FC>
            </div>
          </div>
        </CT>
      </div>
    );
  }
}
